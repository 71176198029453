import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';

const AddDomicile = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
  return (
    <>
      {/*----- Main Container For domicile Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-domicile-form position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)">
                  <img src="./assets/images/icon/BackIcon.svg" onClick={() => navigate(-1)} />
                </a>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Your Address</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>
                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-3 w-75 mx-auto text-white">Please enter your address</h3>
            <p className="text-dark-gray w-75 mx-auto">Please enter your physical address</p>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure domicile Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Add Domicile Form Start -----*/}
            <div className="AddDomicileform">
              <form action="#">
                <ul className="vs-domicileList list-unstyled pt-4 rounded-4">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Name of domicile" name="domicile" {...register("domicile", { required: true })} />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Name of domicile</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                      {/* {errors.domicile && errors.domicile.message && <span>This field is required</span>}
                      {<span className="m-0 f-error ">{errors.name && errors.name.message}</span>} */}
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Description" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-dark-gray">Description</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf3" placeholder="Street address" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf3" className="h-auto text-dark-gray">Street address</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf4" placeholder="Suburb" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf4" className="h-auto text-dark-gray">Suburb</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf5" placeholder="City" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf5" className="h-auto text-dark-gray">City</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf6" placeholder="Province (optional)" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf6" className="h-auto text-dark-gray">Province
                        (optional)</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf7" placeholder="Post code" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf7" className="h-auto text-dark-gray">Post code</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Name of domicile" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Name of domicile</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Description" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-dark-gray">Description</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf3" placeholder="Street address" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf3" className="h-auto text-dark-gray">Street
                        address</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf4" placeholder="Suburb" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf4" className="h-auto text-dark-gray">Suburb</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf5" placeholder="City" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf5" className="h-auto text-dark-gray">City</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf6" placeholder="Province (optional)" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf6" className="h-auto text-dark-gray">Province
                        (optional)</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 pt-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf7" placeholder="Post code" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf7" className="h-auto text-dark-gray">Post code</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
            {/*----- Add Domicile Form End -----*/}
          </section>
          {/*----- Vodasure domicile Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <form action="#" className="btn-width mx-auto">
              <Link to="/domicile-map" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-100">
                Confirm
              </Link>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For domicile End -----*/}
    </>
  )
}

export default AddDomicile