import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfirmAsset from "./ConfirmAsset";
import ObjectSelectCanvas from "./ObjectSelectCanvas";
import Cookies from "js-cookie";
import exifr from "exifr";
import {
  calculateRadius,
  checkWithinRadius,
} from "../../Utility/CalculateRadius";
import { GetDataWithToken } from "../../ApiHelper/ApiHelper";
import { toast } from "material-react-toastify";
import Loader from "../../common/Loader";

const CheckAsset = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  // console.log("qwerty", location)
  const imgPreviewURL = location?.state?.imgPreview;
  const assetsImage = location?.state?.img;
  const assetLatLngAdd = location?.state?.assetLatLngAdd;
  // console.log("assetLatLngAdd", assetLatLngAdd);
  const [callApi, setCallApi] = useState(true);
  const [isWithinRadius, setIsWithinRadius] = useState(true);
  // console.log("isWithinRadius", isWithinRadius)
  const [base64Image, setBase64Image] = useState('');
  const [addressLatLng, setAddressLatLng] = useState({
    lat: null,
    lng: null,
  });

  // console.log("addressLatLng", addressLatLng);
  const [assetLatLng, setAssetLatLng] = useState({
    lat: null,
    lng: null,
  });
  console.log("assetLatLng", assetLatLng)

  const [reTake, setReTake] = useState(false);
  const [retakeAgain, setRetakeAgain] = useState(false);
  // console.log("retake", retakeAgain);
  const [reTakeImage, setReTakeImage] = useState(null);
  const [reTakeImagePreview, setReTakeImagePreview] = useState(null);

  const [selectObjectCanvas, setSelectObjectCanvas] = useState(false);
  const selectObjectCanvasClose = () => setSelectObjectCanvas(false);

  const [selectObject, setSelectObject] = useState([]);

  const [selectAssets, setSelectAssets] = useState("");
  const [latLng, setlatlLng] = useState({});
  // console.log("latLng", latLng);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(showPosition);
  }, []);

  const showPosition = (position) => {
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    setlatlLng({ latitude, longitude });
  };

  const objectDetect = `https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCGmrsyl4cNgdSzXELMguT82taHAhXvNk4`;

  useEffect(() => {
    // if (loader) {
    let reader = new FileReader();
    if (retakeAgain == true) {
      reader.readAsDataURL(reTakeImage);
    } else {
      reader.readAsDataURL(assetsImage);
    }
    setLoader(true);
    reader.onloadend = function () {
      let base64data = reader.result;
      // console.log("64data", base64data.split(/[\s,]+/));
      // let arr = base64data.substring(23, base64data?.length);
      let arr = base64data.split(/[\s,]+/);
      setBase64Image(arr[1]);
      // console.log("base64data", arr);

      let objData = {
        requests: [
          {
            image: {
              content: arr[1],
            },
            features: [
              {
                type: "OBJECT_LOCALIZATION",
                maxResults: 5,
              },
            ],
          },
        ],
      };
      axios.post(objectDetect, objData).then((responses) => {
        setSelectObject(
          responses?.data?.responses?.[0]?.localizedObjectAnnotations
        );
        // console.log("responses", responses?.data?.responses?.[0]?.localizedObjectAnnotations);
        setReTake(false);
        setLoader(false);
      });
    };
    // }
  }, [reTake, assetsImage]);

  useEffect(() => {
    if (callApi) {
      GetDataWithToken("user/get-physical-address", "").then((response) => {
        // console.log("ressss", typeof (response));
        console.log("ressss", response.data);
        // response = JSON.parse(response);
        if (response.status == true) {
          setCallApi(false);
          // setLoader(false);
          // setAddressDetail(response.data);
          setAddressLatLng({
            lat: +response?.data?.lat,
            lng: +response?.data?.lng,
          });
          console.log("assets lat lng", assetLatLngAdd);
          console.log("physical address", response.data);
          // setAddressLong(response.data.lng);
          // setAddressLat(response.data.lat);
        }
      });
    }
    if (reTake == true) {
      const result = checkWithinRadius(addressLatLng, assetLatLng);
      setIsWithinRadius(result);
      console.log("retakeAgain result", result);
    } else {
      // reader.readAsDataURL(assetsImage);
      const result = checkWithinRadius(addressLatLng, assetLatLngAdd);
      setIsWithinRadius(result);
      console.log("result", result);
    }
  }, [callApi, retakeAgain]);

  const [confirmAssetCanvas, setConfirmAssetCanvas] = useState(false);
  const confirmAssetCanvasClose = () => setConfirmAssetCanvas(false);

  // const handleSearchPrice = (assetCategory) => {
  //   const checkPriceApi = `https://api-v2.pricecheck.co.za/pro-search/search?q=${assetCategory}`;
  //   axios.get(checkPriceApi)
  //     .then((response) => {
  //       // setAssetData(response?.data?.data);
  //       setAssetList(response?.data?.data);
  //       setObjectPriceCanvas(true);
  //     })
  // };

  const handleAddAssets = async (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;

      // console.log({ latLng });
      if (latLng == undefined) {
        toast(
          <>
            <div className="activityalert">
              <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                <p className="m-0">
                  <img
                    src="./assets/Newimg/redclose.png"
                    className="bluecheckimg"
                    alt
                  />
                  This image does not contain latitude and longitude
                </p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </>
        );
      } else {
        // const latLndData = {
        //   lat: latLng?.latitude,
        //   lng: latLng?.longitude,
        // }
        setAssetLatLng({
          lat: latLng?.latitude,
          lng: latLng?.longitude,
        });
        // setImageLat(latLng?.latitude);
        // setImageLong(latLng?.longitude);
        setReTake(true);
        setRetakeAgain(true);
        setReTakeImage(e.target.files[0]);
        setReTakeImagePreview(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  // const result = checkWithinRadius(addressLatLng, assetLatLng);
  // setIsWithinRadius(result);
  // console.log("setIsWithinRadius", isWithinRadius);

  const handleCheckAsset = () => {
    toast(
      <>
        <div className="activityalert">
          <div className="alert alert-dismissible fade show m-0 px-0 text-center">
            <p className="m-0">
              <img
                src="./assets/Newimg/redclose.png"
                className="bluecheckimg"
                alt
              />
              This image does not belongs at physical address.
            </p>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </>
    );
    navigate('/physical-address',
      { state: { updateAddress: true } }
    );
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {/*----- Main Container Take a photo Start -----*/}
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-addphoto vs-submission position-relative">
                {/*----- Vs Header Start -----*/}
                <div className="vs-header Withbtngroup">
                  {/*------ Header Mid Heading (Left Part) ------*/}
                  <div
                   type="button"
                    onClick={() => navigate(`/${Cookies.get("accessToken")}`)}
                    className="headericon"
                  >
                    <img src="./assets/images/icon/BackIcon.svg" />
                  </div>

                  {/*------ Header Mid Heading (Middle Part) ------*/}
                  <h4 className="vs-header-title d-none">Take a photo</h4>
                </div>
                {/*----- Vs Header end -----*/}

                {/*----- Section Content Start -----*/}
                <section className="vs-content_section h-auto text-center vs-empty px-4 flex-column justify-content-between">
                  {/*----- Captured Image Output -----*/}
                  <img
                    src={
                      retakeAgain == true ? reTakeImagePreview : imgPreviewURL
                    }
                    alt
                    className="vs-Capture"
                  />
                  {/*----- Section Random Intro Start -----*/}
                  <div className="Section_random_intro text-center mt-5 pt-5">
                    <h3 className="fs-1 w-75 mb-4 mx-auto">
                      Now, check the photo
                    </h3>
                    <p className="text-secondary fs-3 w-75 mx-auto">
                      Make sure the photos are not blurry
                      <br />
                      or obscured by any harsh light.
                    </p>
                  </div>
                </section>
                {/*----- Section Content End -----*/}
                {/*----- Basic Footer Section Start -----*/}
                <footer className="vs-floting-footer vs-footer-gradient">
                  <button
                    className="btn btn-outline-primary rounded-pill first"
                    style={{ cursor: "pointer" }}
                  >
                    <label
                      htmlFor="assetIMG"
                      className="d-block h-100 w-100 px-5 py-2"
                    >
                      Retake
                    </label>
                  </button>

                  <input
                    type="file"
                    capture="environment"
                    id="assetIMG"
                    className="d-none"
                    onChange={(e) => handleAddAssets(e)}
                  />
                  {isWithinRadius == true ? (
                    <Link
                      to="/add-asset"
                      state={{
                        assetsImage:
                          retakeAgain == true ? reTakeImage : assetsImage,
                        selectObject: selectObject,
                        base64Image: base64Image,
                      }}
                      // onClick={() => setSelectObjectCanvas(true)}
                      className="btn btn-primary rounded-pill px-5 py-3"
                    >
                      Confirm
                    </Link>
                  ) : (
                    <a
                      to="javascipt:void(0)"
                      onClick={handleCheckAsset}
                      className="btn btn-primary rounded-pill px-5 fs-3 py-3  ms-3"
                    >
                      Confirm
                    </a>
                  )}
                </footer>
                {/*----- Basic Footer Section End -----*/}
              </div>
            </div>
          </div>
          {/*----- Add New asset confirm Offcanvas -----*/}
          <ObjectSelectCanvas
            selectObjectCanvasClose={selectObjectCanvasClose}
            selectObjectCanvas={selectObjectCanvas}
            selectObject={selectObject}
            imgPreviewURL={
              retakeAgain == true ? reTakeImagePreview : imgPreviewURL
            }
            assetsImage={retakeAgain == true ? reTakeImage : assetsImage}
            setConfirmAssetCanvas={setConfirmAssetCanvas}
            setSelectAssets={setSelectAssets}
            loader={loader}
          />

          {/*----- Add New asset confirm Offcanvas -----*/}
          <ConfirmAsset
            confirmAssetCanvas={confirmAssetCanvas}
            confirmAssetCanvasClose={confirmAssetCanvasClose}
            assetsImage={retakeAgain == true ? reTakeImage : assetsImage}
            selectAssets={selectAssets}
          />

          {/*----- Main Container Take a photo End -----*/}
          {/* <SelectObject
            handleSearchPrice={handleSearchPrice}
            selectBbj={selectBbj}
            selectObjectCanvas={selectObjectCanvas}
            selectObjectCanvasClose={selectObjectCanvasClose}
            setSearchObject={setSearchObject}
          /> */}
        </>
      )}
    </>
  );
};

export default CheckAsset;
