import React from 'react'

const SuccessScreen = () => {
  return (
    <>
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-successrocket position-relative">
            <img src='./assets/images/rocketsuccess.gif' className='rocketimg' />
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessScreen