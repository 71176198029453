import React, { useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ProfileStepsCount from './ProfileStepsCount';
import Cookies from 'js-cookie';

const ProfileUnderWritingSteps = () => {
  const accessToken = Cookies.get('accessToken');
  const [stepAnswer, setStepAnswer] = useState(null);
  const [stepPercentage, setStepPercentage] = useState(1 / 11 * 100);

  const nextStep = () => {

  }

  return (
    <>
      {/*----- Main Container For Share your data Start -----*/}
      <div className="vs-main-container">
        <div className="container">
          <div className="row justify-content-center responsive-row">
            <div className="col-xl-4 col-lg-6 col-sm-9 col-12 col-md-8 responsive-col">
              <div className="vs-main-content vs-domicile-form position-relative">
                {/*----- Vs Default Header Start -----*/}
                <div className="vs-header px-4">
                  <div className="row justify-content-between align-items-center m-0">
                    <div className="col-2 text-start ps-0">
                      <Link to={`/${accessToken}`}><img src="./assets/images/icon/BackIcon.svg" /></Link>
                    </div>
                    <div className="col-7 text-center">
                      <h4 className="vs-header-title">Underwriting</h4>
                    </div>
                    <div className="col-3 text-end pe-0">
                      <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                        <a href="javascript:void(0)" className="btn border-0 me-2">
                          <img src="./assets/images/icon/HeaderMenu.svg" alt />
                        </a>
                        <a href="javascript:void(0)" className="btn border-0 ms-2">
                          <img src="./assets/images/icon/HeaderClose.svg" alt />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*----- Vs Default Header End -----*/}
                {/*----- Porgressbar For wizard form -----*/}
                <form className="slideform-form slideform-initialized">
                  <div className="slideform-footer">
                    <div className="slideform-progress-bar">
                      <ProgressBar variant="danger" now={stepPercentage} />
                    </div>
                  </div>
                </form>
                {/*----- Vodasure Share your data Content container Start -----*/}
                <section className="vs-content_section px-4 vs-acceptpolicy d-flex justify-content-between align-items-stretch flex-column">
                  {/*----- Add Share your data Form Start -----*/}
                  <div id="regForm" className="h-100">
                    <div className="h-100">
                      <ProfileStepsCount setStepAnswer={setStepAnswer} />
                    </div>
                  </div>
                </section>
                {/*----- Vodasure Share your data Content container End -----*/}
                {/*----- Basic Footer Section Start -----*/}
                <footer className="vs-floting-footer vs-footer-gradient">
                  <div className="d-flex btn-width mx-auto justify-content-between align-items-center slideform-form">
                    <button disabled={stepAnswer == null} onClick={() => nextStep()} className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-100 ms-3 slideform-btn slideform-btn-next d-block">
                      Next
                    </button>
                  </div>
                </footer>
                {/*----- Basic Footer Section End -----*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*----- Main Container For Share your data End -----*/}
    </>
  )
}

export default ProfileUnderWritingSteps