import React from 'react'
import { Link } from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas';

const RequestQuoteModal = (props) => {
  return (
    <>
      {/*----- Gat a new quote options offcanvas -----*/}
      <Offcanvas show={props.reqQuoteCanvas} onHide={props.reqQuoteCanvasClose} scroll={false} placement='bottom'>
        {/*----- Offcanvas Header -----*/}
        <div className="offcanvas-header px-5 pt-5">
          <button type="button" className="btn-close ms-auto" onClick={props.reqQuoteCanvasClose} />
        </div>
        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body">
          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5">
            <h3 className="fs-2 mx-auto">Request a quotation</h3>
          </div>
          {/*----- Section Random Intro End -----*/}
          <ul className="vs-getaquote list-unstyled">
            <li className="py-5">
              <a href="#" className="row justify-content-between align-items-center">
                {/*-- Locaiton Icon --*/}
                <div className="col-1">
                  <img src="./assets/images/icon/camera1.svg" className='w-100' />
                </div>
                {/*-- Domicile Details --*/}
                <div className="col-9">
                  <p className="w-100 text-truncate mb-0 fs-4">
                    Add a new quote
                  </p>
                </div>
                {/*-- Go Icon --*/}
                <div className="col-1 text-end">
                  <img src="./assets/images/icon/GoIcon.svg" />
                </div>
              </a>
            </li>
            <li className="py-5" data-bs-dismiss="offcanvas">
              <Link to="/chat-index" className="row justify-content-between align-items-center">
                {/*-- Locaiton Icon --*/}
                <div className="col-1">
                  <img src="./assets/images/icon/MessageIcon.svg" className='w-100' />
                </div>
                {/*-- Domicile Details --*/}
                <div className="col-9">
                  <div className>
                    <p className="d-inline-block mb-0 fs-4">
                      Get a quote using our chatbot
                    </p>
                    <img src="./assets/images/icon/newBadge.svg" className=" ms-2" alt="" />
                  </div>
                </div>
                {/*-- Go Icon --*/}
                <div className="col-1 text-end">
                  <img src="./assets/images/icon/GoIcon.svg" />
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </Offcanvas>
    </>
  )
}

export default RequestQuoteModal