import React from 'react'
import { useNavigate } from 'react-router-dom'

const ViewPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*----- Main Container For View Policy Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-viewPolicy position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                  <img src="./assets/images/icon/BackIcon.svg" />
                </a>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Policy</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>
                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5">
            <h3 className="fs-2 mb-3 text-white">View policy details</h3>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure View Policy Content container Start -----*/}
          <section className="vs-content_section pt-0 px-4">
            {/*----- Policy deiails start -----*/}
            <ul className="vs-View list-unstyled accordion accordion-flush ps-0 rounded-3" id="viewPolicyaccordioon">
              <li className="accordion-item py-0">
                <button className="accordion-button collapsed row ms-0 justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#view1">
                  {/*-- what we cover Icon --*/}
                  <div className="col-3 bg-secondary py-5 text-center rounded-start">
                    <img src="./assets/images/icon/PolicyViewIcon.svg" />
                  </div>
                  {/*-- what we cover Title --*/}
                  <div className="col-8 ps-5">
                    <p className="mb-0">Main account holder</p>
                    <h3 className="vs-domicileName w-100 text-truncate mb-0">
                      Saaiman Chadley
                    </h3>
                  </div>
                </button>
              </li>
            </ul>

            <div id="view1" className="accordion-collapse collapse" data-bs-parent="#viewPolicyaccordioon">
              <div className="accordion-body">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*----- Policy Product data -----*/}
                      <div className="col-10">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4 mb-1">Value: R 12 999.00</p>
                        <p className="fs-4 mb-1">Product type:
                          <span className="text-white">Comprehensive Cover</span>
                        </p>
                        <p className="fs-4 mb-1">Policy number:
                          <span className="text-white">VCPOL27671560</span>
                        </p>
                        <p className="fs-4 mb-1">Payment menthod:
                          <span className="text-white">Debit order</span>
                        </p>
                        <p className="fs-4 mb-5">Insurance term:
                          <span className="text-white">Monthly</span>
                        </p>
                        <a href="#" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50 mb-4">
                          Update
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*----- Policy Product data -----*/}
                      <div className="col-10">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2">
                          Samsung Galaxy S9+
                        </h2>
                        <p className="fs-4 mb-1">Value: R 7 999.00</p>
                        <p className="fs-4 mb-1">Product type:
                          <span className="text-white">Accidental cover</span>
                        </p>
                        <p className="fs-4 mb-1">Policy number:
                          <span className="text-white">VCPOL27671560</span>
                        </p>
                        <p className="fs-4 mb-1">Payment menthod:
                          <span className="text-white">Add to bill</span>
                        </p>
                        <p className="fs-4 mb-5">Insurance term:
                          <span className="text-white">Monthly</span>
                        </p>
                        <a href="#" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50  mb-4">
                          Update
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*----- Policy Product data -----*/}
                      <div className="col-9">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2">
                          Samsung Galaxy S9+
                        </h2>
                        <p className="fs-4 mb-1">Value: R 7 999.00</p>
                        <p className="fs-4 mb-1">Product type:
                          <span className="text-white">Accidental cover</span>
                        </p>
                        <p className="fs-4 mb-1">Policy number:
                          <span className="text-white">VCPOL27671560</span>
                        </p>
                        <p className="fs-4 mb-1">Payment menthod:
                          <span className="text-white">Add to bill</span>
                        </p>
                        <p className="fs-4 mb-5">Insurance term:
                          <span className="text-white">Monthly</span>
                        </p>
                        <a href="#" className="btn btn-secondary rounded-pill px-sm-5 px-4 fs-2 py-2 w-50 mb-4">
                          Update
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*----- Policy deiails End -----*/}
          </section>
          {/*----- Vodasure View Policy Content container  End -----*/}
        </div>
      </div>
      {/*----- Main Container For View Policy End -----*/}
    </>
  )
}

export default ViewPolicy