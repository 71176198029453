import React, { useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

function TestingComponent() {
  const { register, watch } = useForm();
  const watchSerialNumber = watch("serialNumber");

  const setAutofocus = () => {
    // serialnumberRef.current.focus();
    const inputElement = document.getElementById("adf15");
    inputElement.focus();
  };

  const handleSetManuallVal = (e) => {
    const value = e.target.value;
    // Perform your logic here
    console.log(value);
  };

  useEffect(() => {
    if (watchSerialNumber) {
      const inputElement = document.getElementById("adf15"); // Replace "adf15" with the actual ID of your input field
      inputElement.focus();
    }
  }, [watchSerialNumber]);

  return (
    <div>
      <input
        type="text"
        className="form-control"
        id="adf15"
        autoComplete="off"
        ref={watchSerialNumber}
        {...register("location", {
          maxLength: {
            value: 25,
            message: 'Serial number must be 25 characters or less.',
          },
        })}
      // onChange={handleSetManuallVal}
      />
      <button onClick={setAutofocus}>Set autofocus</button>
    </div>
  );
}

export default TestingComponent;
