import React, { useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom'
import { GetDataWithToken, PostDataWithToken } from '../../ApiHelper/ApiHelper';
import Loader from '../../common/Loader';
import ObjectLoader from '../../common/ObjectLoader';

const ConfirmAsset = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [viewAssets, setViewAssets] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [searchTextLength, setSearchTextLength] = useState('');
  const [addressList, setAddressList] = useState([]);
  const [catId, setCatId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [icons, setIcons] = useState(false);
  const [makeModel, setMakeModel] = useState('');
  const [product, setProduct] = useState('');
  const [price, setPrice] = useState('');
  const [assetCount, setAssetCount] = useState(null);
  // const serialValue = watch('imeiNumber1', '');
  const assetAgeValue = watch('asset_age', '');
  const [addAnotherAsset, setAddAnotherAsset] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    reset({
      asset_age: "New"
    })
    GetDataWithToken('user/dashboard', '')
      .then((response) => {
        if (response.status == true) {
          setAssetCount(response.data.asset);
        }
      })
  }, []);

  const handleSearch = async (text) => {
    setSearchTextLength(text);
    if (text == '') {
      setAddressList([])
    }
    setShowAddress(false);
    let assetDataFormData = new FormData();
    assetDataFormData.append('query', text);

    if (text.length > 3) {
      PostDataWithToken(`asset/price-api`, assetDataFormData).then((response) => {
        if (response.status == true) {
          console.log("first", response.data.results);
          setAddressList(response.data.results);
          setDisabled(true);
        }
      })
    }
  };

  const selectedAssets = (data) => {
    console.log("data", data);
    setShowAddress(true);
    setCatId(data?.category_id);
    console.log("data", data.price?.toLocaleString()?.replaceAll(",", " "));
    setMakeModel(data?.brand);
    setProduct(data?.product);
    setPrice(data?.price?.toLocaleString()?.replaceAll(",", " "))
    reset({
      brand: data?.brand,
      name: data?.product,
      price: data?.price?.toLocaleString()?.replaceAll(",", " "),
    })
  }

  const handleAddAssets = (data) => {
    console.log("datasssss", data, props?.assetsImage);
    const addAssets = new FormData();
    addAssets.append("asset_image", props?.assetsImage);
    addAssets.append("name", data?.name);
    addAssets.append("purchase_price", data?.price?.replace(/\s/g, ""));
    addAssets.append("category", catId);
    addAssets.append("brand", data?.brand);
    addAssets.append("asset_age", data?.asset_age);
    addAssets.append("imeiNumber1", data?.imeiNumber1);

    setLoader(true);
    PostDataWithToken('asset/add-single-asset', addAssets)
      .then((response) => {
        if (response.status == true) {
          setViewAssets(true);
          setDisabled(true);
          setIcons(true);
          setLoader(false);
          setAddAnotherAsset(true);
        }
      })
  }

  const AddAssets = (e) => {
    console.log("qwerth", e);
    if (e.type.includes("image")) {
      navigate('/check-asset',
        {
          state: {
            imgPreview: URL.createObjectURL(e),
            img: e,
          }
        });
      props.confirmAssetCanvasClose(false);
      setDisabled(false);
      setIcons(false);
      setViewAssets(false);
      setAddAnotherAsset(false);
      reset({
        brand: '',
        name: '',
        price: '',
        imeiNumber1: '',
        asset_age: 'New'
      })
      // setViewAssets(true);
      // setDisabled(true);
      // setIcons(true);
      setMakeModel('');
      setPrice('');
      setProduct('');

    }
  };

  return (
    <>
      <Offcanvas show={props.confirmAssetCanvas} onHide={props.confirmAssetCanvasClose} scroll={false} placement='bottom' id="viewaddimgprocess" className="p-4  newassetflow" >
        {/*----- Offcanvas Header -----*/}
        {loader ?
          <ObjectLoader />
          :
          <>
            <div className="offcanvas-header px-4">
              {/*----- offcanvas close button -----*/}
              <button type="button" className="btn-close ms-auto" onClick={props.confirmAssetCanvasClose} data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            {/*----- Offcanvas Body -----*/}
            <div className="offcanvas-body mynewano">
              {/*----- Section Random Intro Start -----*/}
              <div className="Section_random_intro text-center mb-4">
                <h3 className="text-gray fs-2 mb-1 w-75 mx-auto">
                  Please confirm this asset
                </h3>
              </div>
              {/*----- Section Random Intro End -----*/}
              {/*----- Assets types with list start -----*/}
              <form onSubmit={handleSubmit(handleAddAssets)}>
                <div className="row mx-0 justify-content-between align-items-center">
                  <div className="col-7">
                    <div className='d-flex'>
                      <div className='' style={{ width: "20%" }}>
                        {icons == false ?
                          <img src="./assets/images/icon/camera1.svg" className='me-4' />
                          :
                          <img src="./assets/images/icon/CheckInput.svg" className='me-4' />
                        }
                      </div>

                      <div className='' style={{ width: "80%" }}>
                        <h4 className="text-white mb-2 d-inline-block">
                          {makeModel} {product}
                        </h4>
                        <p className="fs-5 d-inline-block">
                          R {price}
                        </p>
                      </div>
                    </div>
                  </div>
                  {disabled == false && icons == false ?
                    <div className="col-5 text-end my-5">
                      <button className="fs-5 bg-primary inventorybutton btn border-0 text-center py-2 px-4 rounded-3 text-white" type="submit" disabled>
                        Add to inventory
                      </button>
                    </div>
                    :
                    icons == true && disabled == true ?
                      <div className="col-5 text-end my-5">
                        <button className="fs-5 bg-primary inventorybutton btn border-0 text-center py-2 px-4 rounded-3 text-white" type="submit" disabled>
                          Added to inventory
                        </button>
                      </div>
                      :
                      <div className="col-5 text-end my-5">
                        <button className="fs-5 bg-primary inventorybutton btn border-0 text-center py-2 px-4 rounded-3 text-white" type="submit">
                          Add to inventory
                        </button>
                      </div>
                  }


                  <div className="col-12 mb-5">
                    {/*----- Vodasure domicile Content container Start -----*/}
                    <section className="vs-content_section pt-0">
                      {/*----- Add Domicile Form Start -----*/}
                      <div className="AddDomicileform">
                        <div className="position-relative">
                          <ul className="vs-domicileList list-unstyled rounded-4 pb-3">
                            <li className="border-0 pb-0 position-relative">
                              {/*----- Floating Label input -----*/}
                              <div className="form-floating mb-4">
                                {/*--- Input ---*/}
                                <input type="text" className="form-control" autocomplete="off" autoFocus="false" id="adf15"
                                  onChange={(e) => handleSearch(e?.target?.value)}
                                />
                                {/*--- Label ---*/}
                                <label htmlFor="adf15" className="h-auto opacity-100 fw-bold" style={{ color: '#ffffff', }}>
                                  Search: {props.selectAssets}
                                </label>
                                <img src="./assets/images/icon/InfoUtilityIcon.svg" className="inputsoicon" alt />
                              </div>

                              {showAddress == false && (
                                <>
                                  {searchTextLength?.length > 3 && (
                                    <div className="searchaddresslisting rounded-4">
                                      <ul className="list-unstyled">
                                        {addressList && addressList?.length > 0 && addressList?.filter((brand) => brand?.brand != "Unbranded")?.map((asset, index) => (
                                          <li key={index} className='border-0 py-0'>
                                            <a href="javascript:void(0)" className="w-100 text-truncate" title={asset?.product} onClick={() => selectedAssets(asset)}>
                                              {asset?.product}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </>
                              )}
                            </li>

                            <li className="border-0 py-0">
                              <div className="form-floating mb-4">
                                {/*--- Input ---*/}
                                <input type="text" className="form-control" id="adf1" placeholder="Name of domicile" disabled {...register("brand")} autoFocus="false" autocomplete="off" />
                                {/*--- Label ---*/}
                                <label htmlFor="adf1" className="h-auto text-secondary">
                                  Make
                                </label>
                                {/*--- Error Message ---*/}
                                {/* <div className="m-0 f-error ">This input is invalid</div> */}
                              </div>
                            </li>
                            <li className="border-0 py-0">
                              {/*----- Floating Label input -----*/}
                              <div className="form-floating mb-4">
                                {/*--- Input ---*/}
                                <input type="text" className="form-control" id="adf1" placeholder="Name of domicile" disabled {...register("name")} autoFocus="false" autocomplete="off" />
                                {/*--- Label ---*/}
                                <label htmlFor="adf1" className="h-auto text-secondary">Model</label>
                                {/*--- Error Message ---*/}
                                {/* <div className="m-0 f-error ">This input is invalid</div> */}
                              </div>
                            </li>
                            <li className="border-0 py-0">
                              {/*----- Floating Label input -----*/}
                              <div className="form-floating mb-4">
                                {/*--- Input ---*/}
                                <input type="text" className="form-control" id="adf2" placeholder="Description" {...register("price")} disabled autoFocus="false" autocomplete="off" />
                                {/*--- Label ---*/}
                                <label htmlFor="adf2" className="h-auto text-secondary">
                                  Asset cost
                                </label>
                                {/*--- Error Message ---*/}
                                {/* <div className="m-0 f-error ">This input is invalid</div> */}

                                {/* <p className='randomR'>R</p> */}
                              </div>
                            </li>
                            <li className="border-0 py-0">
                              {/*----- Floating Label input -----*/}
                              <div className="form-floating mb-4">
                                {/*--- Input ---*/}
                                <input type="text" className="form-control" id="adf3" placeholder="Street address" autoFocus="false" autocomplete="false"
                                  {...register("imeiNumber1")}
                                />
                                {/*--- Label ---*/}
                                <label htmlFor="adf3" className="h-auto text-secondary">
                                  Enter serial number
                                </label>
                                {/*--- Error Message ---*/}
                                <img src="./assets/images/icon/InfoUtilityIcon.svg" className="inputsoicon" alt />
                              </div>
                            </li>
                            <li className="border-0 py-0">
                              <div className="form-floating pb-0">
                                <select class="form-select py-0"
                                  autoFocus="false" autocomplete="false"
                                  {...register("asset_age", {
                                    required: "This input is invalid",
                                  })}
                                >
                                  <option className='d-none'>Select Asset age</option>
                                  <option value={'New'}>New</option>
                                  <option value={'0-2 years'}>0-2 years</option>
                                  <option value={'2+ years'}>2+ years</option>
                                </select>

                                <label for="demo">Asset age</label>
                                <div className="m-0 f-error ">{errors.asset_age && errors.asset_age.message}</div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/*----- Add Domicile Form End -----*/}
                    </section>
                    {/*----- Vodasure domicile Content container End -----*/}
                  </div>
                </div>
              </form>
              {/*----- Assets types with list end -----*/}
              {/*----- Basic Footer Section Start -----*/}
              <footer className="vs-floting-footer">
                {/*----- add new asset button -----*/}
                {/* <Link to="/take-photo" className=" d-block">
                      <img src="./assets/images/icon/AddIcon.svg" className="d-inline-block border brder-white brder-1  rounded-circle p-2 me-3" alt />
                      Add another asset
                    </Link> */}
                {assetCount > 0 ?
                  <Link to="/take-photo" className="mastbtn mb-5 d-inline-block">
                    <img src="./assets/images/icon/AddIcon.svg" className="d-inline-block border brder-white brder-1  rounded-circle p-2 me-2" alt />
                    Add another asset
                  </Link>
                  :
                  addAnotherAsset == true ?
                    <>
                      <input type='file' className='d-none' id="mynewid" accept="image/*" capture="environment" onChange={(e) => AddAssets(e.target.files[0])} />
                      <label htmlFor='mynewid'>
                        <p className="mastbtn mb-5 d-inline-block">
                          <img src="./assets/images/icon/AddIcon.svg" className="d-inline-block border brder-white brder-1  rounded-circle p-2 me-2" alt />
                          Add another asset
                        </p>
                      </label>
                    </>
                    :
                    <>
                      {null}
                    </>
                }

                <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
                  <a href="javascript:void(0)" onClick={props.confirmAssetCanvasClose} className="btn btn-outline-secondary rounded-pill fs-3 py-3 w-50 me-3">
                    Cancel
                  </a>
                  {viewAssets == false ?
                    <a href="javascript:void(0)" className="btn btn-outline-primary disabled rounded-pill px-5 fs-3 py-3 w-50 me-3">
                      View my asset
                    </a>
                    :
                    <Link to="/my-assets" disabled={viewAssets == false} className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3">
                      View my asset
                    </Link>
                  }
                </form>
              </footer>
              {/*----- Basic Footer Section End -----*/}
              {/*  */}
            </div >
          </>
        }
      </Offcanvas >
    </>
  )
}

export default ConfirmAsset