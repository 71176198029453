import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';

const ChooseBeneficiary = () => {
  const navigate = useNavigate();
  const [syncContact, setSyncContact] = useState(true);
  const syncContactClose = () => setSyncContact(false);
  const supportsContacts = ('contacts' in navigator && 'ContactsManager' in window);
  console.log("supportsContacts", supportsContacts)
  try {
    const contacts = navigator.select(["name", "tel"]);
    console.log(contacts);
  } catch (error) {
    console.error(error);
  }
  //=> [{ "name": ["Phil Nash"], "tel": ["+61412345678", "+447123456789"]}]

  return (
    <>
      {/*----- Main Container For Profile Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-profile position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="#">
                  <img src="./assets/images/icon/Notification.svg" />
                </a>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Transfer</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-3  pb-3 mx-auto text-white">
              Choose your beneficiary
            </h3>
            {/*----- Here we don't need the subheading of Random intro so we are not using it -----*/}
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure Profile Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Add Domicile Form Start -----*/}
            <div className="editprofileform">
              <form action="#">
                <ul className="vs-profileOption  list-unstyled pt-5 rounded-4">
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating pb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Beneficiary mobile no." />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-dark-gray">Beneficiary mobile no.</label>


                      <img src="./assets/images/icon/ContactIcon.png" className="calicon" alt="" style={{ width: '20px' }} />
                    </div>
                  </li>
                </ul>

                <ul className="vs-profileOption  list-unstyled pt-4 rounded-4 mt-5">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Beneficiary name" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Beneficiary name</label>
                    </div>
                  </li>

                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating pb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Beneficiary surname (Optional)" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Beneficiary surname (Optional)</label>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
            {/*----- Add Domicile Form End -----*/}
          </section>
          {/*----- Vodasure Profile Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <Link to="/my-profile" className="btn btn-primary rounded-pill px-5 fs-3 py-3 btn-width">
              Accept & continue
            </Link>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Sync Contact Modal -----*/}
      <Modal
        show={syncContact}
        onHide={syncContactClose}
        centered
      >
        <div className="modal-body text-center p-5">
          <p className="fs-3 mb-3">
            Sync your contacts?
          </p>
          <small className="d-block">
            This makes it easier to transfer an asset
            <br />
            or a locker to people in your contacts.
          </small>
          <a href="javascript:void(0)" className="d-block fs-4 text-muted my-5">
            Sync my contacts
            <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt="" />
          </a>
          <a href="javascript:void(0)" onClick={syncContactClose} className="d-block text-muted">
            Cancel
            <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt="" />
          </a>
        </div>
      </Modal>
      {/*----- Main Container For Profile End -----*/}
    </>
  )
}

export default ChooseBeneficiary