import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const DomicileMap = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*----- Main Container For domicile Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-domicile-form position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)"><img src="./assets/images/icon/BackIcon.svg" onClick={() => navigate(-1)} /></a>
              </div>

              <div className="col-7 text-center">
                <h4 className="vs-header-title">Confirm Address</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>
                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5">
            <h3 className="fs-1 mb-3 w-75 mx-auto text-truncate text-white">15 Niven Avenue, Douglasdale</h3>
            <p className="text-dark-gray w-75 mx-auto">Your selected location</p>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure domicile Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Add Domicile Form Start -----*/}
            <div className="AddDomicileform">
              <form action="#">
                {/*----- Location Map -----*/}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.458189762116!2d27.989178215655958!3d-26.051278164101813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95743c34e0d3c9%3A0xb6ed1913cb578672!2s15%20Niven%20Ave%2C%20Douglasdale%2C%20Sandton%2C%202191%2C%20South%20Africa!5e0!3m2!1sen!2sin!4v1652703746385!5m2!1sen!2sin" width="100%" height={460} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe>
              </form>
            </div>
            {/*----- Add Domicile Form End -----*/}
          </section>
          {/*----- Vodasure domicile Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
              <Link to="/add-domicile" className="btn btn-outline-primary rounded-pill px-5 fs-3 py-3 w-50 me-3">
                Edit
              </Link>

              <Link to="/my-domicile" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3">
                Add Domicile
              </Link>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For add domicile End -----*/}
    </>
  )
}

export default DomicileMap