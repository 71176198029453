import React from 'react'

const Loader = () => {
  return (
    <>
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-lodar position-relative">
            <div className="lodarbg">
              <div className="lodarcontainer">
                <img src="./assets/images/lodar.gif" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Loader