import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const DebitOrder = () => {

  const navigate = useNavigate();
  return (
    <>
      {/*----- Main Container For Debit Order Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-debitorder position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                  <img src="./assets/images/icon/BackIcon.svg" />
                </a>
              </div>

              <div className="col-7 text-center">
                <h4 className="vs-header-title">Enter card details</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>
                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5">
            {/*----- Debit Order Image -----*/}
            <img src="./assets/images/icon/CardOnHand.svg" alt="" />
            <h3 className="fs-2 mb-3 mx-auto text-white">Debit order</h3>
            <p className="text-dark-gray w-75 mx-auto">
              Add your banking details<br />to create a monthly debit order.
            </p>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure Buy Debit Order Content container Start -----*/}
          <section className="vs-content_section  pt-0 px-4">
            {/*----- Add Buy Insurance Form Start -----*/}
            <div className="DebitOrder">
              <form action="#">
                <ul className="vs-domicileList list-unstyled pt-4 rounded-4">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Select bank" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Select bank</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Account type" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Account type</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Account number" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-dark-gray">Account number</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf4" placeholder="Branch code" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf4" className="h-auto text-dark-gray">
                        Branch code
                      </label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf5" placeholder="Account holder name" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf5" className="h-auto text-dark-gray">
                        Account holder name
                      </label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating pb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf7" placeholder="Debit order date" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf7" className="h-auto text-dark-gray">
                        Debit order date
                      </label>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
            {/*----- Add Buy Insurance Form End -----*/}
          </section>
          {/*----- Vodasure Buy Debit Order Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
              <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-primary rounded-pill px-5 fs-3 py-3 w-50 me-3">
                Cancel
              </a>
              <Link to="/your-covered" className="btn btn-primary rounded-pill px-sm-5 px-4 fs-3 py-3 w-75 ms-3">
                Create debit order
              </Link>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For Debit Order End -----*/}

    </>
  )
}

export default DebitOrder