import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const UpdateAddress = () => {

  const navigate = useNavigate();

  return (
    <>
      {/*----- Main Container For Profile Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-profile position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)">
                  <img src="./assets/images/icon/BackIcon.svg" onClick={() => navigate(-1)} />
                </a>
              </div>

              <div className="col-7 text-center">
                <h4 className="vs-header-title">My details</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-3 pb-3 mx-auto text-white">Please update your details</h3>
            {/*----- Here we don't need the subheading of Random intro so we are not using it -----*/}
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure Profile Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Add Domicile Form Start -----*/}
            <div className="editprofileform">
              <form action="#">
                <ul className="vs-profileOption  list-unstyled pt-4 rounded-4">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Name of domicile" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Unit/apartment no.
                        (optional)</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Name of domicile" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Street address</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Description" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-dark-gray">Suburb</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf3" placeholder="Street address" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf3" className="h-auto text-dark-gray">City</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf4" placeholder="Suburb" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf4" className="h-auto text-dark-gray">Province
                        (optional)</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating pb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf5" placeholder="City" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf5" className="h-auto text-dark-gray">Post code</label>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
            {/*----- Add Domicile Form End -----*/}
          </section>
          {/*----- Vodasure Profile Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
              <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-primary rounded-pill px-5 fs-3 py-3 w-50 me-3">
                Cancel
              </a>
              <Link to="/profile-confirmation" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3">
                Update Details
              </Link>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For Profile End -----*/}
    </>
  )
}

export default UpdateAddress