import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Link, useNavigate } from 'react-router-dom';

const YourDetails = () => {
  const [radioInfo, setRadioInfo] = useState("");
  const [verification, setVerification] = useState("");

  const navigate = useNavigate();

  const changeNumberRadio = (e) => {
    console.log("radio", e.target.value);
    setRadioInfo(e.target.value);
  };

  const changeVerificationRadio = (e) => {
    setVerification(e.target.value);
  }

  $(function () {
    $("#datepicker").datepicker();
  });
  return (
    <>
      {/*----- Main Container For Profile Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-profile position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)">
                  <img src="./assets/images/icon/BackIcon.svg" onClick={() => navigate(-1)} />
                </a>
              </div>

              <div className="col-7 text-center">
                <h4 className="vs-header-title">Your details</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-3 pb-3 mx-auto text-white">Please confirm your details</h3>
            {/*----- Here we don't need the subheading of Random intro so we are not using it -----*/}
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure Profile Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Add Domicile Form Start -----*/}
            <div className="editprofileform">
              <form action="#">
                <ul className="vs-profileOption  list-unstyled pt-4 rounded-4">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Full name" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Full name</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="number" className="form-control" id="adf1" placeholder="Mobile No." />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">Mobile No.</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Name Prefix" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-dark-gray">Name Prefix</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf3" placeholder="Initials" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf3" className="h-auto text-dark-gray">Initials</label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4 pb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="datepicker" placeholder="Date of Birth" />
                      {/*--- Label ---*/}
                      <label htmlFor="datepicker" className="h-auto text-dark-gray">
                        Date of Birth
                      </label>
                      <img src="./assets/images/icon/CalenderIcon.svg" className="calicon" alt="" />
                    </div>
                  </li>
                </ul>
                <div className="ms-4 mb-4">
                  {/*----- Floating Label input -----*/}
                  <div className="form-floating pb-0" onChange={changeNumberRadio}>
                    <div className="form-check form-check-inline ms-2 ps-0">
                      {/*--- Input ---*/}
                      <input className="form-check-input float-end ms-4" type="radio" id="idradio" name="proff" value="id_number" />
                      {/*--- Label ---*/}
                      <label className="form-check-label h-auto text-dark-gray" htmlFor="idradio">
                        ID number
                      </label>
                    </div>
                    {/*--- Input ---*/}
                    <div className="form-check form-check-inline ps-3">
                      <input className="form-check-input float-end ms-4" type="radio" id="passport" name="proff" value="passport_number" />
                      {/*--- Label ---*/}
                      <label className="form-check-label h-auto text-dark-gray" htmlFor="passport">
                        Passport number
                      </label>
                    </div>
                    {/*--- Error Message ---*/}
                    <div className="ms-2 f-error position-static">This input is invalid</div>
                  </div>
                </div>
                {radioInfo === "" ? <>

                </>
                  :
                  radioInfo === "id_number" ? <>
                    <ul className="vs-profileOption  list-unstyled pt-5 rounded-4">
                      <li className="border-0 py-0">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating pb-0">
                          {/*--- Input ---*/}
                          <input type="text" className="form-control" id="adf6" placeholder="Province (optional)" />
                          {/*--- Label ---*/}
                          <label htmlFor="adf6" className="h-auto text-dark-gray">ID number</label>
                        </div>
                      </li>
                    </ul>
                    {/*--- Error Message ---*/}
                    <div className="ms-4 f-error position-static">This input is invalid</div>
                  </> : <>
                    <ul className="vs-profileOption  list-unstyled pt-5 rounded-4">
                      <li className="border-0 py-0">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating pb-0">
                          {/*--- Input ---*/}
                          <input type="text" className="form-control" id="adf7" placeholder="Post code" />
                          {/*--- Label ---*/}
                          <label htmlFor="adf7" className="h-auto text-dark-gray">Passport number</label>
                        </div>
                      </li>
                    </ul>
                    {/*--- Error Message ---*/}
                    <div className="ms-4 f-error position-static">This input is invalid</div>
                  </>}
                <div className="ms-4 mb-4">
                  {/*----- Floating Label input -----*/}
                  <div className="form-floating pb-0" onChange={changeVerificationRadio}>
                    <div className="form-check form-check-inline ms-2 ps-0">
                      {/*--- Input ---*/}
                      <input className="form-check-input float-end ms-4" type="radio" id="emailradio" name="way" />
                      {/*--- Label ---*/}
                      <label className="form-check-label h-auto text-dark-gray" htmlFor="emailradio">
                        Email
                      </label>
                    </div>
                    {/*--- Input ---*/}
                    <div className="form-check form-check-inline ps-3">
                      <input className="form-check-input float-end ms-4" type="radio" id="smsradio" name="way" value="sms" />
                      {/*--- Label ---*/}
                      <label className="form-check-label h-auto text-dark-gray" htmlFor="smsradio">
                        SMS
                      </label>
                    </div>
                    {/*--- Error Message ---*/}
                    {/* <div className="ms-2 f-error position-static">This input is invalid</div> */}
                  </div>
                </div>
                {verification === "sms" ? null
                  : <>
                    <ul className="vs-profileOption  list-unstyled pt-5 rounded-4">
                      <li className="border-0 py-0">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating pb-0">
                          {/*--- Input ---*/}
                          <input type="text" className="form-control" id="adf2" placeholder="Description" />
                          {/*--- Label ---*/}
                          <label htmlFor="adf2" className="h-auto text-dark-gray">Email address</label>
                        </div>
                      </li>
                    </ul>
                    {/*--- Error Message ---*/}
                    <div className="ms-4 f-error position-static">This input is invalid</div>
                  </>}
              </form>
            </div>
            {/*----- Add Domicile Form End -----*/}
          </section>
          {/*----- Vodasure Profile Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
              <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-primary rounded-pill px-5 fs-3 py-3 w-50 me-3">
                Cancel
              </a>
              <Link to="/your-address" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3">
                Next
              </Link>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For Profile End -----*/}
    </>
  )
}

export default YourDetails