import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { GetDataWithToken, PostDataWithToken, mainUrl } from '../../ApiHelper/ApiHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../common/Loader';
import axios from 'axios';

const DebitCard = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const [callApi, setCallApi] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');

  useEffect(() => {
    if (callApi) {
      GetDataWithToken('quotation/get-banking-list', '')
        .then((response) => {
          if (response.status == true) {
            setBankList(response.data);
            setLoader(false);
            setCallApi(false);
          }
        })
    }
  }, [callApi]);

  const handleSelectBank = (e) => {
    console.log("e------", e);
    const [bankName, branchCode] = e.split("-");
    setBankCode(branchCode);
    setBankName(bankName);
  };

  const watchedFields = watch(['bank', 'account_type', 'account_number']);

  const location = useLocation();
  const assetsId = location?.state?.assetsId;
  const myDetails = location?.state?.myDetails;
  const inventryValue = location?.state?.inventryValue;
  const quoteId = location?.state?.quoteId;
  const campaignSchema = location?.state?.campaignSchema;
  const underwritingQuestions = location?.state?.underwritingQuestions;
  const quoteLineItems = location?.state?.quoteLineItems;
  const selectedAssetsList = location?.state?.selectedAssetsList;
  const selectedIds = location?.state?.selectedIds;
  const oAuthToken = localStorage.getItem('oAuthToken');
  const idNumber = localStorage.getItem('idNumber');

  const handleAddDebit = (data) => {
    // let headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + oAuthToken,
    // };
    // const validateBank = {
    //   sourceTransactionId: "e4c161b7-d881-4066-a016-e72e51f72537",
    //   origin: "iWyze",
    //   workflow: "SMME New Business",
    //   accountNumber: data?.account_number,
    //   accountType: data?.account_type,
    //   bankName: bankName,
    //   branchCode: bankCode,
    //   idNumber: idNumber,
    //   surname: myDetails?.first_name
    // }
    // axios.post(`${mainUrl}quotation/bank-details-validation`, validateBank, { headers: headers })
    //   .then((response) => {
    //     console.log("resss----", response)
    //   })
    console.log("data", data);
    let addDebit = {
      payment_date: new Date(),
      bank: bankName,
      account_type: data?.account_type,
      account_number: data?.account_number,
      branch_code: bankCode,
      holder_name: data?.holder_name,
      order_date: data?.order_date,
      insurence_term: "monthly",
      payment_method: "Debit Order",
      monthly_premium: "1250",
    }
    console.log("addDebit --------------", addDebit)

    navigate('/cover-summary', {
      state: {
        addDebit: addDebit,
        assetsId: assetsId,
        inventryValue: inventryValue,
        quoteId: quoteId,
        campaignSchema: campaignSchema,
        underwritingQuestions: underwritingQuestions,
        quoteLineItems: quoteLineItems,
        selectedAssetsList: selectedAssetsList,
        selectedIds: selectedIds,
      }
    });
  };

  const handleFocus = (id, inputId, isFocus) => {
    const inputElement = document.getElementById(id);
    const divElement = document.getElementById(inputId);
    if (inputElement) {
      if (isFocus) {
        setTimeout(() => {
          inputElement.classList.add("focuskeyboard");
          divElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
      } else {
        inputElement.classList.remove("focuskeyboard");
      }
    }
  };

  return (
    <>
      {loader ?
        <Loader />
        :
        <>
          {/*----- Main Container For Debit Order Start -----*/}
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-debitorder position-relative">
                {/*----- Vs Header Start -----*/}
                <div className="vs-header Withbtngroup">
                  {/*------ Header Mid Heading (Left Part) ------*/}
                  <a href="javascript:void(0)" className='headericon' onClick={() => navigate(-1)}><img src="./assets/images/icon/BackIcon.svg" /></a>


                  {/*------ Header Mid Heading (Middle Part) ------*/}
                  <h4 className="vs-header-title d-none">Enter card details</h4>


                  {/*------ Header Mid Heading (Right Part) ------*/}
                  <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                    <a href="#" className="btn border-0 me-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                    </a>

                    <a href="#" className="btn border-0 ms-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                    </a>
                  </div>
                </div>
                {/*----- Vs Header end -----*/}

                {/*----- Section Random Intro Start -----*/}
                <div className="Section_random_intro text-center mb-5">
                  {/*----- Debit Order Image -----*/}
                  <img src="./assets/images/icon/CardOnHand.svg" alt />
                  <h3 className="fs-2 mb-3 w-75 mx-auto">Debit order</h3>
                  <p className="text-secondary w-75 mx-auto">
                    Add your banking details<br />to create a monthly debit order.
                  </p>
                </div>
                {/*----- Section Random Intro End -----*/}
                {/*----- Vodasure Buy Debit Order Content container Start -----*/}
                <form onSubmit={handleSubmit(handleAddDebit)}>
                  <section className="vs-content_section  pt-0" id='keyboardFocus'>
                    {/*----- Add Buy Insurance Form Start -----*/}
                    <div className="DebitOrder">
                      <ul className="vs-domicileList list-unstyled pt-4 rounded-4 pb-0 px-4">
                        <li className="border-0 pb-0">
                          <div className="form-floating mb-4">
                            <select class="form-select py-0"
                              {...register("bank", {
                                required: "This input is invalid",
                                onChange: (e) => { handleSelectBank(e?.target?.value) }
                              })}
                            >
                              <option className='d-none'>Select bank</option>
                              {bankList && bankList?.length > 0 && bankList?.map((list, index) => (
                                <option value={`${list?.bank_name} - ${list?.universal_branch_code}`}>{list?.bank_name}</option>
                              ))}
                            </select>

                            <label htmlFor="demo">Select bank</label>
                            <div className="m-0 f-error ">{errors.bank && errors.bank.message}</div>
                          </div>
                        </li>
                        <li className="border-0 py-0">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating mb-4">
                            {/*--- Input ---*/}
                            <input type="text" className="form-control" id="adf1" placeholder="Account type" {...register("account_type", {
                              required: "This input is invalid",
                            })} />
                            {/*--- Label ---*/}
                            <label htmlFor="adf1" className="h-auto text-secondary">Account type</label>
                            {/*--- Error Message ---*/}
                            <div className="m-0 f-error ">{errors.account_type && errors.account_type.message}</div>
                          </div>
                        </li>
                        <li className="border-0 py-0">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating mb-4">
                            {/*--- Input ---*/}
                            <input type="text" className="form-control" id="adf2" placeholder="Account number" {...register("account_number", {
                              required: "This input is invalid",
                            })} />
                            {/*--- Label ---*/}
                            <label htmlFor="adf2" className="h-auto text-secondary">Account number</label>
                            {/*--- Error Message ---*/}
                            <div className="m-0 f-error ">{errors.account_number && errors.account_number.message}</div>
                          </div>
                        </li>
                        <li className="border-0 py-0">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating mb-4">
                            {/*--- Input ---*/}
                            <input type="text" className="form-control" id="adf4" placeholder="Branch code" defaultValue={bankCode} disabled />
                            {/*--- Label ---*/}
                            <label htmlFor="adf4" className="h-auto text-secondary">
                              Branch code
                            </label>
                          </div>
                        </li>
                        <li className="border-0 py-0">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating mb-4">
                            {/*--- Input ---*/}
                            <input type="text" className="form-control" id="adf5" placeholder="Account holder name" defaultValue={myDetails?.first_name} {...register("holder_name", {
                              required: "This input is invalid",
                            })}
                              onBlur={() => handleFocus("keyboardFocus", "adf5", false)}
                              onFocus={() => handleFocus("keyboardFocus", "adf5", true)}
                            />
                            {/*--- Label ---*/}
                            <label htmlFor="adf5" className="h-auto text-secondary">
                              Account holder name
                            </label>
                            {/*--- Error Message ---*/}
                            <div className="m-0 f-error ">{errors.holder_name && errors.holder_name.message}</div>
                          </div>
                        </li>
                        <li className="border-0 pt-0">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating pb-0">
                            {/*--- Input ---*/}
                            <input type="date" className="form-control" id="adf7" placeholder="Debit order date" {...register("order_date", {
                              required: "This input is invalid",
                            })} />
                            {/*--- Label ---*/}
                            <label htmlFor="adf7" className="h-auto text-secondary">
                              Debit order date
                            </label>
                            {/*--- Error Message ---*/}
                            <div className="m-0 f-error ">{errors.order_date && errors.order_date.message}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/*----- Add Buy Insurance Form End -----*/}
                  </section>
                  {/*----- Vodasure Buy Debit Order Content container End -----*/}
                  {/*----- Basic Footer Section Start -----*/}
                  <footer className="vs-floting-footer vs-footer-gradient">
                    <div className="d-flex btn-width mx-auto justify-content-between align-items-center">
                      <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-primary rounded-pill px-5 fs-3 py-3 w-50 me-3">
                        Cancel
                      </a>
                      <button type='submit' className="btn btn-primary rounded-pill px-1 fs-3 py-3 w-75 ms-3">
                        Create debit order
                      </button>
                    </div>
                  </footer>
                  {/*----- Basic Footer Section End -----*/}
                </form>
              </div>
            </div>
          </div>
          {/*----- Main Container For Debit Order End -----*/}
        </>
      }
    </>
  )
}

export default DebitCard