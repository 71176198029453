import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

const ClaimSubmit = () => {

  const [replacementCanvas, setReplacementCanvas] = useState(false);
  const replacementCanvasClose = () => setReplacementCanvas(false);

  return (
    <>
      {/*----- Main Container Asset adden Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-submitClaim vs-submission position-relative px-4">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center m-x0">
              <div className="col-2 text-start" />

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Lost Device</h4>
              </div>

              <div className="col-2 text-end" />
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Content Start -----*/}
          <section className="vs-content_section text-center vs-empty px-4 justify-content-between">
            {/*----- Section Random Intro Start -----*/}
            <div className="Section_random_intro text-center">
              <h3 className="fs-1 mb-4 mx-auto text-white">
                Claim Submitted!
              </h3>

              <p className="text-dark-gray fs-3 mx-auto">
                Your claim has been successfully submitted.<br />We’ll notify you within 24
                hours<br />once it’s reviewed.
              </p>
            </div>
          </section>
          {/*----- Section Content End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <form action="#">
              <a href="javascript:void(0)" onClick={() => setReplacementCanvas(true)} className="btn btn-primary rounded-pill px-5 fs-3 py-3 btn-width">
                Choose replacement device
              </a>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container Asset adden End -----*/}


      <Offcanvas show={replacementCanvas} onHide={replacementCanvasClose} scroll={false} placement='bottom'>
        {/*----- Offcanvas Header -----*/}
        <div className="offcanvas-header px-5 pt-5">
          <a href="javascript:void(0)" onClick={replacementCanvasClose} className="btn-close ms-auto" />
        </div>

        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body">
          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5">
            <h3 className="fs-2 w-75 mx-auto">
              Choose replacement device
            </h3>
          </div>
          {/*----- Section Random Intro End -----*/}
          <ul className="vs-getaquote list-unstyled">
            <li>
              <Link to="/delivery-method" className="row justify-content-between align-items-center">
                {/*-- Locaiton Icon --*/}
                <div className="col-1">
                  <img src="./assets/images/icon/Device.svg" />
                </div>
                {/*-- Domicile Details --*/}
                <div className="col-9">
                  <p className="w-100 text-truncate mb-0">
                    Choose the same device<span className="fs-5 ms-3 rounded-2 bg-danger px-3 py-2">Avaiable</span>
                  </p>
                  <small className="text-dark-gray fs-5">Similar to the one you previously owned</small>
                </div>
                {/*-- Go Icon --*/}
                <div className="col-1 text-end">
                  <img src="./assets/images/icon/GoIcon.svg" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/delivery-method" className="row justify-content-between align-items-center">
                {/*-- Locaiton Icon --*/}
                <div className="col-1">
                  <img src="./assets/images/icon/Device.svg" />
                </div>
                {/*-- Domicile Details --*/}
                <div className="col-9">
                  <p className="w-100 text-truncate mb-0">
                    Choose a new device model
                  </p>
                  <small className="text-dark-gray fs-5">This will be a new device</small>
                </div>
                {/*-- Go Icon --*/}
                <div className="col-1 text-end">
                  <img src="./assets/images/icon/GoIcon.svg" />
                </div>
              </Link>
            </li>
          </ul>
        </div>

      </Offcanvas>
    </>
  )
}

export default ClaimSubmit