import React from 'react'
import { Offcanvas } from 'react-bootstrap'

const QuotationCanvas = ({ quotationCanvas, quotationCanvasClose, allPrice, myAssets, assetsPrice, handleSelectAssets, handleAcceptQoute, selectedIds, selectedID, inventryValue, formattedString, inventryValueId }) => {
  // console.log("selectedIds -----------------------------------------", selectedIds)
  const isAssetSelected = (assetId) => selectedID?.includes(assetId);
  // console.log("assetsPrice", assetsPrice);

  return (
    <>
      <Offcanvas show={quotationCanvas} onHide={quotationCanvasClose} scroll={false} placement='bottom' id="viewquote" className='suddenoffcanvas p-4 show offcanvas offcanvas-bottom'>
        {/*----- Offcanvas Header -----*/}
        <div className="offcanvas-header px-4">
          {/*----- quote detials tab -----*/}
          <ul className="nav nav-pills mb-3 asseBUTonlael bg-gray rounded-3 position-relative" style={{ overflow: "visible" }} id="pills-tab" role="tablist">
            <li className="nav-item position-relative">
              <button className="nav-link active pt-4" data-bs-toggle="pill" data-bs-target="#Contents" type="button">
                Asset cover
              </button>
            </li>

            <img className='vodasparkal' src='./assets/Newimg/vodasparkal.png' />
          </ul>


          {/*----- offcanvas close button -----*/}
          <button type="button" className="btn-close" style={{ position: "absolute", right: "20px" }} data-bs-dismiss="offcanvas" aria-label="Close" onClick={quotationCanvasClose} />
        </div>
        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body px-3">
          <div className="tab-content">
            <div className="tab-pane fade show active" id="Contents" tabIndex={0}>
              {/*----- Quote type image -----*/}
              <div className="quotetypeimg text-center mb-5">
                <img src="./assets/images/qouteprodicon.png" style={{ width: 90 }} alt />
              </div>
              {/*----- Section Random Intro Start -----*/}
              <div className="Section_random_intro text-center mb-5">
                <h3 className="text-gray mb-3 text-center" style={{ fontSize: "30px" }}>
                  Quotation
                </h3>
                <p className=" mb-0 text-center" style={{ fontSize: "20px" }}>Cover against loss, damage and theft.</p>
                <p className="mt-2 text-center" style={{ fontSize: "17px" }}>National coverage. Cancel anytime.</p>
                <small className="fs-5 text-gray">Vodasure | asset cover Terms and conditions</small>
              </div>
              {/*----- Section Random Intro End -----*/}
              <div className="card vs-InfoCardStatus shareniwcard rounded-4">
                {/*----- Info Card Body -----*/}
                <div className="card-body" id={inventryValueId}>
                  <div className="row mx-0 px-0 justify-content-between align-items-center">
                    <div className="col-6">
                      <div className="pt-3">
                        <p className="fs-4 d-inline-block">
                          Your premium
                        </p>

                        <span className='d-inline-block tobehover'>
                          <img src="./assets/images/icon/InfoUtilityIcon.svg" className='ms-2' alt />
                        </span>

                        <div className='hoverablediv inofcanc'>
                          <p className='mb-0'>
                            This is a premium based on the length of time the assets are actually at risk. The customer will only be charged for the service they’ve received within the month before payment of the premium started.
                          </p>
                        </div>
                        {/* <img src="./assets/images/icon/InfoUtilityIcon.svg" className="ms-2" alt /> */}
                      </div>
                      {/*----- Info title -----*/}
                      <h1 className="vs-card-title nemamheding mb-2">
                        R <span className="text-white">{inventryValue == 0 || assetsPrice?.length == 0 ? "0.00" : formattedString}</span>
                      </h1>
                    </div>
                    <div className="col-6">
                      <div className="fs-3 py-2">
                        <p className="fs-5 mb-0">
                          Total insured value
                          <br />
                          R {allPrice?.toLocaleString()?.replaceAll(",", " ")}.00
                        </p>
                        <p className="fs-5 mb-0">Excess: <span>15%</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="vs-getaquote list-unstyled accordion" id="QuoteAccordion">
                <li className="accordion-item">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#myassetstocover">
                    {/*-- what we cover Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/assetquotemyasset.svg" />
                    </div>
                    {/*-- what we cover Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        My assets
                      </p>
                    </div>
                  </button>

                  <div id="myassetstocover" className="accordion-collapse collapse show" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      {myAssets && myAssets?.length > 0 && myAssets?.filter(type => type.status == "uninsured")?.map((assets, index) => {
                        const assetId = assets.id;
                        const isSelected = isAssetSelected(assetId);
                        // console.log("isSelected", isSelected);

                        return (
                          <div className="assetinqotemodal mt-3 mb-0 alert alert-dismissible fade show row" key={index}>
                            <div className="col-3">
                              <img src={assets?.asset_image} alt />
                            </div>
                            <div className="col-8">
                              {assets?.policy_type == "Accidental Damage" &&
                                <p className='damageBadge badge bg-secondary px-4 py-2'>
                                  Accidental Damage
                                  <i class="fa-solid fa-angle-down ms-2"></i>
                                </p>
                              }
                              <p className="mb-0 text-muted fs-4">{assets?.model}</p>
                              <small>R {parseInt(assets?.cost)?.toLocaleString()?.replaceAll(",", " ")}</small>
                            </div>
                            <div className="col-1 align-self-center">
                              {/*--- Input ---*/}
                              <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                  type="checkbox"
                                  id={`passport_${assetId}`}
                                  defaultChecked={isSelected}
                                  name="proff"
                                  onChange={() => handleSelectAssets(assets, assets?.cost, assets.id, !isSelected)}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    </div>
                  </div>
                </li>
                <li className="accordion-item">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#Whatwecover">
                    {/*-- what we cover Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/WhatWeCover.svg" />
                    </div>
                    {/*-- what we cover Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        What we cover
                      </p>
                    </div>
                  </button>
                  <div id="Whatwecover" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ul>
                        <li className="position-relative pt-5 mt-3">
                          Loss We'll replace it.
                        </li>
                        <li>
                          Theft: We’ll replace it.
                        </li>
                        <li>
                          Cracked screen: We’ll repair it.
                        </li>
                        <li>
                          Camera damage: We’ll repair it.
                        </li>
                        <li>
                          Touchscreen damage: We’ll repair it.
                        </li>
                        <li>
                          Water damage: We'll repair or replace it.
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="accordion-item">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#claimstep">
                    {/*-- Claim Step Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/EasyClaimStep.svg" />
                    </div>
                    {/*-- Claim Step Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        Claim in three easy steps
                      </p>
                    </div>
                  </button>
                  <div id="claimstep" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ol>
                        <li className="position-relative pt-5 mt-3">
                          <p className="mb-2">Blacklist your device</p>
                          <small className="fs-4 text-secondary">If your device is lost or stolen dial 082
                            135.</small>
                        </li>
                        <li>
                          <p className="mb-2">Log your claim</p>
                          <small className="fs-4 text-secondary">
                            To claim – Call us at 082 1952, email us at
                            servicing.deviceinsurance@vodacom.co.za,
                            or visit your nearest store.
                          </small>
                        </li>
                        <li>
                          <p className="mb-2">Collect your device</p>
                          <small className="fs-4 text-secondary">
                            We process all valid claims within 48 hours.
                          </small>
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li className="accordion-item">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#faq">
                    {/*-- Claim Step Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/FAqOffcanvac.svg" />
                    </div>
                    {/*-- Claim Step Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        Frequently asked questions
                      </p>
                    </div>
                  </button>
                  <div id="faq" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ul>
                        <li className="position-relative pt-5 mt-3">
                          <p className="mb-2">When will my cover start?</p>
                          <small className="text-secondary">
                            Your cover starts immediately. If you choose to add insurance when
                            upgrading or buying a new device instore, you will be
                            covered as soon as your purchase is complete.
                          </small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
              {/*----- Basic Footer Section Start -----*/}
              <footer className="vs-floting-footer">
                <div className="d-flex btn-width mx-auto justify-content-between align-items-center">
                  <a href="javascript:void(0)" onClick={() => quotationCanvasClose(true)} className="btn btn-outline-secondary rounded-pill fs-3 py-3 w-50 me-3">
                    Cancel
                  </a>
                  <button onClick={handleAcceptQoute} className={assetsPrice?.length == 0 ? "btn btn-outline-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3 disabled" : "btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3"} >
                    Accept quote
                  </button>
                </div>
              </footer>
              {/*----- Basic Footer Section End -----*/}
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  )
}

export default QuotationCanvas