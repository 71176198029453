import React from 'react'
import { Link } from 'react-router-dom'

const ChatBot = () => {

  return (
    <>
      {/*----- Main Container For Chat index Start -----*/}
      <div className="vs-main-container">
        {/*----- Vodasure Chat container Start -----*/}
        <div className="vs-main-content vs-chatindex position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start"></div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title text-center">VodaSure | Locker</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-3 w-75 mx-auto text-white">Chatbot</h3>
            <p className="text-dark-gray w-75 mx-auto">This is the stat of your conversation</p>
          </div>
          {/*----- Section Random Intro End -----*/}



          <section className="vs-content_section px-4 pt-0">
            {/*----- chat bot and user message start -----*/}
            <div className="vs-Chat_message">
              <div className="container-fluid">
                <div className="row justify-content-end mx-0">
                  <div className="col-11">
                    <ul className="list-unstyled vs-message_series m-0">
                      <li>
                        {/*----- Bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          awesome! Let's get started on you house hold insurance quote.
                        </p>
                        {/*----- bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          10.00 AM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*----- Bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          awesome! Let's get started on you house hold insurance quote.
                        </p>
                        {/*----- bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          10.00 AM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*----- Bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          awesome! Let's get started on you house hold insurance quote.
                        </p>
                        {/*----- bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          10.00 AM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*---- User message date -----*/}
                        <p className="vs-userside vs-date badge px-4 mb-4">03 Feb 2022</p>
                        {/*----- User message -----*/}
                        <div className="vs-userside vs-message position-relative ">
                          <div className="row justify-content-between align-items-center">
                            <div className="col-8">
                              <p className="text-truncate mb-0">
                                15 Niven Avenue, Douglasdale
                              </p>
                            </div>
                            <div className="col-4">
                              <p className="vs-userside vs-time text-end fs-5 mb-0">
                                23.23 AM
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        {/*---- bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          Git it! Are you the owner or do yu rent here?
                        </p>
                        {/*----- Bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          10.00 AM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*----- User message date -----*/}
                        <p className="vs-userside vs-date badge px-4 mb-4">
                          03 Feb 2022
                        </p>
                        {/*----- User message as input -----*/}
                        <div className="d-flex justify-content-between flex-wrap">
                          {/*----- input -----*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption" id="vsradio" />
                          <label className="form-check-label mb-4" htmlFor="vsradio">
                            I'm the owner
                          </label>
                          {/*----- input -----*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption" id="vsradio1" />
                          <label className="form-check-label mb-4" htmlFor="vsradio1">
                            I rent here
                          </label>
                        </div>
                      </li>
                      <li>
                        {/*----- Bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          How log have you been living at this home?
                        </p>
                        {/*----- Bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          15.00 AM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*----- User message date -----*/}
                        <p className="vs-userside vs-date badge px-4 mb-4">
                          03 Feb 2022
                        </p>
                        {/*----- User message as input -----*/}
                        <div className="d-flex justify-content-between flex-wrap">
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption1" id="vsradio0" />
                          <label className="form-check-label mb-4" htmlFor="vsradio0">
                            Less than 1 year
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption1" id="vsradio3" />
                          <label className="form-check-label mb-4" htmlFor="vsradio3">
                            1-2 years
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption1" id="vsradio5" />
                          <label className="form-check-label mb-4" htmlFor="vsradio5">
                            2-5 years
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption1" id="vsradio8" defaultChecked />
                          <label className="form-check-label mb-4" htmlFor="vsradio8">
                            5 + years
                          </label>
                        </div>
                      </li>
                      <li>
                        {/*----- Bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          Tell me a bit about security, select the options that apply.
                        </p>
                        {/*----- Bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          02.22 PM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*----- User message as input -----*/}
                        <div className="d-flex justify-content-between flex-wrap flex-row-reverse">
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption2" id="vs_radio0" />
                          <label className="form-check-label mb-4" htmlFor="vs_radio0">
                            Burglar bars
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption2" id="vs_radio3" defaultChecked />
                          <label className="form-check-label mb-4" htmlFor="vs_radio3">
                            Electric fence
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption2" id="vs_radio5" />
                          <label className="form-check-label mb-4" htmlFor="vs_radio5">
                            Alarm System
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption2" id="vs_radio8" />
                          <label className="form-check-label mb-4" htmlFor="vs_radio8">
                            Security Boom
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption2" id="vs_radio89" />
                          <label className="form-check-label mb-4" htmlFor="vs_radio89">
                            None of the above
                          </label>
                        </div>
                      </li>
                      <li>
                        {/*----- Bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          Are the contents of this hoe currently insured?
                        </p>
                        {/*----- Bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          02.22 PM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*----- User message as input -----*/}
                        <div className="vs-inputs ">
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoptiOn2" id="vs_radiO" />
                          <label className="form-check-label vs-single mb-4" htmlFor="vs_radiO">
                            No, they aren't Insured
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoptiOn2" id="vs_radiO3" defaultChecked />
                          <label className="form-check-label vs-single mb-4" htmlFor="vs_radiO3">
                            Yes, I have existing insurance
                          </label>
                        </div>
                      </li>
                      <li>
                        {/*----- Bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          How much cover are your looking for?
                        </p>
                        {/*----- Bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          02.12 AM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*----- User message as input -----*/}
                        <div className="vs-inputs ">
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoptiON2" id="vs_radIO" />
                          <label className="form-check-label vs-single mb-4" htmlFor="vs_radIO">
                            Under R200 000
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoptiON2" id="vs_radIO3" />
                          <label className="form-check-label vs-single mb-4" htmlFor="vs_radIO3">
                            R200 000 to R500 000
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoptiON2" id="vs_radIO2" defaultChecked />
                          <label className="form-check-label vs-single mb-4" htmlFor="vs_radIO2">
                            R500 000 to R1 000 000
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-check-input d-none" type="radio" name="vsoptiON2" id="vs_radIO1" />
                          <label className="form-check-label vs-single mb-4" htmlFor="vs_radIO1">
                            More than R1 000 000
                          </label>
                        </div>
                      </li>
                      <li>
                        {/*----- Bot message -----*/}
                        <p className="vs-appside vs-message text-white position-relative">
                          Are there any specific household items you want to
                          mentiion that should be inluded in this cover? For example items
                          over R10 000 in value or unique collectibles?
                        </p>
                        {/*----- Bot message time -----*/}
                        <p className="vs-appside vs-time text-end fs-5">
                          02.12 AM
                          <img src="./assets/images/chatbot/icon/MessageCheckIcon.svg" className="ms-2" />
                        </p>
                      </li>
                      <li>
                        {/*----- User message as input -----*/}
                        <div className="d-flex justify-content-between flex-wrap">
                          {/*----- Input -----*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption12" id="vsradio04" defaultChecked />
                          <label className="form-check-label mb-4" htmlFor="vsradio04">
                            Yes
                          </label>
                          {/*----- Input -----*/}
                          <input className="form-check-input d-none" type="radio" name="vsoption12" id="vsradio34" />
                          <label className="form-check-label mb-4" htmlFor="vsradio34">
                            No
                          </label>
                        </div>
                        {/*----- Add file Input -----*/}
                        <div className="vs-inputs ">
                          {/*--- Input ---*/}
                          <input className="form-control d-none" type="file" name="vsoptiON21" id="vs_radIO11" />
                          <label className="form-label vs-single mb-4" htmlFor="vs_radIO11">
                            Choose from Assets
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-control d-none" type="file" name="vsoptiON21" id="vs_radIO33" />
                          <label className="form-label vs-single mb-4" htmlFor="vs_radIO33">
                            Add Asset
                          </label>
                          {/*--- Input ---*/}
                          <input className="form-control d-none" type="file" name="vsoptiON21" id="vs_radIO22" />
                          <label className="form-label vs-single mb-4" htmlFor="vs_radIO22">
                            Record a short video
                          </label>
                        </div>
                      </li>
                      <li>
                        {/*----- Uploded video -----*/}
                        <div className="vs-chat_video position-relative mb-5">
                          <video src="./assets/video/chat-video.mp4" width="100%" height="100%" autoPlay loop>
                          </video>
                          {/*----- playbutton -----*/}
                          <button type="button" className="border-0 bg-transparent vs-play-btn">
                            <img src="./assets/images/chatbot/icon/PlayIcon.svg" />
                          </button>
                        </div>
                      </li>
                      <li>
                        {/*----- User message as input -----*/}
                        <div className="vs-inputs position-relative">
                          {/*--- Input ---*/}
                          <input className="form-control opacity-0 position-absolute top-0 bottom-0 left-0 right-0 z-2" type="vs-date" id="vs_radiovs-date" />
                          <label className="form-label bg-white text-black vs-single mb-0" htmlFor="vs_radiovs-date">
                            24 / 04 / 2022
                          </label>
                          {/*----- edit date icon -----*/}
                          <button type="button" className="vs-edit border-0 bg-transparent p-0 m-0" style={{ zIndex: 5 }}>
                            <img src="./assets/images/chatbot/icon/EditDateIcon.svg" />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*----- chat bot and user message End -----*/}
          </section>
          {/*----- Chat bot input start -----*/}

          <footer class="vs-floting-footer vs-footer-gradient">
            <div className="vs-chat_bot_input">
              {/*----- Input -----*/}
              <div className="input-group">
                <input type="text" className="form-control bg-transparent border-0 rounded-0" placeholder="Add message......" />
                {/*----- Send button -----*/}
                <Link to="/dashboard" className="p-0">
                  <img src="./assets/images/chatbot/icon/SendIcon.svg" />
                </Link>
              </div>
            </div>
          </footer>


          {/*----- Chat bot input End -----*/}
        </div>
        {/*----- Vodasure Chat container End -----*/}
      </div>
      {/*----- Main Container For Chat index End -----*/}
    </>
  )
}

export default ChatBot