import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import exifr from 'exifr'
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';

const AddImageCanvas = (props) => {
  // console.log("id", props.assetId)
  const navigate = useNavigate();
  const AddAssets = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      const latLng = await exifr.gps(files[0]);
      console.log({ latLng });
      if (latLng == undefined) {
        toast(
          <>
            <div className="activityalert">
              <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                <p className="m-0">
                  <img src="./assets/Newimg/redclose.png" className="bluecheckimg" alt />
                  This image does not contain latitude and longitude
                </p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
            </div>
          </>
        );
      } else {
        navigate('/update-check-asset',
          {
            state: {
              imgPreview: URL.createObjectURL(e.target.files[0]),
              img: e.target.files[0],
              assetId: props.assetId,
              assetLatLngAdd: {
                lat: latLng?.latitude,
                lng: latLng?.longitude,
              }
            }
          });
      }
    }
  };

  return (
    <>
      <Offcanvas show={props.addAssetCanvas} onHide={props.addAssetCanvasClose} scroll={false} placement='bottom' id="addassetModal">
        {/*----- Offcanvas Header -----*/}
        <div className="offcanvas-header px-5 mt-5">
          <a href="javascript:void(0)" onClick={props.addAssetCanvasClose} className="btn-close ms-auto" />
        </div>
        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body">
          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5">
            <h3 className="fs-2 w-75 mx-auto">
              Please choose how you would<br />like to add your asset
            </h3>
          </div>
          {/*----- Section Random Intro End -----*/}
          <ul className="vs-getaquote list-unstyled pb-0 px-0">
            <li className="py-5">
              <input type='file' id="assetIMG" className='d-none' accept="image/*" capture="environment" onChange={(e) => AddAssets(e)} />

              <label htmlFor='assetIMG' className="row justify-content-between align-items-center">
                <div className="col-1">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/camera1.svg"} />
                </div>
                <div className="col-9">
                  <p className="w-100 text-truncate mb-0">
                    Take a photo
                  </p>
                </div>
                <div className="col-1 text-end">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/GoIcon.svg"} />
                </div>
              </label>
            </li>
            <li className="py-5">
              <a href="#" className="row justify-content-between align-items-center">
                {/*-- Locaiton Icon --*/}
                <div className="col-1">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/GalleryIcon.svg"} />
                </div>
                {/*-- Domicile Details --*/}
                <div className="col-9">
                  <p className="w-100 text-truncate mb-0">
                    Upload a photo from my gallery
                  </p>
                </div>
                {/*-- Go Icon --*/}
                <div className="col-1 text-end">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/GoIcon.svg"} />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </Offcanvas>
    </>
  )
}

export default AddImageCanvas