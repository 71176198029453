import React from 'react'

const Tour = (props) => {
  return (
    <>
      {props.tourSteps == 1 && (
        <>
          <div className="wt-popover stepno1">
            <div className="wt-arrow" />
            <div className="wt-popover-inner">
              <div className="wt-content">
                <p className='mb-0 pt-2'>
                  Your asset value is the total value of your assets. Increase this amount by adding devices or home contents.
                </p>

                <button className="wt-btn-back btn closebtn border-0" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  <img src="./assets/images/icon/Closebtn.svg" />
                </button>
              </div>

              <div className='d-flex justify-content-between align-items-center'>
                <button className="wt-btns wt-btn-back" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  Skip
                </button>

                <p className='mb-0'>
                  <span>
                    1
                  </span>
                  /
                  <span>
                    7
                  </span>
                </p>

                <button className="wt-btns wt-btn-next" onClick={() => props.setTourSteps(2)} >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {props.tourSteps == 2 && (
        <>
          <div className="wt-popover stepno2">
            <div className="wt-arrow" />
            <div className="wt-popover-inner">
              <div className="wt-content">
                <p className='mb-0 pt-2'>
                  Tap here to add any asset in just a few clicks. Feel free to insure them anytime.
                </p>

                <button className="wt-btn-back btn closebtn border-0" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  <img src="./assets/images/icon/Closebtn.svg" />
                </button>
              </div>

              <div className='d-flex justify-content-between align-items-center'>
                <button className="wt-btns wt-btn-back" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  Skip
                </button>

                <p className='mb-0'>
                  <span>
                    2
                  </span>
                  /
                  <span>
                    7
                  </span>
                </p>

                <button className="wt-btns wt-btn-next" onClick={() => props.setTourSteps(3)} >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {props.tourSteps == 3 && (
        <>
          <div className="wt-popover stepno3">
            <div className="wt-arrow" />
            <div className="wt-popover-inner">
              <div className="wt-content">
                <p className='mb-0 pt-2'>
                  Tap here to view, manage and insure your assets.
                </p>

                <button className="wt-btn-back btn closebtn border-0" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  <img src="./assets/images/icon/Closebtn.svg" />
                </button>
              </div>

              <div className='d-flex justify-content-between align-items-center'>
                <button className="wt-btns wt-btn-back" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  Skip
                </button>

                <p className='mb-0'>
                  <span>
                    3
                  </span>
                  /
                  <span>
                    7
                  </span>
                </p>

                <button className="wt-btns wt-btn-next" onClick={() => props.setTourSteps(4)}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {props.tourSteps == 4 && (
        <>
          <div className="wt-popover stepno4">
            <div className="wt-arrow" />
            <div className="wt-popover-inner">
              <div className="wt-content">
                <p className='mb-0 pt-2'>
                  Tap here to get your first quote in just a few clicks.
                </p>

                <button className="wt-btn-back btn closebtn border-0" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  <img src="./assets/images/icon/Closebtn.svg" />
                </button>
              </div>

              <div className='d-flex justify-content-between align-items-center'>
                <button className="wt-btns wt-btn-back" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  Skip
                </button>

                <p className='mb-0'>
                  <span>
                    4
                  </span>
                  /
                  <span>
                    7
                  </span>
                </p>

                <button className="wt-btns wt-btn-next" onClick={() => props.setTourSteps(5)}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {props.tourSteps == 5 && (
        <>
          <div className="wt-popover stepno5">
            <div className="wt-arrow" />
            <div className="wt-popover-inner">
              <div className="wt-content">
                <p className='mb-0 pt-2'>
                  Tap here to view and manage
                  your policy covering your assets.
                </p>

                <button className="wt-btn-back btn closebtn border-0" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  <img src="./assets/images/icon/Closebtn.svg" />
                </button>
              </div>

              <div className='d-flex justify-content-between align-items-center'>
                <button className="wt-btns wt-btn-back" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  Skip
                </button>

                <p className='mb-0'>
                  <span>
                    5
                  </span>
                  /
                  <span>
                    7
                  </span>
                </p>

                <button className="wt-btns wt-btn-next" onClick={() => props.setTourSteps(6)}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {props.tourSteps == 6 && (
        <>
          <div className="wt-popover stepno6">
            <div className="wt-arrow" />
            <div className="wt-popover-inner">
              <div className="wt-content">
                <p className='mb-0 pt-2'>
                  Tap here to view and manage
                  your insurance claims.
                </p>

                <button className="wt-btn-back btn closebtn border-0" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  <img src="./assets/images/icon/Closebtn.svg" />
                </button>
              </div>

              <div className='d-flex justify-content-between align-items-center'>
                <button className="wt-btns wt-btn-back" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  Skip
                </button>

                <p className='mb-0'>
                  <span>
                    6
                  </span>
                  /
                  <span>
                    7
                  </span>
                </p>

                <button className="wt-btns wt-btn-next" onClick={() => props.setTourSteps(7)}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {props.tourSteps == 7 && (
        <>
          <div className="wt-popover stepno7">
            <div className="wt-arrow" />
            <div className="wt-popover-inner">
              <div className="wt-content">
                <p className='mb-0 pt-2'>
                  Tap here to view and manage your profile, address, banking and account details.
                </p>

                <button className="wt-btn-back btn closebtn border-0" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  <img src="./assets/images/icon/Closebtn.svg" />
                </button>
              </div>

              <div className='d-flex justify-content-between align-items-center'>
                <button className="wt-btns wt-btn-back" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  Skip
                </button>

                <p className='mb-0'>
                  <span>
                    7
                  </span>
                  /
                  <span>
                    7
                  </span>
                </p>

                <button className="wt-btns wt-btn-next" onClick={() => {
                  localStorage.setItem("tour", "true");
                  props.setTourType(true)
                }
                }>
                  Got it !
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Tour