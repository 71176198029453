import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Accordion, Offcanvas } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetDataWithToken, PostDataWithToken } from '../../ApiHelper/ApiHelper';
import ConfirmAsset from './ConfirmAsset';
import { useForm } from 'react-hook-form';

const SelectObject = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const location = useLocation();
  const [SelectObject, setSelectObject] = useState([]);

  const objectDetect = `https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCGmrsyl4cNgdSzXELMguT82taHAhXvNk4`;
  const image = location?.state?.image;
  console.log("image", image);

  useEffect(() => {
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = function () {
      let base64data = reader.result;
      console.log("64data", base64data.split(/[\s,]+/));
      // let arr = base64data.substring(23, base64data?.length);
      let arr = base64data.split(/[\s,]+/);
      console.log("base64data", arr);

      let objData = {
        "requests": [
          {
            "image": {
              "content": arr[1]
            },
            "features": [
              {
                "type": "OBJECT_LOCALIZATION",
                "maxResults": 15
              }
            ]
          }
        ]
      }
      axios.post(objectDetect, objData)
        .then((responses) => {
          setSelectObject(responses?.data?.responses?.[0]?.localizedObjectAnnotations);
          console.log("responses", responses?.data?.responses?.[0]?.localizedObjectAnnotations);
        })
    }

  }, []);

  const handleSearch = (data) => {
    // setSearchTextLength(text);
    // if (text == '') {
    //   setAssetList([])
    // }
    let assetFormData = new FormData();
    assetFormData.append('brand', data.brand);
    assetFormData.append('query', data.query);
    PostDataWithToken('asset/price-api', assetFormData)
      .then((response) => {
        if (response.status == true) {
          navigate('/select-object-price',
            {
              state: {
                selectObject: response.data.results,
                image: image
              }
            }
          )
          console.log("brand", response.data.results);
          // const data = response.data.results;
          // data.filter((type) => type.brand);
          // const results = data.filter(element => {
          //   return element.brand !== 'Unbranded';
          // });
          // setAssetList(results);
          // setShowList(false);
        }
      })
  };

  return (
    <>
      {/* {console.log("assetData", assetData)} */}
      <div className="vs-main-container">
        <div className="container">
          <div className="row justify-content-center responsive-row">
            <div className="col-xl-4 col-lg-6 col-sm-9 col-12 col-md-8 responsive-col">
              <div className="vs-main-content vs-addphoto vs-submission position-relative px-4">
                {/*----- Vs Default Header Start -----*/}
                <div className="vs-header">
                  <div className="row justify-content-between align-items-center m-0">
                    <div className="col-2 text-start ps-0">
                      <a href="javascipt:void(0)" onClick={() => navigate(-1)} ><img src="./assets/images/icon/BackIcon.svg" /></a>
                    </div>
                    <div className="col-6 text-center">
                      <h4 className="vs-header-title">Select Object</h4>
                    </div>
                    <div className="col-2 text-end pe-0" />
                  </div>
                </div>
                {/*----- Vs Default Header End -----*/}
                <ul className="vs-getaquote list-unstyled">
                  {SelectObject && SelectObject?.length > 0 && SelectObject?.map((obj, index) => (
                    <li className="py-5" key={index}>
                      <a href='javascript:void(0)' className="row justify-content-between align-items-center"
                      >
                        <div className="col-9">
                          <p className="w-100 text-truncate mb-0">
                            {obj?.name}
                          </p>
                        </div>
                        <div className="col-1 text-end">
                          <img src="./assets/images/icon/GoIcon.svg" />
                        </div>
                      </a>
                    </li>
                  ))}

                  <li className="py-5">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <a href='javascript:void(0)' className="row justify-content-between align-items-center">
                            <div className="col-9">
                              <p className="w-100 mb-0">
                                Others
                              </p>
                            </div>
                          </a>
                        </Accordion.Header>
                        <Accordion.Body className='position-relative'>
                          <form onSubmit={handleSubmit(handleSearch)}>
                            <input type="text" className="form-control mt-4 fs-4" id="adf6" placeholder="Make"
                              // onChange={(e) => handleSearch(e?.target?.value)} 
                              {...register("brand", {
                                required: "This input is invalid",
                              })}
                            />
                            {/* <div className="m-0 f-error ">{errors.brand && errors.brand.message}</div> */}
                            <input type="text" className="form-control mt-4 fs-4" id="adf6" placeholder="Model"
                              {...register("query", {
                                required: "This input is invalid",
                              })}
                            />
                            {/* <div className="m-0 f-error ">{errors.query && errors.query.message}</div> */}
                            <button type='submit' className='btn btn-primary fs-3 mt-3'>
                              Submit
                            </button>

                            {/* {showList == false && (
                              <>
                                {searchTextLength?.length > 3 && (
                                  <div className="searchaddresslisting rounded-4">
                                    <ul className="list-unstyled">
                                      {assetList && assetList?.length > 0 && assetList?.map((asset, index) => (
                                        <li key={index} className='noafter'>
                                          <a href="javascript:void(0)" className="w-100 text-truncate" title={asset?.product_title}
                                            onClick={() => { setConfirmAssetCanvas(true); setSelectCategory(asset) }}
                                          >
                                            {asset?.product_title}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </>
                            )} */}
                          </form>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    {/*----- Address listing -----*/}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SelectObject