import React from 'react'

const ClaimHistory = ({ setStepAnswer }) => {
  return (
    <>
      <div className="slideform-slide h-100">
        <div className="slideform-group h-100">
          <div className="Section_random_intro text-center my-5">
            <h3 className="fs-2 mb-3 w-75 mx-auto">
              Claims history
            </h3>
            <p className="text-secondary w-75 mx-auto">
              How many claims have you had
              in the last 5 years?
            </p>
          </div>
          <ul className="list-unstyled pt-4 rounded-4">
            <div className="d-flex justify-content-between flex-wrap multilabel">
              <input className="form-check-input d-none" type="radio" name="vsoption1" id="vsradio0" value={0} onChange={(e) => setStepAnswer(e?.target?.value)} />
              <label className="form-check-label mb-4" htmlFor="vsradio0">
                0
              </label>
              <input className="form-check-input d-none" type="radio" name="vsoption1" id="vsradio3" value={1} onChange={(e) => setStepAnswer(e?.target?.value)} />
              <label className="form-check-label mb-4" htmlFor="vsradio3">
                1
              </label>
              <input className="form-check-input d-none" type="radio" name="vsoption1" id="vsradio3" value={2} onChange={(e) => setStepAnswer(e?.target?.value)} />
              <label className="form-check-label mb-4" htmlFor="vsradio3">
                2
              </label>
              <input className="form-check-input d-none" type="radio" name="vsoption1" id="vsradio3" value={3} onChange={(e) => setStepAnswer(e?.target?.value)} />
              <label className="form-check-label mb-4" htmlFor="vsradio3">
                3+
              </label>
            </div>
          </ul>
        </div>
      </div>
    </>
  )
}

export default ClaimHistory