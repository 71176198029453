import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'
import Cookies from 'js-cookie';

const MyProfile = () => {
  const accessToken = Cookies.get('accessToken');
  const [helpCanvas, setAddAssetCanvas] = useState(false);
  const helpCanvasClose = () => setAddAssetCanvas(false);
  return (
    <>
      {/*----- Main Container For Profile Start -----*/}
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-myprofile position-relative">
            {/*----- Vs Header Start -----*/}
            <div className="vs-header oneBlank">
              {/*------ Header Mid Heading (Left Part) ------*/}
              <Link to={`/${accessToken}`}><img src="./assets/images/icon/BackIcon.svg" /></Link>
              {/*------ Header Mid Heading (Middle Part) ------*/}
              <h4 className="vs-header-title d-none">Profile</h4>
            </div>
            {/*----- Vs Header end -----*/}


            {/*----- Section Random Intro Start -----*/}
            <div className="Section_random_intro text-center mb-5">
              <h3 className="fs-2 mb-3 w-75 mx-auto">
                My profile
              </h3>
              {/*----- Here we don't need the subheading of Random intro so we are not using it -----*/}
            </div>
            {/*----- Section Random Intro End -----*/}
            {/*----- Vodasure Profile Content container Start -----*/}
            <section className="vs-content_section">
              {/*----- Domicile List Container start -----*/}
              <ul className="vs-profileOption list-unstyled pb-0 rounded-3">
                <li>
                  <Link to="/my-details" className="row justify-content-between align-items-center">
                    {/*-- Locaiton Icon --*/}
                    <div className="col-1">
                      <img src="./assets/images/icon/MyProfile.svg" className='iimage' />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-8">
                      <p className="w-100 mb-0">My details</p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-2 text-end">
                      <img src="./assets/images/icon/GoIcon.svg" />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/physical-address" state={{ updateAddress: true }} className="row justify-content-between align-items-center">
                    {/*-- Locaiton Icon --*/}
                    <div className="col-1">
                      <img src="./assets/Newimg/proNewaddress.png" className='iimage' />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-8">
                      <p className="w-100 mb-0">My address</p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-2 text-end">
                      <img src="./assets/images/icon/GoIcon.svg" />
                    </div>
                  </Link>
                </li>
                <li className='d-none'>
                  <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                    {/*-- Locaiton Icon --*/}
                    <div className="col-1">
                      <img src="./assets/images/icon/cancelPolicy.svg" />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-8">
                      <p className="w-100 mb-0">My policy</p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-2 text-end">
                      <img src="./assets/images/icon/GoIcon.svg" />
                    </div>
                  </a>
                </li>
                {/* <li>
                      <Link to="/profile-under-writing" className="row justify-content-between align-items-center">
                        <div className="col-1">
                          <img src="./assets/images/icon/cancelPolicy.svg" />
                        </div>
                        <div className="col-8">
                          <p className="w-100 mb-0">Under writting</p>
                        </div>
                        <div className="col-2 text-end">
                          <img src="./assets/images/icon/GoIcon.svg" />
                        </div>
                      </Link>
                    </li> */}
                <li>
                  <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                    {/*-- Locaiton Icon --*/}
                    <div className="col-1">
                      <img src="./assets/Newimg/proNewbanking.png" className='iimage' />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-8">
                      <p className="w-100 mb-0">Banking details</p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-2 text-end">
                      <img src="./assets/images/icon/GoIcon.svg" />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                    {/*-- Locaiton Icon --*/}
                    <div className="col-1">
                      <img src="./assets/Newimg/proNewdelete.png" className='iimage' />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-8">
                      <p className="w-100 mb-0">Delete my account</p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-2 text-end">
                      <img src="./assets/images/icon/GoIcon.svg" />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="row justify-content-between align-items-center" onClick={() => setAddAssetCanvas(true)}>
                    {/*-- Locaiton Icon --*/}
                    <div className="col-1">
                      <img src="./assets/Newimg/Newprohelp.png" className='iimage' />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-8">
                      <p className="w-100 mb-0">Help</p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-2 text-end">
                      <img src="./assets/images/icon/GoIcon.svg" />
                    </div>
                  </a>
                </li>
              </ul>
              {/*----- Domicile List Container End -----*/}
            </section>
            {/*----- Vodasure Profile Content container End -----*/}
          </div>
        </div>
      </div>
      {/*----- Main Container For Profile End -----*/}
      <Offcanvas show={helpCanvas} onHide={helpCanvasClose} scroll={false} className="p-4" placement='bottom' id="addassetModal">
        <div>
          {/*----- Offcanvas Header -----*/}
          <div className="offcanvas-header">
            <button type="button" className="btn-close ms-auto" onClick={helpCanvasClose} />
          </div>
          {/*----- Offcanvas Body -----*/}
          <div className="offcanvas-body px-5">
            <p className="mb-4">Helping you stay connected</p>
            <small className="text-muted fs-4">
              You can get in touch with customer care agents on your cellphone by dialing 082 135,
              via email on
              customercare@vodacom.co.za, via live chat at www.vodacom.co.za (Mon to Sun from
              06:00 to 22:00). These
              services are available 365 days a year.
            </small>
            <div className="d-flex justify-content-evenly align-items-center py-5 mt-2">
              <a href="javascript:void(0)">
                <img src="./assets/images/icon/QuestionIcon.svg" style={{ verticalAlign: 'sub' }} className='me-3' alt />
                Get help
              </a>
              <a href="javascript:void(0)">
                <img src="./assets/images/icon/MessageIcon.svg" style={{ verticalAlign: 'sub' }} className='me-3' alt />
                Chat to us
              </a>
            </div>
          </div>
        </div>

      </Offcanvas>
    </>
  )
}

export default MyProfile