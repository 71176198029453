import React from "react";
import { Routes, Route } from "react-router";
// Onboarding
// import Onboarding from '../../components/onboarding/Onboarding'
// Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";
// My Domicile
import AddDomicile from "../pages/MyDomicile/AddDomicile";
import DomicileMap from "../pages/MyDomicile/DomicileMap";
import MyDomicile from "../pages/MyDomicile/MyDomicile";
// My Quotes
import EmptyQuote from "../pages/MyQoutes/EmptyQuote";
import QuoteList from "../pages/MyQoutes/QuoteList";
import CancelQuote from "../pages/MyQoutes/CancelQuote";
import QuoteFeedback from "../pages/MyQoutes/QuoteFeedback";
import QuoteCancelReason from "../pages/MyQoutes/QuoteCancelReason";
// My Profile
import MyProfile from "../pages/MyProfile/MyProfile";
import MyDetails from "../pages/MyProfile/MyDetails";
import UpdateAddress from "../pages/MyProfile/UpdateAddress";
import ProfileConfirmation from "../pages/MyProfile/ProfileConfirmation";
// ChatBbot
import ChatIndex from "../chatbot/ChatIndex";
import ChatBot from "../chatbot/ChatBot";
// My Locker
import EmptyLocker from "../pages/MyLocker/EmptyLocker";
import MyLocker from "../pages/MyLocker/MyLocker";
// Add Asset
import TakePhoto from "../pages/AddAssets/TakePhoto";
import CheckAsset from "../pages/AddAssets/CheckAsset";
// import TakePhoto from '../pages/AddAssets/TakePhoto'
// import AssetCamera from '../pages/AddAssets/AssetCamera'
// import OtherAsset from '../pages/AddAssets/OtherAsset'
// import ConfirmAsset from '../pages/AddAssets/ConfirmAsset'
// import AssetAdded from '../pages/AddAssets/AssetAdded'
// import GalleryUpload from '../pages/AddAssets/GalleryUpload'
// import UploadPhoto from '../pages/AddAssets/UploadPhoto'
// import PhotoRejection from '../pages/AddAssets/PhotoRejection'
// import UpdateAsset from '../pages/AddAssets/UpdateAsset'
// import UpdateTakePhoto from '../pages/AddAssets/UpdateTakePhoto'
// import UpdateOtherAsset from '../pages/AddAssets/UpdateOtherAsset'
// My Policy
import MyPolicy from "../pages/MyPolicy/MyPolicy";
import ClaimType from "../pages/MyPolicy/ClaimType";
import ClaimDetails from "../pages/MyPolicy/ClaimDetails";
import ClaimSubmit from "../pages/MyPolicy/ClaimSubmit";
// import CoverSummary from '../pages/MyPolicy/CoverSummary'
import DeliveryMethod from "../pages/MyPolicy/DeliveryMethod";
import YourCovered from "../pages/MyPolicy/YourCovered";
import DebitOrder from "../pages/MyPolicy/DebitOrder";
// UnderWriting
import UnderWritingSteps from "../pages/UnderWritings/UnderWritingSteps";

// import UnderWriting from '../pages/UnderWriting/UnderWriting'
// import ShareData from '../pages/UnderWriting/ShareData'
import YourDetails from "../pages/BuyInsurance/YourDetails";
// Buy Insurance
import YourAddress from "../pages/BuyInsurance/YourAddress";
// My Policy
import ViewPolicy from "../pages/MyPolicy/ViewPolicy";
// Beneficiary or Transfer Locker
import EmpityBeneficiary from "../pages/TransferLocker.js/EmpityBeneficiary";
import ChooseBeneficiary from "../pages/TransferLocker.js/ChooseBeneficiary";
import Lodar from "../pages/lodar";
import MyAssets from "../pages/AddAssets/MyAssets";
import PersonalDetails from "../pages/PersonalDetails/PersonalDetails";
import PhysicalAddress from "../pages/PhysicalAddress/PhysicalAddress";
import DebitCard from "../pages/DebitCard/DebitCard";
import Covered from "../pages/Covered/Covered";
import AddPhysicalAddress from "../pages/PhysicalAddress/AddPhysicalAddress";
import SelectObject from "../pages/AddAssets/SelectObject";
import SelectObjectPrice from "../pages/AddAssets/SelectObjectPrice";
import Notification from "../pages/Notification/Notification";
import ProfileUnderWritingSteps from "../pages/ProfileUnderWriting/ProfileUnderWritingSteps";
import CoverSummery from "../pages/CoverSummery/CoverSummery";
import AddAsset from "../pages/AddAssets/AddAsset";
import TestingComponent from "../pages/AddAssets/TestingComponent";
import UpdateAsset from "../pages/AddAssets/UpdateAsset";
import UpdateCheckAsset from "../pages/AddAssets/UpdateCheckAsset";
import Onboarding from "../onboarding/Onboarding";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/testing" element={<TestingComponent />} />
      {/* Lodar */}
      <Route path="/lodar" element={<Lodar />} />
      {/* Onboarding */}
      <Route path="/" element={<Onboarding />} />
      {/* Dashboard */}
      <Route path="/:id" element={<Dashboard />} />
      {/* My Locker */}
      <Route path="/empty-locker" element={<EmptyLocker />} />
      {/* only for static */}
      <Route path="/my-locker" element={<MyLocker />} />
      {/* My Domicile */}
      <Route path="/my-domicile" element={<MyDomicile />} />
      <Route path="/add-domicile" element={<AddDomicile />} />
      <Route path="/domicile-map" element={<DomicileMap />} />
      {/* My Qoute */}
      <Route path="/empty-quote" element={<EmptyQuote />} />{" "}
      {/* only for static */}
      <Route path="/quote-list" element={<QuoteList />} />
      <Route path="/cancel-quote" element={<CancelQuote />} />
      <Route path="/quote-feedback" element={<QuoteFeedback />} />
      <Route path="/quote-cancel-reason" element={<QuoteCancelReason />} />
      {/* My Profile */}
      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/my-details" element={<MyDetails />} />
      <Route path="/profile-confirmation" element={<ProfileConfirmation />} />
      <Route path="/update-address" element={<UpdateAddress />} />
      {/* Add Assets */}
      <Route path="/take-photo" element={<TakePhoto />} />
      <Route path="/check-asset" element={<CheckAsset />} />
      <Route path="/select-object" element={<SelectObject />} />
      <Route path="/select-object-price" element={<SelectObjectPrice />} />
      <Route path="/my-assets" element={<MyAssets />} />
      <Route path="/add-asset" element={<AddAsset />} />
      <Route path="/update-asset" element={<UpdateAsset />} />
      <Route path="/update-check-asset" element={<UpdateCheckAsset />} />
      {/* <Route path="/asset-camera" element={<AssetCamera />} /> */}
      {/* <Route path="/other-asset" element={<OtherAsset />} /> */}
      {/* <Route path="/confirm-asset" element={<ConfirmAsset />} /> */}
      {/* <Route path="/asset-added" element={<AssetAdded />} /> */}
      {/* <Route path="/gallery-upload" element={<GalleryUpload />} /> */}
      {/* <Route path="/upload-photo" element={<UploadPhoto />} /> */}
      {/* <Route path="/photo-rejection" element={<PhotoRejection />} /> */}
      {/* <Route path="/update-asset" element={<UpdateAsset />} />  same as confrim asset (static) */}
      {/* <Route path="/update-take-photo" element={<UpdateTakePhoto />} /> */}
      {/* <Route path="/update-other-asset" element={<UpdateOtherAsset />} /> */}
      {/* Notiication */}
      <Route path="/notification" element={<Notification />} />
      {/* Cover Summery */}
      <Route path="/cover-summary" element={<CoverSummery />} />
      {/* My Policy */}
      <Route path="/my-policy" element={<MyPolicy />} />
      <Route path="/claim-type" element={<ClaimType />} />
      <Route path="/claim-details" element={<ClaimDetails />} />
      <Route path="/claim-submit" element={<ClaimSubmit />} />
      <Route path="/delivery-method" element={<DeliveryMethod />} />
      {/* <Route path="/cover-summary" element={<CoverSummary />} /> */}
      <Route path="/debit-order" element={<DebitOrder />} />
      <Route path="/your-covered" element={<YourCovered />} />
      <Route path="/view-policy" element={<ViewPolicy />} />
      {/* UnderWriting */}
      <Route path="/under-writing" element={<UnderWritingSteps />} />
      {/* Profile underWriting */}
      <Route
        path="/profile-under-writing"
        element={<ProfileUnderWritingSteps />}
      />
      {/* Personal Details */}
      <Route path="/personal-details" element={<PersonalDetails />} />
      {/* Physical Address */}
      <Route path="/physical-address" element={<PhysicalAddress />} />
      <Route path="/add-physical-address" element={<AddPhysicalAddress />} />
      {/* Debit Card */}
      <Route path="/debit-card" element={<DebitCard />} />
      {/* Covered */}
      <Route path="/covered" element={<Covered />} />
      {/* <Route path="/underwriting" element={<UnderWriting />} /> */}
      {/* <Route path="/share-data" element={<ShareData />} /> */}
      {/* Buy Insurance */}
      <Route path="/your-details" element={<YourDetails />} />
      <Route path="/your-address" element={<YourAddress />} />
      {/* My Beneficiaries */}
      <Route path="/empity-beneficiary" element={<EmpityBeneficiary />} />
      <Route path="/choose-beneficiary" element={<ChooseBeneficiary />} />
      {/* Chats */}
      <Route path="/chat-index" element={<ChatIndex />} />
      <Route path="/chat-bot" element={<ChatBot />} />
    </Routes>
  );
};

export default AllRoutes;
