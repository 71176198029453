import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { GetDataWithToken, PostDataWithToken } from '../../ApiHelper/ApiHelper';
import { useForm } from 'react-hook-form';
import { toast } from 'material-react-toastify';
import Cookies from 'js-cookie';

const AddPhysicalAddress = () => {
  const accessToken = Cookies.get('accessToken');
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  const [searchTextLength, setSearchTextLength] = useState('');
  const [placeData, setPlaceData] = useState();
  const [showAddress, setShowAddress] = useState(false);
  const [suburb, setSuburb] = useState('');
  const [city, setCity] = useState('');
  const [acceptCondition, setAcceptCondition] = useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const location = useLocation();
  const [inputValue, setInputValue] = useState('');
  const [province, setProvince] = useState('');
  // console.log("province", province);

  const watchedFields = watch(['streat', 'subrub', 'city']);

  useEffect(() => {
    if (errors?.location && errors?.location?.message) {
      setShowAddress(false);
    }
  }, [])


  const handleSearch = async (text) => {

    setSearchTextLength(text);
    if (text == '') {
      setAddressList([])
    }
    if (text.length > 3) {
      GetDataWithToken(`user/get-location?name=${text}`, '').then((response) => {
        response = JSON.parse(response);
        if (response.status == true) {
          if (response.data.predictions.length > 0) {
            setAddressList(response.data.predictions);
            setShowAddress(false);
          } else {
            setShowAddress(true);
          }
        }
      })
    }
  };

  const handleSelectPlace = (placeId) => {
    GetDataWithToken(`user/place-details?id=${placeId}`)
      .then((response) => {
        console.log("typpeeeee=====", typeof (response))
        response = JSON.parse(response);

        if (response.status == true) {
          // console.log("lat", response.data.result.geometry.location.lat);
          // console.log("lng", response.data.result.geometry.location.lng);
          setLat(response.data.result.geometry.location.lat);
          setLng(response.data.result.geometry.location.lng);
          setAcceptCondition(true);
          setPlaceData(response.data?.result?.formatted_address);
          const data = response.data.result.address_components
          data.map(item => {
            console.log("check-----", item.types.includes("sublocality_level_1" || "sublocality" || "political"))
            if (item.types.includes("sublocality_level_1" || "sublocality" || "political")) {
              setSuburb(item.long_name);
              // reset({
              //   suburb: item?.long_name
              // })
            } else
              if (item.types.includes("locality" || "political")) {
                setCity(item.long_name);
                // reset({
                //   city: item?.long_name
                // })
              } else
                if (item.types.includes("sublocality_level_1" || "sublocality" || "political") == false) {
                  if (item.types.includes("locality" || "political")) {
                    console.log("first---------", item.long_name)
                    setSuburb(item.long_name);
                  }
                }
          })
        }
      })
  };

  const handleAddAddress = (data) => {
    // alert();
    let addressData = {
      appartment: data?.appartment,
      streat: data?.streat ? data?.streat : placeData,
      subrub: data?.subrub ? data?.subrub : suburb,
      city: data?.city ? data?.city : city,
      province: province,
      lat: lat,
      lng: lng
    }
    PostDataWithToken('user/add-physical-address', addressData)
      .then((response) => {
        if (response.status == true) {
          navigate(`/${accessToken}`);
          JSON.stringify(localStorage.setItem("add_address", "true"));
          // toast(
          //   <>
          //     <div className="activityalert">
          //       <div className="alert alert-dismissible fade show m-0 px-0 text-center">
          //         <p className="m-0">
          //           <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
          //           Your address has been successfully saved.
          //         </p>
          //         <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
          //         </button>
          //       </div>
          //     </div>
          //   </>
          // )
        }
      })
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      toast(
        <>
          <div className="activityalert">
            <div className="alert alert-dismissible fade show m-0 px-0 text-center">
              <p className="m-0">
                <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
                Geolocation is not supported by this browser.
              </p>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
              </button>
            </div>
          </div>
        </>
      )
    }
  }

  const showPosition = (position) => {
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);

    // let latitude = -29.81821096418687;
    // let longitude = 30.97404782618146;

    GetDataWithToken(`user/place-details-by-lat-lng?lat=${latitude}&lng=${longitude}`)
      .then((response => {
        if (response.status == true) {
          setAcceptCondition(true);
          const results = response.data.results;
          setPlaceData(response.data.results?.[0]?.formatted_address);
          for (const result of results) {
            for (const component of result.address_components) {
              if (component.types.includes("sublocality")) {
                const suburb = component.long_name;
                setSuburb(suburb);
              }
              if (component.types.includes("locality")) {
                const city = component.long_name;
                setCity(city);
              }
            }
          }
        }
      }))
  };

  const handleFocus = (id, inputId, isFocus) => {
    const inputElement = document.getElementById(id);
    const divElement = document.getElementById(inputId);
    if (inputElement) {
      if (isFocus) {
        setTimeout(() => {
          inputElement.classList.add("focuskeyboard");
          divElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
      } else {
        inputElement.classList.remove("focuskeyboard");
      }
    }
  };

  return (
    <>
      {/*----- Main Container For Dashboard Start -----*/}
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-addressform position-relative">
            <form onSubmit={handleSubmit(handleAddAddress)}>
              {/*----- Vs Header Start -----*/}
              <div className="vs-header Withbtngroup">
                {/*------ Header Mid Heading (Left Part) ------*/}
                <a href="javascript:void(0)" onClick={() => navigate(`/${accessToken}`)} className="">
                  <img src="./assets/Newimg/backbtnicon.png" className="headerlefticon" />
                </a>

                {/*------ Header Mid Heading (Middle Part) ------*/}
                <h4 className="vs-header-title d-none">vodasure | asset cover</h4>

                {/*------ Header Mid Heading (Right Part) ------*/}
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                  <a href="#" className="btn border-0 me-2">
                    <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                  </a>
                </div>
              </div>
              {/*----- Vs Header end -----*/}

              {/*----- Vodasure Add address content section Start -----*/}
              <section className="vs-content_section" id='keyboardFocus'>
                <h2 className="mb-0">
                  Physical address
                </h2>

                <p className="text-secondary my-4 pb-2">
                  Please confirm your physical address:
                </p>

                <div className="position-relative">
                  <ul className="vs-inputform list-unstyled pb-0 pt-5">
                    <li className="gpsinput">
                      {/*----- Floating Label input -----*/}
                      <div className="form-floating pb-0">
                        {/*--- Input ---*/}
                        <input type="text" className="form-control" placeholder=" " id="adf6"
                          autoComplete="off"
                          // onChange={(e) => handleSearch(e?.target?.value)} 
                          {...register("location", {
                            onChange: (e) => handleSearch(e?.target?.value),
                            maxLength: {
                              value: 50,
                              message: 'Address must be is 50 characters.'
                            },
                            // pattern: {
                            //   value: /^[A-Za-z0-9\s]+$/,
                            //   message: 'Address must be alpha numeric.'
                            // },
                          })}
                        />

                        {/*--- Label ---*/}
                        <label htmlFor="adf6" className="text-secondary">
                          Search for your address
                        </label>
                      </div>

                      <div className="m-0 f-error ">{errors.location && errors.location.message}</div>

                      {/*----- GPS Icon -----*/}
                      <a href='javascript:void(0)' onClick={() => getLocation()} className="inputicons">
                        <img src="./assets/Newimg/GPSicon.png" className="w-75 h-100" />
                      </a>

                      {/*----- Search Icon -----*/}
                      <a href='javascript:void(0)' className="inputicons first">
                        <img src="./assets/Newimg/Searchicon.png" className="w-100 h-100" />
                      </a>
                    </li>
                  </ul>


                  {/*----- Address listing -----*/}
                  {showAddress == false && (
                    <>
                      {searchTextLength?.length > 3 && (
                        <div className="searchaddresslisting rounded-4">
                          <ul className="list-unstyled">
                            {addressList && addressList?.length > 0 && addressList?.map((place, index) => (
                              <li key={index}>
                                <a href="javascript:void(0)" onClick={() => { handleSelectPlace(place?.place_id); setShowAddress(true) }} className="w-100 text-truncate" title={place?.description}>
                                  {place?.description}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {/*----- Google trademark -----*/}
                <img src="./assets/images/googletag.png" className="googletabimg" alt />


                <ul className="vs-inputform list-unstyled pb-0">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Asset category type"
                        {...register("appartment", {
                          maxLength: {
                            value: 50,
                            message: 'Appartment must be is 50 characters.'
                          },
                        })}
                      />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-secondary">
                        Unit/apartment no. (optional)
                      </label>
                      <div className="m-0 f-error ">{errors.appartment && errors.appartment.message}</div>
                    </div>
                  </li>

                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Make"
                        defaultValue={placeData}
                        {...register("streat")}
                      />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-secondary">
                        Street address
                      </label>
                    </div>
                  </li>

                  {/* <li className="border-0 py-0">
                    <div className="form-floating mb-0">
                      <select class="form-select py-0">
                        <option className='d-none' select>Select age</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </select>

                      <label for="demo">demo select lable</label>
                    </div>
                  </li> */}

                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Modal"
                        defaultValue={suburb}
                        {...register("subrub")}
                      />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-secondary">
                        Suburb
                      </label>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf3" placeholder="Color"
                        defaultValue={city}
                        {...register("city")}
                        onFocus={() => handleFocus("keyboardFocus", "adf3", true)}
                        onBlur={() => handleFocus("keyboardFocus", "adf3", false)}
                      />
                      {/*--- Label ---*/}
                      <label htmlFor="adf3" className="h-auto text-secondary">City</label>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-0 pb-0">
                      {/* <input type="text" className="form-control" id="province" placeholder="Size"
                        {...register("province")}
                      />
                      <label htmlFor="province" className="h-auto text-secondary">
                        Province (optional)
                      </label> */}
                      <select class="form-select py-0"
                        // {...register("province")}
                        onChange={(e) => setProvince(e?.target?.value)}
                      >
                        <option className='d-none'>Select province</option>
                        <option value={'Eastern Cape'}>Eastern Cape</option>
                        <option value={'Free State'}>Free State</option>
                        <option value={'Gauteng'}>Gauteng</option>
                        <option value={'KwaZulu-Natal'}>KwaZulu-Natal</option>
                        <option value={'Limpopo'}>Limpopo</option>
                        <option value={'Mpumalanga'}>Mpumalanga</option>
                        <option value={'Northern Cape'}>Northern Cape</option>
                        <option value={'North West'}>North West</option>
                        <option value={'Western Cape'}>Western Cape</option>
                      </select>

                      <label for="demo">Province (optional)</label>
                    </div>
                  </li>
                </ul>
              </section>
              {/*----- Vodasure Add address content section Start -----*/}

              {/*----- Vodasure dashboard container End -----*/}
              <footer className="vs-floting-footer vs-footer-gradient">
                <a href="javascript:void(0)" onClick={() => navigate(`/${accessToken}`)} data-bs-dismiss="offcanvas" className="btn btn-outline-secondary rounded-4 px-5 py-3">
                  Cancel
                </a>

                <button type='submit' className={(acceptCondition == true || watchedFields?.[0] && watchedFields?.[1] && watchedFields?.[2]) ? "btn btn-primary rounded-4 px-5 py-3" : "btn btn-outline-primary disabled rounded-4 px-5 py-3"}>
                  Review and confirm
                </button>
              </footer>
            </form >
          </div >
        </div >
      </div >
      {/*----- Main Container For Dashboard End -----*/}
    </>
  )
}

export default AddPhysicalAddress