import React from 'react'
import { Link } from 'react-router-dom'

const EmpityBeneficiary = () => {
  return (
    <>
      {/* only for static */}
      {/*----- Main Container For Empty Qoute Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-quotes position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <Link to="/dashboard"><img src="./assets/images/icon/BackIcon.svg" /></Link>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">My beneficiaries</h4>
              </div>

              <div className="col-2 text-end">
                <Link to="/choose-beneficiary" className="d-block" >
                  <img src="./assets/images/icon/Add_Button.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Content Start -----*/}
          <section className="vs-content_section text-center vs-empty px-4">
            {/*----- Add Domicile Button -----*/}
            <Link to="/choose-beneficiary" className="btn btn-primary rounded-pill px-5 fs-3 py-3 align-self-center w-auto">
              Add a beneficiary
              <img src="./assets/images/icon/AddIcon.svg" className="align-middle ms-2" />
            </Link>


            {/*----- Section Random Intro Start -----*/}
            <div className="Section_random_intro text-center mb-5 mt-3">
              <h3 className="fs-2 mb-5 w-75 mx-auto text-white">No beneficiaries yet...</h3>
              <p className="text-dark-gray w-75 mx-auto">
                You can add any beneficiaries you want to transfer the contents of this locker to.
              </p>
            </div>
            {/* <img src="./assets/images/icon/EmptyBg.svg" alt="" class="position-absolute"> */}
            {/*----- Section Random Intro End -----*/}
          </section>
          {/*----- Section Content End -----*/}
        </div>
      </div>
      {/*----- Main Container For Empty Qoute End -----*/}
    </>
  )
}

export default EmpityBeneficiary