import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';

const DashboardModal = ({
  termConditionCanvas,
  handleCloseTerm
}) => {
  const [acceptCondition, setAcceptCondition] = useState(false);

  return (
    <>
      {/*----- All Modal and Offcanvas Code Start  -----*/}
      <Offcanvas show={termConditionCanvas} onHide={handleCloseTerm} id="thefirstoff" backdrop="static" placement='bottom'>
        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body">
          {/*----- Floating icon of offcanvas -----*/}
          <img src="./assets/Newimg/Canvasfloatinglogo.png" alt className="Floatingpopuplogo" />

          {/*----- Offcanvas top title -----*/}
          <p className="Offcanvastoptital">
            Enjoy the full power of
            <br />
            Vodasure | asset cover by allowing us to:
          </p>

          {/*----- Tnc Point list start -----*/}
          <ul className="vs-borderbottomListrandom mb-0">
            <li className="pt-0">
              <p className="text-muted mb-0">
                <img src="./assets/Newimg/LocationIconwhite.png" className="" />
                Access to your location
              </p>
            </li>

            <li className="">
              <p className="text-muted mb-0">
                <img src="./assets/Newimg/accesscamera.png" className="" />
                Access your camera
              </p>
            </li>

            <li className="">
              <p className="text-muted mb-0">
                <img src="./assets/Newimg/pencilewhite.png" className="" />
                Answer underwriting questions
              </p>
            </li>

            <li>
              <p className="text-muted mb-0">
                <img src="./assets/Newimg/gallerywhite.png" className="" />
                Upload photos from your gallery
              </p>
            </li>
          </ul>
          {/*----- Tnc Point list end -----*/}

          {/*----- Accept Policy and T&Cs Checkbox -----*/}
          <div className="dashboardTnC_checkbox pb-0">
            <div className="form-check p-0">
              <input className="form-check-input d-none" type="checkbox" id="tncCheck" onChange={(e) => setAcceptCondition(e?.target?.checked)} />

              <label className="form-check-label text-muted" htmlFor="tncCheck">
                I give Vodasure | asset cover permission to capture,
                geo-locate and track identifying data to assist in
                the quoting and insuring of my digital assets.
              </label>
            </div>
          </div>

          <div className="dashboardTnC_checkbox px-0 pt-0">
            <div className="form-check p-0">
              <label className="form-check-label text-muted afternone beforenone d-block" htmlFor="tncCheck">
                <span className="text-center d-block mt-4 pt-4">
                  By tapping "Allow &amp; continue," you agree to the
                  <br />
                  Vodasure | asset cover Privacy Notice and Terms and Conditions
                </span>
              </label>
            </div>
          </div>

          {/*----- Offcanvas custom footer start -----*/}
          <div className="offcanvasFooter">
            <button onClick={() => { handleCloseTerm() }} className="btn btn-outline-secondary rounded-pill px-5 py-3">
              Cancel
            </button>

            <button onClick={() => { handleCloseTerm() }} className={acceptCondition == true ? "btn btn-primary rounded-pill px-5 py-3" : "btn btn-outline-primary disabled rounded-pill px-5 py-3"}>
              Allow &amp; continue
            </button>
          </div>
          {/*----- Offcanvas custom footer end -----*/}
        </div>
      </Offcanvas>
    </>
  )
}

export default DashboardModal