import React, { useEffect, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';

const UnderwrittingQuestionaries = ({ underwritingQA, step, stepCount, setStepPercentage, setStep, activeTab, setActiveTab, setCheckBox, checkBox, setAddAssetCanvas, underwritingQuestions, setUnderwritingQuestions }) => {
  // console.log("underwritingQA", underwritingQA);
  const [answers, setAnswers] = useState({});
  const isLastQuestion = activeTab === underwritingQA.length - 1;

  const handleSelectOption = (e, question) => {
    // console.log("question-------", question);
    setTimeout(() => {
      if (step != stepCount) {
        setStep(step + 1);
        setStepPercentage((step / stepCount) * 100);
      }
      const questionId = question?.id;
      const value = e?.target?.value;
      const riskFactorType = question?.riskFactorType;
      const availableValues = question?.availableValues;
      const name = question.name;

      setUnderwritingQuestions((prevQuestions) => {
        const existingQuestionIndex = prevQuestions?.findIndex(
          (question) => question?.id === questionId
        );

        if (existingQuestionIndex !== -1) {
          const updatedQuestions = [...prevQuestions];
          updatedQuestions[existingQuestionIndex].value = value;
          return updatedQuestions;
        } else {
          return [...prevQuestions, { riskFactorId: questionId, name: name, value: value, riskFactorType: riskFactorType, availableValues: availableValues }];
        }
      });

      if (activeTab < underwritingQA?.length - 1) {
        setActiveTab((prevActiveTab) => prevActiveTab + 1);
      }
    }, 500);
  };

  const renderDataItem = (data) => {
    if (typeof data === 'string') {
      return <div>{data}</div>;
    } else if (typeof data === 'object' && data.label && data.value) {
      return <div>{data.label}</div>;
    }
    return null;
  };

  const handleSubmit = () => {
    const newQuestion = {
      riskFactorId: activeTab,
      name: underwritingQA[activeTab]?.name,
      value: answers[underwritingQA[activeTab]?.id] || '',
      riskFactorType: underwritingQA[activeTab]?.riskFactorType,
      availableValues: underwritingQA[activeTab]?.availableValues,
    };

    setUnderwritingQuestions((prevQuestions) => {
      // Remove the last added question with the same riskFactorId, if any
      const filteredQuestions = prevQuestions?.filter(
        (question) => question?.riskFactorId !== newQuestion?.riskFactorId
      );

      return [...filteredQuestions, newQuestion];
    });

    if (activeTab < underwritingQA?.length - 1) {
      setActiveTab((prevActiveTab) => prevActiveTab + 1);
    } else {
      // All questions answered, do something with underwritingQuestions
      console.log('All underwriting questions:', JSON.stringify(underwritingQuestions));
      setAddAssetCanvas(true);
    }
  };


  return (
    <>
      <TabContent activeTab={activeTab}>
        {underwritingQA.map((question, index) => (
          <TabPane key={question.id} tabId={index}>
            <div className="slideform-slide h-100">
              <div className="slideform-group h-100">
                <h1 className="text-center my-5">
                  {question?.label != null ? question?.label : question?.name}
                </h1>
                <ul className="list-unstyled pt-4 rounded-4">
                  <div className="d-flex justify-content-between flex-wrap multilabel">
                    {question?.availableValues && question?.availableValues?.map((labels, index) => (
                      <>
                        {/* {console.log("first", labels.replace(/^\s+|\s+$/gm, ''))} */}
                        <input
                          className="form-check-input d-none"
                          type="radio"
                          name={question?.name}
                          id={`${question?.label != null ? question?.label : question?.name}${index}`}
                          value={typeof labels === 'object' ? labels?.value : labels}
                          onChange={(e) => handleSelectOption(e, question)}
                        // checked={selectedOption === (typeof labels === 'object' ? labels?.value : labels)}
                        />
                        <label className="form-check-label mb-4" htmlFor={`${question?.label != null ? question?.label : question?.name}${index}`}>
                          {renderDataItem(labels)}
                        </label>
                      </>
                    ))}
                  </div>
                </ul>
              </div>
            </div>
            <footer className="vs-floting-footer vs-footer-gradient d-block">
              {step === stepCount && (
                <>
                  <div className="form-check text-center mb-5 pb-5 px-0 underwiritinCheckbox"
                  >
                    <input
                      className="form-check-input mx-auto"
                      type="checkbox"
                      id="tncCheck"
                      checked={checkBox}
                      onClick={(event) => setCheckBox(event?.target?.checked)}
                    />
                    <label
                      className="form-check-label fs-4 text-muted text-start"
                      htmlFor="tncCheck"
                    >
                      I confirm that the information provided is accurate
                    </label>
                  </div>

                  <button
                    className={
                      checkBox === true && step === stepCount
                        ? "btn btn-primary rounded-pill px-5 py-3 w-100"
                        : "btn btn-primary rounded-pill px-5 py-3 disabled w-100"
                    }
                    // onClick={() => setAddAssetCanvas(true)}
                    onClick={() => handleSubmit()}
                  >
                    Next
                  </button>
                </>
              )}
            </footer>
          </TabPane>
        ))}
      </TabContent>
    </>
  )
}

export default UnderwrittingQuestionaries