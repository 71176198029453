import React, { useState } from 'react'
import Slider from "react-slick"

const Onboarding = (props) => {
  const [slideData, setSlideData] = useState(1);
  const settings = {
    dots: true,
    infinite: false,
    centerMode: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  // console.log("slideData", slideData);
  // 

  return (
    <>
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-Onboarding position-relative">
            {/*----- Vs Header Start -----*/}
            <div className="vs-header oneBlank">
              {/*------ Header Mid Heading (Middle Part) ------*/}
              <h4 className="vs-header-title"></h4>

              {/*------ Header Mid Heading (Right Part) ------*/}
              <a href="javascript:void(0)" onClick={props.handleCloseOnboardingScreen} className="headericon">
                <img src="./assets/Newimg/Close.png" className='' alt="" />
              </a>
            </div>
            {/*----- Vs Header end -----*/}

            {/*----- Vodasure Onboarding Content container Start -----*/}
            <section className="vs-content_section text-center">
              <Slider {...settings}>
                <div className="Onboarding_Content">
                  {/*----- Onboarding 1st image -----*/}
                  {console.log("setttt", settings)}
                  <img src="./assets/images/onboarding/img/Onboarding1.png" alt="" className="" onChange={() => setSlideData(1)} />

                  {/*----- Section Random Intro Start (For First Onboarding) -----*/}
                  <div className="Section_random_intro text-center mt-5 pt-5">
                    <h3 className="onboardingHeading">
                      <span>Take a pic of your assets</span>
                      <br className="" />
                      <span className='ms-1'>so we can insure them</span>
                    </h3>

                    <p className='onboardingDesc'>
                      <span>By simply taking a photo of your asset,</span>
                      <br className="" />
                      <span className='mx-1'>we will be able to provide you with a</span>
                      <br className="" />
                      <span>quote and insurance cover.</span>
                    </p>
                  </div>
                  {/*----- Section Random Intro End -----*/}
                </div>

                {/* <div className="Onboarding_Content d-none">
                  <img src="./assets/images/onboarding/img/Onboarding2.png" alt="" className=""
                  // onChange={() => setSlideData(2)} 
                  />

                  <div className="Section_random_intro text-center mt-5 pt-5" >
                    <h3 className="onboardingHeading">
                      <span>Add your assets to your</span>
                      <br className="" />
                      <span className='ms-1'>locker using our chatbot</span>
                    </h3>

                    <p className='onboardingDesc'>
                      <span>Use our automated bot process to assist you</span>
                      <br className="" />
                      <span className='mx-1'>in building a locker to add your assets, quote and</span>
                      <br className="" />
                      <span>claim for accidental, loss and theft.</span>
                    </p>
                  </div>
                </div> */}

                <div className="Onboarding_Content">
                  {/*----- Onboarding 3rd image -----*/}
                  <img src="./assets/images/onboarding/img/Onboarding3.png" alt="" className="" onChange={() => setSlideData(2)} />

                  {/*----- Section Random Intro Start (For Third Onboarding) -----*/}
                  <div className="Section_random_intro text-center mt-5 pt-5">
                    <h3 className="onboardingHeading">
                      <span>Get an insurance quote</span>
                      <br className="" />
                      <span className='ms-1'>on a range of assets</span>
                    </h3>

                    <p className='onboardingDesc'>
                      <span>Get an insurance quote on various assets</span>
                      <br className="" />
                      <span className='ms-1'>such as devices, laptops, tablets and</span>
                      <br className="" />
                      <span>other household items.</span>
                    </p>
                  </div>
                  {/*----- Section Random Intro End -----*/}
                </div>

                <div className="Onboarding_Content">
                  {/*----- Onboarding 4th image -----*/}
                  <img src="./assets/images/onboarding/img/Onboarding4.png" alt="" className="" onChange={() => setSlideData(3)} />

                  {/*----- Section Random Intro Start (For Fourth Onboarding) -----*/}
                  <div className="Section_random_intro text-center mt-5 pt-5">
                    <h3 className="onboardingHeading">
                      <span>Fast-track the replacement of</span>
                      <br className='' />
                      <span className='ms-1'>stolen goods with quick claims</span>
                    </h3>

                    <p className='onboardingDesc'>
                      <span>Use the captured photo in your locker to start</span>
                      <br className="" />
                      <span className='mx-1'>the claims process and self-approve</span>
                      <br className="" />
                      <span>your claim in the app.</span>
                    </p>
                  </div>
                  {/*----- Section Random Intro End -----*/}
                </div>
              </Slider>
            </section>
            {/*----- Vodasure Onboarding Content container End -----*/}

            {/*-----  Floating Footer with gradient Section Start -----*/}
            <footer className="vs-floting-footer vs-footer-gradient">
              <a href="#" className="btn btn-outline-primary rounded-pill px-5 py-3">
                FAQs
              </a>

              <a href="javascript:void(0)" onClick={props.handleCloseOnboardingScreen} className="btn btn-primary rounded-pill px-5 py-3">
                Get started
              </a>
            </footer>
            {/*-----  Floating Footer with gradient Section end -----*/}
          </div>
        </div>
      </div>
    </>
  )
}

export default Onboarding