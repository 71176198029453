import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const CancelQuote = () => {

  const navigate = useNavigate();

  return (
    <>
      {/*----- Main Container For Cancel Quote Reason Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-delete position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                  <img src="./assets/images/icon/BackIcon.svg" />
                </a>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Cancel quote</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-5 w-75 mx-auto text-white">
              Please select your reason<br />for canceling your quote
            </h3>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure Cancel Quote Reason container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Cancel Quote Reason Container start -----*/}
            <ul className="vs-deletereason list-unstyled">
              <li>
                <Link to="/quote-feedback" className="row justify-content-between align-items-center">
                  {/*-- Reason Title --*/}
                  <div className="col-10">
                    <p className="w-100 text-truncate mb-0">
                      I no longer require device insurance
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/quote-feedback" className="row justify-content-between align-items-center">
                  {/*-- Reason Details --*/}
                  <div className="col-10">
                    <p className="w-100 text-truncate mb-0">
                      I no longer have a device
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/quote-feedback" className="row justify-content-between align-items-center">
                  {/*-- Reason Details --*/}
                  <div className="col-10">
                    <p className="w-100 text-truncate mb-0">
                      I can no long afford device cover
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/quote-feedback" className="row justify-content-between align-items-center">
                  {/*-- Reason Details --*/}
                  <div className="col-10">
                    <p className="w-100 text-truncate mb-0">
                      My excess too high
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/quote-feedback" className="row justify-content-between align-items-center">
                  {/*-- Reason Details --*/}
                  <div className="col-10">
                    <p className="w-100 text-truncate mb-0">
                      My claim was denied
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </Link>
              </li>
              <li className="border-0">
                <Link to="/quote-cancel-reason" className="row justify-content-between align-items-center">
                  {/*-- Reason Details --*/}
                  <div className="col-10">
                    <p className="w-100 text-truncate mb-0">
                      Other
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </Link>
              </li>
            </ul>
            {/*----- Cancel Quote Reason Container End -----*/}
          </section>
          {/*----- Vodasure Cancel Quote Reason container End -----*/}
        </div>
      </div>
      {/*----- Main Container For Cancel Quote Reason End -----*/}
    </>
  )
}

export default CancelQuote