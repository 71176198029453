import React from 'react'

const PrivacyNotice = (props) => {
  return (
    <>
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-PrivacyPolicy position-relative">
            {/*----- Vs Header Start -----*/}
            <div className="vs-header Withbtngroup">
              {/*------ Header Mid Heading (Left Part) ------*/}
              <a href="javascript:void(0)" onClick={props.handleCloseOnboardingScreen} className="headericon">
                <img src="./assets/Newimg/backbtnicon.png" alt="" />
              </a>
            </div>
            {/*----- Vs Header end -----*/}

            {/*----- Vodasure Privacy Policy content section Start -----*/}
            <section className="vs-content_section">
              <small className='d-block text-gray text-center mb-5 pb-3'>Underwritten by Vodacom Life Assurance Company Ltd.</small>

              {/*----- Section Random Intro Start (For First Onboarding) -----*/}
              <div className="Section_random_intro text-center">
                <h3 className="onboardingHeading mb-3">
                  Privacy Notice
                </h3>

                <p className='onboardingDesc'>
                  Last updated 8 November, 2021
                </p>
              </div>

              <div className="vs-datasection rounded-4">
                <small className='d-block text-gray mb-4'>General terms / 8 November, 2021</small>

                {/*----- Tnc first -----*/}
                <div className="TnCcontainer">
                  <p className="">
                    01
                    <span className="text-danger mx-2">
                      //
                    </span>
                    Terms and Conditions

                    <br />

                    <span className="tncdes">
                      The VodaPay Life Style services offered on the VodaPay application or the VodaPay website hosted on www.vodapay.vodacom.co.za (collectively the “VodaPay Platforms”) offer you the ability to pay for goods and services without carrying cash (the “Services”).
                      <br />
                      <br />
                      By using the VodaPay Platforms, you indicate that agree to the following terms and conditions of this service (the “Terms and Conditions”).
                    </span>
                  </p>
                </div>

                {/*----- Tnc second -----*/}
                <div className="TnCcontainer">
                  <p className="">
                    02
                    <span className="text-danger mx-2">
                      //
                    </span>
                    What is VodaSure | locker?

                    <br />

                    <span className="tncdes">
                      Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio.
                      <br />
                      <br />
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor.
                    </span>
                  </p>
                </div>

                {/*----- Tnc third -----*/}
                <div className="TnCcontainer">
                  <p className="">
                    03
                    <span className="text-danger mx-2">
                      //
                    </span>
                    Fees and limits

                    <br />

                    <span className="tncdes">
                      Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio.
                      <br />
                      <br />
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor.
                    </span>
                  </p>
                </div>
              </div>

              {/*----- Section Random Intro End -----*/}
            </section>
            {/*----- Vodasure Privacy Policy content section Start -----*/}

            {/*-----  Floating Footer with gradient Section Start -----*/}
            <footer className="vs-floting-footer vs-footer-gradient">
              <a href="javascript:void(0)" onClick={props.handlePrivacyNoticeClose} className="btn btn-primary rounded-pill px-5 py-3">
                Accept & continue
              </a>
            </footer>
            {/*-----  Floating Footer with gradient Section end -----*/}
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyNotice