import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import DashboardModal from "../../common/DashboardModal";
import Modal from "react-bootstrap/Modal";
import AddAssetCanvas from "../AddAssets/AddAssetCanvas";
import axios from "axios";
import { GetDataWithToken, PostData } from "../../ApiHelper/ApiHelper";
import Loader from "../../common/Loader";
import Onboarding from "../../onboarding/Onboarding";
import Tour from "./Tour";
import PrivacyNotice from "./PrivacyNotice";
import { toast } from "material-react-toastify";
import exifr from "exifr";
import { Offcanvas } from "react-bootstrap";

const Dashboard = () => {
  const applyAuthCode =
    "https://vodapay-gateway.sandbox.vfs.africa/v2/customers/user/inquiryUserInfo";
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  // console.log("loaction=============", location.pathname.replace(/\//g));
  console.log("new Build>>>>>>>>>")

  const navigate = useNavigate();
  const [quotationCanvas, setQuotationCanvas] = useState(false);
  const quotationCanvasClose = () => setQuotationCanvas(false);
  const [myAssets, setMyAssets] = useState([]);
  const [assetsPrice, setAssetsPrice] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allPrice, setAllPrice] = useState(0);
  const [selectedCatalogIds, setSelectedCatalogIds] = useState([]);

  const [callApi, setCallApi] = useState(true);
  const [checkLogin, setCheckLogin] = useState("");
  const [dashboard, setDashboard] = useState([]);
  const [firstTimeDashboard, setFirsstTimeDashboard] = useState(false);
  const [physicalAddress, setPhysicalAddress] = useState(false);
  // const [assetOffcanvas, setAssetOffcanvas] = useState(false);
  const [uninsuredCount, setUninsuredCount] = useState([]);
  const [insuredCount, setInsuredCount] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [addAssetCanvas, setAddAssetCanvas] = useState(false);
  const addAssetCanvasClose = () => setAddAssetCanvas(false);

  const [termConditionCanvas, setTermConditionCanvas] = useState(false);
  const termConditionCanvasClose = () => setTermConditionCanvas(false);
  // const [loadScript, setLoadScript] = useState(true);

  const [tourType, setTourType] = useState(true);

  const [tourSteps, setTourSteps] = useState(1);
  const [checkTour, setcheckTour] = useState(null);

  const [quotationShow, setQuotationShow] = useState(false);

  const handleQuotationModalClose = () => setQuotationShow(false);
  const handleQuotationModalShow = () => setQuotationShow(true);
  const [getStep, setGetStep] = useState("");
  const [latLng, setlatlLng] = useState({});
  // console.log("latLng", latLng);

  // useEffect(() => {
  //   if (firstTimeDashboard || checkLogin == "Please Login.") {
  //     let headers = {
  //       "Content-Type": "application/json",
  //       "signature": "algorithm=RSA256, keyVersion=1, signature=test",
  //       "client-id": "2022040664970909271130",
  //       "request-time": "2022-05-10T10:51:36+00:00",
  //     };
  //     axios.post(applyAuthCode + `applyAuthCode`, {
  //       "clientId": "2022040664970909271130",
  //       "userId": "216610000000662735582",
  //       "scopes": "auth_user"
  //     }, { headers: headers })
  //       .then((response) => {
  //         // console.log("ressss", response);
  //         axios.post(applyAuthCode + `applyToken`, {
  //           "grantType": "AUTHORIZATION_CODE",
  //           "username": "3460010120501782",
  //           "authCode": response.data.authCode
  //         }, { headers: headers })
  //           .then((response) => {
  //             // console.log("ressss", response);
  //             // Cookies.set("customerId", response?.data?.customerId, { secure: true, SameSite: "Strict" })
  //             Cookies.set("customerId", response?.data?.customerId);
  //             setFirsstTimeDashboard(false);
  //             let isCustomerId = {
  //               customerId: response?.data?.customerId
  //             }
  //             PostData('check-customerId', isCustomerId)
  //               .then((response) => {
  //                 // Cookies.set("token", response?.data?.token, { secure: true, SameSite: "Strict" });
  //                 Cookies.set("token", response?.data?.token);
  //                 setCallApi(true);
  //                 localStorage.setItem("tour", false);
  //               })
  //           })
  //       })
  //     //
  //   }
  // }, [firstTimeDashboard]);
  useEffect(() => {

    if (navigator.geolocation) {
      // alert("qwertyui");
      navigator.geolocation.getCurrentPosition(showPosition);
      // navigator.geolocation.getCurrentPosition((position) => { console.log("postion", position) }, (error) => { console.log("error", error) })
    } else {
      toast(
        <>
          <div className="activityalert">
            <div className="alert alert-dismissible fade show m-0 px-0 text-center">
              <p className="m-0">
                <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
                Geolocation is not supported by this browser.
              </p>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
              </button>
            </div>
          </div>
        </>
      )
    }

    GetDataWithToken("asset/get-asset-list", "").then((response) => {
      if (response.status == true) {
        setMyAssets(response.data);
        setCallApi(false);
        setLoader(false);
        const status = response.data.map((item) => item.status);
        // console.log("status", status);
        const hasUninsured = status.includes("uninsured");
        // console.log("hasUninsured", hasUninsured);
        // setCheckStatus(hasUninsured);
        if (response.data.length > 0) {
          let arr = [];
          let i = 0;
          let total_price = 0;

          while (i < response.data.length) {
            if (response?.data?.[i]?.status == "uninsured") {
              arr.push({
                price: response.data?.[i].cost,
                id: response.data?.[i].id,
                catId: response.data?.[i].catalog_id,
              });
              let cost = parseFloat(response.data?.[i].cost);
              total_price += cost;
            }
            i++;
          }
          // setCsvData(arr);
          setAssetsPrice(arr);
          // console.log("arr", arr);
          const idsArray = arr
            .filter((item) => item.catId > 0)
            .map((item) => item.id);
          setSelectedIds(idsArray);
          console.log("idsArray", idsArray);
          const catalogIdsArray = arr
            .filter((item) => item.catId > 0)
            .map((item) => item.catId);
          // console.log("catalogIdsArray", catalogIdsArray);
          setSelectedCatalogIds(catalogIdsArray);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (firstTimeDashboard || checkLogin == "Please Login.") {
      const accessToken = location.pathname.replace(/\//g, "");
      console.log("loaction=============", accessToken);
      Cookies.set("accessToken", accessToken);
      setFirsstTimeDashboard(false);
      let headers = {
        "Content-Type": "application/json",
        signature: "algorithm=RSA256, keyVersion=1, signature=test",
        "client-id": "2022040664970909271130",
        "request-time": "2022-05-10T10:51:36+00:00",
      };
      // let isCustomerId = {
      //   accessToken: accessToken
      // }
      axios
        .post(
          applyAuthCode,
          {
            accessToken: accessToken,
          },
          { headers: headers }
        )
        .then((response) => {
          // console.log("ssssssSSS------------", response?.data?.result?.resultStatus);

          if (response?.data?.result?.resultStatus == "S") {
            let email = null;
            let contactNo = null;
            // console.log(
            //   "ssssssSSSSSSSSS",
            //   response?.data?.userInfo.contactInfos
            // );
            const info = response?.data?.userInfo.contactInfos;

            for (let i = 0; i < info.length; i++) {
              const contactInfo = info[i];
              console.log("contactInfo", contactInfo);
              if (contactInfo.contactType === "EMAIL") {
                email = contactInfo.contactNo;
              } else if (contactInfo.contactType === "MOBILE_PHONE") {
                contactNo = contactInfo.contactNo;
              }
            }
            function removeBeforeHyphen(contactNo) {
              const index = contactNo.indexOf("-");
              if (index !== -1) {
                return contactNo.substring(index + 1);
              } else {
                return contactNo;
              }
            }
            const result = removeBeforeHyphen(contactNo);
            console.log("result----------", result);

            let checkCustomerId = {
              token: accessToken,
              customerId: response?.data?.userInfo?.userId,
              name: response?.data?.userInfo?.nickName,
              email: email,
              mobile: result,
            };
            console.log("checkCustomerId", checkCustomerId);
            PostData("check-customerId", checkCustomerId).then((response) => {
              // Cookies.set("token", response?.data?.token, { secure: true, SameSite: "Strict" });
              Cookies.set("token", response?.data?.token);
              setCallApi(true);
              localStorage.setItem("tour", false);
            });
          }
        });
    }
  }, [firstTimeDashboard]);

  useEffect(() => {
    if (callApi || loader) {
      setCallApi(false);
      if (!Cookies.get("token")) {
        setFirsstTimeDashboard(true);
        setTermConditionCanvas(true);
      }
      GetDataWithToken("user/dashboard", "").then((response) => {
        // console.log("ressss", typeof (response))
        response = JSON.parse(response);

        if (response.status == true) {
          setCallApi(false);
          setDashboard(response.data);
          setPhysicalAddress(response?.data?.physical_address);
          // Cookies.set('asset_count', response.data.assets);
          // setAssetOffcanvas(response?.data?.assetOffCanvas);
          // console.log("---------", response?.data?.physical_address == true && response?.data?.assetOffCanvas == true);
          // if (response?.data?.physical_address == true && response?.data?.assetOffCanvas == true) {
          //   setAddAssetCanvas(true);
          // } else {
          //   setAddAssetCanvas(false);
          // }
          setCheckLogin("logged in");
          setLoader(false);
        }
      });
      GetDataWithToken("asset/get-asset-list", "").then((response) => {
        if (response.status == true) {
          const status = response.data.map((item) => item.status);
          const uninsuredItems = status.filter((item) => item === "uninsured");
          const insuredItems = status.filter((item) => item === "insured");
          setUninsuredCount(uninsuredItems);
          setInsuredCount(insuredItems);
        }
      });
      GetDataWithToken("user/get-user-answer", "").then((response) => {
        if (response.status == true) {
          setGetStep(JSON.parse(response?.data?.step));
          // console.log("first", JSON.parse(response?.data?.step));
          //
          setLoader(false);
        }
      });
    }
  }, [callApi, loader]);

  const handleCloseTerm = () => {
    JSON.stringify(localStorage.setItem("tour", "false"));
    termConditionCanvasClose(false);
  };

  useEffect(() => {
    if (tourType == true) {
      setTourType(false);
      setcheckTour(JSON.parse(localStorage.getItem("tour")));
    }
  }, [tourType]);

  const [showOnboardScreen, setShowOnboardingScreen] = useState(true);
  const [privacyNotice, setPrivacyNotice] = useState(false);

  useEffect(() => {
    const localStorageOnboardingScreenData =
      localStorage.getItem("showOnboardScreen");
    if (localStorageOnboardingScreenData === "false") {
      setShowOnboardingScreen(false);
    }
    const localStoragePrivacyNoticeData = localStorage.getItem("privacyNotice");
    if (localStoragePrivacyNoticeData === "false") {
      setPrivacyNotice(false);
    }
  }, []);

  const handleCloseOnboardingScreen = () => {
    setPrivacyNotice(true);
    setShowOnboardingScreen(false);
    localStorage.setItem("showOnboardScreen", "false");
  };

  const handlePrivacyNoticeClose = () => {
    setPrivacyNotice(false);
    localStorage.setItem("privacyNotice", "false");
  };

  useEffect(() => {
    adjustContainer(); // Call the adjustContainer function on component mount and resize
    window.addEventListener("resize", adjustContainer); // Add event listener for resize
    return () => {
      window.removeEventListener("resize", adjustContainer); // Clean up the event listener on component unmount
    };
  }, []);

  function adjustContainer() {
    var footer = document.querySelector(".vs-footer")
      ? document.querySelector(".vs-footer").offsetHeight + 20
      : document.querySelector(".vs-floting-footer")
        ? document.querySelector(".vs-floting-footer").offsetHeight
        : 0;
    if (footer > 0) {
      var header = document.querySelector(".vs-header").offsetHeight + 0;
      var title = document.querySelector(
        ".vs-main-contentContainer .static-title-top"
      )
        ? document.querySelector(".vs-main-contentContainer .static-title-top")
          .offsetHeight
        : 0;
      var body = window.innerHeight;
      var container = body - (header + footer + title);
      document.querySelector(".vs-content_section").style.maxHeight =
        container + "px";
    }
  }

  const showPosition = (position) => {
    // console.log("position", position);
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    console.log("latitude", latitude);
    setlatlLng({ latitude, longitude });
  };

  // console.log({ latLng });
  const AddAssets = async (e) => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      toast(
        <>
          <div className="activityalert">
            <div className="alert alert-dismissible fade show m-0 px-0 text-center">
              <p className="m-0">
                <img
                  src="./assets/images/blueradio.png"
                  className="bluecheckimg"
                  alt
                />
                Geolocation is not supported by this browser.
              </p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </>
      );
    }
    setTimeout(() => {
      if (e.target.files && e.target.files.length > 0) {
        const files = e.target.files;

        if (latLng == undefined) {
          toast(
            <>
              <div className="activityalert">
                <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                  <p className="m-0">
                    <img
                      src="./assets/Newimg/redclose.png"
                      className="bluecheckimg"
                      alt
                    />
                    This image does not contain latitude and longitude
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </>
          );
        } else {
          // setAssetLatLng({
          //   lat: latLng?.latitude,
          //   lng: latLng?.longitude,
          // })
          navigate("/check-asset", {
            state: {
              imgPreview: URL.createObjectURL(e.target.files[0]),
              img: e.target.files[0],
              assetLatLngAdd: {
                lat: latLng?.latitude,
                lng: latLng?.longitude,
              },
            },
          });
          // firstTimeAssetOffCanvas();
        }
      }
    }, 1000);
  };
  // console.log("selectedIds", selectedIds);
  // console.log("selectedIds", selectedIds);
  // console.log("selectedCatalogIds", selectedCatalogIds);

  const handleSelectAssets = (price, id, catalogId) => {
    const idIndex = selectedIds.indexOf(id);
    const catalogIdIndex = selectedCatalogIds.indexOf(catalogId);
    if (idIndex !== -1) {
      selectedIds.splice(idIndex, 1);
    } else {
      selectedIds.push(id);
    }
    if (catalogIdIndex !== -1) {
      selectedCatalogIds.splice(catalogIdIndex, 1);
    } else {
      selectedCatalogIds.push(catalogId);
    }

    if (assetsPrice?.length > 0) {
      if (assetsPrice?.find((e) => e.id == id)) {
        let index = assetsPrice?.findIndex((e) => e.id == id);
        let total_price =
          parseFloat(allPrice) - parseFloat(assetsPrice?.[index]?.price);
        // console.log("jhgfghjjjjjjjjjjjj", parseFloat(total_price));
        assetsPrice.splice(index, 1);
        setAllPrice(parseFloat(total_price));
      } else {
        setAssetsPrice([
          ...assetsPrice,
          {
            price: price,
            id: id,
          },
        ]);
        // console.log("idsssssss", id)
        let total_price = parseFloat(allPrice) + parseFloat(price);
        setAllPrice(total_price);
      }
    } else {
      setAssetsPrice([
        ...assetsPrice,
        {
          price: price,
          id: id,
        },
      ]);
      // console.log("id", id)
      let total_price = parseFloat(allPrice) + parseFloat(price);
      setAllPrice(total_price);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {showOnboardScreen ? (
            <Onboarding
              handleCloseOnboardingScreen={handleCloseOnboardingScreen}
            />
          ) : (
            <>
              {privacyNotice ? (
                <PrivacyNotice
                  handlePrivacyNoticeClose={handlePrivacyNoticeClose}
                />
              ) : (
                <>
                  <div className="vs-main-container">
                    <div className="responsive-row">
                      <div className="vs-main-contentContainer vs-dashboard position-relative">
                        {/*------- Tour body design start -------*/}
                        <div
                          className={
                            checkTour == true ? "d-none" : "newtourbody"
                          }
                        >
                          <Tour
                            setTourType={setTourType}
                            tourSteps={tourSteps}
                            setTourSteps={setTourSteps}
                          />
                        </div>

                        {/*----- Vs Header Start -----*/}
                        <div className="vs-header Withbtngroup d-none">
                          {/*------ Header Mid Heading (Left Part) ------*/}
                          <Link to={"/notification"} className="headericon">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icon/Notification.svg"
                              }
                              className="notifimg"
                            />
                            <span className="notifbadge">4</span>
                          </Link>

                          {/*------ Header Mid Heading (Middle Part) ------*/}
                          <h4 className="vs-header-title">
                            vodasure | asset cover
                          </h4>

                          {/*------ Header Mid Heading (Right Part) ------*/}
                          <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                            <a href="#" className="btn border-0 me-2">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Newimg/headerdotted.png"
                                }
                                alt
                              />
                            </a>

                            <a href="#" className="btn border-0 ms-2">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Newimg/headerroundclose.png"
                                }
                                alt
                              />
                            </a>
                          </div>
                        </div>
                        {/*----- Vs Header end -----*/}

                        {/*----- Vodasure Dahsboard content section Start -----*/}
                        <section className="vs-content_section pt-5">
                          <h2 className="mb-5 text-center d-none">
                            Add any asset
                          </h2>

                          {/*----- Dashboard Add address and add asset Card start -----*/}
                          <div className="card dashboard-card rounded-4 position-relative mb-4">
                            <div className="card-body">
                              <span className="badge generalbadge w-auto">
                                Asset value R{" "}
                                {dashboard?.price
                                  ?.toLocaleString()
                                  ?.replaceAll(",", " ")}
                                .00
                              </span>

                              <p className="mb-3 mt-4">
                                Let’s build your inventory
                              </p>

                              {/*----- Add Address or add asset dashboard button -----*/}
                              {/* {console.log("physicalAddress", physicalAddress)} */}
                              {physicalAddress == false ? (
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => handleShow(true)}
                                  className="addressAssesaddbtn"
                                  id="AddressassetADD"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/Newimg/addressAssetaddbtnicon.png"
                                    }
                                    className="me-3"
                                    alt
                                  />
                                  Add any asset
                                </a>
                              ) : dashboard?.assets == 0 ? (
                                <Link
                                  to="/take-photo"
                                  className="addressAssesaddbtn"
                                  id="AddressassetADD"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/Newimg/addressAssetaddbtnicon.png"
                                    }
                                    className="me-3"
                                    alt
                                  />
                                  Add any asset
                                </Link>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    id="assetIMG"
                                    accept="image/*"
                                    capture="environment"
                                    className="d-none"
                                    onChange={(e) => AddAssets(e)}
                                  />

                                  <label
                                    className="addressAssesaddbtn"
                                    id="AddressassetADD"
                                    htmlFor="assetIMG"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Newimg/addressAssetaddbtnicon.png"
                                      }
                                      className="me-3"
                                      alt
                                    />
                                    Add any asset
                                  </label>
                                </>
                              )}
                            </div>
                          </div>
                          {/*----- Dashboard Add address and add asset Card end -----*/}

                          {/*----- Dasboart asset alert as per user activity start -----*/}
                          {/* {dashboard?.assets == 0 && ( */}
                          {
                            physicalAddress == false ? (
                              <div className="userassetalert rounded-4 p-4 mb-4">
                                <div className>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/Newimg/cartbasketicon.png"
                                    }
                                    alt
                                    className=""
                                  />
                                  <p className="d-inline-block mb-0">
                                    You have {dashboard?.assets} assets in your
                                    locker
                                  </p>
                                </div>

                                <a
                                  href="javascript:void(0)"
                                  onClick={() => handleShow(true)}
                                >
                                  Add my first asset
                                </a>
                              </div>
                            ) : dashboard?.assets == 0 ? (
                              <div className="userassetalert rounded-4 p-4 mb-4">
                                <div className>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/Newimg/cartbasketicon.png"
                                    }
                                    alt
                                    className=""
                                  />
                                  <p className="d-inline-block mb-0">
                                    You have 0 assets in your locker
                                  </p>
                                </div>

                                <Link to="/take-photo">Add my first asset</Link>
                              </div>
                            ) : uninsuredCount?.length > 0 ? (
                              <div className="userassetalert rounded-4 p-4 mb-4">
                                <div className>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/newCart.png"
                                    }
                                    alt
                                    className=""
                                  />
                                  <p className="d-inline-block mb-0">
                                    You have {uninsuredCount.length}{" "}
                                    {uninsuredCount.length == 1
                                      ? "asset"
                                      : "assets"}{" "}
                                    in your locker
                                  </p>
                                </div>

                                <a
                                  href="javascript:void(0)"
                                  onClick={() => setQuotationCanvas(true)}
                                >
                                  Get a quote now!
                                </a>
                              </div>
                            ) : (
                              <div className="userassetalert rounded-4 p-4 mb-4">
                                <div className>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/newCart.png"
                                    }
                                    alt
                                    className=""
                                  />
                                  <p className="d-inline-block mb-0">
                                    You have 0 assets in your locker
                                  </p>
                                </div>

                                <input
                                  type="file"
                                  id="myneeid"
                                  accept="image/*"
                                  capture="environment"
                                  className="d-none"
                                  onChange={(e) => AddAssets(e)}
                                />

                                <label htmlFor="myneeid">Add more assets</label>
                              </div>
                            )
                            //
                          }
                          {/*----- Dasboart asset alert as per user activity end -----*/}

                          {/*------ Dashboard Utility Card Container start -----*/}
                          <div className="vs-app-card-container">
                            <div className="row g-4">
                              {/*------ Dashboard Utility Card 1 -----*/}
                              <div className="col-6">
                                <Link
                                  to="/my-assets"
                                  className="card rounded-4"
                                  id=""
                                >
                                  <div className="card-body">
                                    {/*----- Card badge -----*/}
                                    <span className="vs-stack-badge bg-gray" />

                                    <div className="vs-card text-center">
                                      <div className="vs-card-icon">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Newimg/dashcardlock.png"
                                          }
                                          alt
                                        />
                                      </div>

                                      <p className="mb-0">My assets</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              {/*------ Dashboard Utility Card 2 -----*/}
                              <div className="col-6">
                                <a href="#!" className="card rounded-4" id="">
                                  <div className="card-body">
                                    {/*----- Card badge -----*/}
                                    <span className="vs-stack-badge bg-gray" />

                                    <div className="vs-card text-center">
                                      <div className="vs-card-icon">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Newimg/dashcardlocation.png"
                                          }
                                          alt
                                        />
                                      </div>

                                      <p className="mb-0">My quotes</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              {/*------ Dashboard Utility Card 3 -----*/}
                              <div className="col-6">
                                <a href="#!" className="card rounded-4" id="">
                                  <div className="card-body">
                                    {/*----- Card badge -----*/}
                                    <span className="vs-stack-badge bg-gray" />

                                    <div className="vs-card text-center">
                                      <div className="vs-card-icon">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Newimg/dashcardpolicy.png"
                                          }
                                          alt
                                        />
                                      </div>

                                      <p className="mb-0">My policy</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              {/*------ Dashboard Utility Card 4 -----*/}
                              <div className="col-6">
                                <a href="#!" className="card rounded-4" id="">
                                  <div className="card-body">
                                    {/*----- Card badge -----*/}
                                    <span className="vs-stack-badge bg-gray" />

                                    <div className="vs-card text-center">
                                      <div className="vs-card-icon">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Newimg/dashcardclaims.png"
                                          }
                                          alt
                                        />
                                      </div>

                                      <p className="mb-0">My claims</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/*------ Dashboard Utility Card Container end -----*/}
                        </section>
                        {/*----- Vodasure dashboard container End -----*/}

                        {/*----- Vodasure Optional Footer Start -----*/}
                        <div className="vs-footer rounded-4">
                          {/*----- Home Icon -----*/}
                          <div className="vs-icon-btn active">
                            <a href="javascript:void(0)" className="d-block">
                              <svg
                                width={30}
                                viewBox="0 0 40 46"
                                x={100}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M20.471 0.218909C20.0839 -0.0729697 19.5503 -0.0729697 19.1633 0.218909L1.05975 13.8719C1.0307 13.8938 1.00278 13.9172 0.976078 13.9419C0.395771 14.4789 0.0464758 15.2201 0.00173962 16.0094C0.000580072 16.0299 0 16.0504 0 16.0709V36.92L5.00679e-06 36.9233C0.00457096 38.4186 1.21562 39.6297 2.71093 39.6343L2.71424 39.6343H13.9968C14.0359 39.6385 14.0755 39.6407 14.1157 39.6407C14.6828 39.6407 15.1484 39.206 15.1973 38.6516C15.2005 38.6176 15.2022 38.5832 15.2022 38.5484C15.2022 38.5363 15.202 38.5243 15.2016 38.5123V30.4108C15.2016 27.862 17.2678 25.7959 19.8165 25.7959C22.3653 25.7959 24.4315 27.862 24.4315 30.4108V38.5548C24.4315 39.1545 24.9177 39.6407 25.5174 39.6407C25.5575 39.6407 25.5972 39.6385 25.6363 39.6343H36.92L36.9233 39.6343C38.4186 39.6297 39.6297 38.4186 39.6343 36.9233L39.6343 36.92V16.0709C39.6343 16.0504 39.6337 16.0299 39.6325 16.0094C39.5878 15.2201 39.2385 14.4789 38.6582 13.9419C38.6315 13.9172 38.6036 13.8938 38.5745 13.8719L20.471 0.218909ZM26.6032 37.4625H36.9184C37.2181 37.4607 37.4607 37.2181 37.4625 36.9184V16.1077C37.4452 15.9038 37.3574 15.7124 37.2146 15.5664L19.8171 2.44592L2.41967 15.5664C2.27683 15.7124 2.18903 15.9038 2.17174 16.1077V36.9179C2.17331 37.2179 2.41599 37.4607 2.71588 37.4625H13.0299V30.4108C13.0299 26.6626 16.0684 23.6241 19.8165 23.6241C23.5647 23.6241 26.6032 26.6626 26.6032 30.4108V37.4625Z"
                                  fill="#6D6F81"
                                />
                              </svg>

                              <p className="text-secondary mb-0 mt-1">Home</p>
                            </a>
                          </div>

                          {/*----- Add Asset Icon  -----*/}
                          {physicalAddress == false ? (
                            <div
                              className="vs-icon-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <a
                                href="javascript:void(0)"
                                onClick={() => handleShow(true)}
                                className="d-block"
                              >
                                <svg
                                  width={30}
                                  viewBox="0 0 46 46"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27.9191 34.2016C33.2711 34.2016 37.6098 29.8629 37.6098 24.5109C37.6098 19.159 33.2711 14.8203 27.9191 14.8203C22.5672 14.8203 18.2285 19.159 18.2285 24.5109C18.2285 29.8629 22.5672 34.2016 27.9191 34.2016Z"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M37.6094 12.0512L35.6066 8.04387C35.1374 7.10577 34.1784 6.51336 33.1295 6.51367H22.708C21.6591 6.51336 20.7001 7.10577 20.2309 8.04387L18.2281 12.0512H5.76875C4.23961 12.0512 3 13.2908 3 14.8199V36.9699C3 38.4991 4.23961 39.7387 5.76875 39.7387H41.7625C43.2916 39.7387 44.5312 38.4991 44.5312 36.9699V14.8199C44.5312 13.2908 43.2916 12.0512 41.7625 12.0512H37.6094Z"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.22734 17.5889C9.60963 17.5889 9.91953 17.8988 9.91953 18.2811C9.91953 18.6633 9.60963 18.9732 9.22734 18.9732C8.84506 18.9732 8.53516 18.6633 8.53516 18.2811C8.53516 17.8988 8.84506 17.5889 9.22734 17.5889"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.3066 12.052V9.2832"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>

                                <p className="text-secondary mb-0 mt-1">
                                  Add asset
                                </p>
                              </a>
                            </div>
                          ) : dashboard?.assets == 0 ? (
                            <div
                              className="vs-icon-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <Link to="/take-photo" className="d-block">
                                <svg
                                  width={30}
                                  viewBox="0 0 46 46"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27.9191 34.2016C33.2711 34.2016 37.6098 29.8629 37.6098 24.5109C37.6098 19.159 33.2711 14.8203 27.9191 14.8203C22.5672 14.8203 18.2285 19.159 18.2285 24.5109C18.2285 29.8629 22.5672 34.2016 27.9191 34.2016Z"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M37.6094 12.0512L35.6066 8.04387C35.1374 7.10577 34.1784 6.51336 33.1295 6.51367H22.708C21.6591 6.51336 20.7001 7.10577 20.2309 8.04387L18.2281 12.0512H5.76875C4.23961 12.0512 3 13.2908 3 14.8199V36.9699C3 38.4991 4.23961 39.7387 5.76875 39.7387H41.7625C43.2916 39.7387 44.5312 38.4991 44.5312 36.9699V14.8199C44.5312 13.2908 43.2916 12.0512 41.7625 12.0512H37.6094Z"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.22734 17.5889C9.60963 17.5889 9.91953 17.8988 9.91953 18.2811C9.91953 18.6633 9.60963 18.9732 9.22734 18.9732C8.84506 18.9732 8.53516 18.6633 8.53516 18.2811C8.53516 17.8988 8.84506 17.5889 9.22734 17.5889"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.3066 12.052V9.2832"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <p className="text-secondary mb-0 mt-1">
                                  Add asset
                                </p>
                              </Link>
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                id="myneoddness"
                                accept="image/*"
                                capture="environment"
                                className="d-none"
                                onChange={(e) => AddAssets(e)}
                              />
                              <label
                                className="vs-icon-btn d-block"
                                style={{ cursor: "pointer" }}
                                htmlFor="myneoddness"
                              >
                                <svg
                                  width={30}
                                  viewBox="0 0 46 46"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27.9191 34.2016C33.2711 34.2016 37.6098 29.8629 37.6098 24.5109C37.6098 19.159 33.2711 14.8203 27.9191 14.8203C22.5672 14.8203 18.2285 19.159 18.2285 24.5109C18.2285 29.8629 22.5672 34.2016 27.9191 34.2016Z"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M37.6094 12.0512L35.6066 8.04387C35.1374 7.10577 34.1784 6.51336 33.1295 6.51367H22.708C21.6591 6.51336 20.7001 7.10577 20.2309 8.04387L18.2281 12.0512H5.76875C4.23961 12.0512 3 13.2908 3 14.8199V36.9699C3 38.4991 4.23961 39.7387 5.76875 39.7387H41.7625C43.2916 39.7387 44.5312 38.4991 44.5312 36.9699V14.8199C44.5312 13.2908 43.2916 12.0512 41.7625 12.0512H37.6094Z"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.22734 17.5889C9.60963 17.5889 9.91953 17.8988 9.91953 18.2811C9.91953 18.6633 9.60963 18.9732 9.22734 18.9732C8.84506 18.9732 8.53516 18.6633 8.53516 18.2811C8.53516 17.8988 8.84506 17.5889 9.22734 17.5889"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.3066 12.052V9.2832"
                                    stroke="#6D6F81"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <p className="text-secondary mb-0 mt-1">
                                  Add asset
                                </p>
                              </label>
                            </>
                          )}

                          {/*----- User Profile Icon  -----*/}
                          <div className="vs-icon-btn">
                            <Link
                              to={"/my-profile"}
                              href="#!"
                              className="d-block"
                            >
                              <svg
                                width={30}
                                viewBox="0 0 46 46"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M21.8516 23.1357C25.5407 23.1357 28.5313 20.1451 28.5313 16.4561C28.5313 12.767 25.5407 9.77637 21.8516 9.77637C18.1625 9.77637 15.1719 12.767 15.1719 16.4561C15.1719 20.1451 18.1625 23.1357 21.8516 23.1357Z"
                                  stroke="#6D6F81"
                                  strokeWidth="2.10938"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M21.7637 41.7148C32.7824 41.7148 41.7148 32.7824 41.7148 21.7637C41.7148 10.7449 32.7824 1.8125 21.7637 1.8125C10.7449 1.8125 1.8125 10.7449 1.8125 21.7637C1.8125 32.7824 10.7449 41.7148 21.7637 41.7148Z"
                                  stroke="#6D6F81"
                                  strokeWidth="2.10938"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M35.5625 35.5625C35.5625 33.6977 34.8217 31.9093 33.5031 30.5907C32.1845 29.272 30.3961 28.5313 28.5312 28.5312H14.4688C12.6039 28.5313 10.8155 29.272 9.49691 30.5907C8.17829 31.9093 7.4375 33.6977 7.4375 35.5625"
                                  stroke="#6D6F81"
                                  strokeWidth="2.10938"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>

                              <p className="text-secondary mb-0 mt-1">
                                Profile
                              </p>
                            </Link>
                          </div>
                        </div>
                        {/*----- Vodasure Optional Footer end -----*/}
                      </div>
                    </div>
                  </div>

                  <div
                    className={checkTour == true ? "d-none" : "mybackdrop"}
                  ></div>
                  {/*----- Main Container For Dashboard End -----*/}

                  <DashboardModal
                    termConditionCanvas={termConditionCanvas}
                    termConditionCanvasClose={termConditionCanvasClose}
                    handleCloseTerm={handleCloseTerm}
                  />
                  {/*----- Add New asset option Offcanvas -----*/}
                  <AddAssetCanvas
                    addAssetCanvas={addAssetCanvas}
                    addAssetCanvasClose={addAssetCanvasClose}
                    assetCount={dashboard?.assets == 0}
                  />

                  {/*----- Add address modal -----*/}
                  <Modal
                    Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    className="AddaddressModal"
                  >
                    <div className="modal-body text-center py-5 px-4">
                      <img
                        src="./assets/Newimg/YellowBulb.png"
                        alt
                        className="bulb"
                      />

                      <p className="my-5">
                        In order to add any asset to your inventory, we need a
                        physical address.
                      </p>

                      <Link to="/add-physical-address" className="d-block">
                        Create my address
                        <img
                          src="./assets/Newimg/Arrow-right.png"
                          className="modalIcons"
                          alt
                        />
                      </Link>

                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          handleClose(true);
                        }}
                        className="d-block mt-5"
                      >
                        Add later
                        <img
                          src="./assets/Newimg/Arrow-right.png"
                          className="modalIcons"
                          alt
                        />
                      </a>
                    </div>
                  </Modal>

                  {/*----- Informative modal -----*/}
                  <Modal
                    show={quotationShow}
                    onHide={handleQuotationModalClose}
                    centered
                  >
                    <div className="modal-body text-center p-5">
                      <img
                        src="./assets/Newimg/YellowBulb.png"
                        alt
                        className="bulb"
                      />

                      <h3 className="my-5">
                        Easily get the right cover at the best price for your
                        assets
                      </h3>

                      <p className="my-5">
                        By answering a few simple questions, we’ll be able to
                        offer you the right cover at an affordable price.
                      </p>

                      <Link
                        to="/under-writing"
                        state={{
                          selectedIds: selectedIds,
                          selectedCatalogIds: selectedCatalogIds,
                        }}
                        className="d-block mt-5"
                      >
                        Let's do this!
                        <img
                          src="./assets/images/icon/GorightIcon.svg"
                          className="modalIcons"
                          alt
                        />
                      </Link>
                    </div>
                  </Modal>

                  <Offcanvas
                    show={quotationCanvas}
                    onHide={quotationCanvasClose}
                    scroll={false}
                    placement="bottom"
                    id="viewquote"
                    className="suddenoffcanvas p-4 show offcanvas offcanvas-bottom"
                  >
                    <div className="offcanvas-header px-4">
                      <button
                        type="button"
                        className="btn-close"
                        style={{ position: "absolute", right: "20px" }}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={quotationCanvasClose}
                      />
                    </div>
                    <div className="offcanvas-body px-3">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="Contents"
                          tabIndex={0}
                        >
                          <div className="Section_random_intro text-center mb-5">
                            <h3
                              className="text-gray mb-3 text-center"
                              style={{ fontSize: "30px" }}
                            >
                              Get a quote
                            </h3>
                            <p
                              className="mt-2 text-center"
                              style={{ fontSize: "17px" }}
                            >
                              Please select the assets that
                            </p>
                            <p
                              className="mt-2 text-center"
                              style={{ fontSize: "17px" }}
                            >
                              would you like to quote for?
                            </p>
                          </div>
                          <ul
                            className="vs-getaquote list-unstyled accordion"
                            id="QuoteAccordion"
                          >
                            <li className="accordion-item">
                              <button
                                className="accordion-button collapsed row justify-content-between align-items-center"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#myassetstocover"
                              >
                                <div className="col-2">
                                  <img src="./assets/images/assetquotemyasset.svg" />
                                </div>
                                <div className="col-9">
                                  <p className="mb-0">My assets</p>
                                </div>
                              </button>

                              <div
                                id="myassetstocover"
                                className="accordion-collapse collapse show"
                                data-bs-parent="#QuoteAccordion"
                              >
                                <div className="accordion-body">
                                  {myAssets &&
                                    myAssets?.length > 0 &&
                                    myAssets
                                      ?.filter(
                                        (type) =>
                                          type.status == "uninsured" &&
                                          type.catalog_id > 0
                                      )
                                      ?.map((assets, index) => (
                                        <div
                                          className="assetinqotemodal mt-3 mb-0 alert alert-dismissible fade show row"
                                          key={index}
                                        >
                                          <div className="col-3">
                                            <img
                                              src={assets?.asset_image}
                                              alt
                                            />
                                          </div>
                                          <div className="col-8">
                                            {assets?.policy_type ==
                                              "Accidental Damage" && (
                                                <p className="damageBadge badge bg-secondary px-4 py-2">
                                                  Accidental Damage
                                                  <i class="fa-solid fa-angle-down ms-2"></i>
                                                </p>
                                              )}

                                            <p className="mb-0 text-muted fs-4">
                                              {assets?.model}
                                            </p>
                                            <small>
                                              R{" "}
                                              {parseInt(assets?.cost)
                                                ?.toLocaleString()
                                                ?.replaceAll(",", " ")}
                                            </small>
                                          </div>
                                          <div className="col-1 align-self-center">
                                            <div className="form-check form-check-inline">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="passport"
                                                checked={
                                                  assetsPrice &&
                                                    assetsPrice?.find(
                                                      (e) => e.id == assets.id
                                                    )
                                                    ? true
                                                    : false
                                                }
                                                name="proff"
                                                onChange={() =>
                                                  handleSelectAssets(
                                                    assets?.cost,
                                                    assets?.id,
                                                    assets.catalog_id
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </div>
                              </div>
                            </li>
                            <li className="accordion-item">
                              <button
                                className="accordion-button collapsed row justify-content-between align-items-center"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#Whatwecover"
                              >
                                <div className="col-2">
                                  <img src="./assets/images/icon/WhatWeCover.svg" />
                                </div>
                                <div className="col-9">
                                  <p className="mb-0">What we cover</p>
                                </div>
                              </button>
                              <div
                                id="Whatwecover"
                                className="accordion-collapse collapse"
                                data-bs-parent="#QuoteAccordion"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    <li className="position-relative pt-5 mt-3">
                                      Loss We'll replace it.
                                    </li>
                                    <li>Theft: We’ll replace it.</li>
                                    <li>Cracked screen: We’ll repair it.</li>
                                    <li>Camera damage: We’ll repair it.</li>
                                    <li>
                                      Touchscreen damage: We’ll repair it.
                                    </li>
                                    <li>
                                      Water damage: We'll repair or replace it.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="accordion-item">
                              <button
                                className="accordion-button collapsed row justify-content-between align-items-center"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#claimstep"
                              >
                                <div className="col-2">
                                  <img src="./assets/images/icon/EasyClaimStep.svg" />
                                </div>
                                <div className="col-9">
                                  <p className="mb-0">
                                    Claim in three easy steps
                                  </p>
                                </div>
                              </button>
                              <div
                                id="claimstep"
                                className="accordion-collapse collapse"
                                data-bs-parent="#QuoteAccordion"
                              >
                                <div className="accordion-body">
                                  <ol>
                                    <li className="position-relative pt-5 mt-3">
                                      <p className="mb-2">
                                        Blacklist your device
                                      </p>
                                      <small className="fs-4 text-secondary">
                                        If your device is lost or stolen dial
                                        082 135.
                                      </small>
                                    </li>
                                    <li>
                                      <p className="mb-2">Log your claim</p>
                                      <small className="fs-4 text-secondary">
                                        To claim – Call us at 082 1952, email us
                                        at
                                        servicing.deviceinsurance@vodacom.co.za,
                                        or visit your nearest store.
                                      </small>
                                    </li>
                                    <li>
                                      <p className="mb-2">
                                        Collect your device
                                      </p>
                                      <small className="fs-4 text-secondary">
                                        We process all valid claims within 48
                                        hours.
                                      </small>
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </li>
                            <li className="accordion-item">
                              <button
                                className="accordion-button collapsed row justify-content-between align-items-center"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faq"
                              >
                                <div className="col-2">
                                  <img src="./assets/images/icon/FAqOffcanvac.svg" />
                                </div>
                                <div className="col-9">
                                  <p className="mb-0">
                                    Frequently asked questions
                                  </p>
                                </div>
                              </button>
                              <div
                                id="faq"
                                className="accordion-collapse collapse"
                                data-bs-parent="#QuoteAccordion"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    <li className="position-relative pt-5 mt-3">
                                      <p className="mb-2">
                                        When will my cover start?
                                      </p>
                                      <small className="text-secondary">
                                        Your cover starts immediately. If you
                                        choose to add insurance when upgrading
                                        or buying a new device instore, you will
                                        be covered as soon as your purchase is
                                        complete.
                                      </small>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <footer className="vs-floting-footer">
                            <div className="d-flex btn-width mx-auto justify-content-between align-items-center">
                              <a
                                href="javascript:void(0)"
                                onClick={() => quotationCanvasClose(true)}
                                className="btn btn-outline-secondary rounded-pill fs-3 py-3 w-50 me-3"
                              >
                                Cancel
                              </a>
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  quotationCanvasClose(true);
                                  handleQuotationModalShow(true);
                                }}
                                className={
                                  selectedIds?.length > 0
                                    ? "btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3"
                                    : "btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3 disabled"
                                }
                              >
                                Continue
                              </a>
                            </div>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </Offcanvas>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
