import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import SuccessScreen from '../../common/SuccessScreen';
import ExperienceRating from './ExperienceRating';

const Covered = () => {
  const location = useLocation();
  // console.log("location", location?.state?.coveredData);
  const coveredData = location?.state?.coveredData;
  const [successLoader, setSuccessLoader] = useState(true);
  const [experienceRating, setExperienceRating] = useState(false);
  const experienceRatingClose = () => setExperienceRating(false);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccessLoader(false);
      // return (
      //   <SuccessScreen />
      // )
    }, 1000);
  }, []);

  return (
    <>
      {/*----- Main Container For Debit Order Start -----*/}
      {successLoader ?
        <SuccessScreen /> :
        <>
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-debitorder  position-relative">
                {/*----- Vs Header Start -----*/}
                <div className="vs-header Withbtngroup">
                  {/*------ Header Mid Heading (Left Part) ------*/}


                  {/*------ Header Mid Heading (Middle Part) ------*/}
                  <h4 className="vs-header-title d-none">vodasure | asset cover</h4>

                  {/*------ Header Mid Heading (Right Part) ------*/}

                </div>
                {/*----- Vs Header end -----*/}

                {/*----- Section Random Intro Start -----*/}
                <div className="Section_random_intro text-center mb-5 pt-5">
                  {/*----- Debit Order Image -----*/}
                  <svg width={50} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.9" filter="url(#filter0_i_303_23424)">
                      <circle cx={26} cy={26} r={26} fill="#00B0CA" />
                    </g>
                    <path d="M16.9219 26.7031L21.5275 33.2461C21.8182 33.6803 22.3008 33.9473 22.8231 33.9629C23.3454 33.9786 23.843 33.7408 24.1591 33.3247L37.7812 19.4375" stroke="#F2F4F7" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                    <defs>
                      <filter id="filter0_i_303_23424" x={0} y={0} width={52} height={56} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy={4} />
                        <feGaussianBlur stdDeviation={5} />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_303_23424" />
                      </filter>
                    </defs>
                  </svg>
                  <h3 className="fs-2 mb-4 mt-5 w-75 mx-auto">
                    Success!
                  </h3>
                  <p className="text-secondary mx-auto">
                    Your request is being processed.<br />You’ll get an SMS or email shortly.
                  </p>
                </div>
                {/*----- Section Random Intro End -----*/}
                {/*----- Vodasure Buy Debit Order Content container Start -----*/}
                {/* {console.log("coveredData?.policy_holder", coveredData?.policy_holder)} */}
                <section className="vs-content_section vs-debitsuMMry  pt-0" style={{ overflowY: 'scroll' }}>
                  <div className="customerDetails d-flex justify-content-between flex-wrap px-4">
                    <p className="text-secondary w-50 text-break">Policy number:</p>
                    <p className="w-50 text-end text-break">{coveredData?.policy_number}</p>
                    <p className="text-secondary w-50 text-break">Payment date:</p>
                    <p className="w-50 text-end text-break">{formatDate(coveredData?.payment_date)}</p>
                    <p className="text-secondary w-50 text-break">Insurance term:</p>
                    <p className="w-50 text-end text-break text-capitalize">{coveredData?.insurence_term}</p>
                    <hr className="w-100" />
                    <p className="text-secondary w-50 text-break">Policy holder:</p>
                    <p className="w-50 text-end text-break text-capitalize ">{coveredData?.policy_holder}</p>
                    {/*  */}
                    <p className="text-secondary w-50 text-break">Cellphone number:</p>
                    <p className="w-50 text-end text-break">{coveredData?.mobile}</p>
                    <hr className="w-100" />
                    <p className="text-secondary w-50 text-break">Payment method:</p>
                    <p className="w-50 text-end text-break text-capitalize">{coveredData?.payment_method}</p>
                    <p className="text-secondary w-50 text-break">Monthly premium :</p>
                    {/* {console.log("coveredData?.monthly_premium", coveredData?.monthly_premium)} */}
                    <p className="w-50 text-end text-break">R {coveredData?.monthly_premium?.toLocaleString()?.replaceAll(",", " ")}</p>
                  </div>
                </section>
                {/*----- Vodasure Buy Debit Order Content container End -----*/}
                {/*----- Basic Footer Section Start -----*/}
                <footer className="vs-floting-footer vs-footer-gradient">
                  <a href="javascript:void(0)" onClick={() => setExperienceRating(true)} className="btn btn-primary rounded-pill px-5 py-3 w-100">
                    Done
                  </a>
                  {/* <Link to="/" className="btn btn-primary rounded-pill px-5 py-3 w-100">
                    Done
                  </Link> */}
                </footer>
                {/*----- Basic Footer Section End -----*/}
              </div>
            </div>
          </div>
          {/*----- Main Container For Debit Order End -----*/}
        </>
      }
      <ExperienceRating
        experienceRating={experienceRating}
        experienceRatingClose={experienceRatingClose}
        paymentId={location?.state?.coveredData?.id}
      />
    </>
  )
}

export default Covered