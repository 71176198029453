import React from 'react'
import ClaimHistory from './ClaimHistory'
import InsuranceHistory from './InsuranceHistory'

const ProfileStepsCount = (props) => {
  switch (props.step) {
    case 0:
      return (
        <ClaimHistory setStepAnswer={props.setStepAnswer} />
      )
    case 1:
      return (
        <InsuranceHistory setStepAnswer={props.setStepAnswer} />
      )
    // case 2:
    //   return (
    //     <EmployeeStep setStepAnswer={props.setStepAnswer} />
    //   )
    // case 3:
    //   return (
    //     <OccupationStep setStepAnswer={props.setStepAnswer} />
    //   )
    // case 4:
    //   return (
    //     <InsuranceCountStep setStepAnswer={props.setStepAnswer} />
    //   )
    // case 5:
    //   return (
    //     <ClaimHistoryStep setStepAnswer={props.setStepAnswer} />
    //   )
    default:
      return (
        <>
          {/* <ClaimHistoryStep /> */}
        </>
      )
  }
}

export default ProfileStepsCount