import React from 'react'

const ObjectLoader = () => {
  return (
    <>
      <div className='lodarcontainer'>
        <div className='lodar'>
          <img src='./assets/images/lodar.gif' />
        </div>
      </div>
    </>
  )
}

export default ObjectLoader