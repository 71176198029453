import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const DeliveryMethod = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*----- Main Container For domicile Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-domicile position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                  <img src="./assets/images/icon/BackIcon.svg" />
                </a>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Choose device</h4>
              </div>

              <div className="col-2 text-end" />
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-lg-5 mb-3 mt-3">
            <h3 className="fs-2 mb-3 text-white">Delivery mehtod</h3>
            <p className="text-drk-gray w-75 mx-auto">
              How would you like to receive your device?
            </p>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure domicile Content container Start -----*/}
          <section className="vs-content_section px-4 pt-4">
            {/*----- Selected Device name -----*/}
            <p className="bg-secondary text-gray rounded-3 pt-5 pb-3 px-5 mb-5">
              Apple iPhone 12 | 64GB | Grey
            </p>
            {/*----- Domicile List Container start -----*/}
            <ul className="vs-domicileList list-unstyled">
              <li>
                <Link to="/cover-summary" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/DeliveryIcoN.svg" className='dlvryicon' />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Delivery</h3>
                    <p className="text-gray w-100 text-truncate mb-2">
                      Delivered straight to your door
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/cover-summary" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/RoundQuote.svg" className='dlvryicon' />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Collect</h3>
                    <p className="text-gray w-100 text-truncate mb-2">
                      Collect from any Vodacom store
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </Link>
              </li>
            </ul>
            {/*----- Domicile List Container End -----*/}
          </section>
          {/*----- Vodasure domicile Content container End -----*/}
        </div>
      </div>
      {/*----- Main Container For domicile End -----*/}
    </>
  )
}

export default DeliveryMethod