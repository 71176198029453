import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from 'react-router-dom';

const ClaimType = () => {

  const navigate = useNavigate();

  const [claimCanvas, setClaimCanvas] = useState(false);
  const claimCanvasClose = () => setClaimCanvas(false);

  return (
    <>
      {/*----- Main Container For Profile Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-profile position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)" onClick={() => navigate(-1)}><img src="./assets/images/icon/BackIcon.svg" /></a>
              </div>

              <div className="col-7 text-center">
                <h4 className="vs-header-title">Submit a claim</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>
                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-3 text-white">Please select an asset status</h3>
            {/*----- Here we don't need the subheading of Random intro so we are not using it -----*/}
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure Profile Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Domicile List Container start -----*/}
            <ul className="vs-profileOption list-unstyled rounded-4">
              <li onClick={() => setClaimCanvas(true)}>
                <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <p className="w-100 mb-2">Safe</p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>

              <li onClick={() => setClaimCanvas(true)}>
                <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <p className="w-100 mb-2">Lost</p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>

              <li onClick={() => setClaimCanvas(true)}>
                <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <p className="w-100 mb-2">Stolen</p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>

              <li onClick={() => setClaimCanvas(true)}>
                <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <p className="w-100 mb-2">Damaged</p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>

              <li onClick={() => setClaimCanvas(true)}>
                <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <p className="w-100 mb-2">Recovered</p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>

              <li onClick={() => setClaimCanvas(true)}>
                <a href="javascript:void(0)" className="row justify-content-between align-items-center">
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <p className="w-100 mb-2">Other</p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
            </ul>
            {/*----- Domicile List Container End -----*/}
          </section>
          {/*----- Vodasure Profile Content container End -----*/}
        </div>
      </div>

      {/*----- Main Container For Profile End -----*/}
      <Offcanvas show={claimCanvas} onHide={claimCanvasClose} scroll={false} placement='bottom'>
        {/*----- Offcanvas Header -----*/}
        <div className="offcanvas-header px-5 pt-4 mt-2">
          <a href="javascript:void(0)" onClick={claimCanvasClose} className="btn-close ms-auto" />
        </div>
        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body px-5">
          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5">
            <h3 className="fs-2 mb-3 w-75 mx-auto">Submit a claim</h3>
            {/*----- Here we don't need the subheading of Random intro so we are not using it -----*/}
          </div>
          {/*----- Section Random Intro End -----*/}
          <ul className="vs-domicileList list-unstyled pt-0 rounded-4 mb-5 px-sm-4 px-0">
            <li className="border-0 pb-0">
              <div className="form-floating mb-4">
                {/*--- Input ---*/}
                <input type="text" className="form-control" id="adf1" placeholder="Device" />
                {/*--- Label ---*/}
                <label htmlFor="adf1" className="h-auto text-dark-gray">Device</label>
                {/*--- Error Message ---*/}
                <div className="m-0 f-error ">This input is invalid</div>
              </div>
            </li>
            <li className="border-0 py-0">
              <div className="form-floating mb-4">
                {/*--- Input ---*/}
                <input type="text" className="form-control" id="adf1" placeholder="Claim type" />
                {/*--- Label ---*/}
                <label htmlFor="adf1" className="h-auto text-dark-gray">Claim type</label>
                {/*--- Error Message ---*/}
                <div className="m-0 f-error ">This input is invalid</div>
              </div>
            </li>
            <li className="border-0 py-0">
              {/*----- Floating Label input -----*/}
              <div className="form-floating mb-4">
                {/*--- Input ---*/}
                <input type="text" className="form-control" id="adf1" placeholder="Policy number" />
                {/*--- Label ---*/}
                <label htmlFor="adf1" className="h-auto text-dark-gray">Policy number</label>
                {/*--- Error Message ---*/}
                <div className="m-0 f-error ">This input is invalid</div>
              </div>
            </li>
          </ul>
          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro mb-4">
            <h3 className="fs-5 mb-1 text-dark-gray">Disclaimer:</h3>
            <p className="fs-5 text-muted">
              This policy only covers the insured device(s), used together<br />with the Vodacom SIM Card listed on
              the attached schedule.
            </p>
          </div>
          {/*----- Section Random Intro End -----*/}
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="tncCheck" />
            <label className="form-check-label fs-5 text-muted text-start" htmlFor="tncCheck">
              I give VodaSure | Locker permission to capture, geo-locate and track
              identifying data to assist in the quoting and insuring of my digital
              assets.
            </label>
          </div>
          {/*----- Basic Footer Section Start -----*/}
          <footer className="mt-5 mb-4">
            <form action="#" className="d-flex mx-auto justify-content-between align-items-center">
              <Link to="/claim-details" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-100 ms-3">
                Block my sim card
              </Link>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>

      </Offcanvas>
    </>
  )
}

export default ClaimType