import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetDataWithToken, PostDataWithToken, decryptResponseData, encryptRequestData, mainUrl } from '../../ApiHelper/ApiHelper';
import { Offcanvas } from 'react-bootstrap';
import Loader from '../../common/Loader';
import axios from 'axios';

const CoverSummery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [placeData, setPlaceData] = useState({});
  const addDebit = location?.state?.addDebit;
  // const selectedIds = location?.state?.selectedIds;
  const selectedAssetsList = location?.state?.selectedAssetsList;
  const selectedIds = location?.state?.selectedIds;
  const inventryValue = location?.state?.inventryValue;
  const quoteLineItems = location?.state?.quoteLineItems;
  const underwritingQuestions = location?.state?.underwritingQuestions;
  const campaignSchema = location?.state?.campaignSchema;
  const quoteId = location?.state?.quoteId;
  // console.log("(campaignSchema)", JSON.stringify(campaignSchema));

  function populateRiskFactorsSchema(assets, riskFactors) {
    return assets?.map(asset => {
      const riskFactorsSchema = riskFactors?.map(riskFactor => {
        const availableValues = riskFactor.availableValues?.map(value => {
          if (typeof value === 'object' && value.label && value.value) {
            return {
              label: value.label,
              value: value.value,
            };
          } else {
            return value;
          }
        });

        return {
          riskFactorId: riskFactor.riskFactorId,
          name: riskFactor.name,
          value: riskFactor.value,
          riskFactorType: riskFactor.riskFactorType,
          availableValues: availableValues,
        };
      });

      return {
        ...asset,
        riskFactorsSchema: JSON.stringify(riskFactorsSchema),
      };
    });
  }

  const assetsWithRiskFactors = populateRiskFactorsSchema(quoteLineItems, underwritingQuestions);

  // console.log("assetsWithRiskFactors", assetsWithRiskFactors);

  const [proRataPrice, setProRataPrice] = useState(null);

  // console.log("inventryValue-------------", inventryValue);
  const premiumQuote = parseFloat(inventryValue).toFixed(2);
  const dueMonthlyPayment = proRataPrice + Number(premiumQuote);

  // console.log("premiumQuote", premiumQuote.replace('.', ','));
  // console.log("addDebit", addDebit);
  const [paymentType, setPaymentType] = useState(addDebit == undefined ? 'Select your payment method' : 'Debit order');

  // console.log("selectedIds", selectedIds);
  const [callApi, setCallApi] = useState(true);
  const [loader, setLoader] = useState(true);
  const [myDetails, setMyDetails] = useState({});
  const [vodacomCustomer, setVodacomCustomer] = useState(false);

  function formatDate(date) {
    if (!(date instanceof Date)) {
      throw new Error('Input must be a Date object');
    }

    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  // console.log(formattedDate);

  useEffect(() => {
    let headers = {
      Authorization: "Bearer " + oAuthToken,
    };
    const inputDate = new Date();
    const formattedDate = formatDate(inputDate);
    let proRataData = {
      EffectiveDate: formattedDate,
      Premium: premiumQuote
    }
    const encrptData = encryptRequestData(JSON.stringify(proRataData));
    // console.log("encrptData", encrptData);
    axios.post(`${mainUrl}quotation/Prorata`, { payload: encrptData }, { headers: headers })
      .then((response) => {
        // console.log("resssssponseeeeeee----", decryptResponseData(response.data));
        response = decryptResponseData(response.data);
        setProRataPrice(response.data.amount);
      })
  }, []);

  useEffect(() => {
    adjustContainer(); // Call the adjustContainer function on component mount and resize
    window.addEventListener('resize', adjustContainer); // Add event listener for resize
    return () => {
      window.removeEventListener('resize', adjustContainer); // Clean up the event listener on component unmount
    };
  }, []);

  function adjustContainer() {
    var footer = document.querySelector('.vs-footer') ? document.querySelector('.vs-footer').offsetHeight + 20 : document.querySelector('.vs-floting-footer') ? document.querySelector('.vs-floting-footer').offsetHeight : 0;
    if (footer > 0) {
      var header = document.querySelector('.vs-header').offsetHeight + 0;
      var title = document.querySelector('.vs-main-contentContainer .static-title-top') ? document.querySelector('.vs-main-contentContainer .static-title-top').offsetHeight : 0;
      var body = window.innerHeight;
      var container = body - (header + footer + title);
      document.querySelector('.vs-content_section').style.maxHeight = container + 'px';
    }
  };

  const getMobileNoWithZero = (mobile) => {
    if (mobile) {
      const str = String(mobile);
      if (str.charAt(0) === "0") {
        return mobile;
      }
      return `0${mobile}`
    }
    return mobile;
  }


  useEffect(() => {
    if (callApi || loader) {
      GetDataWithToken('user/get-personal-details', '')
        .then((response) => {
          if (response.status == true) {
            setMyDetails(response.data);
            let headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + oAuthToken,
            };
            getMobileNoWithZero(response.data.mobile)
            // console.log("first", response.data.mobile);
            // if (addDebit == undefined) {
            // const mobileNumber = response.data.mobile;
            axios.get(`${mainUrl}quotation/vodacom-customer-check/${getMobileNoWithZero(response.data.mobile)}`, { headers: headers })
              .then((response) => {
                // console.log("qwertyu", response.data)

                // console.log("resssssponseeeeeee----", decryptResponseData(response.data));
                response = decryptResponseData(response.data);
                if (response.data.status == true) {
                  setVodacomCustomer(response.data.backendResponse.qualifiesForAddToBill);
                  setLoader(false);
                } else {
                  setLoader(false);
                  setVodacomCustomer(false);
                }
              });
            // }
            setCallApi(false);
          }
        })
      GetDataWithToken('user/get-physical-address', '')
        .then((response) => {
          if (response.status == true) {
            setPlaceData(response.data);
            setLoader(false);
          }
        })

    }
  }, [callApi]);

  const [addAssetCanvas, setAddAssetCanvas] = useState(false);
  const addAssetCanvasClose = () => setAddAssetCanvas(false);
  const oAuthToken = localStorage.getItem('oAuthToken');
  const idNumber = localStorage.getItem('idNumber');
  // console.log("oAuthToken", oAuthToken);
  const handleAddPayment = () => {
    setLoader(true);
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oAuthToken,
    };


    const dataQA = {
      sourceTransactionId: "e4c161b7-d881-4066-a016-e72e51f72537",
      createdBy: 3205,
      campaignId: 251,
      supplierId: 103627,
      id: quoteId,
      referenceNumber: "",
      campaignSchema: JSON.stringify(campaignSchema),
      lastModifiedBy: 3205,
      assetCatalogueSchema: "{}",
      isEstimate: false,
      quoteLineItems: assetsWithRiskFactors,
      assets: selectedAssetsList,
      customer: {
        firstName: myDetails?.first_name,
        lastName: " ",
        emailAddress: myDetails?.email,
        msisdn: String(myDetails?.mobile),
        idPassportNumber: idNumber,
        gender: "Male",
        dateOfBirth: myDetails?.dob,
        physicalAddress: {
          addressLine1: placeData?.appartment,
          addressLine2: placeData?.streat,
          city: placeData?.city,
          province: placeData?.province,
          suburb: placeData?.subrub,
          postalCode: "4001"
        },
        preferredMethodOfContact: "SMS",
        typeOfIdentification: "ID"
      },
      underwritingQuestions: underwritingQuestions
    }
    // console.log("dataQA", dataQA);
    const encrptData = encryptRequestData(JSON.stringify(dataQA));
    // console.log("encrptData", encrptData);

    axios.post(`${mainUrl}quotation/quote`, JSON.stringify({ payload: encrptData }), { headers: headers })
      .then((response) => {
        // console.log("resssssponseeeeeee----", decryptResponseData(response.data));
        response = decryptResponseData(response.data);
        if (response.status == true) {
          const outputArray = response.data.backendResponse.quoteLineItems.map(item => ({

            productId: "",
            termsAndConditionsId: 0,
            campaignId: 251,
            insuredUserFirstName: myDetails?.first_name,
            insuredUserLastName: " ",
            insuredUserIdNumber: "8001135800084",
            premium: item.premium,
            purchaseValue: item.cost,
            vasCode: item.vasCode,
            skipComms: true,
            iaaQuoteLineItemId: item.quoteId,
            msisdn: String(myDetails?.mobile),
            serialNumber: item.serialNumber,
            quantity: 1,
            riskFactorsDetails: item.riskFactorsSchema,
            details: {
              make: item.make,
              model: item.model,
              purchaseValue: item.cost,
              description: item.description
            }
          }));

          // console.log("outputArray", outputArray);
          const newBusiness = {
            sourceTransactionId: "e4c161b7-d881-4066-a016-e72e51f72537",
            origin: "iWyze",
            action: "Activate",
            uniqueTransactionNumber: "",
            transactionDate: new Date(),
            dealerCode: "FSVIC",
            finalPremium: 29,
            customer: {
              firstName: myDetails?.first_name,
              lastName: "S",
              emailAddress: myDetails?.email,
              msisdn: String(myDetails?.mobile),
              idPassportNumber: idNumber,
              gender: "Male",
              dateOfBirth: myDetails?.dob,
              physicalAddress: {
                addressLine1: placeData?.appartment,
                addressLine2: placeData?.streat,
                city: placeData?.city,
                province: placeData?.province,
                suburb: placeData?.subrub,
                postalCode: "4001"
              },
              preferredMethodOfContact: "SMS",
              typeOfIdentification: "ID"
            },
            policy: {
              productId: "",
              paymentTypeId: 1,
              iaaQuoteId: 871,
              bankingDetails: {
                accountHolder: "A D T",
                accountNumber: "23123213123",
                bankAccountType: "Cheque",
                debitDay: 1,
                branchCode: "250655",
                bank: "First National Bank (FNB)",
                bankId: 12,
                coverStart: "Immediately"
              },
              assetCatalogueDetails: "{}",
              campaignDetails: JSON.stringify(campaignSchema)
            },
            policyLineItems: outputArray
          }
          const encrptData = encryptRequestData(JSON.stringify(newBusiness));
          // console.log("encrptData", encrptData);

          axios.post(`${mainUrl}quotation/newBusiness`, JSON.stringify({ payload: encrptData }), { headers: headers })
            .then((response) => {
              // console.log("resssssponseeeeeee----", decryptResponseData(response.data));
              response = decryptResponseData(response.data);
              // console.log("resssponseeeeee----", response.data.data);
              if (response.data.backendResponse.success == true) {
                let orderData = {};
                if (addDebit == undefined && vodacomCustomer == true) {
                  orderData = {
                    payment_date: new Date(),
                    insurence_term: "monthly",
                    payment_method: paymentType,
                    monthly_premium: premiumQuote,
                    assetId: JSON.stringify(selectedIds),
                    referenceNumber: response.data.backendResponse.reference,
                  }
                } else {
                  orderData = {
                    payment_date: new Date(),
                    bank: addDebit?.bank,
                    account_type: addDebit?.account_type,
                    account_number: addDebit?.account_number,
                    branch_code: addDebit?.branch_code,
                    holder_name: addDebit?.holder_name,
                    order_date: addDebit?.order_date,
                    insurence_term: "monthly",
                    payment_method: paymentType,
                    monthly_premium: premiumQuote,
                    assetId: JSON.stringify(selectedIds),
                    referenceNumber: response.data.backendResponse.reference,
                  }
                }
                PostDataWithToken('user/add-payment-details', orderData)
                  .then((response) => {
                    // response = JSON.parse(response);
                    // console.log("ressss", response)
                    if (response.status == true) {
                      // alert();
                      navigate('/covered', {
                        state: {
                          coveredData: response.data,
                          premiumQuote: premiumQuote
                        }
                      });
                      setLoader(false);
                      // console.log("qwerty", response.data);
                    }
                  })
              }
            });

        }
      })

  };
  return (
    <>
      {loader ?
        <Loader />
        :
        <>
          {/* {console.log({ paymentType })} */}
          {/*----- Main Container For Cover Summary Start -----*/}
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-coverSummary position-relative">
                {/*----- Vs Header Start -----*/}
                <div className="vs-header Withbtngroup">
                  {/*------ Header Mid Heading (Left Part) ------*/}
                  <a href="javascript:void(0)" className="headericon" onClick={() => navigate(-1)}><img src="./assets/images/icon/BackIcon.svg" /></a>


                  {/*------ Header Mid Heading (Middle Part) ------*/}
                  <h4 className="vs-header-title d-none">vodasure | asset cover</h4>

                  {/*------ Header Mid Heading (Right Part) ------*/}
                  <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                    <a href="#" className="btn border-0 me-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                    </a>

                    <a href="#" className="btn border-0 ms-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                    </a>
                  </div>
                </div>
                {/*----- Vs Header end -----*/}


                {/*----- Vodasure Buy Cover Summary Content container Start -----*/}
                <section className="vs-content_section">
                  {/*----- Section Random Intro Start -----*/}
                  <div className="Section_random_intro mb-5">
                    <h3 className="fs-2 mb-3 mx-auto text-center">Payment details</h3>
                  </div>
                  {/*----- Section Random Intro End -----*/}

                  {/*----- company name card start -----*/}
                  <div className="card dashboard-card border-0 rounded-4 position-relative my-4">
                    <div className="card-body px-4 py-4 d-flex flex-wrap justify-content-between align-items-center">
                      <div className="websitefloaticon">
                        <img src="./assets/images/VodaResponse.svg" className="w-100" alt />
                      </div>
                      <p className="vs-card-title mb-3 mt-4">
                        Product name
                      </p>
                      <p className="vs-card-title mb-3 mt-4">
                        vodasure asset cover
                      </p>

                      <hr className="w-100" />

                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p className="position-relative">
                          Full name:
                        </p>
                        <p className="text-end text-break">{myDetails?.first_name}</p>
                      </div>

                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p className="position-relative">
                          Cellphone number:
                        </p>
                        <p className="text-end text-break">{myDetails.mobile}</p>
                      </div>

                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p className="position-relative">
                          Email address:
                        </p>
                        <p className="text-end text-break">{myDetails.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="customerDetails rounded-4 my-4">
                    <h3 className="pb-3">Payment method</h3>

                    <hr className="w-100" />

                    <div className='mt-5'>
                      <a href="javascript:void(0)" onClick={() => setAddAssetCanvas(true)} className="row justify-content-between align-items-center" data-bs-toggle="offcanvas" data-bs-target="#payMethod">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-10">
                          <div className='d-flex align-items-center'>
                            {/* <img src="./assets/images/icon/DebitCard.svg" className='d-inline-block me-3 align-baseline' /> */}
                            <i class="fa-regular fa-file-lines  me-3"></i>

                            <p className="text-truncate mb-0 d-inline-block align-sup">
                              {paymentType}
                            </p>
                          </div>
                        </div>

                        {/*-- Domicile Details --*/}
                        <div className="col-2">
                          <img src="/assets/images/icon/GoIcon.svg" className='align-baseline' />
                        </div>
                      </a>
                    </div>
                    <hr className="w-100" />
                  </div>


                  {/*----- company name card end -----*/}
                  <div className="customerDetails rounded-4">
                    <h3 className="pb-3">Cost details</h3>
                    <hr className="w-100" />
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="position-relative">
                        Pro-rata cost:

                        <span className='d-inline-block tobehover'>
                          <img src="./assets/images/icon/InfoUtilityIcon.svg" className='ms-2' alt />
                        </span>

                        <div className='hoverablediv'>
                          <p className='mb-0'>
                            This is a premium based on the length of time your device is actually at risk. You’ll only be charged for the service you’ve receive within the month before payment of
                            the premium started.
                          </p>
                        </div>
                      </p>
                      <p className="text-end text-break">R {proRataPrice}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className>
                        Due this month (0nce-off):
                      </p>
                      {/* {console.log("proRataPrice", typeof (proRataPrice))}
                      {console.log("premiumQuote", typeof (premiumQuote))} */}
                      <p className="text-end text-break">R {(dueMonthlyPayment).toFixed(2)}</p>
                    </div>
                    <hr className="w-100" />
                    <div className="d-flex justify-content-between align-items-center">
                      <p className>
                        Future total monthly premium:
                      </p>

                      <p className="text-end text-break">R {premiumQuote}</p>
                    </div>
                  </div>

                  <div className='bottomLine mt-4 px-3'>
                    <div className='row'>
                      <div className='col-5 pe-0'>
                        <hr />
                      </div>

                      <div className='col-2 text-center px-0'>
                        <i class="fa-solid fa-lock text-secondary"></i>
                      </div>

                      <div className='col-5 ps-0'>
                        <hr />
                      </div>

                      <div className='col-lg-12'>
                        <p className='mb-0 text-secondary text-center mt-3'>Secure Checkout</p>
                      </div>
                    </div>
                  </div>


                  {/* <div className="customerDetails rounded-4 mt-4">
              <h3 className="pb-3">Select Payment method</h3>


              <ul className="vs-uplodfield  list-unstyled  rounded-4 mb-0 p-0">
                <li className="border-0 py-0">
                  <div className="form-floating m-0 p-0">
                    <select class="form-select py-0" onClick={(e) => setPaymentType(e?.target?.value)}>
                      <option className='d-none' select>Payment method</option>
                      <option value={'Add to Vodacom bill'}>Add to Vodacom bill</option>
                      <option value={'Debit order'}>Debit order</option>
                    </select>
                  </div>
                </li>
              </ul>
            </div> */}
                </section>
                {/*----- Vodasure Buy Insurance Content container End -----*/}

                {/*----- Basic Footer Section Start -----*/}
                <form action="#" className="">
                  <footer className="vs-floting-footer vs-footer-gradient">
                    <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-primary rounded-pill px-5 py-3 first">
                      Cancel
                    </a>
                    <a href="javascript:void(0)" onClick={() => handleAddPayment()} className={paymentType == "Select your payment method" ? "btn btn-primary rounded-pill px-5 py-3 disabled" : "btn btn-primary rounded-pill px-5 py-3"}>
                      Confirm
                    </a>
                  </footer>
                </form>
                {/*----- Basic Footer Section End -----*/}
              </div>
            </div>
          </div>
          {/*----- Main Container Cover Summary End -----*/}
          <Offcanvas show={addAssetCanvas} onHide={addAssetCanvasClose} scroll={false} placement='bottom' id="addassetModal">
            {/*----- Offcanvas Header -----*/}
            <div className="offcanvas-header px-5 mt-5">
              <a href="#" data-bs-dismiss="offcanvas" onClick={addAssetCanvasClose} className="btn-close ms-auto" />
            </div>
            {/*----- Offcanvas Body -----*/}
            <div className="offcanvas-body">
              {/*----- Section Random Intro Start -----*/}
              <div className="Section_random_intro text-center mb-5">
                <h3 className="fs-2 mx-auto">
                  Please select a payment method
                </h3>
              </div>
              {/*----- Section Random Intro End -----*/}
              <ul className="vs-getaquote list-unstyled pb-0 px-0">
                {vodacomCustomer == true && (
                  <li className="py-5">
                    <label htmlFor='mypay1' onClick={() => { setPaymentType("Add to Vodacom bill"); setAddAssetCanvas(false); }} className="row justify-content-between align-items-center noheight assetinqotemodal">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-1">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/camera1.svg"} />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-9">
                        <p className="w-100 text-truncate mb-0">
                          Add to Vodacom bill
                        </p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-1 text-end">
                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/icon/GoIcon.svg"} /> */}

                        <input type='radio' name="chooseonepayment" id='mypay1' checked={paymentType == "Add to Vodacom bill"} className='form-check-input' />
                      </div>
                    </label>
                  </li>
                )}
                <li className="py-5">
                  <label htmlFor='mypay2' onClick={() => { setPaymentType("Debit order"); setAddAssetCanvas(false); navigate('/debit-card', { state: { selectedIds: selectedIds, myDetails: myDetails, inventryValue: inventryValue, quoteId: quoteId, campaignSchema: campaignSchema, underwritingQuestions: underwritingQuestions, quoteLineItems: quoteLineItems, selectedAssetsList: selectedAssetsList } }) }} className="row justify-content-between align-items-center">
                    {/*-- Locaiton Icon --*/}
                    <div className="col-1">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/GalleryIcon.svg"} />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-9">
                      <p className="w-100 text-truncate mb-0">
                        Debit order
                      </p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-1 text-end">
                      {/* <img src={process.env.PUBLIC_URL + "/assets/images/icon/GoIcon.svg"} /> */}
                      <input type='radio' name="chooseonepayment" className='form-check-input' id="mypay2" checked={paymentType == "Debit order"} />
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </Offcanvas>
        </>
      }
    </>
  )
}

export default CoverSummery