import React, { useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { PostDataWithToken } from '../../ApiHelper/ApiHelper';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ExperienceRating = ({ experienceRatingClose, experienceRating, paymentId }) => {
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');
  const [selectRating, setSelectRating] = useState(0);
  const [directClose, setDirectClose] = useState(false);

  useEffect(() => {
    if (directClose == true) {
      handleSubmitRating();
    }
  }, [directClose]);

  const handleSubmitRating = () => {
    let ratingData = {};
    if (directClose == true) {
      ratingData = {
        user_experince: 0,
        id: paymentId,
      }
    } else {
      ratingData = {
        user_experince: selectRating,
        id: paymentId
      }
    }
    // console.log("ratingData", ratingData)
    PostDataWithToken('user/add-rating', ratingData)
      .then((response) => {
        response = JSON.parse(response);
        // console.log("ressss", response)
        if (response.status == true) {
          navigate(`/${accessToken}`);
        }
      })
  }

  return (
    <>
      <Offcanvas show={experienceRating} onHide={experienceRatingClose} className="p-4" id="thefirstoff" backdrop="static" placement='bottom'>
        <button type="button" className="btn-close ms-auto" onClick={handleSubmitRating} />

        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body pt-0">
          {/*----- Share Your Feedback Start -----*/}
          <div className="Sharefeedback">
            {/*----- Rating Container -----*/}
            <div className="rating">
              {/*----- Input -----*/}
              <input type="radio" name="rating" id="rating-5" onClick={() => setSelectRating(5)} />
              <label htmlFor="rating-5" />

              {/*----- Input -----*/}
              <input type="radio" name="rating" id="rating-4" onClick={() => setSelectRating(4)} />
              <label htmlFor="rating-4" />

              {/*----- Input -----*/}
              <input type="radio" name="rating" id="rating-3" onClick={() => setSelectRating(3)} />
              <label htmlFor="rating-3" />

              {/*----- Input -----*/}
              <input type="radio" name="rating" id="rating-2" onClick={() => setSelectRating(2)} />
              <label htmlFor="rating-2" />

              {/*----- Input -----*/}
              <input type="radio" name="rating" id="rating-1" onClick={() => setSelectRating(1)} />
              <label htmlFor="rating-1" />

              {/*----- Expression Emoji Wrapper -----*/}
              <div className="emoji-wrapper">
                <div className="emoji">
                  <img src='/assets/Newimg/ratebase.png' className='rating-0' />
                  <img src='/assets/Newimg/rate1.png' className='rating-1' />
                  <img src='/assets/Newimg/rate2.png' className='rating-2' />
                  <img src='/assets/Newimg/rate3.png' className='rating-3' />
                  <img src='/assets/Newimg/rate4.png' className='rating-4' />
                  <img src='/assets/Newimg/rate5.png' className='rating-5' />
                </div>
              </div>

            </div>
          </div>
          {/*----- Share Your Feedback End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center my-5">
            <h3 className="text-gray fs-2 mb-3 w-75 mx-auto">
              Rate your experience
            </h3>
            <p className="text-gray w-75 mx-auto">
              How was your experience with<br />vodasure | locker?
            </p>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer>
            <div className="d-flex mx-auto justify-content-between align-items-center">
              <a href="javascript:void(0)" onClick={handleSubmitRating} className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-100 ms-3">
                Submit Rating
              </a>
            </div>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </Offcanvas>
    </>
  )
}

export default ExperienceRating