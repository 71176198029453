import Cookies from 'js-cookie';
import React from 'react'
import { Link } from 'react-router-dom'

const Notification = () => {
  const accessToken = Cookies.get('accessToken');
  console.log("accessToken", accessToken)
  return (
    <>
      {/*----- Main Container For Quote Start -----*/}
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-notification position-relative">
            {/*----- Vs Header Start -----*/}
            <div className="vs-header Withbtngroup">
              {/*------ Header Mid Heading (Left Part) ------*/}
              <Link to={`/${accessToken}`} className='headericon'>
                <img src="./assets/images/icon/BackIcon.svg" />
              </Link>

              {/*------ Header Mid Heading (Middle Part) ------*/}
              <h4 className="vs-header-title">vodasure | asset cover</h4>

              {/*------ Header Mid Heading (Right Part) ------*/}
              <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                <a href="#" className="btn border-0 me-2">
                  <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                </a>

                <a href="#" className="btn border-0 ms-2">
                  <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                </a>
              </div>
            </div>
            {/*----- Vs Header end -----*/}

            {/*----- Section Random Intro Start -----*/}
            <div className="Section_random_intro text-center mb-4">
              {/*----- Quote Tab Content Navigation -----*/}
              <nav className="nav nav-tabs">
                {/*----- Navigation button 1 -----*/}
                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#All_tab" type="button">
                  <i className="fa-solid fa-circle text-danger fs-6 me-2" />
                  All
                </button>
                {/*----- Navigation button 2 -----*/}
                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Phone_tab" type="button">
                  <i className="fa-solid fa-circle text-danger fs-6 me-2" />
                  Read
                </button>
              </nav>
            </div>
            {/*----- Section Random Intro End -----*/}
            {/*----- Section Content Start -----*/}
            <section className="vs-content_section">
              {/*----- Tab Content -----*/}
              <div className="tab-content" id="nav-tabContent">
                {/*----- Tab 1 -----*/}
                <div className="tab-pane fade show active" id="All_tab">
                  <ul className="vs-profileOption list-unstyled">
                    <li>
                      <a href="#!" className="row justify-content-between align-items-center">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-2 position-relative">
                          <img src="./assets/images/notiflisticon.png" className="w-100" />
                          <span className="notifbadge">
                          </span>
                        </div>
                        {/*-- Domicile Details --*/}
                        <div className="col-9">
                          <small className="fs-5 text-muted">
                            15 Mar 2023 | 14:30
                          </small>
                          <p className="w-100 text-truncate mb-0 text-uppercase">
                            It looks like you left something behind...
                          </p>
                          <p className="text-truncate-by-2 fs-4 text-muted">
                            We saved all of the great assets you've added so when you're
                            ready, simply cover them!
                          </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="row justify-content-between align-items-center">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-2 position-relative">
                          <img src="./assets/images/notiflisticon.png" className="w-100" />
                          <span className="notifbadge">
                          </span>
                        </div>
                        {/*-- Domicile Details --*/}
                        <div className="col-9">
                          <small className="fs-5 text-muted">
                            15 Mar 2023 | 14:30
                          </small>
                          <p className="w-100 text-truncate mb-0 text-uppercase">
                            It looks like you left something behind...
                          </p>
                          <p className="text-truncate-by-2 fs-4 text-muted">
                            We saved all of the great assets you've added so when you're
                            ready, simply cover them!
                          </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="row justify-content-between align-items-center">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-2 position-relative">
                          <img src="./assets/images/notiflisticon.png" className="w-100" />
                          <span className="notifbadge">
                          </span>
                        </div>
                        {/*-- Domicile Details --*/}
                        <div className="col-9">
                          <small className="fs-5 text-muted">
                            15 Mar 2023 | 14:30
                          </small>
                          <p className="w-100 text-truncate mb-0 text-uppercase">
                            It looks like you left something behind...
                          </p>
                          <p className="text-truncate-by-2 fs-4 text-muted">
                            We saved all of the great assets you've added so when you're
                            ready, simply cover them!
                          </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="row justify-content-between align-items-center">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-2 position-relative">
                          <img src="./assets/images/notiflisticon.png" className="w-100" />
                          <span className="notifbadge">
                          </span>
                        </div>
                        {/*-- Domicile Details --*/}
                        <div className="col-9">
                          <small className="fs-5 text-muted">
                            15 Mar 2023 | 14:30
                          </small>
                          <p className="w-100 text-truncate mb-0 text-uppercase">
                            It looks like you left something behind...
                          </p>
                          <p className="text-truncate-by-2 fs-4 text-muted">
                            We saved all of the great assets you've added so when you're
                            ready, simply cover them!
                          </p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                {/*----- Tab 2 -----*/}
                <div className="tab-pane fade" id="Phone_tab">
                  <ul className="vs-profileOption list-unstyled">
                    <li>
                      <a href="#!" className="row justify-content-between align-items-center">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-2 position-relative">
                          <img src="./assets/images/notiflisticon.png" className="w-100" />
                          <span className="notifbadge">
                          </span>
                        </div>
                        {/*-- Domicile Details --*/}
                        <div className="col-9">
                          <small className="fs-5 text-muted">
                            15 Mar 2023 | 14:30
                          </small>
                          <p className="w-100 text-truncate mb-0 text-uppercase">
                            It looks like you left something behind...
                          </p>
                          <p className="text-truncate-by-2 fs-4 text-muted">
                            We saved all of the great assets you've added so when you're
                            ready, simply cover them!
                          </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="row justify-content-between align-items-center">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-2 position-relative">
                          <img src="./assets/images/notiflisticon.png" className="w-100" />
                          <span className="notifbadge">
                          </span>
                        </div>
                        {/*-- Domicile Details --*/}
                        <div className="col-9">
                          <small className="fs-5 text-muted">
                            15 Mar 2023 | 14:30
                          </small>
                          <p className="w-100 text-truncate mb-0 text-uppercase">
                            It looks like you left something behind...
                          </p>
                          <p className="text-truncate-by-2 fs-4 text-muted">
                            We saved all of the great assets you've added so when you're
                            ready, simply cover them!
                          </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="row justify-content-between align-items-center">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-2 position-relative">
                          <img src="./assets/images/notiflisticon.png" className="w-100" />
                          <span className="notifbadge">
                          </span>
                        </div>
                        {/*-- Domicile Details --*/}
                        <div className="col-9">
                          <small className="fs-5 text-muted">
                            15 Mar 2023 | 14:30
                          </small>
                          <p className="w-100 text-truncate mb-0 text-uppercase">
                            It looks like you left something behind...
                          </p>
                          <p className="text-truncate-by-2 fs-4 text-muted">
                            We saved all of the great assets you've added so when you're
                            ready, simply cover them!
                          </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="row justify-content-between align-items-center">
                        {/*-- Locaiton Icon --*/}
                        <div className="col-2 position-relative">
                          <img src="./assets/images/notiflisticon.png" className="w-100" />
                          <span className="notifbadge">
                          </span>
                        </div>
                        {/*-- Domicile Details --*/}
                        <div className="col-9">
                          <small className="fs-5 text-muted">
                            15 Mar 2023 | 14:30
                          </small>
                          <p className="w-100 text-truncate mb-0 text-uppercase">
                            It looks like you left something behind...
                          </p>
                          <p className="text-truncate-by-2 fs-4 text-muted">
                            We saved all of the great assets you've added so when you're
                            ready, simply cover them!
                          </p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            {/*----- Section Content End -----*/}
          </div>
        </div>
      </div>
      {/*----- Main Container For Quote End -----*/}

    </>
  )
}

export default Notification