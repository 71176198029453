export const checkWithinRadius = (addressLatLng, assetLatLng) => {
  const radius = 500; // meters

  // Step 1: Calculate the distance between the address and assetLatLng using the Haversine formula
  const earthRadius = 6371e3; // meters
  const lat1 = addressLatLng.lat * Math.PI / 180;
  const lat2 = assetLatLng.lat * Math.PI / 180;
  const lng1 = addressLatLng.lng * Math.PI / 180;
  const lng2 = assetLatLng.lng * Math.PI / 180;

  const deltaLat = lat2 - lat1;
  const deltaLng = lng2 - lng1;

  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) *
    Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  // Step 2: Check if the distance is within the radius
  const isWithinRadius = distance <= radius;

  return isWithinRadius;
};
