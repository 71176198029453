import React from 'react'
import { Link } from 'react-router-dom'

const ProfileConfirmation = () => {
  return (
    <>
      {/*----- Main Container For Information Submit Start -----*/}
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-submission position-relative">

            {/*----- Section Content Start -----*/}
            <section className="vs-content_section vs-empty px-4 pb-0">
              {/*----- Section Random Intro Start -----*/}
              <div className="Section_random_intro text-center mb-5  pb-5">
                <h3 className="fs-1 mb-5 mx-auto text-white">
                  Your information has <br /> been submitted.
                </h3>

                <p className="text-secondary fs-3 w-75 mx-auto">
                  We will notify you once your <br /> information has been updated.
                </p>
              </div>
            </section>
            {/*----- Section Content End -----*/}
            {/*----- Basic Footer Section Start -----*/}
            <footer className="vs-floting-footer vs-footer-gradient px-4">
              {/*----- Add Domicile Button -----*/}
              <Link to="/my-profile" className="btn btn-primary rounded-pill px-5 fs-3 py-3 align-self-center w-100">
                Done
              </Link>
            </footer>
            {/*----- Basic Footer Section End -----*/}
          </div>
        </div>
      </div>
      {/*----- Main Container For Information Submit End -----*/}
    </>
  )
}

export default ProfileConfirmation