import React, { useEffect, useMemo, useState } from 'react';
import AddImageCanvas from './AddImageCanvas';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetDataWithToken, PutDataWithToken } from '../../ApiHelper/ApiHelper';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../common/Loader';
import { toast } from 'material-react-toastify';
import { useForm } from 'react-hook-form';
import { Offcanvas } from 'react-bootstrap';
import exifr from 'exifr'

const customMap = (func, iterable) => {
  return iterable.slice(1).map((item, index) => func(item, index + 1));
}

const UpdateAsset = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const location = useLocation();
  const navigate = useNavigate();
  const assetId = location?.state?.assetId;

  const [loader, setLoader] = useState(true);
  const [addAssetCanvas, setAddAssetCanvas] = useState(false);
  const addAssetCanvasClose = () => setAddAssetCanvas(false);
  const [assetStatus, setAssetStatus] = useState(false);
  const assetStatusClose = () => setAssetStatus(false);
  const [assetImg, setAssetImg] = useState([]);
  const [callApi, setCallApi] = useState(true);
  const [assetImageId, setAssetImageId] = useState(null);

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeletModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  const [updateAsset, setUpdateAsset] = useState({});
  const [latLng, setlatlLng] = useState({});
  console.log("latLng", latLng.latitude);
  const [selectStatus, setSelectStatus] = useState('Safe');

  useEffect(() => {
    if (navigator.geolocation) {
      // alert("qwertyui");
      navigator.geolocation.getCurrentPosition(showPosition);
      // navigator.geolocation.getCurrentPosition((position) => { console.log("postion", position) }, (error) => { console.log("error", error) })
    } else {
      toast(
        <>
          <div className="activityalert">
            <div className="alert alert-dismissible fade show m-0 px-0 text-center">
              <p className="m-0">
                <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
                Geolocation is not supported by this browser.
              </p>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
              </button>
            </div>
          </div>
        </>
      )
    }

    if (callApi || loader) {
      GetDataWithToken(`asset/get-asset-detail/${assetId}`, '')
        .then((response) => {
          if (response.status == true) {
            setCallApi(false);
            setLoader(false);
            setAssetImg(response.data.asset_files);
            setUpdateAsset(response.data);
            reset({
              make: response?.data.make,
              model: response?.data.model,
              cost: parseInt(response?.data?.cost)?.toLocaleString()?.replaceAll(",", " "),
              imeiNumber1: response?.data.imeiNumber1,
              imeiNumber2: response?.data.imeiNumber2,
              serialNumber: response?.data.serialNumber,
              asset_age: response?.data.asset_age,
            })
          }
        })
    }
  }, [callApi]);

  const handleDeleteImgModal = (id) => {
    // console.log("id", id)
    handleDeleteModalShow();
    setAssetImageId(id);
  };

  // console.log("asset id", assetImageId);

  const handleDeleteImg = () => {
    handleDeletModalClose();
    GetDataWithToken(`asset/delete-asset-image/${assetImageId}`)
      .then((response) => {
        if (response.status == true) {
          setCallApi(true);
          setLoader(true);
          toast(
            <>
              <div className="activityalert">
                <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                  <p className="m-0">
                    <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
                    Asset image has been successfully removed.
                  </p>
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                  </button>
                </div>
              </div>
            </>
          )
        }
      })
  };

  const showPosition = (position) => {
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    // console.log("qwertyuk", longitude, latitude)
    setlatlLng({ latitude, longitude });
  };

  const AddAssets = async (e) => {
    // console.log(latLng);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      toast(
        <>
          <div className="activityalert">
            <div className="alert alert-dismissible fade show m-0 px-0 text-center">
              <p className="m-0">
                <img
                  src="./assets/images/blueradio.png"
                  className="bluecheckimg"
                  alt
                />
                Geolocation is not supported by this browser.
              </p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </>
      );
    }
    setTimeout(() => {
      if (e.target.files && e.target.files.length > 0) {
        const files = e.target.files;

        if (latLng == undefined) {
          toast(
            <>
              <div className="activityalert">
                <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                  <p className="m-0">
                    <img
                      src="./assets/Newimg/redclose.png"
                      className="bluecheckimg"
                      alt
                    />
                    This image does not contain latitude and longitude
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </>
          );
        } else {
          navigate('/update-check-asset',
            {
              state: {
                imgPreview: URL.createObjectURL(e.target.files[0]),
                img: e.target.files[0],
                assetId: assetId,
                assetLatLngAdd: {
                  lat: latLng.latitude,
                  lng: latLng.longitude,
                }
              }
            });
        }
      }
    }, 1000);
    // if (e.target.files && e.target.files.length > 0) {
    //   const files = e.target.files;
    //   const latLng = await exifr.gps(files[0]);
    //   console.log({ latLng });
    //   if (latLng == undefined) {
    //     toast(
    //       <>
    //         <div className="activityalert">
    //           <div className="alert alert-dismissible fade show m-0 px-0 text-center">
    //             <p className="m-0">
    //               <img src="./assets/Newimg/redclose.png" className="bluecheckimg" alt />
    //               This image does not contain latitude and longitude
    //             </p>
    //             <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
    //             </button>
    //           </div>
    //         </div>
    //       </>
    //     );
    //   } else {
    //     navigate('/update-check-asset',
    //       {
    //         state: {
    //           imgPreview: URL.createObjectURL(e.target.files[0]),
    //           img: e.target.files[0],
    //           assetId: assetId,
    //           assetLatLngAdd: {
    //             lat: latLng?.latitude,
    //             lng: latLng?.longitude,
    //           }
    //         }
    //       });
    //   }
    // }
  };

  const incrementIdByOne = (obj, index) => {
    return { ...obj, index };
  }

  const result = customMap(incrementIdByOne, assetImg);

  const handleUpdateAsset = () => {
    toast(
      <>
        <div className="activityalert">
          <div className="alert alert-dismissible fade show m-0 px-0 text-center">
            <p className="m-0">
              <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
              Your asset has been successfully updated.
            </p>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
          </div>
        </div>
      </>
    )
    navigate(-1);
    // let updateAsset = {
    //   assetId: assetId,
    //   status: selectStatus,
    // }
    // PutDataWithToken('asset/update-asset', updateAsset)
    //   .then((response) => {
    //     if (response.status == true) {
    //       toast(
    //         <>
    //           <div className="activityalert">
    //             <div className="alert alert-dismissible fade show m-0 px-0 text-center">
    //               <p className="m-0">
    //                 <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
    //                 Your asset has been successfully updated.
    //               </p>
    //               <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
    //               </button>
    //             </div>
    //           </div>
    //         </>
    //       )
    //       navigate(-1);
    //     }
    //   })
  }

  return (
    <>
      {loader ?
        <Loader />
        :
        <>
          {/*----- Main Container For Confirm Asset Start -----*/}
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-updateassetForm position-relative">
                <div className="header_imgcontainer position-relative" style={{ height: 250 }}>

                  {/*----- Vs Header Start -----*/}
                  <div className="vs-header position-absolute top-0 start-0 end-0">
                    {/*------ Header Mid Heading (Left Part) ------*/}
                    <a href="javascript:void(0)" className="bg-white px-3 py-1 rounded-circle" onClick={() => navigate(-1)}>
                      <svg width={10} viewBox="0 0 20 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.8125 33.625L2.23873 18.0512L17.8125 2.47746" stroke="#000000" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </a>

                    {/*------ Header Mid Heading (Middle Part) ------*/}
                    <h4 className="vs-header-title d-none">Update asset</h4>

                    {/*------ Header Mid Heading (Right Part) ------*/}
                    <a href="#" className="d-block d-none">
                      <svg width="25px" viewBox="0 0 37 37" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M35.6972 15.5118C35.9033 16.5991 36.0103 17.7214 36.0103 18.8674C36.0103 28.7948 27.9628 36.8423 18.0354 36.8423C8.10802 36.8423 0.0605469 28.7948 0.0605469 18.8674C0.0605469 8.94006 8.10802 0.892578 18.0354 0.892578C20.8746 0.892578 23.5594 1.55019 25.947 2.72251L24.0267 5.88521C22.0776 4.92772 20.2634 4.48755 18.0354 4.48755C10.094 4.48755 3.65552 10.9261 3.65552 18.8674C3.65552 26.8088 10.094 33.2473 18.0354 33.2473C25.9768 33.2473 32.4153 26.8088 32.4153 18.8674C32.4153 17.9555 32.3311 17.0577 32.1654 16.1791L35.6972 15.5127V15.5118ZM17.8276 20.9262L13.9029 24.8509L11.8442 22.7921L15.7688 18.8674L11.5408 14.6403L13.5996 12.5815L17.8276 16.8087L22.0548 12.5815L24.1135 14.6403L19.8864 18.8674L23.8102 22.7921L21.7514 24.8509L17.8276 20.9262ZM27.0224 9.38066C27.0224 11.3111 28.5874 12.8761 30.5179 12.8761C32.4483 12.8761 34.0133 11.3111 34.0133 9.38066C34.0133 7.45017 32.4483 5.88521 30.5179 5.88521C28.5874 5.88521 27.0224 7.45017 27.0224 9.38066Z" fill="#000000" />
                      </svg>
                    </a>
                  </div>
                  {/*----- Vs Header end -----*/}

                  <img src={assetImg?.[0]?.file} alt className="w-100 h-100" style={{ objectFit: "cover" }} />
                </div>

                <div className="vs-main-content vs-confirmasset">
                  {/*----- Section Random Intro Start -----*/}
                  <div className="Section_random_intro text-center my-5">
                    <h2>
                      Please upload images of your asset
                    </h2>
                  </div>
                  {/*----- Section Random Intro End -----*/}
                  <form onSubmit={handleSubmit(handleUpdateAsset)}>
                    {/*----- Vodasure Confirm asset container Start -----*/}
                    <section className="vs-content_section first pb-0">
                      {/*----- Add/confirm asset form start -----*/}
                      <div className="Addassetform">
                        {/*----- Add More Image of asset input Start -----*/}
                        <ul className="vs-profileOption pb-0 px-2 list-unstyled rounded-3 d-flex flex-wrap my-2  align-items-center mb-3">
                          <li className="border-0 position-relative py-0 mx-3 alert alert-dismissible fade show my-4">
                            <img src={assetImg?.[0]?.file} alt style={{ width: 55, height: 55 }} />
                          </li>

                          {result && result?.length > 0 && result.filter((type) => type.type == "image")?.map((img, index) => (
                            <li className="border-0 position-relative py-0 mx-3 alert alert-dismissible fade show my-4" key={index}>
                              {/* {console.log("id--------", img.id)} */}
                              <img src={img?.file} alt style={{ width: 55, height: 55 }} />
                              <button type="button" className="position-absolute top-0 end-0 p-0 me-1 btn border-0 bg-transparent" onClick={() => handleDeleteImgModal(img?.id)}>
                                <img src="./assets/images/icon/RemoveIcon.svg" alt className="w-100 h-100" />
                              </button>
                            </li>
                          ))}

                          <li className="border-0 py-0 my-4">
                            {/* <input className=" d-none" type="file" id="idradio" />
                        <label htmlFor="idradio" style={{ cursor: 'pointer' }}>
                          <img src="./assets/images/icon/AddButton.svg" alt />
                        </label> */}
                            {/* <img src="./assets/images/icon/AddButton.svg" className='mx-3 py-0' alt onClick={() => setAddAssetCanvas(true)} /> */}

                            <input className='d-none' type='file' id="UPassEtadd" onChange={(e) => AddAssets(e)} />

                            <label htmlFor='UPassEtadd' style={{ cursor: 'pointer' }}>
                              <img src="./assets/images/icon/AddButton.svg" className='mx-3 py-0' />
                            </label>
                          </li>

                        </ul>
                        {/*----- Add More Image of asset input End -----*/}

                        {/*----- Warning for uploading image -----*/}

                        {/*----- Inputs for uploading asset information -----*/}
                        <ul className="vs-uplodfield  list-unstyled pt-5 pb-0 rounded-4 mb-5">
                          <li className="border-0 pb-0">
                            {/*----- Floating Label input -----*/}
                            <div className="form-floating">
                              {/*--- Input ---*/}
                              <input type="text" className="form-control" id="adf6" placeholder=" "
                                {...register("make")} disabled />
                              {/*--- Label ---*/}
                              <label htmlFor="adf6" className="h-auto text-secondary">
                                Make
                              </label>
                            </div>
                          </li>

                          <li className="border-0 pb-0">
                            <div className="form-floating">
                              {/*--- Input ---*/}
                              <input type="text" className="form-control" id="adf1" placeholder=" "
                                {...register("model")} disabled />
                              {/*--- Label ---*/}
                              <label htmlFor="adf1" className="h-auto text-secondary">
                                Model
                              </label>
                            </div>
                          </li>
                          <li className="border-0 pb-0">
                            {/*----- Floating Label input -----*/}
                            <div className={updateAsset?.imeiNumber1 == null && updateAsset?.serialNumber == null ? "form-floating pb-3" : "form-floating"}>
                              {/*--- Input ---*/}
                              <input type="text" className="form-control inputforrandomR" id="adf2" placeholder="" {...register("cost")} disabled />
                              {/*--- Label ---*/}
                              <label htmlFor="adf2" className="h-auto text-secondary">Asset cost</label>

                              <p class="randomR">R</p>
                            </div>
                          </li>
                          {updateAsset?.imeiNumber1 !== null && (
                            <>
                              <li className="border-0 pb-0">
                                {/*----- Floating Label input -----*/}
                                <div className="form-floating">
                                  {/*--- Input ---*/}
                                  <input type="text" className="form-control" id="adf3" placeholder=" "
                                    {...register("imeiNumber1")} disabled />
                                  {/*--- Label ---*/}
                                  <label htmlFor="adf3" className="h-auto text-secondary">IMEI number 1</label>
                                </div>
                              </li>
                              <li className="border-0 pb-0">
                                {/*----- Floating Label input -----*/}
                                <div className="form-floating pb-3">
                                  {/*--- Input ---*/}
                                  <input type="text" className="form-control" id="datepicker" placeholder=" " {...register("imeiNumber2")} disabled />
                                  {/*--- Label ---*/}
                                  <label htmlFor="datepicker" className="h-auto text-secondary">
                                    IMEI number 2
                                  </label>
                                </div>
                              </li>
                            </>
                          )}

                          {updateAsset?.serialNumber !== null && (
                            <>
                              <li className="border-0 pb-0">
                                {/*----- Floating Label input -----*/}
                                <div className="form-floating pb-3">
                                  {/*--- Input ---*/}
                                  <input type="text" className="form-control" id="adf34" placeholder=" "
                                    {...register("serialNumber")} disabled />
                                  {/*--- Label ---*/}
                                  <label htmlFor="adf34" className="h-auto text-secondary">Serial number</label>
                                </div>
                              </li>
                            </>
                          )}

                          <li className="pb-0">
                            <small className='text-secondary'>Asset status</small>

                            <div className='form-floating pb-0'>
                              <a href="javascript:void(0)" className="d-flex justify-content-between alitn-items-center border-bottom  pb-3" onClick={() => setAssetStatus(true)}>
                                <p className="text-white w-100 text-truncate mb-2">
                                  {selectStatus}
                                </p>

                                <img src="./assets/images/icon/GoIcon.svg" />
                              </a>
                            </div>
                          </li>
                        </ul>
                        {/*----- Inputs for uploading asset information -----*/}
                        <ul className="vs-uploadlist pb-0 list-unstyled rounded-4">
                          <li>
                            <a href="#" className="row justify-content-between align-items-center">
                              {/*-- Locaiton Icon --*/}
                              <div className="col-1">
                                <img src="./assets/images/icon/Invoice.svg" />
                              </div>
                              {/*-- Domicile Details --*/}
                              <div className="col-8">
                                <p className="text-secondary w-100 text-truncate mb-2">
                                  Add an invoice
                                </p>
                              </div>
                              {/*-- Go Icon --*/}
                              <div className="col-2 text-end">
                                <img src="./assets/images/icon/GoIcon.svg" />
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="#" className="row justify-content-between align-items-center">
                              {/*-- Invoice Icon --*/}
                              <div className="col-1">
                                <img src="./assets/images/icon/Invoice.svg" />
                              </div>
                              {/*-- Title --*/}
                              <div className="col-8">
                                <p className="text-secondary  w-100 text-truncate mb-2">
                                  Add a warrranty
                                </p>
                              </div>
                              {/*-- Go Icon --*/}
                              <div className="col-2 text-end">
                                <img src="./assets/images/icon/GoIcon.svg" />
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/*----- Add/confirm asset form End -----*/}
                    </section>
                    {/*----- Vodasure Confirm asset container End -----*/}
                    {/*----- Basic Footer Section Start -----*/}
                    <footer className="vs-floting-footer vs-footer-gradient">
                      <button type='submit' className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-100">
                        Update Asset
                      </button>
                    </footer>
                    {/*----- Basic Footer Section End -----*/}
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*----- Main Container For Confirm Asset End -----*/}
          <AddImageCanvas
            addAssetCanvas={addAssetCanvas}
            addAssetCanvasClose={addAssetCanvasClose}
            assetId={assetId}
          />
          {/*----- Add address modal -----*/}
          <Modal Modal show={deleteModalShow} onHide={handleDeletModalClose} centered className="AddaddressModal" >
            <div className="modal-body text-center py-5 px-4">
              <img src="./assets/Newimg/YellowBulb.png" alt className="bulb" />

              <p className="my-5">
                Are you sure want to
                <br />
                remove this image?
              </p>

              <a href="javascript:void(0)" onClick={() => handleDeleteImg()} className="d-block">
                Yes, continue

                <img src="./assets/Newimg/Arrow-right.png" className="modalIcons" alt />
              </a>

              <a href="javascript:void(0)" onClick={() => { handleDeletModalClose(true) }} className="d-block mt-5">
                No
                <img src="./assets/Newimg/Arrow-right.png" className="modalIcons" alt />
              </a>
            </div>
          </Modal >

          <Offcanvas show={assetStatus} onHide={setAssetStatus} scroll={false} className='p-4' placement='bottom' id="addassetModal">
            <a href="javascript:void(0)" onClick={() => setAssetStatus(false)} className="btn-close ms-auto" />

            {/*----- Offcanvas Body -----*/}
            <div className="offcanvas-body">
              <h2 className="text-center">
                Please select an asset status
              </h2>

              <ul className="vs-getaquote MyassetStatus list-unstyled pb-0 px-0 mt-5">
                <li className="border-0" onClick={() => { setSelectStatus('Safe'); setAssetStatus(false) }}>
                  <label htmlFor="assetstatus2" className="cursor-pointer row justify-content-between align-items-center position-relative">
                    <div className="col-9 mx-auto">
                      <p className="w-100 text-truncate mb-0 text-center">Safe</p>
                    </div>

                    <div className="col-1 text-end">
                      <input type="radio" name="Selectassetstatus" id="assetstatus2" className="form-check-input d-none" checked={selectStatus == "Safe"} />
                      <img src='/assets/Newimg/assetstatusCheck.png' />
                    </div>
                  </label>
                </li>

                <li className="border-0" onClick={() => { setSelectStatus('Lost'); setAssetStatus(false) }}>
                  <label htmlFor="assetstatus3" className="cursor-pointer row justify-content-between align-items-center position-relative">
                    <div className="col-9 mx-auto">
                      <p className="w-100 text-truncate mb-0 text-center">Lost</p>
                    </div>

                    <div className="col-1 text-end">
                      <input type="radio" name="Selectassetstatus" id="assetstatus3" className="form-check-input d-none" checked={selectStatus == "Lost"} />
                      <img src='/assets/Newimg/assetstatusCheck.png' />
                    </div>
                  </label>
                </li>

                <li className="border-0" onClick={() => { setSelectStatus('Stolen'); setAssetStatus(false) }}>
                  <label htmlFor="assetstatus4" className="cursor-pointer row justify-content-between align-items-center position-relative">
                    <div className="col-9 mx-auto">
                      <p className="w-100 text-truncate mb-0 text-center">Stolen</p>
                    </div>

                    <div className="col-1 text-end">
                      <input type="radio" name="Selectassetstatus" id="assetstatus4" className="form-check-input d-none" checked={selectStatus == "Stolen"} />
                      <img src='/assets/Newimg/assetstatusCheck.png' />
                    </div>
                  </label>
                </li>

                <li className="border-0" onClick={() => { setSelectStatus('Damaged'); setAssetStatus(false) }}>
                  <label htmlFor="assetstatus5" className="cursor-pointer row justify-content-between align-items-center position-relative">
                    <div className="col-9 mx-auto">
                      <p className="w-100 text-truncate mb-0 text-center">Damaged</p>
                    </div>

                    <div className="col-1 text-end">
                      <input type="radio" name="Selectassetstatus" id="assetstatus5" className="form-check-input d-none" checked={selectStatus == "Damaged"} />
                      <img src='/assets/Newimg/assetstatusCheck.png' />
                    </div>
                  </label>
                </li>

                <li className="border-0" onClick={() => { setSelectStatus('Recovered'); setAssetStatus(false) }}>
                  <label htmlFor="assetstatus6" className="cursor-pointer row justify-content-between align-items-center position-relative">
                    <div className="col-9 mx-auto">
                      <p className="w-100 text-truncate mb-0 text-center">Recovered</p>
                    </div>

                    <div className="col-1 text-end">
                      <input type="radio" name="Selectassetstatus" id="assetstatus6" className="form-check-input d-none" checked={selectStatus == "Recovered"} />
                      <img src='/assets/Newimg/assetstatusCheck.png' />
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </Offcanvas>
        </>
      }
    </>
  )
}

export default UpdateAsset