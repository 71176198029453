import React from 'react'
import { Link } from 'react-router-dom'

const MyDomicile = () => {
  return (
    <>
      {/*----- Main Container For domicile Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-domicile position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <Link to="/dashboard">
                  <img src="./assets/images/icon/BackIcon.svg" />
                </Link>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">My Domicile</h4>
              </div>

              <div className="col-2 text-end">
                <Link to="/add-domicile" className="d-block">
                  <img src="./assets/images/icon/Add_Button.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-3 w-75 mx-auto text-white">Physical locations</h3>
            <p className="text-dark-gray w-75 mx-auto">My saved addresses</p>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure domicile Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Domicile List Container start -----*/}
            <ul className="vs-domicileList list-unstyled m-0 rounded-3">
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="row justify-content-between align-items-center">
                  {/*-- Locaiton Icon --*/}
                  <div className="col-2">
                    <img src="./assets/images/icon/CheckinIcon.svg" />
                  </div>
                  {/*-- Domicile Details --*/}
                  <div className="col-8">
                    <h3 className="vs-domicileName w-100 text-truncate mb-2">Home</h3>
                    <p className="vs-domicileAddress w-100 text-truncate mb-2">
                      15 Niven Avenue, Douglasdale, New South
                      Wales, Australia
                    </p>
                  </div>
                  {/*-- Go Icon --*/}
                  <div className="col-2 text-end">
                    <img src="./assets/images/icon/GoIcon.svg" />
                  </div>
                </a>
              </li>
            </ul>
            {/*----- Domicile List Container End -----*/}
          </section>
          {/*----- Vodasure domicile Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <Link to="/add-domicile" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-auto">
              Add domicile
              <img src="./assets/images/icon/AddIcon.svg" className="align-middle ms-2" />
            </Link>

            {/*----- Activity Alert -----*/}
            <div className="activityalert">
              <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                <p className="m-0">Your domicile has been successfully removed.</p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
            </div>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div >
      {/*----- Main Container For domicile End -----*/}
    </>
  )
}

export default MyDomicile