import React, { useEffect, useState } from 'react'
import { DeleteDataWithToken, GetDataWithToken } from '../../ApiHelper/ApiHelper';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../common/Loader';
import Cookies from 'js-cookie';
import { Offcanvas } from 'react-bootstrap';

const MyAssets = () => {
  const accessToken = Cookies.get('accessToken');
  const [callApi, setCallApi] = useState(true);
  const [myAssets, setMyAssets] = useState([]);
  const [assetsPrice, setAssetsPrice] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedCatalogIds, setSelectedCatalogIds] = useState([]);
  const [allPrice, setAllPrice] = useState(0);

  const [loader, setLoader] = useState(true);
  const [checkStatus, setCheckStatus] = useState(false);
  const navigate = useNavigate();
  const [viewPolicy, setViewPolicy] = useState(false);
  const [quotationCanvas, setQuotationCanvas] = useState(false);
  const quotationCanvasClose = () => setQuotationCanvas(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (callApi || loader) {
      GetDataWithToken('asset/get-asset-list', '')
        .then((response) => {
          if (response.status == true) {
            setMyAssets(response.data);
            setCallApi(false);
            setLoader(false);
            const status = response.data.map(item => item.status);
            // console.log("status", status);
            const hasUninsured = status.includes('uninsured');
            // console.log("hasUninsured", hasUninsured);
            setCheckStatus(hasUninsured);
            if (response.data.length > 0) {
              let arr = [];
              let i = 0;
              let total_price = 0;

              while (i < response.data.length) {
                if (response?.data?.[i]?.status == "uninsured") {
                  arr.push({
                    price: response.data?.[i].cost,
                    id: response.data?.[i].id,
                    catId: response.data?.[i].catalog_id,
                  });
                  let cost = parseFloat(response.data?.[i].cost);
                  total_price += cost;
                }
                i++;
              }
              // setCsvData(arr);
              setAssetsPrice(arr);
              // console.log("arr", arr);
              const idsArray = arr.filter(item => item.catId > 0).map(item => item.id);
              setSelectedIds(idsArray);
              const catalogIdsArray = arr.filter(item => item.catId > 0).map(item => item.catId);
              console.log("catalogIdsArray", catalogIdsArray);
              setSelectedCatalogIds(catalogIdsArray);
            }
          }
        })
    }
  }, [callApi]);

  const handleDeleteAsset = (id) => {
    DeleteDataWithToken(`asset/delete-asset/${id}`)
      .then((response) => {
        response = JSON.parse(response.data);
        console.log("ressss--------------", response)
        if (response.status == 200) {
          setCallApi(true);
          setLoader(true);
        }
      })
  };

  const goToAssetDetail = (id) => {
    navigate("/update-asset", {
      state: {
        assetId: id,
      }
    })
  }

  // const [myArray, setMyArray] = useState([]);

  // const handleDeleteAsset = (id) => {
  //   //  const  selectedVal = e?.target?.value;
  //   console.log("id", id)
  //   const newValue = JSON.parse(id);
  //   setMyArray(prevArray => [...prevArray, newValue]);
  //   // const updatedArray = [...myArray];
  //   // console.log("updatedArray", updatedArray);
  // }
  // const sum = myArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  // console.log("myArray", myArray);
  // console.log("sum", sum);
  console.log("selectedIds", selectedIds);
  console.log("selectedCatalogIds", selectedCatalogIds);

  const handleSelectAssets = (price, id, catalogId) => {
    const idIndex = selectedIds.indexOf(id);
    const catalogIdIndex = selectedCatalogIds.indexOf(catalogId);
    if (idIndex !== -1) {
      selectedIds.splice(idIndex, 1);
    } else {
      selectedIds.push(id);
    }
    if (catalogIdIndex !== -1) {
      selectedCatalogIds.splice(catalogIdIndex, 1);
    } else {
      selectedCatalogIds.push(catalogId);
    }

    if (assetsPrice?.length > 0) {
      if (assetsPrice?.find(e => e.id == id)) {
        let index = assetsPrice?.findIndex((e) => e.id == id);
        let total_price = parseFloat(allPrice) - parseFloat(assetsPrice?.[index]?.price);
        // console.log("jhgfghjjjjjjjjjjjj", parseFloat(total_price));
        assetsPrice.splice(index, 1);
        setAllPrice(parseFloat(total_price));
      } else {
        setAssetsPrice([...assetsPrice, {
          price: price,
          id: id
        }]);
        // console.log("idsssssss", id)
        let total_price = parseFloat(allPrice) + parseFloat(price);
        setAllPrice(total_price);
      }
    } else {
      setAssetsPrice([...assetsPrice, {
        price: price,
        id: id
      }]);
      // console.log("id", id)
      let total_price = parseFloat(allPrice) + parseFloat(price);
      setAllPrice(total_price);
    }
  }

  return (
    <>
      {loader ?
        <Loader />
        :
        <>
          {/*----- Main Container For Quote Start -----*/}
          {/*  */}
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-Myassetlist position-relative">
                {/*----- Vs Header Start -----*/}
                <div className="vs-header Withbtngroup">
                  {/*------ Header Mid Heading (Left Part) ------*/}
                  <Link to={`/${accessToken}`}><img src="./assets/images/icon/BackIcon.svg" /></Link>


                  {/*------ Header Mid Heading (Middle Part) ------*/}
                  <h4 className="vs-header-title d-none">Add asset</h4>


                  {/*------ Header Mid Heading (Right Part) ------*/}
                  <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                    <a href="#" className="btn border-0 me-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                    </a>

                    <a href="#" className="btn border-0 ms-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                    </a>
                  </div>
                </div>
                {/*----- Vs Header end -----*/}

                {/*----- Section Random Intro Start (For Fourth Onboarding) -----*/}
                <h2 className="text-center my-4">
                  My assets
                </h2>

                {/*----- Quote Tab Content Navigation -----*/}
                <nav className="nav nav-tabs">
                  {/*----- Navigation button 1 -----*/}
                  <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#All_tab" type="button" onClick={() => setViewPolicy(false)}>
                    All
                  </button>
                  {/*----- Navigation button 2 -----*/}
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Phone_tab" type="button" onClick={() => setViewPolicy(true)}>
                    Insured
                  </button>
                  {/*----- Navigation button 3 -----*/}
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Tablet_tab" type="button" onClick={() => setViewPolicy(false)}>
                    Uninsured
                  </button>
                </nav>

                {/*----- Section Content Start -----*/}
                <section className="vs-content_section">
                  {/*----- Tab Content -----*/}
                  <div className="tab-content" id="nav-tabContent">
                    {/*----- Tab 1 -----*/}
                    <div className="tab-pane fade show active" id="All_tab">
                      {myAssets && myAssets?.length > 0 && myAssets?.map((assets, index) => (
                        <>
                          {/*----- Info Card  -----*/}
                          <div className="card vs-InfoCardStatus fornewasset" key={index}>
                            {/*---- status Badge -----*/}
                            <span className={assets?.status == "uninsured" ? "badge uninsured" : "w-auto badge insured"}>{assets?.status == "safe and insured" ? "Insured" : "Uninsured"}</span>
                            {/*----- Info Card Body -----*/}
                            <div className="card-body pt-4">
                              <div className="row mx-0 mt-4 align-items-center">
                                {/*-- Locaiton Icon --*/}
                                <div className="col-2">
                                  <img src="./assets/images/labelicon.png" />
                                </div>
                                {/*-- Domicile Details --*/}
                                <div className="col-8" onClick={() => goToAssetDetail(assets?.id)} >
                                  {/*----- Info title -----*/}
                                  <h4 className="vs-card-title text-white mb-2">
                                    {assets?.model}
                                  </h4>
                                  {/* <p className="fs-4">Serial number : {assets?.imeiNumber1}</p> */}
                                  <p className="fs-4">Price : R {parseInt(assets?.cost)?.toLocaleString()?.replaceAll(",", " ")}</p>
                                </div>
                                {/*-- Go Icon --*/}
                                <div className="col-2 text-end">
                                  <img style={{ cursor: "pointer" }} src="./assets/images/Close Small.png" onClick={() => handleDeleteAsset(assets?.id)} />
                                </div>
                                {/* for onchange method */}
                                {/* <div className="col-2 text-end">
                                  <img src="./assets/images/Close Small.png" onClick={() => handleDeleteAsset(assets?.cost)} />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                    {/*----- Tab 2 -----*/}
                    <div className="tab-pane fade" id="Phone_tab">
                      {myAssets && myAssets?.length > 0 && myAssets?.filter(type => type.status == "safe and insured")?.map((assets, index) => (
                        <>
                          {/*----- Info Card  -----*/}
                          <div className="card vs-InfoCardStatus fornewasset" key={index}>
                            {/*---- status Badge -----*/}
                            <span className="badge insured">Insured</span>
                            {/*----- Info Card Body -----*/}
                            <div className="card-body pt-5">
                              <div className="row mx-0 mt-4">
                                {/*-- Locaiton Icon --*/}
                                <div className="col-2">
                                  <img src="./assets/images/labelicon.png" />
                                </div>
                                {/*-- Domicile Details --*/}
                                <div className="col-8" onClick={() => goToAssetDetail(assets?.id)} >
                                  {/*----- Info title -----*/}
                                  <h4 className="vs-card-title text-white mb-2">
                                    {assets?.model}
                                  </h4>
                                  <p className="fs-4">Price : R {parseInt(assets?.cost)?.toLocaleString()?.replaceAll(",", " ")}</p>
                                </div>
                                {/*-- Go Icon --*/}
                                <div className="col-2 text-end">
                                  <img style={{ cursor: "pointer" }} src="./assets/images/Close Small.png" onClick={() => handleDeleteAsset(assets?.id)} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                    {/*----- Tab 3 -----*/}
                    <div className="tab-pane fade" id="Tablet_tab">
                      {myAssets && myAssets?.length > 0 && myAssets?.filter(type => type.status == "uninsured")?.map((assets, index) => (
                        <>
                          {/*----- Info Card  -----*/}
                          <div className="card vs-InfoCardStatus fornewasset" key={index}>
                            {/*---- status Badge -----*/}
                            <span className="badge uninsured">Uninsured</span>
                            {/*----- Info Card Body -----*/}
                            <div className="card-body pt-4">
                              <div className="row mx-0 mt-4 align-items-center">
                                {/*-- Locaiton Icon --*/}
                                <div className="col-2">
                                  <img src="./assets/images/labelicon.png" />
                                </div>
                                {/*-- Domicile Details --*/}
                                <div className="col-8" onClick={() => goToAssetDetail(assets?.id)} >
                                  {/*----- Info title -----*/}
                                  <h4 className="vs-card-title text-white mb-2">
                                    {assets?.model}
                                  </h4>
                                  <p className="fs-4">Price : R {parseInt(assets?.cost)?.toLocaleString()?.replaceAll(",", " ")}</p>
                                </div>
                                {/*-- Go Icon --*/}
                                <div className="col-2 text-end">
                                  <img style={{ cursor: "pointer" }} src="./assets/images/Close Small.png" onClick={() => handleDeleteAsset(assets?.id)} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </section>
                {/*----- Section Content End -----*/}
                {/*----- Basic Footer Section Start -----*/}
                <footer className="vs-floting-footer vs-footer-gradient">
                  {viewPolicy ?
                    <button
                      className={"btn btn-primary rounded-pill px-5 py-3 w-100"}
                    // className='btn btn-primary rounded-pill px-5 fs-3 py-3 w-100'
                    >
                      View Policy
                    </button>
                    :
                    <button onClick={() => setQuotationCanvas(true)}
                      className={checkStatus == false ? "btn btn-outline-primary rounded-pill px-5  py-3 w-100 disabled" : "btn btn-primary rounded-pill px-5 py-3 w-100 "}
                    // className='btn btn-primary rounded-pill px-5 fs-3 py-3 w-100'
                    >
                      Get my insurance quote
                    </button>
                  }
                </footer>
                {/*----- Basic Footer Section End -----*/}
              </div>
            </div>
          </div>

          <Modal show={show} onHide={handleClose} centered>
            <div className="modal-body text-center p-5">
              <img src="./assets/images/icon/Yellow_Bulb.svg" alt className="my-4" />
              <p className="fs-3 mb-1">
                Easily get the right cover at the best price for your assets
              </p>

              <p className="fs-4 my-5 fw-normal">
                By answering a few simple questions, we’ll be able to offer you the right cover at an affordable
                price.
              </p>

              <Link to="/under-writing" state={{ selectedIds: selectedIds, selectedCatalogIds: selectedCatalogIds }} className="d-block">
                Let's do this!
                <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt />
              </Link>
            </div>
          </Modal>
          <Offcanvas show={quotationCanvas} onHide={quotationCanvasClose} scroll={false} placement='bottom' id="viewquote" className='suddenoffcanvas p-4 show offcanvas offcanvas-bottom'>
            <div className="offcanvas-header px-4">


              <button type="button" className="btn-close" style={{ position: "absolute", right: "20px" }} data-bs-dismiss="offcanvas" aria-label="Close" onClick={quotationCanvasClose} />
            </div>
            <div className="offcanvas-body px-3">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="Contents" tabIndex={0}>
                  <div className="Section_random_intro text-center mb-5">
                    <h3 className="text-gray mb-3 text-center" style={{ fontSize: "30px" }}>
                      Get a quote
                    </h3>
                    <p className="mt-2 text-center" style={{ fontSize: "17px" }}>Please select the assets that</p>
                    <p className="mt-2 text-center" style={{ fontSize: "17px" }}>would you like to quote for?</p>
                  </div>
                  <ul className="vs-getaquote list-unstyled accordion" id="QuoteAccordion">
                    <li className="accordion-item">
                      <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#myassetstocover">
                        <div className="col-2">
                          <img src="./assets/images/assetquotemyasset.svg" />
                        </div>
                        <div className="col-9">
                          <p className="mb-0">
                            My assets
                          </p>
                        </div>
                      </button>

                      <div id="myassetstocover" className="accordion-collapse collapse show" data-bs-parent="#QuoteAccordion">
                        <div className="accordion-body">
                          {myAssets && myAssets?.length > 0 && myAssets?.filter(type => type.status == "uninsured" && type.catalog_id > 0)?.map((assets, index) => (
                            <div className="assetinqotemodal mt-3 mb-0 alert alert-dismissible fade show row" key={index}>
                              <div className="col-3">
                                <img src={assets?.asset_image} alt />
                              </div>
                              <div className="col-8">
                                {assets?.policy_type == "Accidental Damage" &&
                                  <p className='damageBadge badge bg-secondary px-4 py-2'>
                                    Accidental Damage
                                    <i class="fa-solid fa-angle-down ms-2"></i>
                                  </p>
                                }
                                <p className="mb-0 text-muted fs-4">{assets?.model}</p>
                                <small>R {parseInt(assets?.cost)?.toLocaleString()?.replaceAll(",", " ")}</small>
                              </div>
                              <div className="col-1 align-self-center">
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="passport"

                                    checked=
                                    {assetsPrice && assetsPrice?.find(e => e.id == assets.id) ? true : false} name="proff"
                                    onChange={() => handleSelectAssets(assets?.cost, assets?.id, assets.catalog_id)}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                    <li className="accordion-item">
                      <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#Whatwecover">
                        <div className="col-2">
                          <img src="./assets/images/icon/WhatWeCover.svg" />
                        </div>
                        <div className="col-9">
                          <p className="mb-0">
                            What we cover
                          </p>
                        </div>
                      </button>
                      <div id="Whatwecover" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                        <div className="accordion-body">
                          <ul>
                            <li className="position-relative pt-5 mt-3">
                              Loss We'll replace it.
                            </li>
                            <li>
                              Theft: We’ll replace it.
                            </li>
                            <li>
                              Cracked screen: We’ll repair it.
                            </li>
                            <li>
                              Camera damage: We’ll repair it.
                            </li>
                            <li>
                              Touchscreen damage: We’ll repair it.
                            </li>
                            <li>
                              Water damage: We'll repair or replace it.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="accordion-item">
                      <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#claimstep">
                        <div className="col-2">
                          <img src="./assets/images/icon/EasyClaimStep.svg" />
                        </div>
                        <div className="col-9">
                          <p className="mb-0">
                            Claim in three easy steps
                          </p>
                        </div>
                      </button>
                      <div id="claimstep" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                        <div className="accordion-body">
                          <ol>
                            <li className="position-relative pt-5 mt-3">
                              <p className="mb-2">Blacklist your device</p>
                              <small className="fs-4 text-secondary">If your device is lost or stolen dial 082
                                135.</small>
                            </li>
                            <li>
                              <p className="mb-2">Log your claim</p>
                              <small className="fs-4 text-secondary">
                                To claim – Call us at 082 1952, email us at
                                servicing.deviceinsurance@vodacom.co.za,
                                or visit your nearest store.
                              </small>
                            </li>
                            <li>
                              <p className="mb-2">Collect your device</p>
                              <small className="fs-4 text-secondary">
                                We process all valid claims within 48 hours.
                              </small>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </li>
                    <li className="accordion-item">
                      <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#faq">
                        <div className="col-2">
                          <img src="./assets/images/icon/FAqOffcanvac.svg" />
                        </div>
                        <div className="col-9">
                          <p className="mb-0">
                            Frequently asked questions
                          </p>
                        </div>
                      </button>
                      <div id="faq" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                        <div className="accordion-body">
                          <ul>
                            <li className="position-relative pt-5 mt-3">
                              <p className="mb-2">When will my cover start?</p>
                              <small className="text-secondary">
                                Your cover starts immediately. If you choose to add insurance when
                                upgrading or buying a new device instore, you will be
                                covered as soon as your purchase is complete.
                              </small>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <footer className="vs-floting-footer">
                    <div className="d-flex btn-width mx-auto justify-content-between align-items-center">
                      <a href="javascript:void(0)" onClick={() => quotationCanvasClose(true)} className="btn btn-outline-secondary rounded-pill fs-3 py-3 w-50 me-3">
                        Cancel
                      </a>
                      <a href='javascript:void(0)' onClick={() => { quotationCanvasClose(true); handleShow(true); }} className={selectedIds?.length > 0 ? "btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3" : "btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3 disabled"} >
                        Continue
                      </a>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </Offcanvas>
          {/* <QuotationCnavas allPrice={allPrice} quotationCanvasClose={quotationCanvasClose} quotationCanvas={quotationCanvas} myAssets={myAssets} assetsPrice={assetsPrice} handleSelectAssets={handleSelectAssets} selectedIds={selectedIds} /> */}
        </>
      }
    </>
  )
}

export default MyAssets