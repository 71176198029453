import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Link, useNavigate } from 'react-router-dom';
import { GetDataWithToken, PostDataWithToken, PutDataWithToken } from '../../ApiHelper/ApiHelper';
import { useForm } from 'react-hook-form';
import Loader from '../../common/Loader';

const MyDetails = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [callApi, setCallApi] = useState(true);
  const [loader, setLoader] = useState(true);
  const [docType, setDocType] = useState('');
  const [type, setType] = useState('');
  const [myDetails, setMyDetails] = useState({});

  const handleFocus = (id, inputId, isFocus) => {
    const inputElement = document.getElementById(id);
    const divElement = document.getElementById(inputId);
    if (inputElement) {
      if (isFocus) {
        setTimeout(() => {
          inputElement.classList.add("focuskeyboard");
          divElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
      } else {
        inputElement.classList.remove("focuskeyboard");
      }
    }
  };

  useEffect(() => {
    if (callApi || loader) {
      GetDataWithToken('user/get-personal-details', '')
        .then((response) => {
          if (response.status == true) {
            setTimeout(() => {
              reset({
                first_name: response.data.first_name,
                mobile: response.data.mobile,
                dob: response.data.dob,
                gender: response.data.gender,
                initial: response.data.initial,
                email: response.data.email,
                customerId: response?.data?.customerId,
                passport: response?.data?.passport,
              });
            }, 500);
            setMyDetails(response.data);
            setTimeout(() => {
              setType(response?.data?.type);
              setDocType(response?.data?.doc_type);
            }, 500);
            setCallApi(false);
            setLoader(false);
          }
        })
    }
  }, [callApi]);

  const navigate = useNavigate();

  $(function () {
    $("#datepicker").datepicker();
  });

  const handleDocType = (type) => {
    setDocType(type);
    if (type == "customerId") {
      reset({
        customerId: myDetails?.customerId
      })
      setTimeout(() => {
        handleFocus('keyboardFocus', 'idradio', true);
      }, 0);
    } else {
      reset({
        passport: myDetails.passport
      })
      setTimeout(() => {
        handleFocus('keyboardFocus', 'passportNo', true);
      }, 0);
    }
  };

  const policyType = (type) => {
    setType(type);
    if (type == "email") {
      reset({
        email: myDetails?.email
      })
      setTimeout(() => {
        handleFocus('keyboardFocus', 'emailradio', true);
      }, 0);
    } else {
      reset({
        mobile: myDetails.mobile
      })
      setTimeout(() => {
        handleFocus('keyboardFocus', 'smsradio', true);
      }, 0);
    }
  }

  const handleUpdateProfile = (data) => {
    console.log("data", data);
    let updateProfile = {
      first_name: data.first_name,
      mobile: data.mobile,
      dob: data.dob,
      gender: data.gender,
      initial: data.initial,
      email: data.email,
      customerId: data.customerId,
      passport: data.passport,
      type: type,
      doc_type: docType,
    }
    console.log("updateProfile", updateProfile);
    PutDataWithToken('user/edit-profile', updateProfile)
      .then((response) => {
        if (response.status == true) {
          navigate('/profile-confirmation');
        }
      })
  };

  useEffect(() => {
    adjustContainer(); // Call the adjustContainer function on component mount and resize
    window.addEventListener('resize', adjustContainer); // Add event listener for resize
    return () => {
      window.removeEventListener('resize', adjustContainer); // Clean up the event listener on component unmount
    };
  }, []);

  function adjustContainer() {
    var footer = document.querySelector('.vs-footer') ? document.querySelector('.vs-footer').offsetHeight + 20 : document.querySelector('.vs-floting-footer') ? document.querySelector('.vs-floting-footer').offsetHeight : 0;
    if (footer > 0) {
      var header = document.querySelector('.vs-header').offsetHeight + 0;
      var title = document.querySelector('.vs-main-contentContainer .static-title-top') ? document.querySelector('.vs-main-contentContainer .static-title-top').offsetHeight : 0;
      var body = window.innerHeight;
      var container = body - (header + footer + title);
      document.querySelector('.vs-content_section').style.maxHeight = container + 'px';
    }
  }

  return (
    <>
      {loader ?
        <Loader />
        :
        <>
          {/*----- Main Container For Profile Start -----*/}
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-mydetailsAll position-relative">
                {/*----- Vs Header Start -----*/}
                <div className="vs-header Withbtngroup">
                  {/*------ Header Mid Heading (Left Part) ------*/}
                  <a href="javascript:void(0)" className='headericon' onClick={() => navigate(-1)} >
                    <img src="./assets/images/icon/BackIcon.svg" />
                  </a>

                  {/*------ Header Mid Heading (Middle Part) ------*/}
                  <h4 className="vs-header-title d-none">My details</h4>


                  {/*------ Header Mid Heading (Right Part) ------*/}
                  <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                    <a href="#" className="btn border-0 me-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                    </a>

                    <a href="#" className="btn border-0 ms-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                    </a>
                  </div>
                </div>
                {/*----- Vs Header end -----*/}

                {/*----- Section Random Intro Start -----*/}
                <h2 className="mb-5 text-center">Please update your details</h2>
                {/*----- Section Random Intro End -----*/}
                <form onSubmit={handleSubmit(handleUpdateProfile)}>
                  {/*----- Vodasure Profile Content container Start -----*/}
                  <section className="vs-content_section" id='keyboardFocus'>
                    {/*----- Add Domicile Form Start -----*/}
                    <div className="editprofileform">
                      <form action="#">
                        <ul className="vs-profileOption pb-0  list-unstyled pt-4 rounded-4">
                          <li className="border-0 pb-0">
                            <div className="form-floating">
                              {/*--- Input ---*/}
                              <input type="text" className="form-control" id="adf1" placeholder="Full name" {...register("first_name")} />

                              {/*--- Label ---*/}
                              <label htmlFor="adf1" className="h-auto text-dark-gray">Full name</label>
                              {/*--- Error Message ---*/}
                              {/* <div className="m-0 f-error ">This input is invalid</div> */}
                            </div>
                          </li>
                          <li className="border-0 p-0">
                            {/*----- Floating Label input -----*/}
                            <div className="form-floating">
                              {/*--- Input ---*/}
                              <input type="number" className="form-control" id="adf1" placeholder="Mobile No."
                                {...register("mobile")} />
                              {/*--- Label ---*/}
                              <label htmlFor="adf1" className="h-auto text-dark-gray">Cellphone </label>
                              {/*--- Error Message ---*/}
                              {/* <div className="m-0 f-error ">This input is invalid</div> */}
                            </div>
                          </li>
                          <li className="border-0 py-0">
                            {/*----- Floating Label input -----*/}
                            <div className="form-floating">
                              <select class="form-select py-0" {...register("gender")}>
                                <option className='d-none selected'>Select Name Prefix</option>
                                <option value={"Mr."}>Mr.</option>
                                <option value={"Mrs."}>Mrs.</option>
                                <option value={"Miss."}>Miss.</option>
                              </select>

                              <label htmlFor="adf3" className="h-auto text-dark-gray">Name Prefix</label>

                            </div>
                          </li>

                          <li className="border-0 py-0">
                            {/*----- Floating Label input -----*/}
                            <div className="form-floating">
                              {/*--- Input ---*/}
                              <input type="text" className="form-control" id="adf3" placeholder="Initials"  {...register("initial")} />
                              {/*--- Label ---*/}
                              <label htmlFor="adf3" className="h-auto text-dark-gray">Initials</label>
                              {/*--- Error Message ---*/}
                              {/* <div className="m-0 f-error ">This input is invalid</div> */}
                            </div>
                          </li>
                          <li className="border-0 py-0">
                            <div className="form-floating pb-0">
                              <input type="date" className="form-control" id="adf77" placeholder="Date of Birth" name="order_date" {...register("dob")} />
                              <label htmlFor="adf77" className="h-auto text-secondary">Date of Birth</label>
                            </div>
                          </li>
                        </ul>
                        <div className="ms-4 mb-4 mt-5 mydetailsPassID">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating pb-0">
                            <div className="form-check form-check-inline ms-2 ps-0">
                              {/*--- Input ---*/}
                              <input className="form-check-input float-end ms-4" type="radio" id="idradio" name="proff" onChange={() => handleDocType("customerId")}
                                checked={docType == "customerId"} />

                              {/*--- Label ---*/}
                              <label className="form-check-label h-auto text-dark-gray" htmlFor="idradio">
                                ID number
                              </label>
                            </div>
                            {/*--- Input ---*/}
                            <div className="form-check form-check-inline ps-3">
                              <input className="form-check-input float-end ms-4" type="radio" id="passportNo" name="proff" onChange={() => handleDocType("passport")}
                                checked={docType == "passport"} />
                              {/*--- Label ---*/}
                              <label className="form-check-label h-auto text-dark-gray" htmlFor="passportNo">
                                Passport number
                              </label>
                            </div>
                            {/*--- Error Message ---*/}
                            {/* <div className="ms-2 f-error position-static">This input is invalid</div> */}
                          </div>
                        </div>
                        {console.log("docType", docType)}
                        {docType == "customerId" ?
                          <>
                            <ul className="vs-profileOption pb-0  list-unstyled pt-5 rounded-4">
                              <li className="border-0 py-0">
                                {/*----- Floating Label input -----*/}
                                <div className="form-floating pb-0">
                                  {/*--- Input ---*/}
                                  <input type="text" className="form-control" id="adf6" placeholder="" {...register("customerId")}
                                    onFocus={() => handleFocus("keyboardFocus", "adf6", true)}
                                    onBlur={() => handleFocus("keyboardFocus", "adf6", false)}
                                  />
                                  {/* {alert(isKeyboardOpen ? 'Keyboard is open' : 'Keyboard is closed')} */}
                                  {/*--- Label ---*/}
                                  <label htmlFor="adf6" className="h-auto text-dark-gray">ID number</label>
                                </div>
                              </li>
                            </ul>
                            {/*--- Error Message ---*/}
                            {/* <div className="ms-4 f-error position-static">This input is invalid</div> */}
                          </>
                          :
                          <>
                            <ul className="vs-profileOption pb-0  list-unstyled pt-5 rounded-4">
                              <li className="border-0 py-0">
                                {/*----- Floating Label input -----*/}
                                <div className="form-floating pb-0">
                                  {/*--- Input ---*/}
                                  <input type="text" className="form-control" id="adf60" placeholder="" {...register("passport")}
                                    onFocus={() => handleFocus("keyboardFocus", "adf60", true)}
                                    onBlur={() => handleFocus("keyboardFocus", "adf60", false)}
                                  />
                                  {/*--- Label ---*/}
                                  <label htmlFor="adf60" className="h-auto text-dark-gray">Passport number</label>
                                </div>
                              </li>
                            </ul>
                            {/*--- Error Message ---*/}
                            {/* <div className="ms-4 f-error position-static">This input is invalid</div> */}
                          </>}
                        <p className='text-secondary d-block ps-4 my-4'>Where can we send your policy documents?</p>
                        <div className="ms-4 my-4 personlainuts ">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating pb-0">
                            <div className="form-check form-check-inline ms-2 ps-0">
                              {/*--- Input ---*/}
                              <input className="form-check-input float-end ms-4" type="radio" id="emailradio" name="way" onChange={() => policyType("email")}
                                checked={type == "email"} />
                              {/*--- Label ---*/}
                              <label className="form-check-label h-auto text-dark-gray" htmlFor="emailradio">
                                Email
                              </label>
                            </div>
                            {/*--- Input ---*/}
                            <div className="form-check form-check-inline ps-3">
                              <input className="form-check-input float-end ms-4" type="radio" id="smsradio" name="way" value="sms"
                                onChange={() => policyType("sms")}
                                checked={type == "sms"} />
                              {/*--- Label ---*/}
                              <label className="form-check-label h-auto text-dark-gray" htmlFor="smsradio">
                                SMS
                              </label>
                            </div>
                            {/*--- Error Message ---*/}
                            {/* <div className="ms-2 f-error position-static">This input is invalid</div> */}
                          </div>
                        </div>
                        {type === "sms" ?
                          <ul className="vs-profileOption pb-0  list-unstyled pt-5 rounded-4">
                            <li className="border-0 py-0">
                              {/*----- Floating Label input -----*/}
                              <div className="form-floating pb-0">
                                {/*--- Input ---*/}
                                <input type="text" className="form-control" id="adf2" placeholder="Description" {...register("mobile")}
                                  onFocus={() => handleFocus("keyboardFocus", "adf2", true)}
                                  onBlur={() => handleFocus("keyboardFocus", "adf2", true)}
                                />
                                {/*--- Label ---*/}
                                <label htmlFor="adf2" className="h-auto text-dark-gray">SMS</label>
                              </div>
                            </li>
                          </ul>
                          :
                          <>
                            <ul className="vs-profileOption pb-0  list-unstyled pt-5 rounded-4">
                              <li className="border-0 py-0">
                                {/*----- Floating Label input -----*/}
                                <div className="form-floating pb-0">
                                  {/*--- Input ---*/}
                                  <input type="text" className="form-control" id="adf21" placeholder="Description" {...register("email")}
                                    onBlur={() => handleFocus("keyboardFocus", "adf21", false)}
                                    onFocus={() => handleFocus("keyboardFocus", "adf21", true)}
                                  />
                                  {/*--- Label ---*/}
                                  <label htmlFor="adf21" className="h-auto text-dark-gray">Email address</label>
                                </div>
                              </li>
                            </ul>
                          </>
                        }

                        <small className='mb-0 text-secondary text-end d-block'>We'll keep in touch without spamming you.</small>
                        {/*--- Error Message ---*/}
                      </form>
                    </div>
                    {/*----- Add Domicile Form End -----*/}
                  </section>
                  {/*----- Vodasure Profile Content container End -----*/}


                  <footer className="vs-floting-footer vs-footer-gradient">
                    <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-secondary rounded-pill px-5 py-3">
                      Cancel
                    </a>
                    {/* <Link to="/profile-confirmation" className="btn btn-primary rounded-pill px-5 py-3">
                      Confirm
                    </Link> */}
                    <button type='submit' className="btn btn-primary rounded-pill px-5 py-3">
                      Confirm
                    </button>
                  </footer>
                  {/*----- Basic Footer Section End -----*/}
                </form>
              </div>
            </div>
          </div>
          {/*----- Main Container For Profile End -----*/}
        </>
      }
    </>
  )
}

export default MyDetails