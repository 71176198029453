import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import exifr from "exifr";
import { toast } from "material-react-toastify";
import {
  GetDataWithToken,
  PostDataWithToken,
  PostImageDataWithToken,
} from "../../ApiHelper/ApiHelper";
import { checkWithinRadius } from "../../Utility/CalculateRadius";

const UpdateCheckAsset = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const assetId = location?.state?.assetId;
  // console.log("assetId", assetId);
  const [retakeAgain, setRetakeAgain] = useState(false);
  const [reTakeImagePreview, setReTakeImagePreview] = useState(null);
  const [reTake, setReTake] = useState(false);
  const [isWithinRadius, setIsWithinRadius] = useState(false);
  const [reTakeImage, setReTakeImage] = useState(null);
  const [assetLatLng, setAssetLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [addressLatLng, setAddressLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [callApi, setCallApi] = useState(true);
  const imgPreviewURL = location?.state?.imgPreview;
  const img = location?.state?.img;
  // console.log("imgeee-------", img)
  const assetLatLngAdd = location?.state?.assetLatLngAdd;
  console.log("assetLatLngAdd", assetLatLngAdd);
  const [base64Image, setBase64Image] = useState('');
  const [latLng, setlatlLng] = useState({});

  const showPosition = (position) => {
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    // console.log("qwertyuk", longitude, latitude)
    setlatlLng({ latitude, longitude });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      // alert("qwertyui");
      navigator.geolocation.getCurrentPosition(showPosition);
      // navigator.geolocation.getCurrentPosition((position) => { console.log("postion", position) }, (error) => { console.log("error", error) })
    } else {
      toast(
        <>
          <div className="activityalert">
            <div className="alert alert-dismissible fade show m-0 px-0 text-center">
              <p className="m-0">
                <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
                Geolocation is not supported by this browser.
              </p>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
              </button>
            </div>
          </div>
        </>
      )
    }

    if (callApi) {
      GetDataWithToken("user/get-physical-address", "").then((response) => {
        if (response.status == true) {
          setCallApi(false);
          setAddressLatLng({
            lat: response?.data?.lat,
            lng: response?.data?.lng,
          });
        }
      });
    }
    let reader = new FileReader();

    reader.onloadend = function () {
      let base64data = reader.result;
      let arr = base64data.split(/[,]+/);
      console.log("arr", arr);
      setBase64Image(arr[1]);
    };
    if (reTake == true) {
      reader.readAsDataURL(reTakeImage);
    } else {
      reader.readAsDataURL(img);
    }

    if (reTake == true) {
      const result = checkWithinRadius(addressLatLng, assetLatLng);
      setIsWithinRadius(result);
      // console.log("result", result);
    } else {
      const result = checkWithinRadius(addressLatLng, assetLatLngAdd);
      setIsWithinRadius(result);
      // console.log("result", result);
    }
  }, [callApi, retakeAgain]);

  const handleAddAssets = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      // const latLng = await exifr.gps(files[0]);
      // console.log({ latLng });
      // if (latLng == undefined) {
      //   toast(
      //     <>
      //       <div className="activityalert">
      //         <div className="alert alert-dismissible fade show m-0 px-0 text-center">
      //           <p className="m-0">
      //             <img
      //               src="./assets/Newimg/redclose.png"
      //               className="bluecheckimg"
      //               alt
      //             />
      //             This image does not contain latitude and longitude
      //           </p>
      //           <button
      //             type="button"
      //             className="btn-close"
      //             data-bs-dismiss="alert"
      //             aria-label="Close"
      //           ></button>
      //         </div>
      //       </div>
      //     </>
      //   );
      // } else {
      setRetakeAgain(true);
      setReTake(true);
      setAssetLatLng({
        lat: latLng?.latitude,
        lng: latLng?.longitude,
      });
      setReTakeImage(e.target.files[0]);
      setReTakeImagePreview(URL.createObjectURL(e.target.files[0]));
      // }
    }
  };

  const handleUpdateImage = () => {
    if (isWithinRadius == true) {
      const updateImageFromData = {
        file: base64Image,
        type: "image",
        assetId: assetId
      }
      // const updateImageFromData = new FormData();
      // updateImageFromData.append("file", img);
      // updateImageFromData.append("type", "image");
      // updateImageFromData.append("assetId", assetId);

      PostDataWithToken(`asset/add-image-to-asset`, updateImageFromData).then((response) => {
        if (response.status == true) {
          toast(
            <>
              <div className="activityalert">
                <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                  <p className="m-0">
                    <img
                      src="./assets/images/blueradio.png"
                      className="bluecheckimg"
                      alt
                    />
                    Asset image has been successfully added.
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </>
          );
          navigate(-1);
        }
      });
    } else {
      toast(
        <>
          <div className="activityalert">
            <div className="alert alert-dismissible fade show m-0 px-0 text-center">
              <p className="m-0">
                <img
                  src="./assets/Newimg/redclose.png"
                  className="bluecheckimg"
                  alt
                />
                This image does not belongs at physical address.
              </p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {/*----- Main Container Take a photo Start -----*/}
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-addphoto vs-submission position-relative">
            {/*----- Vs Header Start -----*/}
            <div className="vs-header Withbtngroup">
              {/*------ Header Mid Heading (Left Part) ------*/}
              <a
                href="javascript:void(0)"
                onClick={() => navigate(-1)}
                className="headericon"
              >
                <img src="./assets/images/icon/BackIcon.svg" />
              </a>

              {/*------ Header Mid Heading (Middle Part) ------*/}
              <h4 className="vs-header-title d-none">Take a photo</h4>
            </div>
            {/*----- Vs Header end -----*/}

            {/*----- Section Content Start -----*/}
            <section className="vs-content_section h-auto text-center vs-empty px-4 flex-column justify-content-between">
              {/*----- Captured Image Output -----*/}
              <img
                src={retakeAgain == true ? reTakeImagePreview : imgPreviewURL}
                alt
                className="vs-Capture"
              />
              {/*----- Section Random Intro Start -----*/}
              <div className="Section_random_intro text-center mt-5 pt-5">
                <h3 className="fs-1 w-75 mb-4 mx-auto">Now, check the photo</h3>
                <p className="text-secondary fs-3 w-75 mx-auto">
                  Make sure the photos are not blurry
                  <br />
                  or obscured by any harsh light.
                </p>
              </div>
            </section>
            {/*----- Section Content End -----*/}
            {/*----- Basic Footer Section Start -----*/}
            <footer className="vs-floting-footer vs-footer-gradient">
              <button
                className="btn btn-outline-primary rounded-pill first"
                style={{ cursor: "pointer" }}
              >
                <label
                  htmlFor="assetIMG"
                  className="d-block h-100 w-100 px-5 py-2"
                >
                  Retake
                </label>
              </button>

              <input
                type="file"
                capture="environment"
                id="assetIMG"
                className="d-none"
                onChange={(e) => handleAddAssets(e)}
              />
              <a
                to="javascipt:void(0)"
                onClick={handleUpdateImage}
                className="btn btn-primary rounded-pill px-5 fs-3 py-3  ms-3"
              >
                Confirm
              </a>
            </footer>
            {/*----- Basic Footer Section End -----*/}
          </div>
        </div>
      </div>
      {/*----- Add New asset confirm Offcanvas -----*/}
    </>
  );
};

export default UpdateCheckAsset;
