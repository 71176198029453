import React from 'react'
import { Link } from 'react-router-dom'

const Lodar = () => {


    return (
        <>
            <div className="vs-main-container">
                <div className="container">
                    <div className="row justify-content-center responsive-row">
                        <div className="col-xl-4 col-lg-6 col-sm-9 col-12 col-md-8 responsive-col">
                            <div className="vs-main-contentContainer position-relative px-4">
                                <div className='lodarcontainer'>
                                    <div className='lodar'>
                                        <img src='./assets/images/lodar.gif' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Lodar