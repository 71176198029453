import './App.css';
import AllRoutes from './components/routes/AllRoutes';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const deviceData = window?.navigator?.userAgent;
  const deviceDataArray = String(deviceData).split(" ");
  console.log("deviceDataArray", JSON.stringify(deviceDataArray[4]));
  let text = deviceDataArray[6];
  const myArray = text.split("/").join();
  localStorage.setItem("device_modal", JSON.stringify(deviceDataArray[4]));
  localStorage.setItem("device_id", JSON.stringify(myArray));
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
