import React, { useEffect, useState } from 'react'
import { GetDataWithToken, PostDataWithToken } from '../../ApiHelper/ApiHelper';
import { Offcanvas } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../common/Loader';
import { useForm } from 'react-hook-form';
import { toast } from 'material-react-toastify';

const PhysicalAddress = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [callApi, setCallApi] = useState(true);
  const [addressDetail, setAddressDetail] = useState([]);
  const [addAssetCanvas, setAddAssetCanvas] = useState(false);
  const addAssetCanvasClose = () => setAddAssetCanvas(false);
  const [searchTextLength, setSearchTextLength] = useState('');
  const [acceptCondition, setAcceptCondition] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  // console.log("assetsId", location?.state?.assetsId)
  const updateAddress = location?.state?.updateAddress;
  const selectedAssetsList = location?.state?.selectedAssetsList;
  const selectedIds = location?.state?.selectedIds;
  const quoteId = location?.state?.quoteId;
  const inventryValue = location?.state?.inventryValue;
  const quoteLineItems = location?.state?.quoteLineItems;
  const underwritingQuestions = location?.state?.underwritingQuestions;
  const campaignSchema = location?.state?.campaignSchema;
  // console.log("quoteLineItems-------------", quoteLineItems);


  // console.log("assetsId", assetsId);
  // console.log("updateAddress", updateAddress);
  const [loader, setLoader] = useState(true);
  const [placeData, setPlaceData] = useState({});
  const [showAddress, setShowAddress] = useState(false);


  const getLocation = () => {
    if (navigator.geolocation) {
      // alert("qwertyui");
      navigator.geolocation.getCurrentPosition(showPosition);
      // navigator.geolocation.getCurrentPosition((position) => { console.log("postion", position) }, (error) => { console.log("error", error) })
    } else {
      toast(
        <>
          <div className="activityalert">
            <div className="alert alert-dismissible fade show m-0 px-0 text-center">
              <p className="m-0">
                <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
                Geolocation is not supported by this browser.
              </p>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
              </button>
            </div>
          </div>
        </>
      )
    }
  };

  const showPosition = (position) => {
    // alert();
    setLoader(true);

    console.log("position", position);
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);

    // let latitude = -29.81821096418687;
    // let longitude = 30.97404782618146;

    GetDataWithToken(`user/place-details-by-lat-lng?lat=${latitude}&lng=${longitude}`)
      .then((response => {
        response = JSON.parse(response);
        // console.log("ressss", response)
        if (response.status == true) {
          setLoader(false);
          // setAcceptCondition(true);
          const results = response.data.results;
          // setPlaceData(response.data.results?.[0]?.formatted_address);
          setTimeout(() => {
            reset({
              streat: results?.[0]?.formatted_address,
            })
          }, 2000);
          for (const result of results) {
            // console.log("result----", result);
            for (const component of result.address_components) {
              // console.log("component----", component);
              if (component.types.includes("sublocality_level_1" || "sublocality" || "political")) {
                const suburb = component.long_name;
                // setSuburb(suburb);
                setTimeout(() => {
                  reset({
                    subrub: suburb,
                  })
                }, 1000);
              }
              if (component.types.includes("locality" || "political")) {
                const city = component.long_name;
                // setCity(city);
                reset({
                  city: city,
                  appartment: placeData?.appartment,
                  province: placeData?.province,
                })
              }
            }
          }
        }
      }))
  };

  const handleSearch = async (text) => {

    setSearchTextLength(text);
    if (text == '') {
      setAddressList([])
    }
    if (text.length > 3) {
      GetDataWithToken(`user/get-location?name=${text}`, '').then((response) => {
        response = JSON.parse(response);
        if (response.status == true) {
          if (response.data.predictions.length > 0) {
            setAddressList(response.data.predictions);
            setShowAddress(false);
          } else {
            setShowAddress(true);
          }
        }
      })
    }
  };

  const handleSelectPlace = (placeId) => {
    setLoader(true);
    GetDataWithToken(`user/place-details?id=${placeId}`)
      .then((response) => {
        response = JSON.parse(response);
        if (response.status == true) {
          setLoader(false);
          // console.log("result -------", response.data?.result?.formatted_address);
          // console.log("lat", response.data.result.geometry.location.lat);
          // console.log("lng", response.data.result.geometry.location.lng);
          setLat(response.data.result.geometry.location.lat);
          setLng(response.data.result.geometry.location.lng);
          setAcceptCondition(true);
          // setPlaceData(response.data?.result?.formatted_address);
          setTimeout(() => {
            reset({
              streat: response.data?.result?.formatted_address,
            })
          }, 1000);
          const data = response.data.result.address_components
          data.map(item => {
            console.log("check-----", item.types.includes("sublocality_level_1" || "sublocality" || "political"))
            if (item.types.includes("sublocality_level_1" || "sublocality" || "political")) {
              // setSuburb(item.long_name);
              setTimeout(() => {
                reset({
                  subrub: item?.long_name
                })
              }, 500);
            } else
              if (item.types.includes("locality" || "political")) {
                // setCity(item.long_name);
                reset({
                  city: item?.long_name,
                  appartment: placeData?.appartment,
                  province: placeData?.province,
                })
              } else
                if (item.types.includes("sublocality_level_1" || "sublocality" || "political") == false) {
                  if (item.types.includes("locality" || "political")) {
                    console.log("first---------", item.long_name)
                    // setSuburb(item.long_name);
                    setTimeout(() => {
                      reset({
                        subrub: item?.long_name
                      })
                    }, 1000);
                  }
                }
          })
        }
      })
  };

  useEffect(() => {
    if (callApi || loader) {
      GetDataWithToken('user/get-physical-address', '')
        .then((response) => {
          if (response.status == true) {
            setCallApi(false);
            setLoader(false);
            // setAddressDetail(response.data);
            setPlaceData(response.data);
            reset({
              appartment: response?.data?.appartment,
              streat: response?.data?.streat,
              subrub: response?.data?.subrub,
              city: response?.data?.city,
              province: response?.data?.province,
            })
            setLat(response?.data?.lat);
            setLng(response?.data?.lng);
          } else {
            navigate("/add-physical-address")
          }
        })
    }
  }, [callApi]);

  const handleAddPayment = (data) => {
    console.log("data", data);
    let addDebit = {
      payment_date: new Date(),
      insurence_term: "monthly",
      payment_method: "add to bill",
      monthly_premium: "1250",
      assetId: selectedIds
    }
    PostDataWithToken('user/add-payment-details', addDebit)
      .then((response) => {
        if (response.status == true) {
          navigate('/covered', {
            state: {
              coveredData: response.data,
            }
          });
          console.log("qwerty", response.data);
        }
      })
  };

  const handleAddAddress = (data) => {
    // alert();
    let addressData = {
      appartment: data?.appartment,
      streat: data?.streat,
      subrub: data?.subrub,
      city: data?.city,
      province: data?.province,
      lat: lat,
      lng: lng
    }
    if (addressData.lat == null && updateAddress == undefined) {
      navigate("/cover-summary", {
        state: { selectedIds: selectedIds, inventryValue: inventryValue, selectedAssetsList: selectedAssetsList, quoteLineItems: quoteLineItems, underwritingQuestions: underwritingQuestions, campaignSchema: campaignSchema, quoteId: quoteId }
      });
    } else {
      console.log("addressData", addressData);
      PostDataWithToken('user/updateAddress', addressData)
        .then((response) => {
          console.log("ressss", typeof (response))
          response = JSON.parse(response);
          if (response.status == true) {
            if (updateAddress == true) {
              toast(
                <>
                  <div className="activityalert">
                    <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                      <p className="m-0">
                        <img src="./assets/images/blueradio.png" className="bluecheckimg" alt />
                        Your address has been successfully updated.
                      </p>
                      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                      </button>
                    </div>
                  </div>
                </>
              )
              navigate(-1);
            } else {
              navigate("/cover-summary", {
                state: { selectedIds: selectedIds, inventryValue: inventryValue, selectedAssetsList: selectedAssetsList, quoteLineItems: quoteLineItems, underwritingQuestions: underwritingQuestions, campaignSchema: campaignSchema, quoteId: quoteId }
              });
            }
          }
        });
    }
  };

  const handleFocus = (id, inputId, isFocus) => {
    const inputElement = document.getElementById(id);
    const divElement = document.getElementById(inputId);
    if (inputElement) {
      if (isFocus) {
        setTimeout(() => {
          inputElement.classList.add("focuskeyboard");
          divElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
      } else {
        inputElement.classList.remove("focuskeyboard");
      }
    }
  };

  return (
    <>
      {loader ?
        <Loader />
        :
        <>
          {/*----- Main Container For Dashboard Start -----*/}
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-addressform position-relative">
                <form onSubmit={handleSubmit(handleAddAddress)}>
                  {/*----- Vs Header Start -----*/}
                  <div className="vs-header Withbtngroup">
                    {/*------ Header Mid Heading (Left Part) ------*/}
                    <a href="javascript:void(0)" onClick={() => navigate(-1)} className="">
                      <img src="./assets/Newimg/backbtnicon.png" className="headerlefticon" />
                    </a>

                    {/*------ Header Mid Heading (Middle Part) ------*/}
                    <h4 className="vs-header-title d-none">vodasure | asset cover</h4>

                    {/*------ Header Mid Heading (Right Part) ------*/}
                    <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                      <a href="#" className="btn border-0 me-2">
                        <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                      </a>

                      <a href="#" className="btn border-0 ms-2">
                        <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                      </a>
                    </div>
                  </div>
                  {/*----- Vs Header end -----*/}


                  {/*----- Vodasure Add address content section Start -----*/}
                  <section className="vs-content_section" id='keyboardFocus'>
                    <h2 className="mb-0">
                      Physical address
                    </h2>

                    <p className="text-secondary my-4 pb-2">
                      Please confirm your physical address:
                    </p>

                    <div className="position-relative">
                      <ul className="vs-inputform list-unstyled pb-0 pt-5">
                        <li className="gpsinput">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating pb-0">
                            {/*--- Input ---*/}
                            <input type="text" className="form-control" placeholder=" " id="adf6"
                              autoComplete="off"
                              {...register("location", {
                                onChange: (e) => handleSearch(e?.target?.value),
                                maxLength: {
                                  value: 50,
                                  message: 'Address must be is 50 characters.'
                                },
                                // pattern: {
                                //   value: /^[A-Za-z0-9\s]+$/,
                                //   message: 'Address must be alpha numeric.'
                                // },
                              })}
                            />

                            {/*--- Label ---*/}
                            <label htmlFor="adf6" className="text-secondary">
                              Search for your address
                            </label>
                          </div>

                          {/*----- GPS Icon -----*/}
                          <a href='javascript:void(0)' className="inputicons">
                            <img src="./assets/Newimg/GPSicon.png" className="w-75 h-100" onClick={() => getLocation()} />
                          </a>

                          {/*----- Search Icon -----*/}
                          <a href='javascript:void(0)' className="inputicons first">
                            <img src="./assets/Newimg/Searchicon.png" className="w-100 h-100" />
                          </a>
                        </li>
                      </ul>


                      {showAddress == false && (
                        <>
                          {searchTextLength?.length > 3 && (
                            <div className="searchaddresslisting rounded-4">
                              <ul className="list-unstyled">
                                {addressList && addressList?.length > 0 && addressList?.map((place, index) => (
                                  <li key={index}>
                                    <a href="javascript:void(0)" onClick={() => { handleSelectPlace(place?.place_id); setShowAddress(true) }} className="w-100 text-truncate" title={place?.description}>
                                      {place?.description}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {/*----- Google trademark -----*/}
                    <img src="./assets/images/googletag.png" className="googletabimg" alt />


                    <ul className="vs-inputform list-unstyled pb-0">
                      <li className="border-0 pb-0">
                        <div className="form-floating mb-0">
                          {/*--- Input ---*/}
                          <input type="text" className="form-control" id="adf1" placeholder=" "
                            {...register("appartment", {
                              maxLength: {
                                value: 50,
                                message: 'Appartment must be is 50 characters.'
                              },
                            })}
                          />
                          {/*--- Label ---*/}
                          <label htmlFor="adf1" className="h-auto text-secondary">
                            Unit/apartment no. (optional)
                          </label>
                        </div>
                      </li>

                      <li className="border-0 py-0">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating mb-0">
                          {/*--- Input ---*/}
                          <input type="text" className="form-control" id="adf1" placeholder=" "  {...register("streat")} />
                          {/*--- Label ---*/}
                          <label htmlFor="adf1" className="h-auto text-secondary">
                            Street address
                          </label>
                        </div>
                      </li>

                      <li className="border-0 py-0">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating mb-0">
                          {/*--- Input ---*/}
                          <input type="text" className="form-control" id="adf2" placeholder=" "
                            {...register("subrub")} />
                          {/*--- Label ---*/}
                          <label htmlFor="adf2" className="h-auto text-secondary">
                            Suburb
                          </label>
                        </div>
                      </li>
                      <li className="border-0 py-0">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating mb-0">
                          {/*--- Input ---*/}
                          <input type="text" className="form-control" id="adf3" placeholder=" "
                            {...register("city")}
                            onFocus={() => handleFocus("keyboardFocus", "adf3", true)}
                            onBlur={() => handleFocus("keyboardFocus", "adf3", false)}
                          />
                          {/*--- Label ---*/}
                          <label htmlFor="adf3" className="h-auto text-secondary">City</label>
                        </div>
                      </li>
                      <li className="border-0 py-0">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating mb-0 pb-0">
                          <select className="form-select py-0" {...register("province")}>
                            <option className='d-none'>Select province</option>
                            <option value={'Eastern Cape'}>Eastern Cape</option>
                            <option value={'Free State'}>Free State</option>
                            <option value={'Gauteng'}>Gauteng</option>
                            <option value={'KwaZulu-Natal'}>KwaZulu-Natal</option>
                            <option value={'Limpopo'}>Limpopo</option>
                            <option value={'Mpumalanga'}>Mpumalanga</option>
                            <option value={'Northern Cape'}>Northern Cape</option>
                            <option value={'North West'}>North West</option>
                            <option value={'Western Cape'}>Western Cape</option>
                          </select>

                          <label htmlFor="demo">Province (optional)</label>
                        </div>
                      </li>
                    </ul>
                  </section>
                  {/*----- Vodasure Add address content section Start -----*/}

                  {/*----- Vodasure dashboard container End -----*/}
                  <footer className="vs-floting-footer ">
                    <a href="javascript:void(0)" onClick={() => navigate(-1)} data-bs-dismiss="offcanvas" className="btn btn-outline-secondary rounded-4 px-5 py-3">
                      Cancel
                    </a>

                    <button className="btn btn-primary rounded-4 px-5 py-3" type='submit'>
                      {updateAddress == true ? "Update address" : "Review and confirm"}
                    </button>
                  </footer>
                </form>
              </div>
            </div>
          </div>
          {/*----- Main Container For Dashboard End -----*/}

          <Offcanvas show={addAssetCanvas} onHide={addAssetCanvasClose} scroll={false} placement='bottom' id="payMethod" className='p-4 show offcanvas offcanvas-bottom'>
            <div>
              {/*----- Offcanvas Header -----*/}
              <div className="offcanvas-header px-5">
                <button type="button" className="btn-close ms-auto" data-bs-dismiss="offcanvas" aria-label="Close" />
              </div>

              {/*----- Offcanvas Body -----*/}
              <div className="offcanvas-body">
                {/*----- Section Random Intro Start -----*/}
                <div className="Section_random_intro text-center mb-5">
                  <h3 className="fs-2 w-75 mx-auto">Please select a payment method</h3>
                </div>
                {/*----- Section Random Intro End -----*/}
                <ul className="vs-getaquote list-unstyled">
                  <li className="py-5">
                    <a href="javascript:void(0)" onClick={handleAddPayment} className="row justify-content-between align-items-center">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/Invoice.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        <label className="form-check-label h-auto" htmlFor="idradio1">
                          Add to vodacom bill
                        </label>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <input className="form-check-input float-end ms-4" type="radio" id="idradio1" name="mymethod" />
                      </div>
                    </a>
                  </li>
                  <li className="py-5">
                    <Link to="/debit-card" state={{ selectedIds: selectedIds }} className="row justify-content-between align-items-center">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/DebitCard.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        <div className>
                          <label className="form-check-label h-auto" htmlFor="idradio2">
                            Debit order
                          </label>
                        </div>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <input className="form-check-input float-end ms-4" type="radio" id="idradio1" name="mymethod" />
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

          </Offcanvas>
        </>
      }
    </>
  )
}

export default PhysicalAddress