import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import AddAssetModal from '../AddAssets/AddAssetModal'

const EmptyLocker = () => {

  const [addAssetCanvas, setAddAssetCanvas] = useState(false);
  const addAssetCanvasClose = () => setAddAssetCanvas(false);
  const addAssetCanvasShow = () => setAddAssetCanvas(true);

  return (
    <>
      {/* only for static */}
      {/*----- Main Container For Empty Locker Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-mylocker position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <Link to="/dashboard">
                  <img src="./assets/images/icon/BackIcon.svg" />
                </Link>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">My Locker</h4>
              </div>

              <div className="col-2 text-end">
                <a href="javascript:void(0)" onClick={addAssetCanvasShow} className="d-block">
                  <img src="./assets/images/icon/Add_Button.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Content Start -----*/}
          <section className="vs-content_section text-center vs-empty px-4">
            {/*----- Add Asset Button -----*/}
            <Link to="/my-locker" className="btn btn-primary rounded-pill px-5 fs-3 py-3 align-self-center w-auto">
              Add asset
              <img src="./assets/images/icon/AddIcon.svg" className="align-middle ms-2" />
            </Link>
            {/*----- Section Random Intro Start -----*/}
            <div className="Section_random_intro text-center mb-5">
              <h3 className="fs-2 mb-4 text-white">No assets yet...</h3>
              <p className="text-dark-gray w-75 mx-auto">
                Add your asset by pressing the button below and filling in the required fields.
              </p>
            </div>
            {/*----- Section Random Intro End -----*/}
          </section>
          {/*----- Section Content End -----*/}
        </div>
      </div>
      {/*----- Main Container For Empty Locker End -----*/}
      {/* <AddAssetModal
        addAssetCanvas={addAssetCanvas}
        addAssetCanvasClose={addAssetCanvasClose}
      /> */}
    </>
  )
}

export default EmptyLocker