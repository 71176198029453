import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const QuoteCancelReason = () => {

  const navigate = useNavigate();

  return (
    <>
      {/*----- Main Container For Cancel quote reason Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-profile position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                  <img src="./assets/images/icon/BackIcon.svg" />
                </a>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Cancel quote</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 mt-3">
            <h3 className="fs-2 mb-3  pb-3 mx-auto text-white">Share more details with us</h3>
            {/*----- Here we don't need the subheading of Random intro so we are not using it -----*/}
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure Profile Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Delete Reason other Form Start -----*/}
            <div className="Feedbackform">
              <form action="#">
                <ul className="vs-profileOption  list-unstyled pt-5 rounded-4">
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating pb-0">
                      {/*--- Input ---*/}
                      <textarea className="form-control" id="adf6" placeholder="Other" style={{ height: '100px' }} defaultValue={""} />
                      {/*--- Label ---*/}
                      <label htmlFor="adf6" className="h-auto text-dark-gray">Other</label>
                    </div>
                  </li>
                </ul>
                {/*--- Error Message ---*/}
                <div className="ms-4 f-error position-static" style={{ lineHeight: '10px' }}>
                  This input is invalid
                </div>
                <ul className="vs-profileOption  list-unstyled pt-4 mt-4 rounded-4">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="txtPhone" placeholder=" " />
                      {/*--- Label ---*/}
                      <label htmlFor="txtPhone" className="h-auto text-dark-gray">Mobile
                        number</label>
                      <div className="f-error">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4 pb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="mailaddress" placeholder="Email address" />
                      {/*--- Label ---*/}
                      <label htmlFor="mailaddress" className="h-auto text-dark-gray">
                        Email address
                      </label>
                    </div>
                  </li>
                </ul>
                <div className="ms-4 f-error position-static" style={{ lineHeight: '1px' }}>
                  This input is invalid
                </div>
              </form>
            </div>
            {/*----- Delete Reason other Form End -----*/}
          </section>
          {/*----- Vodasure Profile Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
              <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-primary rounded-pill px-5 fs-3 py-3 w-50 me-3">
                Cancel
              </a>
              <Link to="/quote-feedback" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3">
                Submit
              </Link>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For Cancel quote reason End -----*/}

    </>
  )
}

export default QuoteCancelReason