import React from 'react'
import { Link } from 'react-router-dom'

const ChatIndex = () => {
  return (
    <>
      {/*----- Main Container For Chat index Start -----*/}
      <div className="vs-main-container">
        {/*----- Vodasure Chat container Start -----*/}
        <div className="vs-main-content vs-chatindex position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start"></div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title text-center">VodaSure | Locker</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          <section className="vs-content_section px-4 pt-0">
            {/*----- Section Random Intro Start -----*/}
            <div className="Section_random_intro text-center mb-5 mt-3">
              <h3 className="fs-2 mb-3 w-75 mx-auto text-white">Chatbot</h3>
              <p className="text-dark-gray w-75 mx-auto">This is the stat of your conversation</p>
            </div>
            {/*----- Section Random Intro End -----*/}
            {/*----- chat index questions start -----*/}
            <div className="vs-questions_btn text-center">
              <button type="button" className="btn btn-outline-secondary w-auto bg-dark">
                <span>Get a quote</span>
              </button>
              <button type="button" className="btn btn-outline-secondary w-auto ">
                <span>Add a domicile</span>
              </button>
              <button type="button" className="btn btn-outline-secondary w-auto bg-dark">
                <span>Add asset</span>
              </button>
              <button type="button" className="btn btn-outline-secondary w-auto ">
                <span>Insure personal item</span>
              </button>
              <button type="button" className="btn btn-outline-secondary w-auto bg-dark">
                <span>Insure Household item</span>
              </button>
              <button type="button" className="btn btn-outline-secondary w-auto ">
                <span>Insure device</span>
              </button>
              <button type="button" className="btn btn-outline-secondary w-auto ">
                <span>Insure your vehicle</span>
              </button>
            </div>
            {/*----- chat index questions End -----*/}
          </section>
          {/*----- Chat bot input start -----*/}

          <footer class="vs-floting-footer vs-footer-gradient">
            <div className="vs-chat_bot_input">
              {/*----- Input -----*/}
              <div className="input-group">
                <input type="text" className="form-control bg-transparent border-0 rounded-0" placeholder="Add message......" />
                {/*----- Send button -----*/}
                <Link to="/chat-bot" className="p-0">
                  <img src="./assets/images/chatbot/icon/SendIcon.svg" />
                </Link>
              </div>
            </div>
          </footer>

          {/*----- Chat bot input End -----*/}
        </div>
        {/*----- Vodasure Chat container End -----*/}
      </div >
      {/*----- Main Container For Chat index End -----*/}

    </>
  )
}

export default ChatIndex