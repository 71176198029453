import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import AddAssetModal from '../AddAssets/AddAssetModal'
import Modal from 'react-bootstrap/Modal';

const MyLocker = () => {

  const [addAssetCanvas, setAddAssetCanvas] = useState(false);
  const addAssetCanvasClose = () => setAddAssetCanvas(false);
  const addAssetCanvasShow = () => setAddAssetCanvas(true);

  const [showModals, setShowModals] = useState(false);

  return (
    <>
      {/*----- Main Container For My Locker Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-mylocker position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <Link to="/dashboard">
                  <img src="./assets/images/icon/BackIcon.svg" />
                </Link>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Add Asset</h4>
              </div>

              <div className="col-2 text-end">
                <a className="d-block" href="javascript:void(0)" onClick={addAssetCanvasShow}>
                  <img src="./assets/images/icon/Add_Button.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-4 mt-3">
            <h3 className="fs-2 mb-3 text-white">My Locker</h3>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Section Content Start -----*/}
          <section className="vs-content_section px-4 pt-3">
            {/*----- Info Card  -----*/}
            <div className="card vs-InfoCardStatus">
              {/*---- status Badge -----*/}
              <span className="badge insured">Insured</span>
              {/*----- Info Card Body -----*/}
              <div className="card-body pt-5">
                <div className="d-flex justify-content-between mb-2 pt-3">
                  {/*----- Info time -----*/}
                  <p className="vs-Info_dateTime fs-4">
                    <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                  </p>
                  {/*----- Info Amount -----*/}
                  <p className="vs-Info-price fs-4">R 12 999.00</p>
                </div>
                {/*----- Info title -----*/}
                <h2 className="vs-card-title text-white mb-2">
                  iPhone 12 Mini 64GB navy
                </h2>
                <p className="fs-4">IMEI: 422929281819661</p>
                {/*----- Info Action buttons -----*/}
                <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                  <a href="javascript:void(0)" onClick={() => setShowModals(true)} className="btn btn-outline-secondary rounded-pill px-lg-5 px-4 fs-3 py-2 w-50 me-3">
                    Delete asset
                  </a>
                  <Link to="/update-asset" type="button" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                    View asset
                  </Link>
                </div>
              </div>
            </div>

            {/*----- Info Card  -----*/}
            <div className="card vs-InfoCardStatus">
              {/*---- status Badge -----*/}
              <span className="badge uninsured">Uninsured</span>
              {/*----- Info Card Body -----*/}
              <div className="card-body pt-5">
                <div className="d-flex justify-content-between mb-2 pt-3">
                  {/*----- Info time -----*/}
                  <p className="vs-Info_dateTime fs-4">
                    <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                  </p>
                  {/*----- Info Amount -----*/}
                  <p className="vs-Info-price fs-4">R 12 999.00</p>
                </div>
                {/*----- Info title -----*/}
                <h2 className="vs-card-title text-white mb-2">
                  iPhone 12 Mini 64GB navy
                </h2>
                <p className="fs-4">IMEI: 422929281819661</p>
                {/*----- Info Action buttons -----*/}
                <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                  <a href="javascript:void(0)" onClick={() => setShowModals(true)} className="btn btn-outline-secondary rounded-pill px-lg-5 px-4 fs-3 py-2 w-50 me-3">
                    Delete asset
                  </a>
                  <Link to="/update-asset" type="button" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                    View asset
                  </Link>
                </div>
              </div>
            </div>

            {/*----- Info Card  -----*/}
            <div className="card vs-InfoCardStatus">
              {/*---- status Badge -----*/}
              <span className="badge bg-gray">Deactivated</span>
              {/*----- Info Card Body -----*/}
              <div className="card-body pt-5">
                <div className="d-flex justify-content-between mb-2 pt-3">
                  {/*----- Info time -----*/}
                  <p className="vs-Info_dateTime fs-4">
                    <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                  </p>
                  {/*----- Info Amount -----*/}
                  <p className="vs-Info-price fs-4">R 12 999.00</p>
                </div>
                {/*----- Info title -----*/}
                <h2 className="vs-card-title text-white mb-2">
                  iPhone 12 Mini 64GB navy
                </h2>
                <p className="fs-4">IMEI: 422929281819661</p>
                {/*----- Info Action buttons -----*/}
                <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                  <a href="javascript:void(0)" onClick={() => setShowModals(true)} className="btn btn-outline-secondary rounded-pill px-lg-5 px-4 fs-3 py-2 w-50 me-3">
                    Delete asset
                  </a>
                  <Link to="/update-asset" type="button" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                    View asset
                  </Link>
                </div>
              </div>
            </div>

            {/*----- Info Card  -----*/}
            <div className="card vs-InfoCardStatus">
              {/*---- status Badge -----*/}
              <span className="badge insured">Insured</span>
              {/*----- Info Card Body -----*/}
              <div className="card-body pt-5">
                <div className="d-flex justify-content-between mb-2 pt-3">
                  {/*----- Info time -----*/}
                  <p className="vs-Info_dateTime fs-4">
                    <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                  </p>
                  {/*----- Info Amount -----*/}
                  <p className="vs-Info-price fs-4">R 12 999.00</p>
                </div>
                {/*----- Info title -----*/}
                <h2 className="vs-card-title text-white mb-2">
                  iPhone 12 Mini 64GB navy
                </h2>
                <p className="fs-4">IMEI: 422929281819661</p>
                {/*----- Info Action buttons -----*/}
                <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                  <a href="javascript:void(0)" onClick={() => setShowModals(true)} className="btn btn-outline-secondary rounded-pill px-lg-5 px-4 fs-3 py-2 w-50 me-3">
                    Delete asset
                  </a>
                  <Link to="/update-asset" type="button" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                    View asset
                  </Link>
                </div>
              </div>
            </div>

            {/*----- Info Card  -----*/}
            <div className="card vs-InfoCardStatus">
              {/*---- status Badge -----*/}
              <span className="badge uninsured">Uninsured</span>
              {/*----- Info Card Body -----*/}
              <div className="card-body pt-5">
                <div className="d-flex justify-content-between mb-2 pt-3">
                  {/*----- Info time -----*/}
                  <p className="vs-Info_dateTime fs-4">
                    <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                  </p>
                  {/*----- Info Amount -----*/}
                  <p className="vs-Info-price fs-4">R 12 999.00</p>
                </div>
                {/*----- Info title -----*/}
                <h2 className="vs-card-title text-white mb-2">
                  iPhone 12 Mini 64GB navy
                </h2>
                <p className="fs-4">IMEI: 422929281819661</p>
                {/*----- Info Action buttons -----*/}
                <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                  <a href="javascript:void(0)" onClick={() => setShowModals(true)} className="btn btn-outline-secondary rounded-pill px-lg-5 px-4 fs-3 py-2 w-50 me-3">
                    Delete asset
                  </a>
                  <Link to="/update-asset" type="button" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                    View asset
                  </Link>
                </div>
              </div>
            </div>

            {/*----- Info Card  -----*/}
            <div className="card vs-InfoCardStatus">
              {/*---- status Badge -----*/}
              <span className="badge bg-gray">Deactivated</span>
              {/*----- Info Card Body -----*/}
              <div className="card-body pt-5">
                <div className="d-flex justify-content-between mb-2 pt-3">
                  {/*----- Info time -----*/}
                  <p className="vs-Info_dateTime fs-4">
                    <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                  </p>
                  {/*----- Info Amount -----*/}
                  <p className="vs-Info-price fs-4">R 12 999.00</p>
                </div>
                {/*----- Info title -----*/}
                <h2 className="vs-card-title text-white mb-2">
                  iPhone 12 Mini 64GB navy
                </h2>
                <p className="fs-4">IMEI: 422929281819661</p>
                {/*----- Info Action buttons -----*/}
                <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                  <a href="javascript:void(0)" onClick={() => setShowModals(true)} className="btn btn-outline-secondary rounded-pill px-lg-5 px-4 fs-3 py-2 w-50 me-3">
                    Delete asset
                  </a>
                  <Link to="/update-asset" type="button" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                    View asset
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/*----- Section Content End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <a className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-auto" href="javascript:void(0)" onClick={addAssetCanvasShow}>
              Add an asset
              <img src="./assets/images/icon/AddIcon.svg" className="align-middle ms-2" />
            </a>

            {/*----- Activity Alert -----*/}
            <div className="activityalert">
              <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                <p className="m-0">Your asset has been successfully added.</p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
            </div>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For My Locker End -----*/}


      {/* <AddAssetModal
        addAssetCanvas={addAssetCanvas}
        addAssetCanvasClose={addAssetCanvasClose}
      /> */}

      <Modal
        show={showModals}
        onHide={() => setShowModals(false)}
        backdrop="static"
        keyboard={false}
        className="modal fade"
        centered
      >
        {/*----- Delete Quote Modal -----*/}
        <div className="modal-body text-center p-5">
          <img src="./assets/images/icon/Yellow_Bulb.svg" alt="" className="my-4" />
          <p className="fs-3 mb-1">
            Are you sure want to<br />delete this?
          </p>
          <a href="javascript:void(0)" className="d-block my-5" onClick={() => setShowModals(false)}>
            Yes, delete
            <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt="" />
          </a>
          <a href="javascript:void(0)" className="d-block" onClick={() => setShowModals(false)}>
            No, cancel
            <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt="" />
          </a>
        </div>
      </Modal>
    </>
  )
}

export default MyLocker