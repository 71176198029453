import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';

const MyPolicy = () => {

  const [deletePolicy, setDeletePolicy] = useState(false);

  return (
    <>
      {/*----- Main Container For Quote Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-quotes  position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <Link to="/dashboard">
                  <img src="./assets/images/icon/BackIcon.svg" />
                </Link>
              </div>

              <div className="col-2 text-center">
                <h4 className="vs-header-title">Policy</h4>
              </div>

              <div className="col-3 text-end">
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill">
                  <a href="#" className="btn border-0 me-2">
                    <img src="./assets/images/icon/HeaderMenu.svg" alt="" />
                  </a>
                  <a href="#" className="btn border-0 ms-2">
                    <img src="./assets/images/icon/HeaderClose.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-4">
            <h3 className="fs-2 mb-3  pb-3 mx-auto text-white">My policy</h3>
            {/*----- Quote Tab Content Navigation -----*/}

            <nav className="nav nav-tabs">
              {/*----- Navigation button 1 -----*/}
              <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#All_tab" type="button">
                All
              </button>
              {/*----- Navigation button 2 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Phone_tab" type="button">
                Phone
              </button>
              {/*----- Navigation button 3 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Tablet_tab" type="button">
                Tablet
              </button>
              {/*----- Navigation button 4 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#All_tab2" type="button">
                All
              </button>
              {/*----- Navigation button 5 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Phone_tab2" type="button">
                Phone
              </button>
              {/*----- Navigation button 6 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Tablet_tab2" type="button">
                Tablet
              </button>
            </nav>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Section Content Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Tab Content -----*/}
            <div className="tab-content" id="nav-tabContent">
              {/*----- Tab 1 -----*/}
              <div className="tab-pane fade show active" id="All_tab">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
              </div>

              {/*----- Tab 2 -----*/}
              <div className="tab-pane fade" id="Phone_tab">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
              </div>

              {/*----- Tab 3 -----*/}
              <div className="tab-pane fade" id="Tablet_tab">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
              </div>

              {/*----- Tab 4 -----*/}
              <div className="tab-pane fade" id="All_tab2">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
              </div>

              {/*----- Tab 5 -----*/}
              <div className="tab-pane fade" id="Phone_tab2">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
              </div>

              {/*----- Tab 6 -----*/}
              <div className="tab-pane fade" id="Tablet_tab2">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="javascript:void(0)" onClick={() => setDeletePolicy(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <Link to="/view-policy" className="btn btn-secondary rounded-pill px-5 fs-2 py-2 w-50">
                        View
                      </Link>
                    </div>
                  </div>
                </div>

                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="row mx-0 mt-4">
                      {/*-- Locaiton Icon --*/}
                      <div className="col-2">
                        <img src="./assets/images/icon/WalletIcon.svg" />
                      </div>
                      {/*-- Domicile Details --*/}
                      <div className="col-8">
                        {/*----- Info title -----*/}
                        <h2 className="vs-card-title text-white mb-2 fs-3">
                          iPhone 12 Mini 64GB navy
                        </h2>
                        <p className="fs-4">IMEI: 422929281819661</p>
                      </div>
                      {/*-- Go Icon --*/}
                      <div className="col-2 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </div>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4 px-5 mx-2">
                      <a href="#deletepolicy" data-bs-toggle="modal" className="btn btn-outline-secondary rounded-pill px-5 fs-2 py-2 w-50 me-5">
                        Delete
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
              </div>
            </div>
          </section>
          {/*----- Section Content End -----*/}

          {/*----- Activity Alert -----*/}
          <footer className="vs-floting-footer vs-footer-gradient">
            <Link to="/claim-type" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-auto">
              Submit Claim
              <img src="./assets/images/icon/AddIcon.svg" className="align-middle ms-2" />
            </Link>

            <div className="activityalert">
              <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                <p className="m-0">Your Policy has been successfully removed.</p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {/*----- Main Container For Quote End -----*/}


      <Modal
        show={deletePolicy}
        onHide={() => setDeletePolicy(false)}
        backdrop="static"
        keyboard={false}
        className="modal fade"
        centered
      >
        {/*----- Delete Quote Modal -----*/}
        <div className="modal-body text-center p-5">
          <img src="./assets/images/icon/Yellow_Bulb.svg" alt="" className="my-4" />
          <p className="fs-3 mb-1">
            Are you sure want to<br />delete this policy?
          </p>
          <Link to="/cancel-quote" className="d-block my-5" onClick={() => setDeletePolicy(false)}>
            Yes, delete
            <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt="" />
          </Link>
          <a href="javascript:void(0)" className="d-block" onClick={() => setDeletePolicy(false)}>
            No, cancel
            <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt="" />
          </a>
        </div>
      </Modal>
    </>
  )
}

export default MyPolicy