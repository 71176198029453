import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmAsset from './ConfirmAsset';

const SelectObjectPrice = () => {
  // const [selectObject, setSelectObject] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const selectObject = location?.state?.selectObject;
  const image = location?.state?.image;
  console.log("imagessssssss", image);

  console.log("selectObject", selectObject);
  const [confirmAssetCanvas, setConfirmAssetCanvas] = useState(false);
  const confirmAssetCanvasClose = () => setConfirmAssetCanvas(false);
  const [selectCategory, setSelectCategory] = useState([]);

  return (
    <>
      <div className="vs-main-container">
        <div className="container">
          <div className="row justify-content-center responsive-row">
            <div className="col-xl-4 col-lg-6 col-sm-9 col-12 col-md-8 responsive-col">
              <div className="vs-main-content vs-addphoto vs-submission position-relative px-4">
                {/*----- Vs Default Header Start -----*/}
                <div className="vs-header">
                  <div className="row justify-content-between align-items-center m-0">
                    <div className="col-2 text-start ps-0">
                      <a href="javascipt:void(0)" onClick={() => navigate(-1)} ><img src="./assets/images/icon/BackIcon.svg" /></a>
                    </div>
                    <div className="col-6 text-center">
                      <h4 className="vs-header-title">Select Object</h4>
                    </div>
                    <div className="col-2 text-end pe-0" />
                  </div>
                </div>
                {/*----- Vs Default Header End -----*/}
                <ul className="vs-getaquote list-unstyled">
                  {selectObject && selectObject?.length > 0 && selectObject?.map((obj, index) => (
                    <li className="py-5" key={index}>
                      <a href='javascript:void(0)' className="row justify-content-between align-items-center" onClick={() => { setConfirmAssetCanvas(true); setSelectCategory(obj); }}
                      >
                        <div className="col-9">
                          <p className="w-100 text-truncate mb-0">
                            {obj?.product}
                          </p>
                        </div>
                        <div className="col-1 text-end">
                          <img src="./assets/images/icon/GoIcon.svg" />
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmAsset
        confirmAssetCanvas={confirmAssetCanvas}
        confirmAssetCanvasClose={confirmAssetCanvasClose}
        // assetData={assetData}
        image={image}
        selectCategory={selectCategory}
        SelectObject={selectObject}
      />
    </>
  )
}

export default SelectObjectPrice