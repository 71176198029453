import React, { useEffect, useState } from 'react'
import { GetDataWithToken } from '../../ApiHelper/ApiHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from '../../common/Loader';

const PersonalDetails = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [callApi, setCallApi] = useState(true);
  const [userDetail, setUserDetail] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedOption, setSelectedOption] = useState('email');
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("quoteLineItems", location?.state?.quoteLineItems)
  const quoteLineItems = location?.state?.quoteLineItems;
  const quoteId = location?.state?.quoteId;
  const inventryValue = location?.state?.inventryValue;
  const selectedAssetsList = location?.state?.selectedAssetsList;
  const selectedIds = location?.state?.selectedIds;
  const underwritingQuestions = location?.state?.underwritingQuestions;
  const campaignSchema = location?.state?.campaignSchema;
  console.log("inventryValue", inventryValue);
  const idNumber = localStorage.getItem('idNumber');
  console.log("idNumber", idNumber);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (callApi || loader) {
      GetDataWithToken('user/get-personal-details', '')
        .then((response) => {
          if (response.status == true) {
            setCallApi(false);
            setLoader(false);
            setUserDetail(response.data);
            reset({
              email: response.data.email
            })
          }
        })
    }
  }, [callApi]);

  const handleSendDoc = () => {
    navigate('/physical-address',
      {
        state: {
          quoteLineItems: quoteLineItems,
          quoteId: quoteId,
          inventryValue: inventryValue,
          selectedAssetsList: selectedAssetsList,
          selectedIds: selectedIds,
          underwritingQuestions: underwritingQuestions,
          campaignSchema: campaignSchema,
        }
      }
    );
  }

  return (
    <>
      {/*----- Main Container For Dashboard Start -----*/}
      {loader ?
        <Loader />
        :
        <div className="vs-main-container">
          <div className="responsive-row">
            <div className="vs-main-contentContainer vs-personalDetails position-relative">
              {/*----- Vs Header Start -----*/}
              <div className="vs-header Withbtngroup">
                {/*------ Header Mid Heading (Left Part) ------*/}
                <a href="javascript:void(0)" onClick={() => navigate(-1)} className="headericon">
                  <img src="./assets/images/icon/BackIcon.svg" />
                </a>

                {/*------ Header Mid Heading (Middle Part) ------*/}
                <h4 className="vs-header-title d-none">vodasure | asset cover</h4>

                {/*------ Header Mid Heading (Right Part) ------*/}
                <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                  <a href="#" className="btn border-0 me-2">
                    <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                  </a>

                  <a href="#" className="btn border-0 ms-2">
                    <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                  </a>
                </div>
              </div>
              {/*----- Vs Header end -----*/}


              {/*----- Vodasure dashboard container Start -----*/}
              <section className="vs-content_section">
                <h3 className="fs-2 mb-3 mx-auto">
                  Personal details
                </h3>

                <p className="text-secondary mb-0 fs-4">
                  Please confirm your personal details:
                </p>

                <ul className="vs-uplodfield  list-unstyled pt-5 rounded-4 mt-4 pb-0">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Asset category type" disabled value={userDetail?.first_name} />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-secondary">
                        Full names (As per ID document)
                      </label>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Make" disabled value={userDetail?.last_name} />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-secondary">
                        Surname (As per ID document)
                      </label>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-0 pb-0">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Modal"
                        disabled value={idNumber}
                      />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-secondary">
                        ID no.
                      </label>
                    </div>
                  </li>
                </ul>
                <div className="form-floating pb-0 mt-5 px-0">
                  <p className=" fs-4 text-secondary">
                    Where can we send your policy documents?
                  </p>
                  <div className="form-check form-check-inline ms-2 ps-0 personlainuts">
                    {/*--- Input ---*/}
                    <input className="form-check-input float-end ms-5" type="radio" id="idradio" name="proff" value="email"
                      checked={selectedOption === 'email'}
                      onChange={handleOptionChange}
                    />
                    {/*--- Label ---*/}
                    <label className="form-check-label h-auto text-secondary" htmlFor="idradio">
                      E-mail
                    </label>
                  </div>
                  {/*--- Input ---*/}
                  <div className="form-check form-check-inline ps-3 personlainuts">
                    <input className="form-check-input float-end ms-5" type="radio" id="passport" name="proff" value="sms"
                      checked={selectedOption === 'sms'}
                      onChange={handleOptionChange} />
                    {/*--- Label ---*/}
                    <label className="form-check-label h-auto text-secondary" htmlFor="passport">
                      SMS
                    </label>
                  </div>
                </div>
                {selectedOption == "email" ?
                  <ul className="vs-uplodfield  list-unstyled pt-5 mt-5 rounded-4 pb-0">
                    <li className="border-0 py-0">
                      {/*----- Floating Label input -----*/}
                      <div className="form-floating pb-0">
                        {/*--- Input ---*/}
                        <input type="text" className="form-control" id="adf6" placeholder="Search asset title" value={userDetail?.email} disabled />
                        {/*--- Label ---*/}
                        <label htmlFor="adf6" className="h-auto text-secondary">
                          Email address
                        </label>
                      </div>
                    </li>
                  </ul>
                  :
                  <ul className="vs-uplodfield  list-unstyled pt-5 mt-5 rounded-4 pb-0">
                    <li className="border-0 py-0">
                      {/*----- Floating Label input -----*/}
                      <div className="form-floating pb-0">
                        {/*--- Input ---*/}
                        <input type="text" className="form-control" id="adf8" placeholder="Search asset title" value={userDetail?.mobile} disabled />
                        {/*--- Label ---*/}
                        <label htmlFor="adf8" className="h-auto text-secondary">
                          Mobile number
                        </label>
                      </div>
                    </li>
                  </ul>
                }
              </section>

              {/*----- Vodasure dashboard container End -----*/}
              <footer className="vs-floting-footer vs-footer-gradient">
                <a href="javascript:void(0)" onClick={() => navigate(-1)} data-bs-dismiss="offcanvas" className="btn btn-outline-secondary rounded-pill px-5 py-3">
                  Cancel
                </a>

                <button onClick={handleSendDoc} className="btn btn-primary rounded-pill px-5 py-3">
                  Next
                </button>
              </footer>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default PersonalDetails