import { toast } from "material-react-toastify";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import exifr from "exifr";
import Cookies from "js-cookie";

const TakePhoto = () => {
  const accessToken = Cookies.get("accessToken");
  const [latLng, setlatlLng] = useState({});
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(showPosition);
  }, []);
  const navigate = useNavigate();
  const showPosition = (position) => {
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    setlatlLng({ latitude, longitude });
  };

  const AddAssets = async (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      // const latLng = await exifr.gps(files[0]);

      if (latLng == undefined) {
        toast(
          <>
            <div className="activityalert">
              <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                <p className="m-0">
                  <img
                    src="./assets/Newimg/redclose.png"
                    className="bluecheckimg"
                    alt
                  />
                  This image does not contain latitude and longitude
                </p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </>
        );
      } else {
        // setAssetLatLng({
        //   lat: latLng?.latitude,
        //   lng: latLng?.longitude,
        // })
        setTimeout(() => {
          navigate("/check-asset", {
            state: {
              imgPreview: URL.createObjectURL(e.target.files[0]),
              img: e.target.files[0],
              assetLatLngAdd: {
                lat: latLng?.latitude,
                lng: latLng?.longitude,
              },
            },
          });
        }, 1000);
      }
    }
  };

  return (
    <>
      {/*----- Main Container Take a photo Start -----*/}
      <div className="vs-main-container">
        <div className="responsive-row">
          <div className="vs-main-contentContainer vs-takeaphoto position-relative">
            {/*----- Vs Header Start -----*/}
            <div className="vs-header Withbtngroup pb-1">
              {/*------ Header Mid Heading (Left Part) ------*/}
              <Link to={`/${accessToken}`}>
                <img src="./assets/images/icon/BackIcon.svg" />
              </Link>

              {/*------ Header Mid Heading (Middle Part) ------*/}
              <h4 className="vs-header-title text-center d-none">
                Take a photo
              </h4>
            </div>
            {/*----- Vs Header end -----*/}

            {/*----- Section Content Start -----*/}
            <section className="vs-content_section py-0">
              {/*----- Take a photo Logo -----*/}
              <div className="text-center my-5">
                <img src="./assets/images/icon/TakeaPhoto.svg" alt />
              </div>

              {/*----- Section Random Intro Start -----*/}
              <div className="Section_random_intro text-center mb-5">
                <h2 className="text-gray  mb-3">
                  Tips for taking a photo of your asset
                  <br />
                  using a camera
                </h2>
              </div>

              {/*----- Section Random Intro End -----*/}
              <div className="tipslist">
                <div className="d-flex align-items-center mt-0 mb-4">
                  {/*-- Right Icon --*/}
                  <img src="./assets/images/icon/RedRightIcon.svg" />
                  {/*-- Intro Details --*/}
                  <p className="mb-0 ps-4 text-secondary">
                    Using your smartphone camera grid, center the asset in the
                    block shown above
                  </p>
                </div>
                <div className="d-flex align-items-center mb-4">
                  {/*-- Right Icon --*/}
                  <img src="./assets/images/icon/RedRightIcon.svg" />
                  {/*-- Intro Details --*/}
                  <p className="mb-0 ps-4 text-secondary">
                    Once all the required asset details are captured, click
                    ‘View’ or ‘Add another asset’.
                  </p>
                </div>
                <div className="d-flex align-items-center mb-4">
                  {/*-- Right Icon --*/}
                  <img src="./assets/images/icon/RedRightIcon.svg" />
                  {/*-- Intro Details --*/}
                  <p className="mb-0 ps-4 text-secondary">
                    Continue to add multiple assets and, click ‘View assets’
                    when you are complete.
                  </p>
                </div>
              </div>
            </section>
            {/*----- Section Content End -----*/}
            {/*----- Basic Footer Section Start -----*/}
            <footer className="vs-floting-footer vs-footer-gradient">
              <a
                href="javascript:void(0)"
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill px-5 py-3 me-5"
              >
                Cancel
              </a>

              <button className="btn btn-primary rounded-pill  w-75">
                <label
                  htmlFor="assetIMG"
                  className="w-100 h-100 d-block px-5 py-2"
                  style={{ cursor: "pointer" }}
                >
                  Open camera
                </label>
              </button>

              <input
                type="file"
                id="assetIMG"
                accept="image/*"
                capture="environment"
                className="d-none"
                onChange={(e) => AddAssets(e)}
              />
            </footer>
            {/*----- Basic Footer Section End -----*/}
          </div>
        </div>
      </div>
      {/*----- Main Container Take a photo End -----*/}
    </>
  );
};

export default TakePhoto;
