import React, { useState } from 'react'
import ExperienceRatingCanvas from './ExperienceRatingCanvas';

const YourCovered = () => {

  const [ratingCanvas, setRatingCanvas] = useState(false);
  const ratingCanvasClose = () => setRatingCanvas(false);

  return (
    <>
      {/*----- Main Container For Debit Order Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-debitorder position-relative px-4">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-12 text-center">
                <h4 className="vs-header-title">VodaSure | Locker</h4>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5 pt-4">
            {/*----- Debit Order Image -----*/}
            <svg width={50} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.9" filter="url(#filter0_i_303_23424)">
                <circle cx={26} cy={26} r={26} fill="#00B0CA" />
              </g>
              <path d="M16.9219 26.7031L21.5275 33.2461C21.8182 33.6803 22.3008 33.9473 22.8231 33.9629C23.3454 33.9786 23.843 33.7408 24.1591 33.3247L37.7812 19.4375" stroke="#F2F4F7" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
              <defs>
                <filter id="filter0_i_303_23424" x={0} y={0} width={52} height={56} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy={4} />
                  <feGaussianBlur stdDeviation={5} />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_303_23424" />
                </filter>
              </defs>
            </svg>

            <h3 className="fs-2 mb-4 mt-5 mx-auto text-white">
              You're Covered!
            </h3>
            <p className="text-dark-gray mx-auto">
              Your device is successfully covered.<br />You’ll get an SMS with a link to your documents.
            </p>
          </div>
          {/*----- Section Random Intro End -----*/}
          {/*----- Vodasure Buy Debit Order Content container Start -----*/}
          <section className="vs-content_section  pt-0">
            <div className="customerDetails d-flex justify-content-between flex-wrap px-4">
              <p className="text-dark-gray w-50 text-break">Policy number:</p>
              <p className="w-50 text-end text-break">12345677890</p>
              <p className="text-dark-gray w-50 text-break">Payment date:</p>
              <p className="w-50 text-end text-break">2021/02/03</p>
              <p className="text-dark-gray w-50 text-break">Insurance term:</p>
              <p className="w-50 text-end text-break">Monthly</p>
              <hr className="w-100" />
              <p className="text-dark-gray w-50 text-break">Policy holder:</p>
              <p className="w-50 text-end text-break">Chandra prakash pandey</p>
              <p className="text-dark-gray w-50 text-break">Cellphone number:</p>
              <p className="w-50 text-end text-break">123 456 4568</p>
              <hr className="w-100" />
              <p className="text-dark-gray w-50 text-break">Payment method:</p>
              <p className="w-50 text-end text-break">Debit order</p>
              <p className="text-dark-gray w-50 text-break">Premium :</p>
              <p className="w-50 text-end text-break">R270.00</p>
            </div>
          </section>
          {/*----- Vodasure Buy Debit Order Content container End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
              <a href="javascript:void(0)" onClick={() => setRatingCanvas(true)} className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-100 ms-3">
                Done
              </a>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For Debit Order End -----*/}
      {/* Rating Canvas */}

      <ExperienceRatingCanvas
        ratingCanvasClose={ratingCanvasClose}
        ratingCanvas={ratingCanvas}
      />
    </>
  )
}

export default YourCovered