import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
export const mainUrl = "https://vciaa.vasholdings.com/api/v1/";
// const mainUrl = "http://191.101.229.162:47000/api/v1/";

const appUrl = "";

export const APPURL = () => {
  return appUrl;
};

export const PostData = (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    "X-localization": "en",
  };
  let request = encryptRequestData(JSON.stringify(data));
  return axios
    .post(
      mainUrl + url,
      { payload: request },
      {
        headers: headers,
      }
    )
    .then((response) => {
      let responseData = decryptResponseData(response.data, url);
      console.log("ress111>>>>>>>",responseData);
      return responseData;
    })
    .catch((error) => {
      let errors = error.response;
      let errorStatus = {
        ...errors,
        data: JSON.parse(decryptResponseData(errors.data)),
      };
      return errorStatus;
    });
};

export const GetData = (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "123",
  };

  let request = encryptRequestData(JSON.stringify(data));
  return axios
    .get(
      mainUrl + url,
      { payload: request },
      {
        headers: headers,
      }
    )
    .then((resposne) => {
      let responseData = decryptResponseData(resposne.data, url);
      console.log("ress222>>>>>>>",responseData);
      return responseData;
    })
    .catch((error) => {
      let errors = error.response;
      let errorStatus = {
        ...errors,
        data: JSON.parse(decryptResponseData(errors.data)),
      };
      return errorStatus;
    });
};

export const PostDataWithToken = (url, data) => {
  let tokens = "";
  if (Cookies.get("token")) {
    tokens = Cookies.get("token");
  }
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + tokens,
  };
  let request = encryptRequestData(JSON.stringify(data));
  return axios
    .post(
      mainUrl + url,
      { payload: request },
      {
        headers: headers,
      }
    )
    .then((response) => {
      let responseData = decryptResponseData(response.data, url);
      return responseData;
    })
    .catch((error) => {
      let errors = error.response;
      let errorStatus = {
        ...errors,
        data: JSON.parse(decryptResponseData(errors.data)),
      };
      return errorStatus;
    });
};

export const PostImageDataWithToken = (url, data, isFormData = false) => {
  let tokens = "";
  if (Cookies.get("token")) {
    tokens = Cookies.get("token");
  }
  let headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + tokens,
  };
  let request = encryptRequestData(JSON.stringify(data));
  // if (isFormData) {
  //   console.log("data=====----", data);
  //   const formEntrie = Object.fromEntries(data);
  //   console.log("formEntrie", formEntrie);
  //   request = encryptRequestData(JSON.stringify(formEntrie));
  // } else {
  //   request = encryptRequestData(JSON.stringify(data));
  // }
  console.log("reqqq", request);
  return axios
    .post(mainUrl + url, { payload: request }, { headers: headers })
    .then((response) => {
      let responseData = decryptResponseData(response.data.data, url);
      return responseData;
    })
    .catch((error) => {
      let errors = error.response;
      let errorStatus = {
        ...errors,
        data: JSON.parse(decryptResponseData(errors.data)),
      };
      return errorStatus;
    });
};

export const GetDataWithToken = (url) => {
  let tokens = "";
  if (Cookies.get("token")) {
    tokens = Cookies.get("token");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      "ngrok-skip-browser-warning": "123",
    },
    params: {},
  };

  return axios
    .get(mainUrl + url, config)
    .then((resposne) => {
      let responseData = decryptResponseData(resposne.data, url);
      // console.log("resssss", resposne);
      return responseData;
    })
    .catch((error) => {
      let errors = error.response;
      let errorStatus = {
        ...errors,
        data: JSON.parse(decryptResponseData(errors.data)),
      };

      return errorStatus;
    });
};

export const DeleteDataWithToken = (url) => {
  let tokens = "";
  if (Cookies.get("token")) {
    tokens = Cookies.get("token");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      "ngrok-skip-browser-warning": "123",
    },
    params: {},
  };
  return axios
    .delete(mainUrl + url, config)
    .then((resposne) => {
      // console.log("resssss", resposne);
      let responseData = decryptResponseData(resposne.data, url);
      return responseData;
    })
    .catch((error) => {
      let errors = error.response;
      let errorStatus = {
        ...errors,
        data: JSON.parse(decryptResponseData(errors.data)),
      };
      return errorStatus;
    });
};

export function PutDataWithToken(url, data) {
  // body..
  //
  let tokens = "";
  if (Cookies.get("token")) {
    tokens = Cookies.get("token");
  }
  var headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + tokens,
  };
  let request = encryptRequestData(JSON.stringify(data));
  return axios
    .put(
      mainUrl + url,
      { payload: request },
      {
        headers: headers,
      }
    )
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      let responseData = decryptResponseData(response.data, url);
      return responseData;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errors = error.response;
      let errorStatus = {
        ...errors,
        data: JSON.parse(decryptResponseData(errors.data)),
      };

      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export const encryptRequestData = (data) => {
  var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_LENGTH);

  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: iv,
  }).toString();
  return ciphertext;
};

export const decryptResponseData = (data, url) => {
  var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_LENGTH);

  var decrypted = CryptoJS.AES.decrypt(data, key, {
    iv: iv,
  });

  let val = decrypted.toString(CryptoJS.enc.Utf8);

  var object = JSON.parse(val);

  return object;
};
