import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import RequestQuoteModal from './RequestQuoteModal';

const QuoteList = () => {

  const [showModals, setShowModals] = useState(false);

  const [reqQuoteCanvas, setReqQuoteCanvas] = useState(false);
  const reqQuoteCanvasShow = () => setReqQuoteCanvas(true);
  const reqQuoteCanvasClose = () => setReqQuoteCanvas(false);

  return (
    <>
      {/*----- Main Container For Quote Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-quotes position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-2 text-start">
                <Link to="/dashboard">
                  <img src="./assets/images/icon/BackIcon.svg" />
                </Link>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Quotes</h4>
              </div>

              <div className="col-2 text-end">
                <a className="d-block" onClick={reqQuoteCanvasShow} href="javascript:void(0)">
                  <img src="./assets/images/icon/Add_Button.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-4">
            <h3 className="fs-2 mb-3  pb-3 mx-auto text-white">My quotes</h3>
            {/*----- Quote Tab Content Navigation -----*/}

            <nav className="nav nav-tabs">
              {/*----- Navigation button 1 -----*/}
              <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#All_tab" type="button">
                All
              </button>
              {/*----- Navigation button 2 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Phone_tab" type="button">
                Phone
              </button>
              {/*----- Navigation button 3 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Tablet_tab" type="button">
                Tablet
              </button>
              {/*----- Navigation button 4 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#All_tab2" type="button">
                All
              </button>
              {/*----- Navigation button 5 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Phone_tab2" type="button">
                Phone
              </button>
              {/*----- Navigation button 6 -----*/}
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Tablet_tab2" type="button">
                Tablet
              </button>
            </nav>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Section Content Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Tab Content -----*/}
            <div className="tab-content" id="nav-tabContent">
              {/*----- Tab 1 -----*/}
              <div className="tab-pane fade show active" id="All_tab">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="javascript:void(0)" onClick={() => setShowModals(true)} className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*----- Tab 2 -----*/}
              <div className="tab-pane fade" id="Phone_tab">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*----- Tab 3 -----*/}
              <div className="tab-pane fade" id="Tablet_tab">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*----- Tab 4 -----*/}
              <div className="tab-pane fade" id="All_tab2">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*----- Tab 5 -----*/}
              <div className="tab-pane fade" id="Phone_tab2">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*----- Tab 6 -----*/}
              <div className="tab-pane fade" id="Tablet_tab2">
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge insured">Insured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge uninsured">Uninsured</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
                {/*----- Info Card  -----*/}
                <div className="card vs-InfoCardStatus">
                  {/*---- status Badge -----*/}
                  <span className="badge bg-danger">Lapsed</span>
                  {/*----- Info Card Body -----*/}
                  <div className="card-body pt-5">
                    <div className="d-flex justify-content-between mb-2 pt-3">
                      {/*----- Info time -----*/}
                      <p className="vs-Info_dateTime fs-4">
                        <span className="me-2">22 March 2022</span>|<span className="ms-2">10:30</span>
                      </p>
                      {/*----- Info Amount -----*/}
                      <p className="vs-Info-price fs-4">R 12 999.00</p>
                    </div>
                    {/*----- Info title -----*/}
                    <h2 className="vs-card-title text-white mb-2">
                      iPhone 12 Mini 64GB navy
                    </h2>
                    <p className="fs-4">IMEI: 422929281819661</p>
                    {/*----- Info Action buttons -----*/}
                    <div className="vs-Info-actionbtn d-flex justify-content-between mt-4">
                      <a href="#" className="btn btn-outline-secondary rounded-pill px-5 fs-3 py-2 w-50 me-3">
                        Delete quote
                      </a>
                      <button type="button" data-bs-toggle="offcanvas" data-bs-target="#viewquote" className="btn btn-secondary rounded-pill px-5 fs-3 py-2 w-50">
                        View quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*----- Section Content End -----*/}

          {/*----- Basic Footer Section Start -----*/}
          <footer className='vs-floting-footer vs-footer-gradient'>
            <a className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-auto" onClick={reqQuoteCanvasShow} href="javascript:void(0)">
              Get a quote
              <img src="./assets/images/icon/AddIcon.svg" className="align-middle ms-2" />
            </a>

            {/*----- Activity Alert -----*/}
            <div className="activityalert">
              <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                <p className="m-0">Your quote has been successfully added.</p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
            </div>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For Quote End -----*/}

      {/*----- All Modal and Offcanvas Code Start  -----*/}

      {/*----- All Modal and Offcanvas Code Start  -----*/}
      <RequestQuoteModal
        reqQuoteCanvas={reqQuoteCanvas}
        reqQuoteCanvasClose={reqQuoteCanvasClose}
      />
      {/*----- View Quote offcanvas -----*/}
      <div className="offcanvas offcanvas-bottom p-4 cardDetailCanvas" data-bs-backdrop="static" tabIndex={-1} id="viewquote">
        {/*----- Offcanvas Header -----*/}
        <div className="offcanvas-header px-sm-4 px-0">
          {/*----- quote detials tab -----*/}
          <ul className="nav nav-pills mb-3 mx-auto bg-secondary rounded-3" id="pills-tab" role="tablist">
            <li className="nav-item">
              <button className="nav-link active" data-bs-toggle="pill" data-bs-target="#Comprehensive" type="button">
                Comprehensive
              </button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="pill" data-bs-target="#Accidental" type="button">
                Accidental
              </button>
            </li>
          </ul>

          {/*----- offcanvas close button -----*/}
          <button type="button" className="btn-close ms-auto" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body">
          <div className="tab-content">
            <div className="tab-pane fade show active" id="Comprehensive" tabIndex={0}>
              {/*----- Quote type image -----*/}
              <div className="quotetypeimg text-center mb-5">
                <img src="./assets/images/icon/Comprihensive.svg" alt="" style={{ width: '80px' }} />
              </div>
              {/*----- Section Random Intro Start -----*/}
              <div className="Section_random_intro text-center mb-5">
                <h3 className="text-gray fs-2 mb-3 w-75 mx-auto">Comprehensive</h3>
                <p className="text-gray fs-3 mx-auto">Cover against loss, damage and theft.</p>
                <small className="text-gray fs-4">Global coverage. Cancel anytime.</small>
              </div>
              {/*----- Section Random Intro End -----*/}
              <div className="card vs-InfoCardStatus bg-secondary rounded-4">
                {/*----- Info Card Body -----*/}
                <div className="card-body">
                  <div className="pt-3">
                    <p className="fs-4 d-inline-block">Locker value</p>
                    <img src="./assets/images/icon/InfoUtilityIcon.svg" className="ms-2" style={{ width: '13px' }} alt="" />
                  </div>
                  {/*----- Info title -----*/}
                  <h1 className="vs-card-title mb-2">
                    R <span className="text-white">12999.00</span>
                  </h1>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div className="fs-3 py-2 me-4">
                      <p className="fs-4 mb-0">Premium: <span>R250.00</span></p>
                      <p className="fs-4 mb-0">Excess: <span>10%</span></p>
                    </div>
                    <a href="#" className="btn btn-outline-secondary rounded-pill px-sm-5 px-3 fs-4 py-3  w-auto">
                      Add to locker
                      <img src="./assets/images/icon/ExpandBadge.svg" className='cardBagGe' alt="" />
                    </a>
                  </div>
                </div>
              </div>
              {/*----- Quote Main Title ----*/}
              <ul className="vs-getaquote list-unstyled mb-1">
                <li>
                  <div className="row justify-content-between align-items-center">
                    {/*-- Locaiton Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/Device.svg" className='w-100' />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-7">
                      <p className="w-100 text-truncate mb-0">
                        iPhone 12 mini 64 GB navy
                      </p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-2 text-end">
                      <a href="#">
                        <img src="./assets/images/chatbot/icon/EditDateIcon.svg" />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="vs-getaquote list-unstyled accordion accordion-flush" id="QuoteAccordion">
                <li className="accordion-item">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#Whatwecover">
                    {/*-- what we cover Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/WhatWeCover.svg" className='w-100' />
                    </div>
                    {/*-- what we cover Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        What we cover
                      </p>
                    </div>
                  </button>
                  <div id="Whatwecover" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ul>
                        <li className="position-relative pt-5 mt-3">
                          Loss We'll replace it.
                        </li>
                        <li>
                          Theft: We’ll replace it.
                        </li>
                        <li>
                          Cracked screen: We’ll repair it.
                        </li>
                        <li>
                          Camera damage: We’ll repair it.
                        </li>
                        <li>
                          Touchscreen damage: We’ll repair it.
                        </li>
                        <li>
                          Water damage: We'll repair or replace it.
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="accordion-item">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#claimstep">
                    {/*-- Claim Step Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/EasyClaimStep.svg" className='w-100' />
                    </div>
                    {/*-- Claim Step Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        Claim in three easy steps
                      </p>
                    </div>
                  </button>
                  <div id="claimstep" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ol>
                        <li className="position-relative pt-5 mt-3">
                          <p className="mb-2">Blacklist your device</p>
                          <small className="text-dark-gray">If your device is lost or stolen dial 082
                            135.</small>
                        </li>
                        <li>
                          <p className="mb-2">Log your claim</p>
                          <small className="text-dark-gray">
                            To claim – Call us at 082 1952, email us at
                            servicing.deviceinsurance@vodacom.co.za,
                            or visit your nearest store.
                          </small>
                        </li>
                        <li>
                          <p className="mb-2">Collect your device</p>
                          <small className="text-dark-gray">
                            We process all valid claims within 48 hours.
                          </small>
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li className="accordion-item pb-0">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#faq">
                    {/*-- Claim Step Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/FAqOffcanvac.svg" />
                    </div>
                    {/*-- Claim Step Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        Frequently asked questions
                      </p>
                    </div>
                  </button>
                  <div id="faq" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ul>
                        <li className="position-relative pt-5 mt-3">
                          <p className="mb-2">When will my cover start?</p>
                          <small className="text-dark-gray">
                            Your cover starts immediately. If you choose to add insurance when
                            upgrading or buying a new device instore, you will be
                            covered as soon as your purchase is complete.
                          </small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
              {/*----- Basic Footer Section Start -----*/}
              <footer className='vs-floting-footer  vs-footer-gradient'>
                <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
                  <a href="javascript:void(0)" data-bs-dismiss="offcanvas" className="btn btn-outline-secondary rounded-pill fs-3 py-3 w-50 me-3">
                    Cancel
                  </a>
                  <a href="#" className="btn btn-primary rounded-pill px-lg-5 fs-3 py-3 w-75 ms-3">
                    Insure for R170 pm
                  </a>
                </form>
              </footer>
              {/*----- Basic Footer Section End -----*/}
            </div>

            <div className="tab-pane fade" id="Accidental" tabIndex={0}>
              {/*----- Quote type image -----*/}
              <div className="quotetypeimg text-center mb-5">
                <img src="./assets/images/icon/Accidental.svg" alt="" style={{ width: '80px' }} />
              </div>
              {/*----- Section Random Intro Start -----*/}
              <div className="Section_random_intro text-center mb-5">
                <h3 className="text-gray fs-2 mb-3 w-75 mx-auto">Comprehensive</h3>
                <p className="text-gray fs-3 mx-auto">Cover against loss, damage and theft.</p>
                <small className="text-gray fs-4">Global coverage. Cancel anytime.</small>
              </div>
              {/*----- Section Random Intro End -----*/}
              <div className="card vs-InfoCardStatus bg-secondary rounded-4">
                {/*----- Info Card Body -----*/}
                <div className="card-body">
                  <div className="pt-3">
                    <p className="fs-4 d-inline-block">Locker value</p>
                    <img src="./assets/images/icon/InfoUtilityIcon.svg" className="ms-2" style={{ width: '13px' }} alt="" />
                  </div>
                  {/*----- Info title -----*/}
                  <h1 className="vs-card-title mb-2">
                    R <span className="text-white">12999.00</span>
                  </h1>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div className="fs-3 py-2 me-4">
                      <p className="fs-4 mb-0">Premium: <span>R250.00</span></p>
                      <p className="fs-4 mb-0">Excess: <span>10%</span></p>
                    </div>
                    <a href="#" className="btn btn-outline-secondary rounded-pill px-sm-5 px-3 fs-4 py-3  w-auto">
                      Add to locker
                      <img src="./assets/images/icon/ExpandBadge.svg" className='cardBagGe' alt="" />
                    </a>
                  </div>
                </div>
              </div>
              {/*----- Quote Main Title ----*/}
              <ul className="vs-getaquote list-unstyled mb-1">
                <li>
                  <div className="row justify-content-between align-items-center">
                    {/*-- Locaiton Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/Device.svg" className='w-100' />
                    </div>
                    {/*-- Domicile Details --*/}
                    <div className="col-7">
                      <p className="w-100 text-truncate mb-0">
                        iPhone 12 mini 64 GB navy
                      </p>
                    </div>
                    {/*-- Go Icon --*/}
                    <div className="col-2 text-end">
                      <a href="#">
                        <img src="./assets/images/chatbot/icon/EditDateIcon.svg" />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="vs-getaquote list-unstyled accordion accordion-flush" id="QuoteAccordion">
                <li className="accordion-item">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#Whatwecover">
                    {/*-- what we cover Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/WhatWeCover.svg" className='w-100' />
                    </div>
                    {/*-- what we cover Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        What we cover
                      </p>
                    </div>
                  </button>
                  <div id="Whatwecover" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ul>
                        <li className="position-relative pt-5 mt-3">
                          Loss We'll replace it.
                        </li>
                        <li>
                          Theft: We’ll replace it.
                        </li>
                        <li>
                          Cracked screen: We’ll repair it.
                        </li>
                        <li>
                          Camera damage: We’ll repair it.
                        </li>
                        <li>
                          Touchscreen damage: We’ll repair it.
                        </li>
                        <li>
                          Water damage: We'll repair or replace it.
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="accordion-item">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#claimstep">
                    {/*-- Claim Step Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/EasyClaimStep.svg" className='w-100' />
                    </div>
                    {/*-- Claim Step Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        Claim in three easy steps
                      </p>
                    </div>
                  </button>
                  <div id="claimstep" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ol>
                        <li className="position-relative pt-5 mt-3">
                          <p className="mb-2">Blacklist your device</p>
                          <small className="text-dark-gray">If your device is lost or stolen dial 082
                            135.</small>
                        </li>
                        <li>
                          <p className="mb-2">Log your claim</p>
                          <small className=" text-dark-gray">
                            To claim – Call us at 082 1952, email us at
                            servicing.deviceinsurance@vodacom.co.za,
                            or visit your nearest store.
                          </small>
                        </li>
                        <li>
                          <p className="mb-2">Collect your device</p>
                          <small className="text-dark-gray">
                            We process all valid claims within 48 hours.
                          </small>
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li className="accordion-item pb-0">
                  <button className="accordion-button collapsed row justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#faq">
                    {/*-- Claim Step Icon --*/}
                    <div className="col-2">
                      <img src="./assets/images/icon/FAqOffcanvac.svg" />
                    </div>
                    {/*-- Claim Step Title --*/}
                    <div className="col-9">
                      <p className="mb-0">
                        Frequently asked questions
                      </p>
                    </div>
                  </button>
                  <div id="faq" className="accordion-collapse collapse" data-bs-parent="#QuoteAccordion">
                    <div className="accordion-body">
                      <ul>
                        <li className="position-relative pt-5 mt-3">
                          <p className="mb-2">When will my cover start?</p>
                          <small className="text-dark-gray">
                            Your cover starts immediately. If you choose to add insurance when
                            upgrading or buying a new device instore, you will be
                            covered as soon as your purchase is complete.
                          </small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
              {/*----- Basic Footer Section Start -----*/}
              <footer className='vs-floting-footer  vs-footer-gradient'>
                <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center">
                  <a href="javascript:void(0)" data-bs-dismiss="offcanvas" className="btn btn-outline-secondary rounded-pill fs-3 py-3 w-50 me-3">
                    Cancel
                  </a>
                  <a href="#" className="btn btn-primary rounded-pill px-lg-5 fs-3 py-3 w-75 ms-3">
                    Insure for R170 pm
                  </a>
                </form>
              </footer>
              {/*----- Basic Footer Section End -----*/}
            </div>
          </div>
        </div>
      </div>

      {/*----- Delete Quote Modal -----*/}
      <Modal
        show={showModals}
        onHide={() => setShowModals(false)}
        backdrop="static"
        keyboard={false}
        className="modal fade"
        centered
      >
        {/*----- Delete Quote Modal -----*/}
        <div className="modal-body text-center p-5">
          <img src="./assets/images/icon/Yellow_Bulb.svg" alt="" className="my-4" />
          <p className="fs-3 mb-1">
            Are you sure want to<br />delete this?
          </p>
          <Link to="/cancel-quote" className="d-block my-5" onClick={() => setShowModals(false)}>
            Yes, delete
            <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt="" />
          </Link>
          <a href="javascript:void(0)" className="d-block" onClick={() => setShowModals(false)}>
            No, cancel
            <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt="" />
          </a>
        </div>
      </Modal>
    </>
  )
}

export default QuoteList