import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const QuoteFeedback = () => {

  const navigate = useNavigate();

  return (
    <>
      {/*----- Main Container For Information Submit Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-feedback vs-submission position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-9 text-end">
                <h4 className="vs-header-title">
                  Successfully submitted
                </h4>
              </div>

              <div className="col-2 text-end">
                <Link to="/quote-list" className="d-block">
                  <img src="./assets/images/icon/Closeicon.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Content Start -----*/}
          <section className="vs-content_section text-center vs-empty px-4">
            {/*----- Section Random Intro Start -----*/}
            <div className="Section_random_intro text-center mb-5  pb-5">
              <h3 className="fs-1 mb-5 mx-auto">
                Thanks for your feedback
              </h3>
              <p className="text-dark-gray fs-3 w-75 mx-auto">
                Your feedback will help to<br />improve our mini app.
              </p>
            </div>
          </section>
          {/*----- Section Content End -----*/}
          {/*----- Basic Footer Section Start -----*/}
          <footer className="vs-floting-footer vs-footer-gradient px-4">
            {/*----- Add Domicile Button -----*/}
            <Link to="/quote-list" className="btn btn-primary rounded-pill px-5 fs-3 py-3 align-self-center w-100">
              Done
            </Link>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For Information Submit End -----*/}
    </>
  )
}

export default QuoteFeedback