import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import ObjectLoader from '../../common/ObjectLoader';
import { Link } from 'react-router-dom';

const ObjectSelectCanvas = (props) => {
  return (
    <>
      <Offcanvas show={props.selectObjectCanvas} onHide={props.selectObjectCanvasClose} scroll={false} placement='bottom' id="viewaddimgprocess" className="p-4  newassetflow" >
        {/*----- Offcanvas Header -----*/}
        <div className="offcanvas-header px-4">
          {/*----- offcanvas close button -----*/}
          <button type="button" className="btn-close ms-auto" onClick={props.selectObjectCanvasClose} data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        {/*----- Offcanvas Body -----*/}
        <div className="offcanvas-body recoimgoffcanbody">
          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mb-5">
            <h3 className="text-gray fs-2 mb-5 w-75 mx-auto">
              Please confirm this asset
            </h3>

            <img src={props.imgPreviewURL} alt className="vs-Capture recoimg" />
          </div>
          {props.loader ?
            <ObjectLoader />
            :
            <>
              {/*----- Vs Default Header End -----*/}
              <ul className="vs-getaquote list-unstyled">
                <>
                  {props.selectObject && props.selectObject?.length > 0 && props.selectObject?.map((obj, index) => (
                    <li className="" key={index}>
                      {/* <a href='javascript:void(0)' className="row justify-content-between align-items-center"
                        onClick={() => { props.selectObjectCanvasClose(true); props.setConfirmAssetCanvas(true); props.setSelectAssets(obj.name) }}
                      >
                        <div className="col-9">
                          <p className="w-100 text-truncate mb-0">
                            {obj?.name}
                          </p>
                        </div>
                        <div className="col-1 text-end">
                          <img src="./assets/images/icon/GoIcon.svg" />
                        </div>
                      </a> */}
                      <Link to='/add-asset' className="row justify-content-between align-items-center"
                        state={{ assetType: obj.name, assetsImage: props?.assetsImage }}
                      >
                        <div className="col-9">
                          <p className="w-100 text-truncate mb-0">
                            {obj?.name}
                          </p>
                        </div>
                        <div className="col-1 text-end">
                          <img src="./assets/images/icon/GoIcon.svg" />
                        </div>
                      </Link>
                    </li>
                  ))}
                  <li className="">
                    {/* <a href='javascript:void(0)' className="row justify-content-between align-items-center"
                      onClick={() => { props.selectObjectCanvasClose(true); props.setConfirmAssetCanvas(true); props.setSelectAssets("Others") }}
                    >
                      <div className="col-9">
                        <p className="w-100 text-truncate mb-0">
                          Others
                        </p>
                      </div>
                      <div className="col-1 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </a> */}
                    <Link to='/add-asset' className="row justify-content-between align-items-center"
                      state={{ assetType: "Others" }}
                    >
                      <div className="col-9">
                        <p className="w-100 text-truncate mb-0">
                          Others
                        </p>
                      </div>
                      <div className="col-1 text-end">
                        <img src="./assets/images/icon/GoIcon.svg" />
                      </div>
                    </Link>
                  </li>
                </>
              </ul>
            </>
          }
        </div>
      </Offcanvas>
    </>
  )
}

export default ObjectSelectCanvas