import React, { useEffect, useState } from 'react'
import { Modal, Offcanvas, ProgressBar } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import StepsCount from './StepsCount';
import { GetDataWithToken, PostDataWithToken, PostImageDataWithToken, decryptResponseData, encryptRequestData, mainUrl } from '../../ApiHelper/ApiHelper';
import Cookies from 'js-cookie';
import axios from 'axios';
import { encode } from 'base-64';
import UnderwritingQuestions from './UnderwritingQuestions';
import QuotationCanvas from './QuotationCanvas';
import UnderwrittingQuestionaries from './UnderwrittingQuestionaries';
import Loader from '../../common/Loader';
import { useForm } from 'react-hook-form';
import { toast } from 'material-react-toastify';

const UnderWritingSteps = () => {
  const vodasureQAURL = "https://apix.vodacom.co.za/";
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const location = useLocation();
  const selectedID = location?.state?.selectedIds;
  const selectedCatalogIds = location?.state?.selectedCatalogIds;
  // console.log("selectedCatalogIds------------------", selectedCatalogIds);

  const [activeTab, setActiveTab] = useState(0);
  const [addAssetCanvas, setAddAssetCanvas] = useState(false);
  const addAssetCanvasClose = () => setAddAssetCanvas(false);
  const [quotationCanvas, setQuotationCanvas] = useState(false);
  const quotationCanvasClose = () => setQuotationCanvas(false);
  const [step, setStep] = useState(1);
  const [oAuthToken, setOAuthToken] = useState('');
  const [quoteId, setQuoteId] = useState(null);

  const [myAssets, setMyAssets] = useState([]);
  const [assetsPrice, setAssetsPrice] = useState([]);
  const [allPrice, setAllPrice] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedAssetsList, setSelectedAssetsList] = useState([]);
  const [checkBox, setCheckBox] = useState(false);
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [inventryValue, setInventryValue] = useState(0);
  const [underwritingQuestions, setUnderwritingQuestions] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [underwritingQA, setUnderwritingQA] = useState([]);
  const [stepCount, setStepCount] = useState(null);
  const [stepPercentage, setStepPercentage] = useState((step / stepCount) * 100);
  const [campaignSchema, setCampaignSchema] = useState({});
  const [quoteLineItems, setQuoteLineItems] = useState([]);
  // console.log("inventryValue", inventryValue);
  const inventryValueId = "inventry-value";
  const sourceTransactionId = "5dfcb852-67b3-4213-a306-433684d14930"

  useEffect(() => {
    if (loader) {

      const username = 'IA4Td4u0Kx844JaebaN2hnCx94YGVSCs';
      const password = 'ZIGFUL3J8GWUreKu';
      const basicAuth = `Basic ${encode(`${username}:${password}`)}`;

      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: basicAuth,
      };

      let clientData = new URLSearchParams();
      clientData.append("grant_type", "client_credentials")
      axios.post(vodasureQAURL + "oauth-framework/generateaccesstoken", clientData, { headers: headers })
        .then((response) => {
          setOAuthToken(response?.data?.access_token);
          localStorage.setItem('oAuthToken', response?.data?.access_token);
          let headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + response?.data?.access_token,
          };
          let dataQA = {
            sourceTransactionId: sourceTransactionId,
            campaignId: 251,
            assetCatalogueIDs: selectedCatalogIds
          }
          const encrptData = encryptRequestData(JSON.stringify(dataQA));
          console.log("encrptData", typeof (encrptData))
          axios.post(`${mainUrl}user/underwriting-questions`, JSON.stringify({ payload: encrptData }), { headers: headers })
            .then((response) => {
              console.log("resssss----", decryptResponseData(response.data));
              response = JSON.parse(decryptResponseData(response.data));
              setUnderwritingQA(response?.data?.backendResponse?.riskFactors?.data);
              setCampaignSchema(response?.data?.backendResponse?.campaignSchema);
              setStepCount(response?.data?.backendResponse?.riskFactors?.data?.length);
              if (response?.data?.backendResponse?.riskFactors?.data?.length == 0) {
                setAddAssetCanvas(true);
              };
              setLoader(false);
            })
        })
    }
  }, [step]);

  useEffect(() => {
    if (oAuthToken) {
      GetDataWithToken('asset/get-asset-list', '')
        .then((response) => {
          if (response.status === true) {
            // setLoader(true);
            const selectedAssets = response.data.filter(item => selectedID.includes(item.id));
            setMyAssets(selectedAssets);
            handleSelectAssets(selectedAssets);
            console.log("selectedAssets", selectedAssets)

            setTimeout(() => {
              const newArray = selectedAssets.map((item) => ({
                id: item.id,
                serialNumber: item.serialNumber ? item.serialNumber : item.imeiNumber1,
                model: item.model,
                cost: item.cost,
                category: item.category,
                make: item.make,
                description: item.description,
                deviceAge: item.deviceAge,
                assetCatalogue: item.catalog,
                assetCatalogueId: item.catalog_id,
                policyType: item.policy_type,
              }));

              // console.log("newArray", newArray);

              let headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + oAuthToken,
              };
              let dataQA = {
                sourceTransactionId: "e4c161b7-d881-4066-a016-e72e51f72537",
                id: 0,
                isEstimate: true,
                campaignId: 251,
                campaignSchema: JSON.stringify(campaignSchema),
                assets: newArray,
                assetCatalogueSchema: "{}",
                createdBy: 3205,
                lastModifiedBy: 3205
              }
              const encrptData = encryptRequestData(JSON.stringify(dataQA));
              setSelectedAssetsList(newArray);

              // console.log("dataQA", dataQA)
              axios.post(`${mainUrl}quotation/quote`, JSON.stringify({ payload: encrptData }), { headers: headers })
                .then((response) => {
                  console.log("resssssponseeeeeee----", decryptResponseData(response.data));
                  response = decryptResponseData(response.data);
                  // setPerformQouteId(response?.data?.data?.backendResponse?.id);
                  setInventryValue(response?.data?.backendResponse?.finalPremium);
                  setQuoteLineItems(response?.data?.backendResponse?.quoteLineItems);
                  setQuoteId(response?.data?.backendResponse?.id);
                  setTimeout(() => {
                    const brandDivElement = document.getElementById(inventryValueId);
                    brandDivElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }, 500);
                })
            }, 2000);

            if (selectedAssets?.length > 0) {
              const uninsuredAssets = selectedAssets.filter(item => item.status == "uninsured" && item.catalog_id > 0);
              console.log("uninsuredAssets", uninsuredAssets);
              const arr = uninsuredAssets.map(asset => ({
                id: asset.id,
                cost: asset.cost,
                serialNumber: asset.serialNumber ? asset.serialNumber : asset.imeiNumber1,
                model: asset.model,
                category: asset.category,
                make: asset.make,
                description: asset.description,
                deviceAge: asset.deviceAge,
                assetCatalogue: asset.catalog,
                assetCatalogueId: asset.catalog_id,
                policyType: asset.policy_type,
              }));

              console.log("arrr", arr);

              setAssetsPrice(arr);
              const total_price = arr.reduce((total, asset) => total + asset.cost, 0);
              setAllPrice(total_price);
              setSelectedIds(arr.map(item => item.id));
            }
          }
        })
    }
  }, [oAuthToken, campaignSchema]);

  console.log("assetsPrice", assetsPrice);

  const accessToken = Cookies.get('accessToken');

  const handleSelectAsset = (assetArr) => {
    console.log("assetArr", assetArr)
    if (assetArr?.length > 0) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + oAuthToken,
      };
      const dataQA = {
        sourceTransactionId: "e4c161b7-d881-4066-a016-e72e51f72537",
        id: 0,
        isEstimate: true,
        campaignId: 251,
        campaignSchema: JSON.stringify(campaignSchema),
        assets: assetArr,
        assetCatalogueSchema: "{}",
        createdBy: 3205,
        lastModifiedBy: 3205
      };

      setSelectedAssetsList(assetArr);

      const encrptData = encryptRequestData(JSON.stringify(dataQA));

      axios.post(`${mainUrl}quotation/quote`, JSON.stringify({ payload: encrptData }), { headers: headers })
        .then((response) => {
          console.log("resssssponseeeeeee----", decryptResponseData(response.data));
          response = decryptResponseData(response.data);
          setLoader(false);
          setTimeout(() => {
            const brandDivElement = document.getElementById(inventryValueId);
            brandDivElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 500);
          setInventryValue(response?.data?.backendResponse?.finalPremium);
          setQuoteLineItems(response?.data?.backendResponse?.quoteLineItems);
          setQuoteId(response?.data?.backendResponse?.id);
        });
    } else {
      setLoader(false);
    }
  };

  const handleSelectAssets = (assets, cost, id) => {
    // alert();
    const assetArr = [...assetsPrice];
    const idIndex = selectedIds.indexOf(id);

    if (idIndex !== -1) {
      selectedIds.splice(idIndex, 1);
    } else {
      selectedIds.push(id);
    }

    let total_price = allPrice;
    let assetIndex = -1;

    if (assetArr?.length > 0) {
      assetIndex = assetArr.findIndex(e => e.id === id);
      if (assetIndex !== -1) {
        total_price -= assetArr[assetIndex].cost;
        assetArr.splice(assetIndex, 1);
      }
    }

    if (assetIndex === -1) {
      assetArr.push({
        id: assets.id,
        cost: cost,
        serialNumber: assets.serialNumber ? assets.serialNumber : assets.imeiNumber1,
        model: assets.model,
        category: assets.category,
        make: assets.make,
        description: assets.description,
        deviceAge: assets.deviceAge,
        assetCatalogue: assets.catalog,
        assetCatalogueId: assets.catalog_id,
        policyType: assets.policy_type,
      });
      total_price += cost;
    }

    setAssetsPrice(assetArr);
    setAllPrice(total_price);
    handleSelectAsset(assetArr);

  };

  const handleAcceptQoute = () => {
    setQuotationCanvas(false);
    setShow(true);
  };

  const handlePreviousQue = () => {
    setStep(step - 1);
    setStepPercentage(((step - 1) / stepCount) * 100);
    setActiveTab(activeTab - 1);
  }

  const roundedNumber = Math.round(inventryValue * 100) / 100;
  const parts = roundedNumber.toFixed(2).split(".");
  const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const formattedString = wholePart + "." + parts[1];
  // console.log("formattedString", formattedString);

  // console.log("first", sum.toLocaleString().replaceAll(",", " "))

  const handleCheckId = (data) => {
    setLoader(true);
    localStorage.setItem('idNumber', data.idNumber);
    let checkId = {
      sourceTransactionId: "e4c161b7-d881-4066-a016-e72e51f72537",
      origin: "VodasureIAA",
      workflow: "SMME New Business",
      idNumber: data.idNumber
    }
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oAuthToken,
    };
    const encrptData = encryptRequestData(JSON.stringify(checkId));
    axios.post(`${mainUrl}asset/checkValidateId`, JSON.stringify({ payload: encrptData }), { headers: headers })
      .then((response) => {
        console.log("resssss----", decryptResponseData(response.data));
        response = decryptResponseData(response.data);
        if (response.data.backendResponse.validIdNumber == true) {
          // console.log("response", response.data.data.backendResponse.validIdNumber)
          if (response.data.backendResponse.validIdNumber == true) {
            setQuotationCanvas(true);
            setLoader(false);
          }
        } else {
          setLoader(false);
          toast(
            <>
              <p className="m-0">
                ID number / Passport number is invalid.
              </p>
            </>
          );
        }
      })
  };

  const handleFocus = (id, inputId, isFocus) => {
    const inputElement = document.getElementById(id);
    const divElement = document.getElementById(inputId);
    if (inputElement) {
      if (isFocus) {
        setTimeout(() => {
          inputElement.classList.add("focuskeyboard");
          divElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
      } else {
        inputElement.classList.remove("focuskeyboard");
      }
    }
  };

  return (
    <>
      {loader ?
        <Loader />
        :
        <>
          {/*----- Main Container For Share your data Start -----*/}
          <div className="vs-main-container">
            <div className="responsive-row">
              <div className="vs-main-contentContainer vs-dashboard position-relative">
                {/*----- Vs Header Start -----*/}
                <div className="vs-header Withbtngroup">
                  {/*------ Header Mid Heading (Left Part) ------*/}
                  {step == 1 ?
                    <Link to={`/${accessToken}`}><img src="./assets/images/icon/BackIcon.svg" /></Link>
                    :
                    <a href="javascript:void(0)" onClick={() => handlePreviousQue()}><img src="./assets/images/icon/BackIcon.svg" /></a>
                  }

                  {/*------ Header Mid Heading (Middle Part) ------*/}
                  <h4 className="vs-header-title d-none">Underwriting</h4>

                  {/*------ Header Mid Heading (Right Part) ------*/}
                  <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                    <a href="#" className="btn border-0 me-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                    </a>

                    <a href="#" className="btn border-0 ms-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                    </a>
                  </div>
                </div>
                {/*----- Vs Header end -----*/}

                {/*----- Porgressbar For wizard form -----*/}
                <form className="slideform-form slideform-initialized">
                  <div className="slideform-footer">
                    <div className="slideform-progress-bar">
                      {/* {console.log("stepPercentage", stepPercentage)} */}
                      <ProgressBar variant="danger" now={stepPercentage} />
                    </div>
                  </div>
                </form>
                {/*----- Vodasure Share your data Content container Start -----*/}
                <section className="vs-content_section px-4 vs-acceptpolicy d-flex justify-content-between align-items-stretch flex-column">
                  {/*----- Add Share your data Form Start -----*/}
                  <div id="regForm" className="h-100">
                    <div className="h-100">
                      {/* <StepsCount step={step} setStepAnswer={setStepAnswer} /> */}
                      {/* <UnderwritingQuestions underwritingQA={underwritingQA} currentIndex={currentIndex} setStepAnswer={setStepAnswer} setDisableButton={setDisableButton} setUnderwritingQuestions={setUnderwritingQuestions} underwritingQuestions={underwritingQuestions} setCurrentIndex={setCurrentIndex} setStepPercentage={setStepPercentage} step={step} setStep={setStep} stepCount={stepCount} setAddAssetCanvas={setAddAssetCanvas} selectedOption={selectedOption} setSelectedOption={setSelectedOption} /> */}
                      <UnderwrittingQuestionaries underwritingQA={underwritingQA} step={step} stepCount={stepCount} setStepPercentage={setStepPercentage} setStep={setStep} setActiveTab={setActiveTab} activeTab={activeTab} setCheckBox={setCheckBox} checkBox={checkBox} setAddAssetCanvas={setAddAssetCanvas} setUnderwritingQuestions={setUnderwritingQuestions} underwritingQuestions={underwritingQuestions} />

                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          {/*----- Main Container For Share your data End -----*/}

          <Offcanvas show={addAssetCanvas} onHide={addAssetCanvasClose} scroll={false} placement='bottom' id="keyboardFocus" className=' p-4 show offcanvas offcanvas-bottom'>
            {/*----- Offcanvas Header -----*/}
            <div className="offcanvas-header px-5">
              <a href="javscript:void(0)" onClick={addAssetCanvasClose} data-bs-dismiss="offcanvas" className="btn-close ms-auto" />
            </div>
            {/*----- Offcanvas Body -----*/}
            {/* <div className="offcanvas-body px-0"> */}
            <form onSubmit={handleSubmit(handleCheckId)} className="offcanvas-body px-0">
              <p className="fs-3 text-center mt-5">
                Customer ID check
              </p>
              <p className="fs-3 mb-4 text-center mt-4 text-muted">
                To process this application, please provide
                <br />
                the following customer information to
                <br />
                complete a customer id check.
              </p>
              <div className="card border-0 vs-InfoCardStatus idcheckoffcan">
                {/*---- status Badge -----*/}
                <span className="badge w-auto">
                  VODAPAY PRO WALLET
                </span>
                {/*----- Info Card Body -----*/}
                <div className="card-body pt-4">
                  <div className="row mx-0 mt-2 align-items-center">
                    {/*-- ID Details --*/}
                    <div className="col-12">
                      {/*----- Info title -----*/}
                      {/* <small className="vs-card-title text-muted mb-3 d-block">
                    ID number / Passport number
                  </small> */}

                      <ul className='vs-uplodfield list-unstyled pt-5 rounded-4 pb-0 mt-5'>
                        <li className="border-0 py-0">
                          <div className="form-floating mb-4">
                            <input type="number" className="form-control" id="vodaID12" placeholder=" " name="name" autoComplete="off"
                              {...register("idNumber", {
                                required: "This input is invalid",
                              })}
                              onFocus={() => handleFocus("keyboardFocus", "vodaID12", true)}
                              onBlur={() => handleFocus("keyboardFocus", "vodaID12", false)}
                            />
                            <label htmlFor="adf2" className="h-auto text-secondary">
                              ID number / Passport number
                            </label>
                            <div className="m-0 f-error ">{errors.idNumber && errors.idNumber.message}</div>
                          </div>

                        </li>
                      </ul>

                      {/* <p className="fs-4 text-white">{accessToken}</p> */}
                      {/* <input type='text' className='form-control proIdInput' /> */}
                    </div>
                  </div>
                </div>
              </div>

              {/*----- Policy Type List Container start -----*/}
              <ul className="list-unstyled">
                <li className>
                  <div className>
                    <button type='submit' className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-100">
                      Submit
                    </button>
                  </div>
                </li>
              </ul>
              {/*----- Policy Type Container End -----*/}
            </form>
          </Offcanvas>
          {/*  */}
          {/* Quotation canvas */}
          <QuotationCanvas allPrice={allPrice} quotationCanvasClose={quotationCanvasClose} quotationCanvas={quotationCanvas} myAssets={myAssets} assetsPrice={assetsPrice} handleSelectAssets={handleSelectAssets} handleAcceptQoute={handleAcceptQoute} selectedIds={selectedIds} selectedID={selectedID} inventryValue={inventryValue} formattedString={formattedString} inventryValueId={inventryValueId} />

          <Modal show={show} onHide={handleClose} centered>
            <div className="modal-body text-center p-5">
              <img src="./assets/images/icon/Yellow_Bulb.svg" alt className="my-4" />
              <h2 className='text-white mb-5'>
                Please note
              </h2>

              <p className="fs-2 mb-1">
                Any damage that occurred before
                insuring the item will not be covered.
                Any pre-existing screen cracks/damage will result
                in screen damage cover
                not taking effect.
              </p>

              <Link to="/personal-details" state={{ quoteLineItems: quoteLineItems, quoteId: quoteId, inventryValue: inventryValue, selectedAssetsList: selectedAssetsList, selectedIds: selectedIds, underwritingQuestions: underwritingQuestions, campaignSchema: campaignSchema }} onClick={() => setShow(false)} className="d-block my-5">
                Accept &amp; continue
                <img src="./assets/images/icon/GorightIcon.svg" className="ms-1" alt />
              </Link>
            </div>
          </Modal>
        </>
      }
    </>
  )
}

export default UnderWritingSteps