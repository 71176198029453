import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GetDataWithToken, PostDataWithToken, PostImageDataWithToken } from '../../ApiHelper/ApiHelper';
import Loader from '../../common/Loader';
import { toast } from 'material-react-toastify';
import exifr from 'exifr'

const AddAsset = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const location = useLocation();
  const navigate = useNavigate();
  const assetType = location?.state?.assetType;
  const assetsImage = location?.state?.assetsImage;
  const selectObject = location?.state?.selectObject;
  const base64Image = location?.state?.base64Image;
  // console.log("selectObject", selectObject);
  // console.log("assetsImage", assetsImage);
  const [loader, setLoader] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [selectCategory, setSelectCategory] = useState('');
  const [searchTextLength, setSearchTextLength] = useState('');
  const [showCategory, setShowCategory] = useState(true);
  const [manually, setManually] = useState(false);
  const [showAsset, setShowAsset] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [showAlphaErr, setShowAlphaErr] = useState(false);
  const [addAsset, setAddAsset] = useState(false);
  const [brand, setBrand] = useState('');
  const [product, setProduct] = useState('');
  const [price, setPrice] = useState(null);
  const [catId, setCatId] = useState(null);
  const [assetCat, setAssetCat] = useState(null);
  const [assetListId, setAssetListId] = useState(null);
  const [anotherAsset, setAnotherAsset] = useState(false);
  const [catalogId, setCatalogId] = useState(0);
  const [catalogName, setCatalogName] = useState(null);
  const [policyType, setPolicyType] = useState(null);
  const [assetDecription, setAssetDecription] = useState(null);
  const [types, setTypes] = useState({
    typeId: null,
    type: null,
  });
  // console.log("types?.type", types?.typeId);
  // console.log("selectCategory", selectCategory);
  const handleSelectCat = (cat) => {
    console.log("cat----", cat);
    // const searchInputElement = document.getElementById("adf150");
    // searchInputElement?.focus();
    if (cat == "Mobile Phone") {
      setTypes({
        typeId: 1,
        type: "Mobile Phone"
      })
    } else if (cat == "Unknown") {
      setTypes({
        typeId: 3,
        type: "Unknown"
      })
    } else {
      setTypes({
        typeId: 2,
        type: cat ? cat : "Electronic Device"
      })
    }
    setSelectCategory(cat);
    setShowCategory(false);
  };

  useEffect(() => {
    reset({
      deviceAge: 1
    });
  }, []);

  const handleSearch = async (text) => {
    // console.log("text", text);

    setSearchTextLength(text);
    if (text == '') {
      setAssetList([])
    }
    setShowAsset(false);
    // let assetDataFormData = new FormData();
    // assetDataFormData.append('query', text);
    // let priceData = {
    //   query: text,
    // }
    // console.log("priceData", priceData);

    if (text.length > 3) {
      GetDataWithToken(`asset/price-api/${text}`, '').then((response) => {
        response = JSON.parse(response);
        console.log("ressss", typeof (response));

        if (response.status == true) {
          if (response.data.results.length > 0) {
            setAssetList(response.data.results);
            // setDisabled(true);
          } else {
            // setDisabled(false);
          }
        }
      })
    }
  };

  const selectedAssets = (data) => {
    console.log("data", data);
    setTypes({
      typeId: data.type_id,
      type: data.type
    });
    if (data?.type_id == 2) {
      setSelectCategory("Electronic Device");
    } else if (data?.type_id == 1) {
      setSelectCategory("Mobile Phone");
    } else if (data?.type_id == 0 || data?.type_id == 3) {
      setSelectCategory("Unknown");
    }
    // if (selectCategory == "Mobile Phone") {
    //   const brandDivElement = document.getElementById("assetData");
    //   brandDivElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //   setTimeout(() => {
    //     const imeiInputElement = document.getElementById("adf301");
    //     imeiInputElement?.focus();
    //   }, 2000);

    // } else if (selectCategory == "Unknown") {
    //   const brandDivElement = document.getElementById("assetData");
    //   brandDivElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // } else {
    //   const brandDivElement = document.getElementById("assetData");
    //   brandDivElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //   // searialNumberInputElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //   setTimeout(() => {
    //     const searialNumberInputElement = document.getElementById("adf333");
    //     searialNumberInputElement?.focus();
    //   }, 2000);
    // }
    setShowAsset(true);
    setAnotherAsset(true);

    reset({
      brand: data.brand,
      name: data.product,
      cost: data.price?.toLocaleString()?.replaceAll(",", " ")
    });
    setPolicyType(data?.policy_type)
    setCatalogId(data.catalog_id);
    setCatalogName(data?.catalog);
    setAssetDecription(data?.description);
    setCatId(data?.category_id);
    setAssetListId(data?.id)
    setBrand(data.brand);
    setProduct(data.product);
    setAssetCat(data.category);
    setPrice(data.price?.toLocaleString()?.replaceAll(",", " "));
  };

  const formatNumber = (value) => {
    if (selectCategory == "Unknown") {
      handleSetManuallVal();
    }
    let val = value.replace(/\s/g, "");
    if (val > 999999) {
      setShowErr(true)
    } else {
      setShowErr(false)
    }
    const numericValue = value.replace(/ /g, '').replaceAll(',', '');

    if (isNaN(numericValue)) {
      // console.log('Invalid input! Only numeric values are allowed.');
      setShowAlphaErr(true);
    } else {
      setShowAlphaErr(false);
      const formattedValue = Number(numericValue).toLocaleString();
      // console.log('Formatted value:', formattedValue);
      reset({ cost: formattedValue.replaceAll(',', ' ').toLocaleString() })
    }
  };

  const handleAddManuall = () => {
    // const brandDivElement = document.getElementById("assetData");
    // brandDivElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // setTimeout(() => {
    //   const brandInputElement = document.getElementById("adf1");
    //   brandInputElement?.focus();
    // }, 2000);
    setManually(true);
    setShowAsset(true);
  };

  const handleSetManuallVal = () => {
    let brand = watch('brand');
    let product = watch('name');
    let price = watch('cost');
    // console.log("price==========", price);
    setBrand(brand);
    setProduct(product);
    setPrice(price?.toLocaleString()?.replaceAll(",", " "));
    if (brand != null && product != null && price != null) {
      setAnotherAsset(true);
    }
  }

  const handleAddAssets = (data) => {
    // console.log("data", data);

    // const addAssets = new FormData();
    // addAssets.append("make", brand);
    // addAssets.append("model", product);
    // addAssets.append("asset_image", assetsImage);
    // addAssets.append("cost", data?.cost?.replace(/\s/g, ""));
    // addAssets.append("category", assetCat);
    // addAssets.append("categoryId", catId ? catId : 0);
    // addAssets.append("assetListId", assetListId ? assetListId : 0);
    // addAssets.append("deviceAge", Number(data?.deviceAge));
    // addAssets.append("catalog_id", catalogId);
    // addAssets.append("catalog", catalogName);
    // addAssets.append("description", assetDecription);
    // addAssets.append("type", types?.type);
    // addAssets.append("type_id", types?.typeId);
    // addAssets.append("policy_type", policyType);

    // if (selectCategory == "Mobile Phone") {
    //   addAssets.append("imeiNumber1", data?.imeiNumber1);
    //   addAssets.append("imeiNumber2", data?.imeiNumber2);
    // } else if (selectCategory == "Unknown") {
    //   // addAssets.append("serialNumber", `REF${assetListId}`);
    //   addAssets.append("serialNumber", data?.serialNumber);
    // } else {
    //   addAssets.append("serialNumber", data?.serialNumber);
    // }

    const requestBody = {
      make: brand,
      model: product,
      asset_image: base64Image,
      cost: data?.cost?.replace(/\s/g, ""),
      category: assetCat,
      categoryId: catId ? catId : 0,
      assetListId: assetListId ? assetListId : 0,
      deviceAge: Number(data?.deviceAge),
      catalog_id: catalogId,
      catalog: catalogName,
      description: assetDecription,
      type: types?.type,
      type_id: types?.typeId,
      policy_type: policyType,
    };

    if (selectCategory === "Mobile Phone") {
      requestBody.imeiNumber1 = data?.imeiNumber1;
      requestBody.imeiNumber2 = data?.imeiNumber2;
    } else if (selectCategory === "Unknown") {
      // requestBody.serialNumber = `REF${assetListId}`;
      requestBody.serialNumber = data?.serialNumber;
    } else {
      requestBody.serialNumber = data?.serialNumber;
    }

    // console.log("requestBody", requestBody)

    setLoader(true);
    PostDataWithToken('asset/add-single-asset', requestBody)
      .then((response) => {
        if (response.status == true) {
          setAddAsset(true);
          setLoader(false);
          // setTimeout(() => {
          //   const brandDivElement = document.getElementById("assetData");
          //   brandDivElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          // }, 500);
          setAnotherAsset(false);
          toast(
            <>
              <p className="m-0">
                You've just added a new asset! To view and edit new and existing items go to 'My Assets'
              </p>
              <p className='mb-0 dismisstext'>
                Dismiss
              </p>
            </>, {
            position: 'bottom-center',
            closeButton: false,
          });
        } else {
          setLoader(false);
          // console.log("response.message", response)
          toast(
            <>
              <p className="m-0">
                {response.data.message}
              </p>
            </>
          );
        }
      })
  };

  const AddAssets = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      const latLng = await exifr.gps(files[0]);
      // console.log({ latLng });
      if (latLng == undefined) {
        toast(
          <>
            <div className="activityalert">
              <div className="alert alert-dismissible fade show m-0 px-0 text-center">
                <p className="m-0">
                  <img src="./assets/Newimg/redclose.png" className="bluecheckimg" alt />
                  This image does not contain latitude and longitude
                </p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
            </div>
          </>
        );
      } else {
        navigate('/check-asset',
          {
            state: {
              imgPreview: URL.createObjectURL(e.target.files[0]),
              img: e.target.files[0],
              assetLatLngAdd: {
                lat: latLng?.latitude,
                lng: latLng?.longitude,
              }
            }
          });
      }
    }
  };

  const ulRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        setShowCategory(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFocus = (id, inputId, isFocus) => {
    const inputElement = document.getElementById(id);
    const divElement = document.getElementById(inputId);
    if (inputElement) {
      if (isFocus) {
        setTimeout(() => {
          inputElement.classList.add("focuskeyboard");
          divElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
      } else {
        inputElement.classList.remove("focuskeyboard");
      }
    }
  };

  return (
    <>
      {loader ?
        <Loader />
        :
        <>
          {/*----- Main Container For Dashboard Start -----*/}
          <div className="vs-main-container">
            <div className="responsive-row">
              <form className="vs-main-contentContainer vs-addassetform position-relative" onSubmit={handleSubmit(handleAddAssets)}>

                {/*----- Vs Header Start -----*/}
                <div className="vs-header Withbtngroup">
                  {/*------ Header Mid Heading (Left Part) ------*/}
                  <a href="javascript:void(0)" onClick={() => navigate(-1)} className="headericon">
                    <img src="./assets/images/icon/BackIcon.svg" />
                  </a>

                  {/*------ Header Mid Heading (Middle Part) ------*/}
                  <h4 className="vs-header-title text-center d-none">
                    vodasure | asset cover
                  </h4>

                  {/*------ Header Mid Heading (Right Part) ------*/}
                  <div className="btn-group vs-header-btn align-items-center px-1 py-1 rounded-pill d-none">
                    <a href="#" className="btn border-0 me-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerdotted.png"} alt />
                    </a>

                    <a href="#" className="btn border-0 ms-2">
                      <img src={process.env.PUBLIC_URL + "/assets/Newimg/headerroundclose.png"} alt />
                    </a>
                  </div>
                </div>
                {/*----- Vs Header end -----*/}

                {/*----- Vodasure dashboard container Start -----*/}
                <section className="vs-content_section" id='keyboardFocus'>
                  <p className="text-secondary mb-4 fs-4 text-center">
                    Please confirm the details of this asset
                  </p>
                  {brand != null && product != null && price != null && (
                    <div className='d-flex align-items-start' id="assetData">
                      <div className='mt-4' style={{ width: "10%" }}>
                        {/* <img src="./assets/images/icon/camera1.svg" className='me-4' /> */}
                        <img src="./assets/images/icon/camera1.svg" className='me-4' />
                      </div>

                      <div className='ms-3' style={{ width: "40%" }}>
                        <h4 className="text-white mb-2 d-inline-block">
                          {product}
                        </h4>
                        <br />
                        <p className="fs-4 text-muted d-inline-block">
                          R {price}
                        </p>
                      </div>

                      {/* {disabled == false && icons == false ?
                        <button className="fs-5 bg-primary inventorybutton btn border-0 text-center py-2 px-4 rounded-3 fs-4 mt-4 ms-auto text-white" type="submit" disabled>
                          Add to inventory
                        </button>
                        :
                        icons == true && disabled == true ?
                          <button className="fs-5 bg-primary inventorybutton btn border-0 text-center py-2 px-4 mt-4 fs-4 rounded-3 ms-auto text-white" type="submit" disabled>
                            Added to inventory
                          </button>
                          :
                          <button className="fs-5 bg-primary inventorybutton btn border-0 text-center py-2 px-4  mt-4 fs-4 rounded-3 ms-auto text-white" type="submit">
                            Add to inventory
                          </button>
                      } */}
                      {addAsset == true &&
                        <a href='javascript:void(0)' className="fs-5 bg-primary inventorybutton btn border-0 text-center py-2 px-4 mt-4 fs-4 rounded-3 ms-auto text-white">
                          <img src="./assets/images/assetaddedcheck.png" className='me-4 admyasseticon' />
                          Asset added
                        </a>
                      }
                    </div>
                  )}
                  <div className="position-relative">
                    <ul className="vs-uplodfield  list-unstyled pt-5 pb-0 mt-5 rounded-4 mb-0" ref={ulRef}>
                      <li className="border-0 pb-0 position-relative">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating mb-4" onClick={() => setShowCategory(true)}>
                          {/*--- Input ---*/}
                          <p className="form-control">
                            {selectCategory}
                          </p>
                          {/* <input type="text" className="form-control"  id="adf15" value={selectCategory} onClick={() => setShowCategory(true)} /> */}
                          {/*--- Label ---*/}
                          <label htmlFor="adf15" className="h-auto opacity-100 fw-bold" style={{ color: '#ffffff', }}>
                            Category
                          </label>
                        </div>
                        {showCategory && (
                          <>
                            <div className="searchaddresslisting rounded-4">
                              {/* {console.log("selectObject----", selectObject)} */}
                              <ul className="list-unstyled">
                                {selectObject && selectObject?.length > 0 && selectObject?.filter((brand) => brand?.name != "Mobile phone")?.filter((item, index, self) =>
                                  index === self.findIndex(obj => obj.name === item.name))?.map((asset, index) => (
                                    <li key={index} className='border-0 py-0'>
                                      <a href="javascript:void(0)" className="w-100 text-truncate" title={asset?.name} onClick={() => handleSelectCat(asset?.name)}>
                                        {asset?.name}
                                      </a>
                                    </li>
                                  ))}
                                <li className='border-0 py-0'>
                                  <a href="javascript:void(0)" className="w-100 text-truncate" title="Mobile Phone" value="Mobile Phone" onClick={() => handleSelectCat("Mobile Phone")}>
                                    Mobile Phone
                                  </a>
                                </li>
                                <li className='border-0 py-0'>
                                  <a href="javascript:void(0)" className="w-100 text-truncate" title="Electronic Device" onClick={() => handleSelectCat("Electronic Device")}>
                                    Electronic Device
                                  </a>
                                </li>
                                <li className='border-0 py-0'>
                                  <a href="javascript:void(0)" className="w-100 text-truncate" title="Unknown" onClick={() => handleSelectCat("Unknown")}>
                                    Unknown
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </li>
                      <li className="border-0 pb-0 position-relative">
                        {/*----- Floating Label input -----*/}
                        <div className="form-floating mb-4">
                          {/*--- Input ---*/}
                          <input type="text" className="form-control" id="adf150"
                            autoComplete="off" defaultValue={selectCategory === "Mobile Phone" ? "Phone" : selectCategory === "Electronic Device" || selectCategory === "Unknown" ? "" : selectCategory}
                            {...register("search", {
                              onChange: (e) => handleSearch(e?.target?.value),
                              maxLength: {
                                value: 50,
                                message: 'Asset must be is 50 characters.'
                              },
                            })}
                          />
                          {/*--- Label ---*/}
                          <label htmlFor="adf150" className="h-auto opacity-100 fw-bold" style={{ color: '#ffffff', }}>
                            Search: {assetType}
                          </label>

                          <img src="./assets/images/icon/InfoUtilityIcon.svg" className="inputsoicon" alt />
                        </div>

                        {showAsset == false && (
                          <>
                            {searchTextLength?.length > 3 && (
                              <div className="searchaddresslisting rounded-4">
                                <ul className="list-unstyled">
                                  <li className='border-0 py-0'>
                                    <a href="javascript:void(0)" className="w-100 text-truncate"
                                      onClick={() => handleAddManuall()}
                                    >
                                      Add Manually
                                    </a>
                                  </li>
                                  {assetList && assetList?.length > 0 && assetList?.filter((brand) => brand?.brand != "Unbranded")?.map((asset, index) => (
                                    <li key={index} className='border-0 py-0'>
                                      <a href="javascript:void(0)" className="w-100 text-truncate" title={asset?.product} onClick={() => { selectedAssets(asset); setManually(false); }}>
                                        {asset?.product}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                      </li>
                    </ul>
                  </div>

                  <ul className="vs-uplodfield  list-unstyled pt-5 rounded-4 pb-0">
                    <li className="border-0 py-0">
                      <div className="form-floating mb-4">
                        {/*--- Input ---*/}

                        <input type="text" className="form-control" id="adf1" placeholder=" " {...register("brand")} defaultValue={brand} autoComplete="off" disabled={manually == false} />
                        {/*--- Label ---*/}
                        <label htmlFor="adf1" className="h-auto text-secondary">
                          Make
                        </label>
                        {/*--- Error Message ---*/}
                        {/* <div className="m-0 f-error ">This input is invalid</div> */}
                      </div>
                    </li>
                    <li className="border-0 py-0">
                      {/*----- Floating Label input -----*/}
                      <div className="form-floating mb-4">
                        {/*--- Input ---*/}
                        <input type="text" className="form-control" id="adf2" placeholder=" " {...register("name")} defaultValue={product} autoComplete="off" disabled={manually == false} />
                        {/*--- Label ---*/}
                        <label htmlFor="adf2" className="h-auto text-secondary">Model</label>
                        {/*--- Error Message ---*/}
                        {/* <div className="m-0 f-error ">This input is invalid</div> */}
                      </div>
                    </li>
                    <li className="border-0 py-0">
                      {/*----- Floating Label input -----*/}
                      <div className="form-floating mb-4">
                        {/*--- Input ---*/}
                        <input type="text" className="form-control inputforrandomR" id="adf11" autoFocus={false} placeholder=" " autoComplete="off" {...register("cost", {
                          onChange: (e) => formatNumber(e.target.value),
                          // onKeyDown: (e) => handleKeyDown(e),
                          min: {
                            value: 500,
                            message: 'Minimum amount is R 500.'
                          },
                          // onChange: () => { handleSetManuallVal() },
                        })}
                          onFocus={() => handleFocus("keyboardFocus", "adf11", true)}
                          onBlur={() => handleFocus("keyboardFocus", "adf11", false)}
                        />
                        {/*--- Label ---*/}
                        <label htmlFor="adf11" className="h-auto text-secondary">
                          Asset cost
                        </label>
                        {/*--- Error Message ---*/}
                        <div className="m-0 f-error ">{errors.price && errors.price.message}</div>
                        {showErr && <div className="m-0 f-error ">Maximum amount is R 999999.</div>}
                        {showAlphaErr && <div className="m-0 f-error ">Invalid input!</div>}
                        <p className='randomR'>R</p>
                      </div>
                    </li>
                    {selectCategory == "Mobile Phone" || types?.typeId == 1 ?
                      <>
                        <li className="border-0 py-0">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating mb-4">
                            {/*--- Input ---*/}
                            <input type="text" className="form-control" id="adf301" placeholder=" " autoComplete="off"
                              {...register("imeiNumber1", {
                                required: "This input is invalid",
                                maxLength: {
                                  value: 25,
                                  message: 'IMEI number must be is 25 characters.'
                                },
                                onChange: () => { handleSetManuallVal() },
                              })}
                              onFocus={() => handleFocus("keyboardFocus", "adf301", true)}
                              onBlur={() => handleFocus("keyboardFocus", "adf301", false)}
                            />
                            {/*--- Label ---*/}
                            <label htmlFor="adf301" className="h-auto text-secondary">
                              IMEI number 1
                            </label>
                            {/*--- Error Message ---*/}
                            <img src="./assets/images/icon/InfoUtilityIcon.svg" className="inputsoicon" alt />
                            <div className="m-0 f-error ">{errors.imeiNumber1 && errors.imeiNumber1.message}</div>
                          </div>
                        </li>
                        <li className="border-0 py-0">
                          {/*----- Floating Label input -----*/}
                          <div className="form-floating mb-4">
                            {/*--- Input ---*/}
                            <input type="text" className="form-control" id="adf3" placeholder=" " autoFocus={false} autoComplete="off"
                              {...register("imeiNumber2", {
                                maxLength: {
                                  value: 25,
                                  message: 'IMEI number must be is 25 characters.'
                                },
                              })}
                              onFocus={() => handleFocus("keyboardFocus", "adf3", true)}
                              onBlur={() => handleFocus("keyboardFocus", "adf3", false)}
                            />
                            {/*--- Label ---*/}
                            <label htmlFor="adf3" className="h-auto text-secondary">
                              IMEI number 2
                            </label>
                            {/*--- Error Message ---*/}
                            <img src="./assets/images/icon/InfoUtilityIcon.svg" className="inputsoicon" alt />
                          </div>
                        </li>
                      </>
                      :
                      (selectCategory == "Electronic Device" || types?.typeId == 2 ?
                        <>
                          <li className="border-0 py-0">
                            {/*----- Floating Label input -----*/}
                            <div className="form-floating mb-4">
                              {/*--- Input ---*/}
                              <input type="text" className="form-control" id="adf333" placeholder=" " autoComplete="off"
                                {...register("serialNumber", {
                                  required: "This input is invalid",
                                  maxLength: {
                                    value: 25,
                                    message: 'Serial number must be is 25 characters.'
                                  },
                                  onChange: () => { handleSetManuallVal() },
                                })}
                                onFocus={() => handleFocus("keyboardFocus", "adf333", true)}
                                onBlur={() => handleFocus("keyboardFocus", "adf333", false)}
                              />
                              {/*--- Label ---*/}
                              <label htmlFor="adf333" className="h-auto text-secondary">
                                Serial number
                              </label>
                              {/*--- Error Message ---*/}
                              <img src="./assets/images/icon/InfoUtilityIcon.svg" className="inputsoicon" alt />
                              <div className="m-0 f-error ">{errors.serialNumber && errors.serialNumber.message}</div>
                            </div>
                          </li>
                        </>
                        :
                        (selectCategory == "Unknown" || types?.typeId == 0 || types?.typeId == 3 ?
                          <>
                            <li className="border-0 py-0">
                              {/*----- Floating Label input -----*/}
                              <div className="form-floating mb-4">
                                {/*--- Input ---*/}
                                <input type="text" className="form-control" id="adf333" placeholder=" " autoComplete="off"
                                  {...register("serialNumber", {
                                    required: "This input is invalid",
                                    maxLength: {
                                      value: 25,
                                      message: 'Serial number must be is 25 characters.'
                                    },
                                    onChange: () => { handleSetManuallVal() },
                                  })}
                                  onFocus={() => handleFocus("keyboardFocus", "adf333", true)}
                                  onBlur={() => handleFocus("keyboardFocus", "adf333", false)}
                                />
                                {/*--- Label ---*/}
                                <label htmlFor="adf333" className="h-auto text-secondary">
                                  Serial number
                                </label>
                                {/*--- Error Message ---*/}
                                <img src="./assets/images/icon/InfoUtilityIcon.svg" className="inputsoicon" alt />
                                <div className="m-0 f-error ">{errors.serialNumber && errors.serialNumber.message}</div>
                              </div>
                            </li>
                          </>
                          :
                          <>
                            {null}
                          </>
                        )
                      )
                    }
                    <li className="border-0 py-0">
                      <div className="form-floating pb-0">
                        <select class="form-select py-0"
                          {...register("deviceAge", {
                            required: "This input is invalid",
                          })}
                        >
                          <option className='d-none'>Select Asset age</option>
                          <option value={1}>New</option>
                          <option value={2}>0-2 years</option>
                          <option value={3}>2+ years</option>
                        </select>

                        <label htmlFor="demo">Asset age</label>
                        <div className="m-0 f-error ">{errors.asset_age && errors.asset_age.message}</div>
                      </div>
                    </li>
                  </ul>

                  {/* {addAsset == true && (
                    <>
                      <input type='file' className='d-none' id="mynewid"
                        accept="image/*" capture="environment"
                        onChange={(e) => AddAssets(e)}
                      />
                      <label htmlFor='mynewid' className='d-block text-center'>
                        <p className="mastbtn mt-5 d-inline-block mb-0">
                          <img src="./assets/images/icon/AddIcon.svg" className="d-inline-block text-center border brder-white brder-1  rounded-circle p-2 me-3" alt />
                          Add another asset
                        </p>
                      </label>
                    </>
                  )} */}

                  {/* {assetCount > 0 ?
                      <Link to="/take-photo" className="mastbtn mt-5 mb-0 d-inline-block">
                        <img src="./assets/images/icon/AddIcon.svg" className="d-inline-block border text-center brder-white brder-1  rounded-circle p-2 me-3" alt />
                        Add another asset
                      </Link>
                      :
                      addAnotherAsset == true ?
                        <>
                          <input type='file' className='d-none' id="mynewid" onChange={(e) => AddAssets(e)} />
                          <label htmlFor='mynewid' className='d-block text-center'>
                            <p className="mastbtn mt-5 d-inline-block mb-0">
                              <img src="./assets/images/icon/AddIcon.svg" className="d-inline-block text-center border brder-white brder-1  rounded-circle p-2 me-3" alt />
                              Add another asset
                            </p>
                          </label>
                        </>
                        :
                        <>
                          {null}
                        </>
                    } */}
                </section>
                {/*----- Vodasure dashboard container End -----*/}
                <footer className="vs-floting-footer vs-footer-gradient">
                  {/*----- add new asset button -----*/}
                  {addAsset == true ?
                    <Link to="/my-assets" className="btn btn-outline-secondary rounded-pill px-5 py-3">
                      View assets
                    </Link>
                    :
                    <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-secondary rounded-pill px-5 py-3">
                      Cancel
                    </a>
                  }
                  {anotherAsset == true ?
                    <button type='sbumit' className="btn btn-primary rounded-pill px-5 py-3">
                      Add to inventory
                    </button>
                    :
                    addAsset == true ?
                      <>
                        <input type='file' id="assetIMG" accept="image/*" capture="environment" className='d-none' onChange={(e) => AddAssets(e)} />

                        <label className="btn btn-primary rounded-pill px-5 py-3 fs-2" type='button' id="AddressassetADD" htmlFor='assetIMG'>
                          Add another asset
                        </label>
                      </>
                      :
                      <a href="javascript:void(0)" className="btn btn-outline-primary disabled rounded-pill px-5 py-3">
                        Add to inventory
                      </a>
                  }
                </footer>
              </form>
            </div>
          </div>
          {/*----- Main Container For Dashboard End -----*/}
        </>
      }
    </>
  )
}

export default AddAsset