import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const ClaimDetails = () => {

  const navigate = useNavigate();

  return (
    <>
      {/*----- Main Container For Share your data Start -----*/}
      <div className="vs-main-container">
        <div className="vs-main-content vs-domicile-form position-relative">
          {/*----- Vs Default Header Start -----*/}
          <div className="vs-header px-3">
            <div className="row justify-content-between align-items-center mx-0">
              <div className="col-3 text-start">
                <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                  <img src="./assets/images/icon/BackIcon.svg" />
                </a>
              </div>

              <div className="col-6 text-center">
                <h4 className="vs-header-title">Lost Device</h4>
              </div>

              <div className="col-3 text-end pe-0" />
            </div>
          </div>
          {/*----- Vs Default Header End -----*/}

          {/*----- Section Random Intro Start -----*/}
          <div className="Section_random_intro text-center mt-4 mb-5">
            <h3 className="fs-2 mb-3  text-white">Incident details</h3>
            <p className="text-dark-gray">
              Please provide the following details
            </p>
          </div>
          {/*----- Section Random Intro End -----*/}

          {/*----- Vodasure Share your data Content container Start -----*/}
          <section className="vs-content_section px-4">
            {/*----- Add Share your data Form Start -----*/}
            <div id="regForm">
              <form action="#">
                <ul className="vs-sharedata list-unstyled pt-4 rounded-4">
                  <li className="border-0 pb-0">
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Full name" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">
                        Full name
                      </label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf1" placeholder="Cellphone number" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf1" className="h-auto text-dark-gray">
                        Cellphone number
                      </label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf2" placeholder="Alternatve number" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf2" className="h-auto text-dark-gray">
                        Alternatve number
                      </label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf3" placeholder="Date of Incident" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf3" className="h-auto text-dark-gray">
                        Date of Incident
                      </label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating mb-4">
                      {/*--- Input ---*/}
                      <input type="text" className="form-control" id="adf4" placeholder="Date reported" />
                      {/*--- Label ---*/}
                      <label htmlFor="adf4" className="h-auto text-dark-gray">
                        Date reported
                      </label>
                      {/*--- Error Message ---*/}
                      <div className="m-0 f-error ">This input is invalid</div>
                    </div>
                  </li>
                  <li className="border-0 py-0">
                    {/*----- Floating Label input -----*/}
                    <div className="form-floating pb-0">
                      {/*--- Input ---*/}
                      <textarea className="form-control" id="adf6" placeholder="Incident Details" style={{ height: '100px' }} defaultValue={""} />
                      {/*--- Label ---*/}
                      <label htmlFor="adf6" className="h-auto text-dark-gray">Incident Details</label>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
            {/*----- Add Share your data Form End -----*/}
          </section>
          {/*----- Vodasure Share your data Content container End -----*/}
          {/*----- Basic Footer Section Start -----*/}
          <footer className="vs-floting-footer vs-footer-gradient">
            <form action="#" className="d-flex btn-width mx-auto justify-content-between align-items-center slideform-form">
              <a href="javascript:void(0)" onClick={() => navigate(-1)} className="btn btn-outline-primary rounded-pill px-5 fs-3 py-3 w-50 me-3">
                Cancel
              </a>

              <Link to="/claim-submit" className="btn btn-primary rounded-pill px-5 fs-3 py-3 w-75 ms-3">
                Next
              </Link>
            </form>
          </footer>
          {/*----- Basic Footer Section End -----*/}
        </div>
      </div>
      {/*----- Main Container For Share your data End -----*/}
    </>
  )
}

export default ClaimDetails